import React, { useEffect, useState, useCallback } from "react";
import { S3Key } from "../../utils";
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { signOut } from "../../state/cuser/operations";

// redux
import { removeDeviceSubscription, subscribeDeviceToNotifications } from "../../state/cuser/operations";
import { toastActions } from "../../state/toast";
import { colorActions } from "../../state/color";
import { cuserActions, cuserOperations } from "../../state/cuser";

// components
import { PasswordInput, CuserDisplayHeader, CuserDisplayStatusCircle, CuserDisplayContent, ErrorMessage, SubmitPasswordButton, Toggle } from "./styled";
import { VerticallyCenteredRow, RadioGroup, RadioButton, FormFieldContainer, LoadingSpinner, FilterContainer } from "../_shared";
import { ReportItemContainer, ReportContentItem, TextWithIconContainer, IconContainer } from "../_shared/report-summary/styled";
import { MenuLinkContainer, LargeIconContainer, MenuItemText } from "../menu/styled";
import { notificationActions } from "../../state/notification";
import { isIOS } from "react-device-detect";
import { FieldTitle, Dropdown } from "../teams/styled";
import { CloseButton } from "../left-modal/styled";
import { Expand, ExpandItem, ShortTextInput } from "../update-report/styled";
import { cloneDeep } from "lodash";
import { clientInfoOperations } from "../../state/clientInfo";
import { SubmitButton } from "../add-report/styled";
import Select from "antd/lib/select";
import { teamsActions } from "../../state/teams";
import { SelectValue } from "antd/lib/select";
import { AdminUserSelfUpdate, Profile } from "../../types/client";
import { leftModalActions } from "../../state/left-modal";

const { Option } = Select;

const KeyWhiteIcon = S3Key + "key-white.png";
const KeyGreyIcon = S3Key + "key-grey.png";
const MailWhiteIcon = S3Key + "mail-white.png";
const MailGreyIcon = S3Key + "mail-grey.png";
const RoleGreyIcon = S3Key + "role-grey.png";
const RoleWhiteIcon = S3Key + "role-white.png";
const TeamsGreyIcon = S3Key + "teams-grey.png";
const TeamsWhiteIcon = S3Key + "teams-white.png";
const MedicWhiteIcon = S3Key + "medic-white.png";
const MedicGreyIcon = S3Key + "medic-grey.png";
const StarGreyIcon = S3Key + "star-grey.png";
const StarWhiteIcon = S3Key + "star-white.png";
const BillingGreyIcon = S3Key + "billing-light.svg";
const BillingWhiteIcon = S3Key + "billing-dark.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const cuser = useSelectFromRedux((state) => state.cuser);
    const colorMode = useSelectFromRedux((state) => state.color.mode);
    const colors = useSelectFromRedux((state) => state.color);
    const militaryMode = useSelectFromRedux((state) => state.cuser.militaryMode);
    // const sosButtonPulsing = useSelectFromRedux((state) => state.sosButton.SOSButtonPulsing);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const editMode = useSelectFromRedux((state) => state.cuser.editMode);
    const userProfile = cuser.userProfile;
    const [changingPassword, updateChangingPassword] = useState<Boolean>(false);
    const [newPasswordsUnequal, updateNewPasswordsUnequal] = useState<Boolean>(true);
    const [oldPassword, updateOldPassword] = useState<string>("");
    const [newPassword, updateNewPassword] = useState<string>("");
    const [newPassword2, updateNewPassword2] = useState<string>("");
    const [changeLoading, updateChangeLoading] = useState<boolean>(false);
    const primaryEmail = useSelectFromRedux((state) => state.cuser.clientProfile?.primary_email);
    // const showClientDevices = useSelectFromRedux((state) => state.clientInfo.showClientDevices);

    const userTeam = teams.find((team) => team.id === userProfile?.team_id);
    // FIXME unnecessary null checking, client profile should not be null able in a loaded app
    const clientProfile = cuser.clientProfile;
    let clientName = clientProfile?.name;
    if (clientName) {
        clientName = clientName.toUpperCase();
    }
    const dispatch = useDispatch();
    const dispatchPromise = useDispatchPromise();

    const logOut = () => {
        dispatch({ type: "RESET_APP" });
        dispatch(signOut());
        return <Redirect to="/login" />;
    };

    const submitChange = () => {
        if (newPassword === newPassword2) {
            updateNewPasswordsUnequal(false);
            updateChangeLoading(true);
            dispatchPromise(cuserOperations.changePassword(oldPassword, newPassword)).then((success) => {
                updateChangeLoading(false);
                if (success) {
                    dispatch(notificationActions.openPasswordChangeConfirmation());
                }
            });
        } else updateNewPasswordsUnequal(true);
    };

    const toggleChangingPassword = () => {
        updateChangingPassword(!changingPassword);
        updateNewPasswordsUnequal(false);
    };

    const [userProfileUpdate, setUserProfileUpdate] = useState<Profile>(cloneDeep(userProfile?.profile) ?? {});
    const [adminUserUpdate, setUserAdminUpdate] = useState<AdminUserSelfUpdate>({
        team_id: userProfile?.team_id ?? undefined,
        designation: userProfile?.designation ?? "None"
    });

    const submitUserProfileUpdate = () => {
        dispatchPromise(clientInfoOperations.updateProfile(userProfileUpdate)).then(() => {
            dispatch(notificationActions.toggleEditUserProfile(true));
            dispatch(cuserActions.updateEditMode(false));
        });
        if (userProfile?.role === 1) {
            submitModifyUser();
        }
    };

    /**
     * Push Notifications Setup
     **/
    const userDevices = useSelectFromRedux((state) => state.cuser.devices);
    const [pnLoading, updatePNLoading] = useState<boolean>(false);

    const determinePNSettings = useCallback(() => {
        const current_fingerprint = localStorage.getItem("fingerprint");
        if (!current_fingerprint) {
            console.error("Could not get device fingerprint.");
            dispatch(toastActions.openError("There's an issue recognizing this device. Please try again."));
            return false;
        }

        let pnEnabled = false;
        userDevices.forEach((device) => {
            if (device.device_fingerprint === current_fingerprint) {
                if (device.token) {
                    pnEnabled = true;
                }
            }
        });

        return pnEnabled;
    }, [dispatch, userDevices]);

    const [pushNotificationsChecked, updatePNToggle] = useState<boolean>(determinePNSettings());

    useEffect(() => {
        if (userDevices) {
            const PNSettings = determinePNSettings();
            if (PNSettings) {
                updatePNToggle(true);
            } else if (pushNotificationsChecked) {
                updatePNToggle(false);
            }
        }
    }, [determinePNSettings, pushNotificationsChecked, userDevices]);

    const submitModifyUser = () => {
        dispatch(clientInfoOperations.updateSelfAdmin(adminUserUpdate));
        dispatch(teamsActions.toggleModifyUserMode(false));
        if (adminUserUpdate.designation === "Medic") {
            dispatch(cuserActions.updateMilitaryMode(true));
        }
    };

    const didUpdateNotificationSetting = (value: Boolean) => {
        localStorage.setItem("autoApplyPN", "false"); // Once user interacts with PN settings stop trying to auto-apply
        if (value) {
            updatePNLoading(true);
            dispatchPromise(subscribeDeviceToNotifications())
                .then(() => {
                    window.analytics.track("Push Notifications Turned On");
                    updatePNLoading(false);
                })
                .catch((err) => {
                    console.log("Error updating push notification settings: " + err);
                    updatePNLoading(false);
                });
        } else {
            dispatch(removeDeviceSubscription());
            window.analytics.track("Push Notifications Turned Off");
        }
    };

    /**
     * END Push Notifications Setup
     **/

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0 25px",
                    width: "100%",
                    overflow: "scroll",
                    filter: editMode && isMobile ? "blur(4px)" : "none"
                }}
            >
                {changeLoading && (
                    <LoadingSpinner
                        background={colors.scheme.primaryText}
                        style={{
                            position: "absolute",
                            top: "22px",
                            alignSelf: "left"
                        }}
                    />
                )}
                {userProfile && (
                    <>
                        <VerticallyCenteredRow>
                            <CuserDisplayHeader color={colors.scheme.primaryText}>
                                {userProfile.first_name} {userProfile.last_name}
                            </CuserDisplayHeader>
                            {userProfile.email === primaryEmail && (
                                <div style={{ marginLeft: "8px" }}>
                                    <img src={colors.mode === "light" ? StarGreyIcon : StarWhiteIcon} alt="Star" style={{ height: "18px" }} />
                                </div>
                            )}
                            {userProfile.designation === "Medic" && (
                                <div style={{ marginLeft: "8px" }}>
                                    <img src={colors.mode === "light" ? MedicGreyIcon : MedicWhiteIcon} alt="Medic" style={{ height: "18px" }} />
                                </div>
                            )}
                            <CuserDisplayStatusCircle
                                background={colors.general.standardGreen}
                                // sosButtonPulsing ? colors.general.sosReportModal : colors.general.standardGreen
                            />
                        </VerticallyCenteredRow>
                        <CuserDisplayHeader color={colors.scheme.primaryText} style={{ fontSize: "14px", marginBottom: "5px" }}>
                            {clientName ?? "No Organization Name"}
                        </CuserDisplayHeader>
                        <div style={{ marginBottom: "20px" }}>
                            {userTeam?.name && (
                                <ReportContentItem style={{ cursor: "pointer" }}>
                                    <IconContainer>
                                        <img src={colors.mode === "light" ? TeamsGreyIcon : TeamsWhiteIcon} alt="Team" style={{ height: "12px" }} />
                                    </IconContainer>
                                    <TextWithIconContainer>{userTeam?.name}</TextWithIconContainer>
                                </ReportContentItem>
                            )}
                            {userProfile?.email && (
                                <ReportContentItem>
                                    <IconContainer>
                                        <img src={colors.mode === "light" ? MailGreyIcon : MailWhiteIcon} alt="Mail" style={{ height: "12px" }} />
                                    </IconContainer>
                                    <TextWithIconContainer>{userProfile?.email}</TextWithIconContainer>
                                </ReportContentItem>
                            )}
                            {userProfile?.role === 1 && (
                                <ReportContentItem style={{ cursor: "pointer" }}>
                                    <IconContainer>
                                        <img src={colors.mode === "light" ? RoleGreyIcon : RoleWhiteIcon} alt="Team" style={{ height: "12px" }} />
                                    </IconContainer>
                                    <TextWithIconContainer>Admin</TextWithIconContainer>
                                </ReportContentItem>
                            )}
                        </div>
                        {/** Hide push notifications toggle in webview for iOS devices **/}
                        {!isIOS && (
                            <VerticallyCenteredRow style={{ width: "100%", justifyContent: "space-between", marginBottom: "15px" }}>
                                <CuserDisplayContent style={{ marginBottom: "0px" }} color={colors.scheme.primaryText}>
                                    Notifications
                                </CuserDisplayContent>
                                <Toggle
                                    checked={pushNotificationsChecked}
                                    onClick={(checked) => didUpdateNotificationSetting(checked)}
                                    background={colors.scheme.toggleBackground}
                                    loading={pnLoading}
                                />
                            </VerticallyCenteredRow>
                        )}
                        {/*{userProfile?.features?.tracking === true && (*/}
                        {/*    <VerticallyCenteredRow style={{ width: "100%", justifyContent: "space-between", marginBottom: "15px" }}>*/}
                        {/*        <CuserDisplayContent style={{ marginBottom: "0px" }} color={colors.scheme.primaryText}>*/}
                        {/*            Show Teammate Locations*/}
                        {/*        </CuserDisplayContent>*/}
                        {/*        <Toggle*/}
                        {/*            checked={showClientDevices}*/}
                        {/*            onClick={(checked) => dispatch(clientInfoActions.toggleShowClientDevices(checked))}*/}
                        {/*            background={colors.scheme.toggleBackground}*/}
                        {/*            loading={pnLoading}*/}
                        {/*        />*/}
                        {/*    </VerticallyCenteredRow>*/}
                        {/*)}*/}
                        {false && (
                            <VerticallyCenteredRow style={{ width: "100%", justifyContent: "space-between", marginBottom: "15px" }}>
                                <CuserDisplayContent style={{ marginBottom: "0px" }} color={colors.scheme.primaryText}>
                                    <div>SOS Quick Mode</div>
                                    {userProfile?.designation === "Medic" && <div style={{ color: "red" }}>(Locked for Medics)</div>}
                                </CuserDisplayContent>
                                <Toggle
                                    checked={militaryMode || userProfile?.designation === "Medic"}
                                    disabled={userProfile?.designation === "Medic"}
                                    onClick={(checked) => dispatch(cuserActions.updateMilitaryMode(checked))}
                                    background={colors.scheme.toggleBackground}
                                />
                            </VerticallyCenteredRow>
                        )}
                        <CuserDisplayContent color={colors.scheme.primaryText}>Color Mode</CuserDisplayContent>
                        <VerticallyCenteredRow style={{ width: "80%" }}>
                            <FormFieldContainer background={colors.scheme.selectorBackground}>
                                <RadioGroup
                                    defaultValue="dark"
                                    value={colorMode}
                                    buttonStyle="solid"
                                    style={{ width: "100%" }}
                                    textColor={colors.scheme.selectorLightText}
                                    selectedTextColor={colors.scheme.selectorDarkText}
                                    selectedColor={colors.scheme.selectedOptionBackground}
                                >
                                    <RadioButton value="light" onClick={() => dispatch(colorActions.changeColorScheme("light"))}>
                                        Light
                                    </RadioButton>
                                    <RadioButton value="dark" onClick={() => dispatch(colorActions.changeColorScheme("dark"))}>
                                        Dark
                                    </RadioButton>
                                </RadioGroup>
                            </FormFieldContainer>
                        </VerticallyCenteredRow>
                    </>
                )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                        marginTop: "0px"
                    }}
                >
                    <MenuLinkContainer
                        onClick={() =>
                            dispatch(
                                leftModalActions.setModalContent({
                                    modalContent: "billing"
                                })
                            )
                        }
                        style={{ marginBottom: "20px" }}
                    >
                        <LargeIconContainer>
                            <img src={colors.mode === "light" ? BillingGreyIcon : BillingWhiteIcon} alt="Billing" style={{ height: "20px" }} />
                        </LargeIconContainer>
                        <MenuItemText color={colors.scheme.primaryText} style={{ marginLeft: "15px" }}>
                            Billing
                        </MenuItemText>
                    </MenuLinkContainer>
                    <a href="https://app.websitepolicies.com/policies/view/xrr1s5do" target="_blank" rel="noopener noreferrer">
                        <MenuLinkContainer style={{ marginBottom: "20px" }}>
                            <LargeIconContainer>
                                <img
                                    src={
                                        colors.mode === "light"
                                            ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/Cuser/locked-dark.png"
                                            : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/Cuser/locked.png"
                                    }
                                    alt="Privacy Policy"
                                    style={{ height: "20px" }}
                                />
                            </LargeIconContainer>
                            <MenuItemText color={colors.scheme.primaryText} style={{ marginLeft: "15px" }}>
                                Privacy Policy
                            </MenuItemText>
                        </MenuLinkContainer>
                    </a>
                    <MenuLinkContainer onClick={() => toggleChangingPassword()} style={{ marginBottom: "5px" }}>
                        <LargeIconContainer>
                            <img src={colors.mode === "light" ? KeyGreyIcon : KeyWhiteIcon} alt="Change Password" style={{ height: "20px" }} />
                        </LargeIconContainer>
                        <MenuItemText color={colors.scheme.primaryText} style={{ marginLeft: "15px" }}>
                            Change Password
                        </MenuItemText>
                    </MenuLinkContainer>
                    {changingPassword && (
                        <div
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                padding: "0 25px"
                            }}
                        >
                            <PasswordInput
                                id="old password"
                                name="old password"
                                placeholder="Enter Current Password"
                                value={oldPassword}
                                onChange={(event) => updateOldPassword(event.target.value)}
                                style={{
                                    paddingLeft: "5%",
                                    marginBottom: "15px",
                                    marginLeft: "15px",
                                    minHeight: "33px"
                                }}
                                background={colors.scheme.generalCard}
                                color={colors.scheme.primaryText}
                            />
                            <PasswordInput
                                id="enter new password"
                                name="enter new password"
                                placeholder="Enter New Password"
                                value={newPassword}
                                onChange={(event) => updateNewPassword(event.target.value)}
                                style={{
                                    paddingLeft: "5%",
                                    marginBottom: "15px",
                                    marginLeft: "15px",
                                    minHeight: "33px"
                                }}
                                background={colors.scheme.generalCard}
                                color={colors.scheme.primaryText}
                            />
                            <PasswordInput
                                id="confirm new password"
                                name="confirm new password"
                                placeholder="Confirm New Password"
                                value={newPassword2}
                                onChange={(event) => updateNewPassword2(event.target.value)}
                                style={{
                                    paddingLeft: "5%",
                                    marginBottom: "15px",
                                    marginLeft: "15px",
                                    minHeight: "33px"
                                }}
                                background={colors.scheme.generalCard}
                                color={colors.scheme.primaryText}
                            />
                            <SubmitPasswordButton
                                type="submit"
                                value="Change Password"
                                onClick={() => submitChange()}
                                style={{
                                    margin: "3px 0 0 15px",
                                    textTransform: "none"
                                }}
                                background={colors.scheme.submitButtonBackground}
                                color={colors.scheme.submitButtonText}
                            />
                            {newPasswordsUnequal && <ErrorMessage>New passwords are not equal.</ErrorMessage>}
                        </div>
                    )}
                    <MenuLinkContainer onClick={() => logOut()} style={{ marginTop: "15px", marginBottom: "15px" }}>
                        <LargeIconContainer>
                            <img
                                src={
                                    colors.mode === "light"
                                        ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/Cuser/logout-dark.png"
                                        : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/Cuser/logout.png"
                                }
                                alt="Log Out"
                                style={{ height: "20px" }}
                            />
                        </LargeIconContainer>
                        <MenuItemText color={colors.scheme.primaryText} style={{ marginLeft: "15px" }}>
                            Log Out
                        </MenuItemText>
                    </MenuLinkContainer>
                </div>
            </div>
            {editMode && (
                <FilterContainer
                    boxshadow={colors.scheme.selectorBackground}
                    background={colors.scheme.modalBackground}
                    style={{ maxHeight: "600px", overflowY: "scroll" }}
                >
                    <CloseButton
                        src={
                            colors.mode === "light"
                                ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/close-dark.png"
                                : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/close-white.png"
                        }
                        alt="Exit"
                        onClick={() => dispatch(cuserActions.updateEditMode(false))}
                    />
                    <ReportItemContainer
                        style={{
                            marginBottom: "0px",
                            overflowY: "scroll",
                            maxHeight: "600px",
                            marginTop: "25px",
                            padding: "0px 15px"
                        }}
                        background={"none"}
                    >
                        <FieldTitle style={{ fontWeight: 600, marginBottom: "10px" }}>Update Profile</FieldTitle>
                        <div style={{ maxHeight: "430px", overflowY: "scroll" }}>
                            <Expand expandIconPosition="right" headerColor={colors.scheme.primaryText} color={colors.scheme.primaryText}>
                                {userProfile?.role === 1 && (
                                    <>
                                        <div style={{ margin: "10px 0" }}>
                                            <div
                                                style={{
                                                    margin: "5px 0",
                                                    textTransform: "uppercase",
                                                    color: colors.scheme.primaryText
                                                }}
                                            >
                                                Designation
                                            </div>
                                            <Dropdown
                                                value={adminUserUpdate.designation}
                                                dropdownMatchSelectWidth={false}
                                                showArrow={true}
                                                onChange={(e: SelectValue) =>
                                                    setUserAdminUpdate({
                                                        ...adminUserUpdate,
                                                        designation: e as string
                                                    })
                                                }
                                            >
                                                <Option value="Medic">Medic</Option>
                                                <Option value="None">None</Option>
                                            </Dropdown>
                                        </div>
                                        {userProfile.email !== primaryEmail && ((userTeam && userTeam?.permissions !== 1) || userProfile.team_id === null) && (
                                            <div style={{ margin: "10px 0" }}>
                                                <div
                                                    style={{
                                                        margin: "5px 0",
                                                        textTransform: "uppercase",
                                                        color: colors.scheme.primaryText
                                                    }}
                                                >
                                                    Team
                                                </div>
                                                <Dropdown
                                                    value={adminUserUpdate.team_id ?? 0}
                                                    dropdownMatchSelectWidth={false}
                                                    showArrow={true}
                                                    onChange={(e: SelectValue) =>
                                                        setUserAdminUpdate({
                                                            ...adminUserUpdate,
                                                            team_id: e === 0 ? null : (e as number)
                                                        })
                                                    }
                                                >
                                                    <Option value={0} key="default">
                                                        Default Group
                                                    </Option>
                                                    {teams.map((team) => (
                                                        <Option value={team.id} key={team.id}>
                                                            {team.name}
                                                        </Option>
                                                    ))}
                                                </Dropdown>
                                            </div>
                                        )}
                                    </>
                                )}
                                <ExpandItem header={"Profile"} key={1} style={{ marginLeft: "-10px" }}>
                                    <>
                                        <FieldTitle style={{ marginTop: "5px" }}>Nickname</FieldTitle>
                                        <ShortTextInput
                                            maxLength={30}
                                            onChange={(e) =>
                                                setUserProfileUpdate({
                                                    ...userProfileUpdate,
                                                    nickname: e.target.value
                                                })
                                            }
                                            value={(userProfileUpdate.nickname as string) || ""}
                                        />
                                    </>
                                    <>
                                        <FieldTitle style={{ marginTop: "5px" }}>phone (work)</FieldTitle>
                                        <ShortTextInput
                                            maxLength={30}
                                            onChange={(e) =>
                                                setUserProfileUpdate({
                                                    ...userProfileUpdate,
                                                    phone_work: e.target.value
                                                })
                                            }
                                            value={(userProfileUpdate.phone_work as string) || ""}
                                        />
                                    </>
                                    <>
                                        <FieldTitle style={{ marginTop: "5px" }}>phone (home)</FieldTitle>
                                        <ShortTextInput
                                            maxLength={30}
                                            onChange={(e) =>
                                                setUserProfileUpdate({
                                                    ...userProfileUpdate,
                                                    phone_home: e.target.value
                                                })
                                            }
                                            value={(userProfileUpdate.phone_home as string) || ""}
                                        />
                                    </>
                                    <>
                                        <FieldTitle style={{ marginTop: "5px" }}>phone (cell)</FieldTitle>
                                        <ShortTextInput
                                            maxLength={30}
                                            onChange={(e) =>
                                                setUserProfileUpdate({
                                                    ...userProfileUpdate,
                                                    phone_cell: e.target.value
                                                })
                                            }
                                            value={(userProfileUpdate.phone_cell as string) || ""}
                                        />
                                    </>
                                    <>
                                        <FieldTitle style={{ marginTop: "5px" }}>home address</FieldTitle>
                                        <ShortTextInput
                                            maxLength={30}
                                            onChange={(e) =>
                                                setUserProfileUpdate({
                                                    ...userProfileUpdate,
                                                    address: e.target.value
                                                })
                                            }
                                            value={(userProfileUpdate.address as string) || ""}
                                        />
                                    </>
                                </ExpandItem>
                                <ExpandItem header={"Emergency Contact 1"} key={2} style={{ marginLeft: "-10px" }}>
                                    <FieldTitle style={{ marginTop: "5px" }}>Name</FieldTitle>
                                    <ShortTextInput
                                        maxLength={30}
                                        onChange={(e) =>
                                            setUserProfileUpdate({
                                                ...userProfileUpdate,
                                                emergency_contact_1: {
                                                    ...userProfileUpdate.emergency_contact_1,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                        value={(userProfileUpdate.emergency_contact_1?.name as string) || ""}
                                    />
                                    <div>
                                        <>
                                            <FieldTitle style={{ marginTop: "5px" }}>emergency contact relationship</FieldTitle>
                                            <ShortTextInput
                                                maxLength={30}
                                                onChange={(e) =>
                                                    setUserProfileUpdate({
                                                        ...userProfileUpdate,
                                                        emergency_contact_1: {
                                                            ...userProfileUpdate.emergency_contact_1,
                                                            relationship: e.target.value
                                                        }
                                                    })
                                                }
                                                value={(userProfileUpdate.emergency_contact_1?.relationship as string) || ""}
                                            />
                                        </>
                                        <>
                                            <FieldTitle style={{ marginTop: "5px" }}>emergency phone (primary)</FieldTitle>
                                            <ShortTextInput
                                                maxLength={30}
                                                onChange={(e) =>
                                                    setUserProfileUpdate({
                                                        ...userProfileUpdate,
                                                        emergency_contact_1: {
                                                            ...userProfileUpdate.emergency_contact_1,
                                                            phone_primary: e.target.value
                                                        }
                                                    })
                                                }
                                                value={(userProfileUpdate.emergency_contact_1?.phone_primary as string) || ""}
                                            />
                                        </>
                                        <>
                                            <FieldTitle style={{ marginTop: "5px" }}>emergency phone (secondary)</FieldTitle>
                                            <ShortTextInput
                                                maxLength={30}
                                                onChange={(e) =>
                                                    setUserProfileUpdate({
                                                        ...userProfileUpdate,
                                                        emergency_contact_1: {
                                                            ...userProfileUpdate.emergency_contact_1,
                                                            phone_secondary: e.target.value
                                                        }
                                                    })
                                                }
                                                value={(userProfileUpdate.emergency_contact_1?.phone_secondary as string) || ""}
                                            />
                                        </>
                                        <>
                                            <FieldTitle style={{ marginTop: "5px" }}>Address</FieldTitle>
                                            <ShortTextInput
                                                maxLength={30}
                                                onChange={(e) =>
                                                    setUserProfileUpdate({
                                                        ...userProfileUpdate,
                                                        emergency_contact_1: {
                                                            ...userProfileUpdate.emergency_contact_1,
                                                            address: e.target.value
                                                        }
                                                    })
                                                }
                                                value={(userProfileUpdate.emergency_contact_1?.address as string) || ""}
                                            />
                                        </>
                                    </div>
                                </ExpandItem>
                                <ExpandItem header={"Emergency Contact 2"} key={3} style={{ marginLeft: "-10px" }}>
                                    <FieldTitle style={{ marginTop: "5px" }}>Name</FieldTitle>
                                    <ShortTextInput
                                        maxLength={30}
                                        onChange={(e) =>
                                            setUserProfileUpdate({
                                                ...userProfileUpdate,
                                                emergency_contact_2: {
                                                    ...userProfileUpdate.emergency_contact_2,
                                                    name: e.target.value
                                                }
                                            })
                                        }
                                        value={(userProfileUpdate.emergency_contact_2?.name as string) || ""}
                                    />
                                    <div>
                                        <>
                                            <FieldTitle style={{ marginTop: "5px" }}>emergency contact relationship</FieldTitle>
                                            <ShortTextInput
                                                maxLength={30}
                                                onChange={(e) =>
                                                    setUserProfileUpdate({
                                                        ...userProfileUpdate,
                                                        emergency_contact_2: {
                                                            ...userProfileUpdate.emergency_contact_2,
                                                            relationship: e.target.value
                                                        }
                                                    })
                                                }
                                                value={(userProfileUpdate.emergency_contact_2?.relationship as string) || ""}
                                            />
                                        </>
                                        <>
                                            <FieldTitle style={{ marginTop: "5px" }}>emergency phone (primary)</FieldTitle>
                                            <ShortTextInput
                                                maxLength={30}
                                                onChange={(e) =>
                                                    setUserProfileUpdate({
                                                        ...userProfileUpdate,
                                                        emergency_contact_2: {
                                                            ...userProfileUpdate.emergency_contact_2,
                                                            phone_primary: e.target.value
                                                        }
                                                    })
                                                }
                                                value={(userProfileUpdate.emergency_contact_2?.phone_primary as string) || ""}
                                            />
                                        </>
                                        <>
                                            <FieldTitle style={{ marginTop: "5px" }}>emergency phone (secondary)</FieldTitle>
                                            <ShortTextInput
                                                maxLength={30}
                                                onChange={(e) =>
                                                    setUserProfileUpdate({
                                                        ...userProfileUpdate,
                                                        emergency_contact_2: {
                                                            ...userProfileUpdate.emergency_contact_2,
                                                            phone_secondary: e.target.value
                                                        }
                                                    })
                                                }
                                                value={(userProfileUpdate.emergency_contact_2?.phone_secondary as string) || ""}
                                            />
                                        </>
                                        <>
                                            <FieldTitle style={{ marginTop: "5px" }}>Address</FieldTitle>
                                            <ShortTextInput
                                                maxLength={30}
                                                onChange={(e) =>
                                                    setUserProfileUpdate({
                                                        ...userProfileUpdate,
                                                        emergency_contact_2: {
                                                            ...userProfileUpdate.emergency_contact_2,
                                                            address: e.target.value
                                                        }
                                                    })
                                                }
                                                value={(userProfileUpdate.emergency_contact_2?.address as string) || ""}
                                            />
                                        </>
                                    </div>
                                </ExpandItem>
                                <ExpandItem header={"Medical"} key={4} style={{ marginLeft: "-10px" }}>
                                    <>
                                        <FieldTitle style={{ marginTop: "5px" }}>Date of Birth</FieldTitle>
                                        <ShortTextInput
                                            maxLength={30}
                                            onChange={(e) =>
                                                setUserProfileUpdate({
                                                    ...userProfileUpdate,
                                                    medical: {
                                                        ...userProfileUpdate.medical,
                                                        dob: e.target.value
                                                    }
                                                })
                                            }
                                            value={(userProfileUpdate.medical?.dob as string) || ""}
                                        />
                                    </>
                                    <>
                                        <FieldTitle style={{ marginTop: "5px" }}>Blood Type</FieldTitle>
                                        <ShortTextInput
                                            maxLength={30}
                                            onChange={(e) =>
                                                setUserProfileUpdate({
                                                    ...userProfileUpdate,
                                                    medical: {
                                                        ...userProfileUpdate.medical,
                                                        blood_type: e.target.value
                                                    }
                                                })
                                            }
                                            value={(userProfileUpdate.medical?.blood_type as string) || ""}
                                        />
                                    </>
                                    <>
                                        <FieldTitle style={{ marginTop: "5px" }}>Medical Conditions</FieldTitle>
                                        <ShortTextInput
                                            maxLength={30}
                                            onChange={(e) =>
                                                setUserProfileUpdate({
                                                    ...userProfileUpdate,
                                                    medical: {
                                                        ...userProfileUpdate.medical,
                                                        medical_conditions: e.target.value
                                                    }
                                                })
                                            }
                                            value={(userProfileUpdate.medical?.medical_conditions as string) || ""}
                                        />
                                    </>
                                    <>
                                        <FieldTitle style={{ marginTop: "5px" }}>Preferred Hospital</FieldTitle>
                                        <ShortTextInput
                                            maxLength={30}
                                            onChange={(e) =>
                                                setUserProfileUpdate({
                                                    ...userProfileUpdate,
                                                    medical: {
                                                        ...userProfileUpdate.medical,
                                                        preferred_hospital: e.target.value
                                                    }
                                                })
                                            }
                                            value={(userProfileUpdate.medical?.preferred_hospital as string) || ""}
                                        />
                                    </>
                                    <Expand expandIconPosition="right" headerColor={colors.scheme.primaryText} color={colors.scheme.primaryText}>
                                        <ExpandItem header={"Doctor 1"} key={1} style={{ margin: "-5px -15px" }}>
                                            {/* <FieldTitle style={{ marginTop: "5px" }}>Doctor 1</FieldTitle> */}
                                            <div style={{ marginLeft: "10px" }}>
                                                <>
                                                    <FieldTitle style={{ marginTop: "5px" }}>Name</FieldTitle>
                                                    <ShortTextInput
                                                        maxLength={30}
                                                        onChange={(e) =>
                                                            setUserProfileUpdate({
                                                                ...userProfileUpdate,
                                                                medical: {
                                                                    ...userProfileUpdate.medical,
                                                                    doctor_1: {
                                                                        ...userProfileUpdate.medical?.doctor_1,
                                                                        name: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        value={(userProfileUpdate.medical?.doctor_1?.name as string) || ""}
                                                    />
                                                </>
                                                <>
                                                    <FieldTitle style={{ marginTop: "5px" }}>Office</FieldTitle>
                                                    <ShortTextInput
                                                        maxLength={30}
                                                        onChange={(e) =>
                                                            setUserProfileUpdate({
                                                                ...userProfileUpdate,
                                                                medical: {
                                                                    ...userProfileUpdate.medical,
                                                                    doctor_1: {
                                                                        ...userProfileUpdate.medical?.doctor_1,
                                                                        office: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        value={(userProfileUpdate.medical?.doctor_1?.office as string) || ""}
                                                    />
                                                </>
                                                <>
                                                    <FieldTitle style={{ marginTop: "5px" }}>Phone</FieldTitle>
                                                    <ShortTextInput
                                                        maxLength={30}
                                                        onChange={(e) =>
                                                            setUserProfileUpdate({
                                                                ...userProfileUpdate,
                                                                medical: {
                                                                    ...userProfileUpdate.medical,
                                                                    doctor_1: {
                                                                        ...userProfileUpdate.medical?.doctor_1,
                                                                        phone: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        value={(userProfileUpdate.medical?.doctor_1?.phone as string) || ""}
                                                    />
                                                </>
                                                <>
                                                    <FieldTitle style={{ marginTop: "5px" }}>Address</FieldTitle>
                                                    <ShortTextInput
                                                        maxLength={30}
                                                        onChange={(e) =>
                                                            setUserProfileUpdate({
                                                                ...userProfileUpdate,
                                                                medical: {
                                                                    ...userProfileUpdate.medical,
                                                                    doctor_1: {
                                                                        ...userProfileUpdate.medical?.doctor_1,
                                                                        address: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        value={(userProfileUpdate.medical?.doctor_1?.address as string) || ""}
                                                    />
                                                </>
                                            </div>
                                        </ExpandItem>
                                        <ExpandItem header={"Doctor 2"} key={2} style={{ margin: "-5px -15px" }}>
                                            <div style={{ marginLeft: "10px" }}>
                                                <>
                                                    <FieldTitle style={{ marginTop: "5px" }}>Name</FieldTitle>
                                                    <ShortTextInput
                                                        maxLength={30}
                                                        onChange={(e) =>
                                                            setUserProfileUpdate({
                                                                ...userProfileUpdate,
                                                                medical: {
                                                                    ...userProfileUpdate.medical,
                                                                    doctor_2: {
                                                                        ...userProfileUpdate.medical?.doctor_2,
                                                                        name: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        value={(userProfileUpdate.medical?.doctor_2?.name as string) || ""}
                                                    />
                                                </>
                                                <>
                                                    <FieldTitle style={{ marginTop: "5px" }}>Office</FieldTitle>
                                                    <ShortTextInput
                                                        maxLength={30}
                                                        onChange={(e) =>
                                                            setUserProfileUpdate({
                                                                ...userProfileUpdate,
                                                                medical: {
                                                                    ...userProfileUpdate.medical,
                                                                    doctor_2: {
                                                                        ...userProfileUpdate.medical?.doctor_2,
                                                                        office: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        value={(userProfileUpdate.medical?.doctor_2?.office as string) || ""}
                                                    />
                                                </>
                                                <>
                                                    <FieldTitle style={{ marginTop: "5px" }}>Phone</FieldTitle>
                                                    <ShortTextInput
                                                        maxLength={30}
                                                        onChange={(e) =>
                                                            setUserProfileUpdate({
                                                                ...userProfileUpdate,
                                                                medical: {
                                                                    ...userProfileUpdate.medical,
                                                                    doctor_2: {
                                                                        ...userProfileUpdate.medical?.doctor_2,
                                                                        phone: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        value={(userProfileUpdate.medical?.doctor_2?.phone as string) || ""}
                                                    />
                                                </>
                                                <>
                                                    <FieldTitle style={{ marginTop: "5px" }}>Address</FieldTitle>
                                                    <ShortTextInput
                                                        maxLength={30}
                                                        onChange={(e) =>
                                                            setUserProfileUpdate({
                                                                ...userProfileUpdate,
                                                                medical: {
                                                                    ...userProfileUpdate.medical,
                                                                    doctor_2: {
                                                                        ...userProfileUpdate.medical?.doctor_2,
                                                                        address: e.target.value
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        value={(userProfileUpdate.medical?.doctor_2?.address as string) || ""}
                                                    />
                                                </>
                                            </div>
                                        </ExpandItem>
                                    </Expand>
                                </ExpandItem>
                            </Expand>
                        </div>
                        <SubmitButton
                            onClick={submitUserProfileUpdate}
                            color={colors.scheme.submitButtonText}
                            background={colors.scheme.submitButtonBackground}
                        >
                            Submit Profile Change
                        </SubmitButton>
                    </ReportItemContainer>
                </FilterContainer>
            )}
        </>
    );
};
