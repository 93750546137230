import React, { useContext } from "react";
import { _MapContext as MapContext } from "react-map-gl";
import { getFrequency } from "../../utils";
import { Marker } from "../../../../state/map";
import { PieChart, Pie, Cell, Label } from "recharts";
import { useSelectFromRedux } from "../../../../utils/_hooks";
import { Legend, LegendEntry } from "../../../analytics/styled";
import { EntryName, EntryCases } from "../../styled";

export default function PieChartMarker(props: any) {
    const { defaultRegionId, markers, zoomLimit = 100 } = props;
    const colors = useSelectFromRedux((state) => state.color);
    const COLORS: Record<string, string> = {
        Violent: colors.scheme.analyticsPieViolent,
        "Non-Violent": colors.scheme.analyticsPieNonviolent,
        Hazard: colors.scheme.analyticsPieHazard,
        Other: colors.scheme.analyticsPieOther
    };

    const regionMarkers = defaultRegionId ? markers.filter((marker: Marker) => marker.defaultRegionId === defaultRegionId) : markers;
    const getCategoriesByRegion = () => {
        const categoryFrequencies = getFrequency(regionMarkers.map((x: Marker) => x.category));
        const categoryFrequenciesObj = [
            {
                name: "Violent",
                count: categoryFrequencies["Violent"] || 0
            },
            {
                name: "Non-Violent",
                count: categoryFrequencies["Non-Violent"] || 0
            },
            {
                name: "Hazard",
                count: categoryFrequencies["Hazard"] || 0
            },
            {
                name: "Other",
                count: categoryFrequencies["Other"] || 0
            }
        ];

        return categoryFrequenciesObj;
    };

    const renderLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, outerRadius, percent } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 6) * cos;
        const sy = cy + (outerRadius + 6) * sin;
        const mx = cx + (outerRadius + 10) * cos;
        const my = cy + (outerRadius + 20) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 6;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        return (
            <g>
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={colors.scheme.primaryText} fill="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 7} y={ey + 5} textAnchor={textAnchor} fill={colors.scheme.primaryText}>{`${Math.round(
                    percent * 100
                )}%`}</text>
            </g>
        );
    };

    const totalReports = regionMarkers.length;
    const data = getCategoriesByRegion() ?? [];

    const viewport = useContext(MapContext).viewport;

    return (
        <>
            {totalReports && viewport && viewport.zoom < zoomLimit ? (
                <>
                    <PieChart
                        width={295}
                        height={170}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            zIndex: "30"
                        }}
                    >
                        <Pie
                            data={data}
                            dataKey={"count"}
                            nameKey={"name"}
                            cx={140}
                            cy={70}
                            innerRadius={28}
                            outerRadius={45}
                            isAnimationActive={false}
                            label={renderLabel}
                        >
                            {data
                                .filter((entry: any) => entry.name !== "Other")
                                .map((entry: any, index: number) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[entry.name ?? 0]} strokeWidth={0} />
                                ))}
                            <Label
                                fill={colors.scheme.primaryText}
                                x={
                                    totalReports.toString().length === 3
                                        ? 129
                                        : totalReports.toString().length === 2
                                        ? 134
                                        : totalReports.toString().length === 4
                                        ? 124
                                        : totalReports.toString().length === 5
                                        ? 122
                                        : 139
                                }
                                y={78}
                                content={
                                    <text style={{ fontSize: "18px", fontWeight: 600, color: colors.scheme.primaryText }}>
                                        {totalReports.toString().length > 4
                                            ? totalReports.toString().split("").slice(0, 2).join("") + "." + totalReports.toString().split("")[3] + "k"
                                            : totalReports}
                                    </text>
                                }
                            />
                            <Label x={131} y={88} fill={colors.scheme.primaryText} content={<text style={{ fontSize: "9px" }}>reports</text>} />
                        </Pie>
                    </PieChart>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignSelf: "center",
                            justifyContent: "center",
                            fontSize: "12px"
                        }}
                    >
                        <Legend>
                            {data
                                .filter((entry: any) => entry.name !== "Other")
                                .map((entry: any, index: number) => {
                                    return (
                                        <LegendEntry
                                            key={`legendentry-${index}`}
                                            style={{
                                                alignContent: "center"
                                            }}
                                        >
                                            <EntryName color={colors.scheme.primaryText}>
                                                <div
                                                    style={{
                                                        backgroundColor: COLORS[entry.name ?? 0],
                                                        height: "12px",
                                                        width: "12px",
                                                        marginTop: "-1px",
                                                        marginRight: "5px"
                                                    }}
                                                />
                                                {entry.name}
                                            </EntryName>
                                            <EntryCases color={colors.scheme.primaryText} style={{ marginTop: "0px" }}>
                                                {entry.count + " reports"}
                                            </EntryCases>
                                        </LegendEntry>
                                    );
                                })}
                        </Legend>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
