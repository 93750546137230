import React, { PureComponent } from "react";
import styled from "styled-components";
import moment from "moment";
import { createSelector } from "reselect";
import { Minus } from "../icons";
import { DEFAULT_TIME_FORMAT } from "../../../constants/default-settings";
import { CenterFlexbox } from "../styled-components";
import { connect } from "react-redux";

const StyledTimeDisplay = styled.div`
    background-color: ${(props) => props.theme.orion.scheme.modalBackground};
    border-radius: ${(props) => props.theme.timeDisplayBorderRadius}px;
    bottom: ${(props) => `calc(100% + ${props.theme.bottomPanelGap}px)`};
    color: ${(props) => props.theme.titleTextColor};
    display: flex;
    height: ${(props) => props.theme.timeDisplayHeight}px;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
    min-width: ${(props) => props.theme.timeDisplayMinWidth}px;
    max-width: ${(props) => props.theme.timeDisplayMinWidth + 70}px;
    opacity: ${(props) => props.theme.timeDisplayOpacity};
    padding: ${(props) => props.theme.timeDisplayPadding};
    position: absolute;
`;

const StyledTimeDisplayGroups = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const StyledTimeDisplayRows = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledTimeDisplayTop = styled.div.attrs({
    className: "animation-control__time-display__top"
})`
    color: ${(props) => props.theme.orion.scheme.primaryText};
    display: flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
`;

const StyledTimeDisplayBottom = styled.div.attrs({
    className: "animation-control__time-display__bottom"
})`
    color: ${(props) => props.theme.orion.scheme.secondaryHeaderText};
    display: flex;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
`;

const StyledTimeValueGroup = styled.div.attrs({
    className: "animation-control__time-value-group"
})`
    display: flex;
    flex-direction: column;
`;

const StyledHorizontalBar = styled.div.attrs({
    className: "animation-control__horizontal-bar"
})`
    margin: 0 12px;
`;

const TimeDivider = () => (
    <StyledHorizontalBar>
        <Minus height="12px" />
    </StyledHorizontalBar>
);

const TimeDisplayRow = ({ timeValues = [] }) => (
    <CenterFlexbox>
        <div>{timeValues[0]}</div>
        {timeValues[1] ? <TimeDivider /> : null}
        {timeValues[1] ? <div>{timeValues[1]}</div> : null}
    </CenterFlexbox>
);

class FloatingTimeDisplay extends PureComponent {
    timeSelector = (props) => props.currentTime;
    formatSelector = (props) => props.format;
    displayTimeSelector = createSelector(this.timeSelector, this.formatSelector, (currentTime, format) => {
        const groupTime = Array.isArray(currentTime) ? currentTime : [currentTime];
        return groupTime.reduce(
            (accu, curr) => {
                const displayDateTime = moment(curr).format(format);
                const [displayDate, displayTime] = displayDateTime.split(" ");

                if (!accu.displayDate.includes(displayDate)) {
                    accu.displayDate.push(displayDate);
                }
                accu.displayTime.push(displayTime);

                return accu;
            },
            { displayDate: [], displayTime: [] }
        );
    });

    render() {
        const { displayDate, displayTime } = this.displayTimeSelector(this.props);
        const twoGroups = displayDate.length === 2 && displayTime.length === 2;
        const bottomRow = displayTime.length ? displayTime : displayDate.length ? displayDate : null;
        const topRow = displayDate.length && displayTime.length ? displayDate : null;

        return (
            <StyledTimeDisplay className="animation-control__time-display">
                {twoGroups ? (
                    <StyledTimeDisplayGroups>
                        <StyledTimeValueGroup>
                            {/* Time Start */}
                            <StyledTimeDisplayTop>{displayDate[0]}</StyledTimeDisplayTop>
                            {this.props.filter.timePeriod === "24 Hour" && <StyledTimeDisplayBottom>{displayTime[0]}</StyledTimeDisplayBottom>}
                        </StyledTimeValueGroup>
                        <TimeDivider />
                        <StyledTimeValueGroup>
                            {/* Time End */}
                            <StyledTimeDisplayTop>{displayDate[1]}</StyledTimeDisplayTop>
                            {this.props.filter.timePeriod === "24 Hour" && <StyledTimeDisplayBottom>{displayTime[1]}</StyledTimeDisplayBottom>}
                        </StyledTimeValueGroup>
                    </StyledTimeDisplayGroups>
                ) : (
                    <StyledTimeDisplayRows>
                        {topRow ? (
                            <StyledTimeDisplayTop>
                                <TimeDisplayRow timeValues={topRow} />
                            </StyledTimeDisplayTop>
                        ) : null}
                        {bottomRow ? (
                            <StyledTimeDisplayBottom>
                                <TimeDisplayRow timeValues={bottomRow} />
                            </StyledTimeDisplayBottom>
                        ) : null}
                    </StyledTimeDisplayRows>
                )}
            </StyledTimeDisplay>
        );
    }
}

FloatingTimeDisplay.defaultProps = {
    format: DEFAULT_TIME_FORMAT,
    currentTime: null
};

export default connect((state) => {
    return { filter: state.filter };
})(FloatingTimeDisplay);
