import * as publicInfoOperations from "./operations";
import { produce } from "immer";
import { actionFactory, ActionUnion, payloadAction } from "reductser";
import { PublicInsight } from "../../types/client";
import { PublicReport } from "../../types/reports";

const PublicInfoActionsMap = {
    loadReports: payloadAction<PublicReport[]>(),
    setLoading: payloadAction<Partial<Loading>>(),
    loadInsights: payloadAction<PublicInsight[]>(),
    setNewInsight: payloadAction<boolean>()
};

export const publicInfoActions = actionFactory(PublicInfoActionsMap, "PUBLIC_INFO");

export type PublicInfoActions = ActionUnion<typeof publicInfoActions>;

interface Loading {
    publicReports: boolean;
}

export interface PublicInfoState {
    reports: PublicReport[];
    loading: Loading;
    insights: PublicInsight[];
    newInsight: boolean;
    // FIXME: add toast state
    // loadReportsError: string
}

export const getInitialState = (): PublicInfoState => ({
    reports: [],
    loading: {
        publicReports: false
    },
    insights: [],
    newInsight: false
});

const publicInfoReducer = (state = getInitialState(), action: PublicInfoActions) =>
    produce(state, (draftState) => {
        if (action.reducer === "PUBLIC_INFO") {
            switch (action.type) {
                case "loadReports":
                    draftState.reports = action.payload;
                    return;
                case "setLoading":
                    draftState.loading = {
                        ...draftState.loading,
                        ...action.payload
                    };
                    break;
                case "loadInsights":
                    draftState.insights = action.payload;
                    break;
                case "setNewInsight":
                    draftState.newInsight = action.payload;
                    break;
                default:
                    return;
            }
        }
    });

export { publicInfoOperations };
export default publicInfoReducer;
