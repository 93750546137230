import React from "react";
import { TooltipTitle, TooltipAddress, TooltipPostedby, IndividualTooltipContainer, FullReportLink } from "./styled";
import { useSelectFromRedux } from "../../../utils/_hooks";
import { useDispatch } from "react-redux";
import { individualReportActions } from "../../../state/individual-report";
import { leftModalActions } from "../../../state/left-modal";
import { S3Key } from "../../../utils";
const checkmarkGreenIcon = S3Key + "check_mark.png";
export default function IndividualTooltip() {
    const dispatch = useDispatch();
    const [{ selectedMarker }] = useSelectFromRedux((state) => [state.map]);
    const colors = useSelectFromRedux((state) => state.color);
    const leftModalIsOpen = useSelectFromRedux((state) => state.leftModal.isOpen);
    const openFullReport = (selectedMarkerId: string) => {
        if (selectedMarkerId.includes("client-alert")) {
            const id = selectedMarkerId.match(/(\d+)/);
            if (id) {
                dispatch(individualReportActions.setCurrentReportId(Number(id[0])));
                dispatch(individualReportActions.setCurrentReportType("client-alert"));
                if (!leftModalIsOpen) dispatch(leftModalActions.toggleModal());
                dispatch(leftModalActions.setModalContent({ modalContent: "individual-report" }));
            }
        } else if (selectedMarkerId.includes("-public")) {
            const id = selectedMarkerId.match(/(\d+)/);
            if (id) {
                dispatch(individualReportActions.setCurrentReportId(Number(id[0])));
                dispatch(individualReportActions.setCurrentReportType("public"));
                if (!leftModalIsOpen) dispatch(leftModalActions.toggleModal());
                dispatch(leftModalActions.setModalContent({ modalContent: "individual-report" }));
            }
        } else if (selectedMarkerId.includes("-client")) {
            const id = selectedMarkerId.match(/(\d+)/);
            if (id) {
                dispatch(individualReportActions.setCurrentReportId(Number(id[0])));
                dispatch(individualReportActions.setCurrentReportType("client"));
                if (!leftModalIsOpen) dispatch(leftModalActions.toggleModal());
                dispatch(leftModalActions.setModalContent({ modalContent: "individual-report" }));
            }
        }
    };
    return (
        <IndividualTooltipContainer>
            <TooltipTitle color={colors.scheme.primaryText}>
                {selectedMarker.properties.title}
                {selectedMarker.properties.verified > 0 && (
                    <img
                        src={checkmarkGreenIcon}
                        alt="Verified"
                        style={{
                            height: "19px",
                            marginTop: "-4px",
                            marginLeft: "4px"
                        }}
                    />
                )}
                {selectedMarker.properties.verified === 0 && (
                    <img
                        src={"https://orion-assets-s3.s3.us-east-2.amazonaws.com/open-circle-amber.png"}
                        alt="Unverified"
                        style={{
                            height: "19px",
                            marginTop: "-4px",
                            marginLeft: "4px"
                        }}
                    />
                )}
            </TooltipTitle>
            <TooltipAddress color={colors.scheme.secondaryHeaderText}>{selectedMarker.properties.location}</TooltipAddress>
            <TooltipPostedby color={colors.scheme.secondaryHeaderText}>
                Posted by {selectedMarker.properties.postedBy}
                <br />
                {selectedMarker.properties.time}
            </TooltipPostedby>
            {selectedMarker.properties.id && (
                <FullReportLink color={colors.scheme.primaryText} onClick={() => openFullReport(selectedMarker.properties.id)}>
                    View Full Report
                </FullReportLink>
            )}
        </IndividualTooltipContainer>
    );
}
