import React from "react";
import store from "../../state/store";
import { toastActions } from "../../state/toast";
import { handleForegroundNotification } from "../../api/push-notifications";
import { ReportNotificationData, ReportNotificationType } from "../../types/reports";

/**
 * React Component exposed to Swift for piping notifications from iOS/Swift to React App
 */

export default class SwiftNotificationWrapper extends React.Component {
    handleSwiftNotification = (id: string, type: string) => {
        if (type && typeof type === "string" && id && typeof id === "string") {
            const payload: ReportNotificationData = {
                id,
                type: type as ReportNotificationType
            };

            handleForegroundNotification(payload as ReportNotificationData);
        } else {
            store.dispatch(toastActions.openError("Invalid payload: " + id + " | " + type));
        }
    };

    componentDidMount() {
        /**
         * Expose this class to Swift
         */
        (window as any).app = this;
    }

    /**
     * This class has no UI functionality, and is structured as a React Component Class
     * only because Swift supports exclusively React Classes for executing javascript
     */
    render() {
        return null;
    }
}
