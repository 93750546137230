import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion, simpleAction } from "reductser";
import * as teamsOperations from "./operations";

const actionMap = {
    toggleCreateTeamMode: payloadAction<boolean>(),
    toggleModifyUserMode: payloadAction<boolean>(),
    updateFullViewUserId: payloadAction<number | null>(),
    updateCreateTeamName: payloadAction<string | null>(),
    updateModifyUserRole: payloadAction<string | number | undefined>(),
    updateModifyUserTeam: payloadAction<string | number | undefined>(),
    updateModifyUserDesignation: payloadAction<string | undefined>(),
    toggleDisplayFullUserView: payloadAction<boolean>(),
    updateAccordianCurrentTeam: payloadAction<string | undefined>(),
    toggleModifyTeamMode: payloadAction<boolean>(),
    updateAddNewUserFirstName: payloadAction<string>(),
    updateAddNewUserLastName: payloadAction<string>(),
    updateAddNewUserTeam: payloadAction<number | null>(),
    updateAddNewUserRole: payloadAction<number | null>(),
    updateAddNewUserDesignation: payloadAction<string | null>(),
    updateAddNewUserUnique: payloadAction<string>(),
    resetAddPendingUserForm: simpleAction(),
    updateLoginOptionValue: payloadAction<"email" | "phone">()
};

export const teamsActions = actionFactory(actionMap, "TEAM");

export type TeamsAction = ActionUnion<typeof teamsActions>;

// space to describe specific types of actions

export interface TeamsState {
    createTeamMode: boolean;
    fullViewUserId: number | null;
    modifyUserMode: boolean;
    modifyUserRole: string | number | undefined;
    modifyUserTeam: string | number | undefined;
    createTeamName: string | null;
    modifyUserDesignation: string | undefined;
    displayFullUserView: boolean;
    accordianCurrentTeam: string | undefined;
    modifyTeamMode: boolean;
    addNewUserFirstName: string;
    addNewUserLastName: string;
    addNewUserTeam: number | null;
    addNewUserRole: number | null;
    addNewUserDesignation: string | null;
    addNewUserUnique: string;
    loginOptionValue: "email" | "phone";
}

export const getInitialState = (): TeamsState => ({
    createTeamMode: false,
    fullViewUserId: null,
    modifyUserMode: false,
    modifyUserRole: undefined,
    modifyUserTeam: undefined,
    createTeamName: null,
    modifyUserDesignation: undefined,
    displayFullUserView: false,
    accordianCurrentTeam: undefined,
    modifyTeamMode: false,
    addNewUserFirstName: "",
    addNewUserLastName: "",
    addNewUserTeam: null,
    addNewUserRole: null,
    addNewUserDesignation: null,
    addNewUserUnique: "",
    loginOptionValue: "email"
});

const teamsReducer = (state = getInitialState(), action: TeamsAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "TEAM") {
            switch (action.type) {
                case "toggleCreateTeamMode":
                    draftState.createTeamMode = action.payload;
                    break;
                case "updateFullViewUserId":
                    draftState.fullViewUserId = action.payload;
                    break;
                case "updateCreateTeamName":
                    draftState.createTeamName = action.payload;
                    break;
                case "toggleModifyUserMode":
                    draftState.modifyUserMode = action.payload;
                    break;
                case "updateModifyUserRole":
                    draftState.modifyUserRole = action.payload;
                    break;
                case "updateModifyUserTeam":
                    draftState.modifyUserTeam = action.payload;
                    break;
                case "updateModifyUserDesignation":
                    draftState.modifyUserDesignation = action.payload;
                    break;
                case "toggleDisplayFullUserView":
                    draftState.displayFullUserView = action.payload;
                    break;
                case "updateAccordianCurrentTeam":
                    draftState.accordianCurrentTeam = action.payload;
                    break;
                case "toggleModifyTeamMode":
                    draftState.modifyTeamMode = action.payload;
                    break;
                case "updateAddNewUserFirstName":
                    draftState.addNewUserFirstName = action.payload;
                    break;
                case "updateAddNewUserLastName":
                    draftState.addNewUserLastName = action.payload;
                    break;
                case "updateAddNewUserTeam":
                    draftState.addNewUserTeam = action.payload;
                    break;
                case "updateAddNewUserRole":
                    draftState.addNewUserRole = action.payload;
                    break;
                case "updateAddNewUserDesignation":
                    draftState.addNewUserDesignation = action.payload;
                    break;
                case "updateAddNewUserUnique":
                    draftState.addNewUserUnique = action.payload;
                    break;
                case "updateLoginOptionValue":
                    draftState.loginOptionValue = action.payload;
                    break;
                case "resetAddPendingUserForm":
                    draftState.addNewUserUnique = getInitialState().addNewUserUnique;
                    draftState.addNewUserFirstName = getInitialState().addNewUserFirstName;
                    draftState.addNewUserLastName = getInitialState().addNewUserLastName;
                    draftState.addNewUserTeam = getInitialState().addNewUserTeam;
                    draftState.addNewUserRole = getInitialState().addNewUserRole;
                    draftState.addNewUserDesignation = getInitialState().addNewUserDesignation;
                    break;
                default:
                    break;
                // unreachableCode(action);
            }
        }
    });

export { teamsOperations };
export default teamsReducer;
