import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";
import * as analyticsOperations from "./operations";
import { AnalyticsSummary, CategoryStats, ThirtyDayCount } from "../../types/analytics";

const actionMap = {
    toggleFilterLocked: payloadAction<boolean>(),
    setSelectedRegion: payloadAction<number>(),
    setData: payloadAction<AnalyticsSummary | null>(),
    setCategoricalDataArray: payloadAction<CategoryStats[] | null>(),
    setError: payloadAction<string | null>(),
    toggleTimeRange: payloadAction<"7 Day" | "28 Day" | "24 Hour" | null>(),
    toggleViolentChecked: payloadAction<boolean>(),
    toggleNonviolentChecked: payloadAction<boolean>(),
    toggleHazardChecked: payloadAction<boolean>(),
    toggleFilterOpen: payloadAction<boolean>(),
    toggleViolentCheckedApplied: payloadAction<boolean>(),
    toggleNonviolentCheckedApplied: payloadAction<boolean>(),
    toggleHazardCheckedApplied: payloadAction<boolean>(),
    toggleViolentCheckedEffect: payloadAction<boolean>(),
    toggleNonviolentCheckedEffect: payloadAction<boolean>(),
    toggleHazardCheckedEffect: payloadAction<boolean>(),
    toggleBlockUpdate: payloadAction<boolean>(),
    setRegionChange: payloadAction<number>(),
    setMapRegionChange: payloadAction<number>(),
    toggleTimeRangeApplied: payloadAction<"7 Day" | "28 Day" | "24 Hour" | null>(),
    setCurrentContainer: payloadAction<number>(),
    updateTotalReports: payloadAction<number>(),
    toggleTotalReportsLoading: payloadAction<boolean>(),
    setTimeRangeArray: payloadAction<ThirtyDayCount[]>(),
    setContainerChange: payloadAction<number>(),
    updateAppliedEventTypes: payloadAction<string[]>()
    // updateAnalyticsState: payloadAction<Partial<AnalyticsState>>(),
    // set: payloadAction<Partial<AnalyticsState>>(),
    // toggle: payloadAction<{ [key: keyof AnalyticsState]: boolean }>()
};

export const analyticsActions = actionFactory(actionMap, "ANALYTICS");

export type AnalyticsAction = ActionUnion<typeof analyticsActions>;

export interface AnalyticsState {
    filterLocked: boolean;
    selectedRegion: number;
    data: AnalyticsSummary | null;
    categoricalDataArray: CategoryStats[] | null;
    error: string | null;
    timeRange: "7 Day" | "28 Day" | "24 Hour" | null;
    violentChecked: boolean;
    nonviolentChecked: boolean;
    hazardChecked: boolean;
    filterOpen: boolean;
    violentCheckedApplied: boolean;
    nonviolentCheckedApplied: boolean;
    hazardCheckedApplied: boolean;
    regionChange: number;
    mapRegionChange: number;
    timeRangeApplied: "7 Day" | "28 Day" | "24 Hour" | null;
    violentCheckedEffect: boolean;
    nonviolentCheckedEffect: boolean;
    hazardCheckedEffect: boolean;
    blockUpdate: boolean;
    currentContainer: number;
    containerChange: number;
    totalReports: number;
    totalReportsLoading: boolean;
    timeRangeArray: ThirtyDayCount[];
    appliedEventTypes: string[];
}

export const getInitialState = (): AnalyticsState => ({
    filterLocked: true,
    selectedRegion: 0,
    data: null,
    categoricalDataArray: null,
    error: null,
    timeRange: null,
    violentChecked: false,
    nonviolentChecked: false,
    hazardChecked: false,
    filterOpen: false,
    violentCheckedApplied: false,
    nonviolentCheckedApplied: false,
    hazardCheckedApplied: false,
    regionChange: 0,
    mapRegionChange: 0,
    timeRangeApplied: null,
    violentCheckedEffect: false,
    nonviolentCheckedEffect: false,
    hazardCheckedEffect: false,
    blockUpdate: false,
    currentContainer: 0,
    containerChange: 0,
    totalReports: 0,
    totalReportsLoading: true,
    timeRangeArray: [],
    appliedEventTypes: []
});

const analyticsReducer = (state = getInitialState(), action: AnalyticsAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "ANALYTICS") {
            switch (action.type) {
                case "toggleFilterLocked":
                    draftState.filterLocked = action.payload;
                    break;
                case "setSelectedRegion":
                    draftState.selectedRegion = action.payload;
                    break;
                case "setData":
                    draftState.data = action.payload;
                    break;
                case "setCategoricalDataArray":
                    draftState.categoricalDataArray = action.payload;
                    break;
                case "setError":
                    draftState.error = action.payload;
                    break;
                case "toggleTimeRange":
                    draftState.timeRange = action.payload;
                    break;
                case "toggleViolentChecked":
                    draftState.violentChecked = action.payload;
                    break;
                case "toggleNonviolentChecked":
                    draftState.nonviolentChecked = action.payload;
                    break;
                case "toggleHazardChecked":
                    draftState.hazardChecked = action.payload;
                    break;
                case "toggleFilterOpen":
                    draftState.filterOpen = action.payload;
                    break;
                case "toggleViolentCheckedApplied":
                    draftState.violentCheckedApplied = action.payload;
                    break;
                case "toggleNonviolentCheckedApplied":
                    draftState.nonviolentCheckedApplied = action.payload;
                    break;
                case "toggleHazardCheckedApplied":
                    draftState.hazardCheckedApplied = action.payload;
                    break;
                case "toggleViolentCheckedEffect":
                    draftState.violentCheckedEffect = action.payload;
                    break;
                case "toggleNonviolentCheckedEffect":
                    draftState.nonviolentCheckedEffect = action.payload;
                    break;
                case "toggleHazardCheckedEffect":
                    draftState.hazardCheckedEffect = action.payload;
                    break;
                case "toggleBlockUpdate":
                    draftState.blockUpdate = action.payload;
                    break;
                case "setRegionChange":
                    draftState.regionChange = action.payload;
                    break;
                case "setMapRegionChange":
                    draftState.mapRegionChange = action.payload;
                    break;
                case "toggleTimeRangeApplied":
                    draftState.timeRangeApplied = action.payload;
                    break;
                case "setCurrentContainer":
                    draftState.currentContainer = action.payload;
                    break;
                case "updateTotalReports":
                    draftState.totalReports = action.payload;
                    break;
                case "toggleTotalReportsLoading":
                    draftState.totalReportsLoading = action.payload;
                    break;
                case "setTimeRangeArray":
                    draftState.timeRangeArray = action.payload;
                    break;
                case "setContainerChange":
                    draftState.containerChange = action.payload;
                    break;
                case "updateAppliedEventTypes":
                    draftState.appliedEventTypes = action.payload;
                    break;
                default:
                    break;
            }
        }
    });

export { analyticsOperations };
export default analyticsReducer;
