import { produce } from "immer";
import { simpleAction, payloadAction, actionFactory, ActionUnion } from "reductser";

const actionMap = {
    updateFilter: payloadAction<FilterState>(),
    toggleHeatmap: payloadAction<boolean>(),
    resetFilter: simpleAction(),
    resetOrgFilter: simpleAction(),
    updateTimeFilter: payloadAction<"28 Day" | "7 Day" | "24 Hour" | null>(),
    toggleShowRiskForecastingDetails: payloadAction<boolean>()
};

export const filterActions = actionFactory(actionMap, "FILTER");

export type FilterActions = ActionUnion<typeof filterActions>;

// space to describe specific types of actions
export type Filter_ReportType = "Public Only" | "My Organization" | "My Reports Only";
export type Filter_ReportCategories = "Violent" | "Non-Violent" | "Hazard" | "Other";
export type Filter_TimePeriod = "28 Day" | "7 Day" | "24 Hour";

export interface FilterState {
    timePeriod: "28 Day" | "7 Day" | "24 Hour" | null;
    incidentsIncluded: boolean;
    sosIncluded: boolean;
    myOrganizationIncluded: boolean;
    myReportsIncluded: boolean;
    violentIncluded: boolean;
    nonviolentIncluded: boolean;
    hazardIncluded: boolean;
    otherIncluded: boolean;
    markerClustering: boolean;
    orionSourceIncluded: boolean;
    orionUserIncluded: boolean;
    openSourceIncluded: boolean;
    selectedRegion: number;
    filteredUsers: number[];
    showTimeFilter?: boolean;
    verifiedIncluded: boolean;
    unverifiedIncluded: boolean;
    teamFilter?: number | null;
    myTeamIncluded?: boolean;
    showRiskForecastingDetails?: boolean;
    eventTypeApplied?: string[];
}

export const getInitialState = (): FilterState => ({
    timePeriod: "7 Day",
    incidentsIncluded: false,
    sosIncluded: false,
    markerClustering: true,
    myOrganizationIncluded: false,
    myReportsIncluded: false,
    violentIncluded: false,
    nonviolentIncluded: false,
    hazardIncluded: false,
    otherIncluded: false,
    orionSourceIncluded: false,
    orionUserIncluded: false,
    openSourceIncluded: false,
    selectedRegion: 0,
    filteredUsers: [],
    showTimeFilter: true,
    verifiedIncluded: false,
    unverifiedIncluded: false,
    teamFilter: null,
    myTeamIncluded: false,
    showRiskForecastingDetails: true,
    eventTypeApplied: []
});

const FilterReducer = (state = getInitialState(), action: FilterActions) =>
    produce(state, (draftState) => {
        if (action.reducer === "FILTER") {
            switch (action.type) {
                case "updateFilter":
                    draftState.timePeriod = action.payload.timePeriod;
                    draftState.markerClustering = action.payload.markerClustering;
                    draftState.sosIncluded = action.payload.sosIncluded;
                    draftState.incidentsIncluded = action.payload.incidentsIncluded;
                    draftState.myOrganizationIncluded = action.payload.myOrganizationIncluded;
                    draftState.myReportsIncluded = action.payload.myReportsIncluded;
                    draftState.violentIncluded = action.payload.violentIncluded;
                    draftState.nonviolentIncluded = action.payload.nonviolentIncluded;
                    draftState.hazardIncluded = action.payload.hazardIncluded;
                    draftState.otherIncluded = action.payload.otherIncluded;
                    draftState.orionSourceIncluded = action.payload.orionSourceIncluded;
                    draftState.orionUserIncluded = action.payload.orionUserIncluded;
                    draftState.openSourceIncluded = action.payload.openSourceIncluded;
                    draftState.selectedRegion = action.payload.selectedRegion;
                    draftState.filteredUsers = action.payload.filteredUsers;
                    draftState.verifiedIncluded = action.payload.verifiedIncluded;
                    draftState.unverifiedIncluded = action.payload.unverifiedIncluded;
                    draftState.teamFilter = action.payload.teamFilter;
                    draftState.myTeamIncluded = action.payload.myTeamIncluded;
                    draftState.eventTypeApplied = action.payload.eventTypeApplied;
                    return;
                case "toggleHeatmap":
                    draftState.showTimeFilter = action.payload;
                    return;
                case "resetFilter":
                    draftState.timePeriod = getInitialState().timePeriod;
                    draftState.markerClustering = getInitialState().markerClustering;
                    draftState.sosIncluded = getInitialState().sosIncluded;
                    draftState.incidentsIncluded = getInitialState().incidentsIncluded;
                    draftState.myOrganizationIncluded = getInitialState().myOrganizationIncluded;
                    draftState.myReportsIncluded = getInitialState().myReportsIncluded;
                    draftState.violentIncluded = getInitialState().violentIncluded;
                    draftState.nonviolentIncluded = getInitialState().nonviolentIncluded;
                    draftState.hazardIncluded = getInitialState().hazardIncluded;
                    draftState.otherIncluded = getInitialState().otherIncluded;
                    draftState.orionSourceIncluded = getInitialState().orionSourceIncluded;
                    draftState.orionUserIncluded = getInitialState().orionUserIncluded;
                    draftState.openSourceIncluded = getInitialState().openSourceIncluded;
                    draftState.selectedRegion = getInitialState().selectedRegion;
                    draftState.filteredUsers = getInitialState().filteredUsers;
                    draftState.showTimeFilter = getInitialState().showTimeFilter;
                    draftState.verifiedIncluded = getInitialState().verifiedIncluded;
                    draftState.unverifiedIncluded = getInitialState().unverifiedIncluded;
                    draftState.teamFilter = getInitialState().teamFilter;
                    draftState.myTeamIncluded = getInitialState().myTeamIncluded;
                    draftState.eventTypeApplied = getInitialState().eventTypeApplied;
                    return;
                case "resetOrgFilter":
                    draftState.myReportsIncluded = getInitialState().myReportsIncluded;
                    draftState.filteredUsers = getInitialState().filteredUsers;
                    draftState.teamFilter = getInitialState().teamFilter;
                    draftState.myTeamIncluded = getInitialState().myTeamIncluded;
                    return;
                case "updateTimeFilter":
                    draftState.timePeriod = action.payload;
                    return;
                case "toggleShowRiskForecastingDetails":
                    draftState.showRiskForecastingDetails = action.payload;
                    return;
                default:
                    return;
            }
        }
    });

export default FilterReducer;
