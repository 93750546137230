import { MapboxAPIKey } from "../../../utils";
import store from "../../../state/store";
import { visualizationsActions } from "../../../state/visualizations";
import { default as axios } from "axios";

var smooth = require("chaikin-smooth");

export function getMatch(e) {
    const profile = store.getState().visualizations.routeProfile;
    var url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${e}?geometries=geojson&overview=full&&access_token=${MapboxAPIKey}`;
    axios({
        method: "GET",
        url
    })
        .then((res) => {
            if (res.data) {
                var coords = smooth(res.data.routes[0].geometry.coordinates);
                store.dispatch(visualizationsActions.updateFuzzyRouteOutput(coords));
            }
        })
        .catch((err) => {
            console.log(err);
        });
}
