import React, { useState, useEffect } from "react";
// components
import { FormField, FullScreenImage, LogoAndFormContainer, SubmitButton, VerstaanLogo } from "../_shared/auth";
import { EmailFormField, MessageContainer, MessageContent, PhoneNumberInput } from "../signup/styled";
import { useDispatch } from "react-redux";
import { toastActions } from "../../state/toast";
import { Error } from "../home/styled";
import { useDispatchPromise, useSelectFromRedux } from "../../utils/_hooks";
import { Redirect } from "react-router-dom";
import { cuserOperations } from "../../state/cuser";
import { fadedRed, S3Key } from "../../utils";
import { Alert } from "../_shared";
import { SmallSpacer } from "../_shared/layout";
import { SmallIcon } from "../_shared/typography";

export interface Props {
    emailOrPhone: string;
    isPhone: boolean;
}

export const ResetPassword = (props: Props) => {
    const dispatch = useDispatch();
    const dispatchPromise = useDispatchPromise();
    const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);
    const errorContent = useSelectFromRedux((state) => state.error.currentText);
    const toastIsError = useSelectFromRedux((state) => state.error.isError);
    const colors = useSelectFromRedux((state) => state.color);

    // eslint-disable-next-line
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const [code, updateCode] = useState<string>("");
    const [newPassword, updateNewPassword] = useState<string>("");
    const [confirmPassword, updateConfirmPassword] = useState<string>("");
    const [passwordIsValid, updatePasswordIsValid] = useState<boolean>(false);
    const [passwordIsTheSame, updatePasswordIsTheSame] = useState<boolean>(false);
    const [resetSuccess, updateResetSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (newPassword.match(format) && /\d/.test(newPassword) && newPassword.length >= 8) {
            updatePasswordIsValid(true);
        } else {
            updatePasswordIsValid(false);
        }
    }, [newPassword, format]);

    const submit = () => {
        if (newPassword !== confirmPassword) {
            dispatch(toastActions.openError("Passwords do not match, please try again."));
            return;
        }

        if (!newPassword.match(format) || /\d/.test(newPassword) === false) {
            dispatch(toastActions.openError("Password does not include one of the following: special character, number."));
            return;
        }

        if (newPassword.length < 8) {
            dispatch(toastActions.openError("Password is less than 8 characters. Please try again."));
            return;
        }

        dispatchPromise(cuserOperations.resetPassword(props.emailOrPhone, code, newPassword)).then(() => {
            setTimeout(() => {
                updateResetSuccess(true);
            }, 1000);
        });
    };

    const handleErrorClose = () => {
        dispatch(toastActions.closeToast());
    };

    if (resetSuccess) {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    state: { from: "/forgotpassword" }
                }}
            />
        );
    }

    return (
        <FullScreenImage>
            <LogoAndFormContainer>
                <VerstaanLogo src="https://orion-assets-s3.s3.us-east-2.amazonaws.com/orion-logo.png" alt="Verstaan Logo" />
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    {!props.isPhone ? (
                        <EmailFormField type="text" value={props.emailOrPhone} readOnly />
                    ) : (
                        <div style={{ margin: "10px" }}>
                            <PhoneNumberInput value={props.emailOrPhone} disableDropdown disabled />
                        </div>
                    )}
                    <MessageContainer>
                        <MessageContent>One-Time Code</MessageContent>
                    </MessageContainer>
                    <FormField type="text" name="code" placeholder="CODE" id="code" value={code} onChange={(e) => updateCode(e.target.value)} />
                    <MessageContainer>
                        <MessageContent>Password must be at least 8 characters, and must include a special character and a number.</MessageContent>
                    </MessageContainer>
                    <FormField
                        type="password"
                        placeholder="PASSWORD"
                        onChange={(e) => updateNewPassword(e.target.value)}
                        highlightcolor={passwordIsValid ? "#3A6852" : "#AA0201"}
                    />
                    <FormField
                        type="password"
                        placeholder="VERIFY PASSWORD"
                        onChange={(e) => {
                            updateConfirmPassword(e.target.value);
                            if (e.target.value === newPassword) {
                                updatePasswordIsTheSame(true);
                            } else {
                                updatePasswordIsTheSame(false);
                            }
                        }}
                        highlightcolor={passwordIsValid && passwordIsTheSame ? "#3A6852" : "#AA0201"}
                    />
                    <SubmitButton type="button" style={{ marginTop: "20px" }} onClick={submit}>
                        Reset Password
                    </SubmitButton>
                </form>
            </LogoAndFormContainer>
            {errorIsOpen && errorContent && (
                <Error
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    onClose={handleErrorClose}
                    autoHideDuration={10000}
                    background={toastIsError ? colors.scheme.filterViolentTag : colors.general.standardGreen}
                >
                    <Alert style={{ background: toastIsError ? fadedRed : colors.general.standardGreen }}>
                        {
                            toastIsError ?
                                <SmallIcon src={S3Key + "alert-white.svg"} alt="alert" />
                                : <SmallIcon src={S3Key + "check-white.svg"} alt="checkmark" />
                        }
                        <SmallSpacer />
                        {errorContent}
                    </Alert>
                </Error>
            )}
        </FullScreenImage>
    );
};
