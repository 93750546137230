import { PromiseOperation } from "..";
import { ConnectionError, ErrorResponse } from "../../types/api";
import { publicInfoActions } from ".";
import { cuserActions } from "../cuser";
import { PublicInsight } from "../../types/client";
import * as client from "../../types/api/client";
import moment from "moment";
import { PublicReport } from "../../types/reports";
import { useSelectFromRedux } from "../../utils/_hooks";
// import * as public from "../../api/public";

// export const loadPublicReports: Operations = () => async (dispatch) => {
//     try {
//         const reports: PublicReport[] = public.getReports();
//         dispatch(publicInfoActions.loadReports(reports));
//     } catch (error) {
//         if (error instanceof ErrorResponse) {
//             console.log("Error response: " + toast.message);
//         } else if (error instanceof ConnectionError) {
//             console.log("Connection error: " + toast.message);
//         } else {
//             console.error("Unknown error occurred in fetchClientInfo: ", error);
//         }
//     }
// }

export const fetchPublicReportsFromContainer: PromiseOperation<void> = (container_id?: number) => async (dispatch, getState) => {
    dispatch(publicInfoActions.setLoading({ publicReports: true }));
    const userProfile = useSelectFromRedux((state) => state.cuser.userProfile);
    try {
        const {
            public_reports: reports,
            container_id: new_container_id
        }: {
            container_id: number;
            public_reports: PublicReport[];
        } = await client.updateContainerAndFetchReports(container_id);

        dispatch(publicInfoActions.loadReports(reports));
        // FIXME ? This is important for first sign in (no container set), will be redundantly called on reloads
        dispatch(cuserActions.updateCurrentContainer(new_container_id));
        if (new_container_id) {
            console.log("Current Container ID updated: " + new_container_id);
        } else {
            console.warn("Failed to update Current Container ID: " + new_container_id);
        }
        const latestInsight = getState().publicInfo.insights.filter((insight) => insight.container_ids.includes(container_id || -1))[0];
        if (latestInsight) {
            if (localStorage.getItem("lastInsightAccess")) {
                if (
                    moment(new Date()).diff(latestInsight.created_at, "hours") < 24 &&
                    moment(JSON.parse(localStorage.getItem("lastInsightAccess") ?? "")).diff(latestInsight.created_at, "milliseconds") < 0
                ) {
                    if (userProfile?.features?.publicInsightsMenu === true) dispatch(publicInfoActions.setNewInsight(true));
                }
            } else {
                if (moment(new Date()).diff(latestInsight.created_at, "hours") < 24) {
                    if (userProfile?.features?.publicInsightsMenu === true) dispatch(publicInfoActions.setNewInsight(true));
                }
            }
        }
        dispatch(publicInfoActions.setLoading({ publicReports: false }));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response when fetching reports from container: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error when fetching reports from container: " + error.message);
        } else {
            console.error("Unknown error occurred in fetchPublicReportsFromContainer: ", error);
        }
        dispatch(publicInfoActions.setLoading({ publicReports: false }));
    }
    return;
};

export const getPublicInsights: PromiseOperation<void> = () => async (dispatch) => {
    try {
        const insights: PublicInsight[] = await client.getPublicInsights();
        dispatch(publicInfoActions.loadInsights(insights));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in fetchClientInfo: ", error);
        }
        dispatch(publicInfoActions.setLoading({ publicReports: false }));
    }
};
