import * as clientInfoOperations from "./operations";
import { produce } from "immer";
import { actionFactory, ActionUnion, payloadAction, simpleAction } from "reductser";
import { Team, UserProfile } from "../../types/client";
import { DisplayDevice } from "../../types/auth";

const ClientInfoActionsMap = {
    loadUsers: payloadAction<UserProfile[]>(),
    loadTeams: payloadAction<Team[]>(),
    confirmAlertNotification: payloadAction<number>(),
    setLoading: payloadAction<Partial<Loading>>(),
    setFirstLoadAlerts: simpleAction(),
    loadClientDevices: payloadAction<DisplayDevice[]>(),
    toggleShowClientDevices: payloadAction<boolean>()
    // setStateField: payloadAction<Partial<ClientInfoState>>()

    // FIXME: add loading errors here
    // loadReportsError: payloadAction<string>()
    // loadAlertReportsError: payloadAction<string>()
};

export const clientInfoActions = actionFactory(ClientInfoActionsMap, "CLIENT_INFO");

export type ClientInfoActions = ActionUnion<typeof clientInfoActions>;

interface Loading {
    combinedReports: boolean;
}

export interface ClientInfoState {
    users: UserProfile[];
    teams: Team[];
    loading: Loading;
    unconfirmedAlerts: number; // Alerts created by the current user that are ended and unconfirmed, applies to admins
    unaddressedAlerts: number; // Alerts that need to be addressed by the admin
    firstLoadAlerts: boolean;
    clientDevices: DisplayDevice[];
    showClientDevices: boolean;
    // FIXME: add error state
    // loadReportsError: string
}

export const getInitialState = (): ClientInfoState => ({
    users: [],
    teams: [],
    loading: {
        combinedReports: false
    },
    unconfirmedAlerts: 0,
    unaddressedAlerts: 0,
    firstLoadAlerts: true,
    clientDevices: [],
    showClientDevices: true
});

const clientInfoReducer = (state = getInitialState(), action: ClientInfoActions) =>
    produce(state, (draftState) => {
        if (action.reducer === "CLIENT_INFO") {
            switch (action.type) {
                case "loadUsers":
                    draftState.users = action.payload;
                    return;
                case "loadTeams":
                    draftState.teams = action.payload;
                    return;
                case "setLoading":
                    draftState.loading = {
                        ...draftState.loading,
                        ...action.payload
                    };
                    break;
                case "setFirstLoadAlerts":
                    draftState.firstLoadAlerts = false;
                    break;
                case "loadClientDevices":
                    draftState.clientDevices = action.payload;
                    break;
                case "toggleShowClientDevices":
                    draftState.showClientDevices = action.payload;
                    break;
                // case "setStateField":
                //     draftState = {
                //         ...draftState,
                //         ...action.payload
                //     };
                //     break;
                default:
                    return;
            }
        }
    });

export { clientInfoOperations };
export default clientInfoReducer;
