import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Slider from "@material-ui/core/Slider";
import { Slide } from "../styled";
import moment from "moment";
import { useDispatch } from "react-redux";
import { visualizationsActions } from "../../../state/visualizations";
import { S3Key } from "../../../utils";
import { useSelectFromRedux } from "../../../utils/_hooks";
import { getCurrentTimeBin, TIMEWINDOW_LABELS } from "../utils";
import Slider, { SliderMarks } from "antd/lib/slider";
import styled from "styled-components";

const EarlyMorningWhiteIcon = S3Key + "early-morning-white.png";
const MidMorningWhiteIcon = S3Key + "mid-morning-white.png";
const AfternoonWhiteIcon = S3Key + "afternoon-white.png";
const EarlyEveningWhiteIcon = S3Key + "early-evening-white.png";
const EveningWhiteIcon = S3Key + "evening-white.png";
const LateNightWhiteIcon = S3Key + "late-night-white.png";
const EarlyMorningGreyIcon = S3Key + "early-morning-grey.png";
const MidMorningGreyIcon = S3Key + "mid-morning-grey.png";
const AfternoonGreyIcon = S3Key + "afternoon-grey.png";
const EarlyEveningGreyIcon = S3Key + "early-evening-grey.png";
const EveningGreyIcon = S3Key + "evening-grey.png";
const LateNightGreyIcon = S3Key + "late-night-grey.png";

const useStyles = makeStyles({
    root: {
        width: 300,
        display: "flex",
        marginTop: 12,
        flexDirection: "column",
        alignItems: "center"
    },
    text: {
        fontFamily: "IBM Plex Sans",
        color: (props: any) => props.theme.scheme.primaryText
    },
    markLabel: {
        color: (props: any) => props.theme.scheme.primaryText,
        fontFamily: "IBM Plex Sans"
    },
    rail: {
        color: (props: any) => props.theme.scheme.primaryButton
    }
});

export default function SevenDayFilter(props: any) {
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const colors = useSelectFromRedux((state) => state.color);
    const { timeWindow, day } = useSelectFromRedux((state) => state.visualizations);

    useEffect(() => {
        return () => {
            dispatch(visualizationsActions.updateDay(today));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    var marks: any[] = [];
    //var weekdays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

    //const today = moment().weekday();
    const today = 0

    // for (let x = 0; x < 7; x++) {
    //     marks.push({
    //         value: x,
    //         label: weekdays[(today + x) % 7]
    //     });
    // }

    const timeOfDayMarks: SliderMarks = {
        0: "00:00",
        1: "12:00",
        2: "24:00"
    };

    const weekMarks: SliderMarks = {
        0: "This Week",
        1: "+ 1 Week",
        2: "+ 2 Weeks"
    };

    const getIconFromValue = (value: number) => {
        if (value === 0) {
            return <img src={MidMorningWhiteIcon} alt="Morning" style={{ width: "16px" }} />;
        } else if (value === 1) {
            return <img src={AfternoonWhiteIcon} alt="Afternoon" style={{ width: "16px" }} />;
        } else if (value === 2) {
            return <img src={LateNightWhiteIcon} alt="Evening/Night" style={{ width: "16px" }} />;
        }
    };

    return (
        <div style={{ marginTop: "20px" }}>
            <div className={classes.root}>
                <span style={{ color: colors.scheme.primaryText, fontWeight: "bold", marginBottom: "5px" }}>Weekly Forecast: {moment().format("MMMM Do")}</span>
                <Slidey
                    marks={weekMarks}
                    min={0}
                    max={2}
                    defaultValue={0}
                    step={null}
                    tooltipVisible={false}
                    //onChange={(value) => dispatch(visualizationsActions.updateDay(((value as number) + today) % 7))}
                    onChange={(value) => dispatch(visualizationsActions.updateDay((value as number)))}
    />*
            </div>
            <div className={classes.root} style={{ paddingTop: "20px" }}>
                <span style={{ color: colors.scheme.primaryText, fontWeight: "bold", marginBottom: "10px" }}>Time of Day: {TIMEWINDOW_LABELS[timeWindow]}</span>
                <div style={{ display: "flex", justifyContent: "space-between", width: "334px" }}>
                    <img
                        src={colors.mode === "light" ? EarlyMorningGreyIcon : EarlyMorningWhiteIcon}
                        alt="morning"
                        style={{ width: "20px", height: "20px", marginLeft: "8px" }}
                    />
                    <img src={colors.mode === "light" ? AfternoonGreyIcon : AfternoonWhiteIcon} alt="afternoon" style={{ width: "20px", height: "20px" }} />
                    <img
                        src={colors.mode === "light" ? LateNightGreyIcon : LateNightWhiteIcon}
                        alt="evening/night"
                        style={{ width: "20px", height: "20px", marginRight: "8px" }}
                    />
                </div>
                <Slidey
                    marks={timeOfDayMarks}
                    min={0}
                    max={2}
                    defaultValue={0}
                    step={null}
                    tooltipVisible={false}
                    onChange={(value) => dispatch(visualizationsActions.updateTimeWindow(value as number))}
                />
            </div>
        </div>
    );
}

const Slidey = styled(Slider)`
    width: 100%;

    .ant-slider-mark-text {
        color: ${({ theme }) => theme.primaryText} !important;
    }
`;
