import React, { useState, useEffect } from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { reportsActions } from "../../state/reports";
import { useDispatch } from "react-redux";
import { FormFieldContainer, RadioGroup, RadioButton, Dot } from "../_shared";
import { Badge } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { UserProfile, Team } from "../../types/client";
import { filterActions, FilterState, Filter_TimePeriod } from "../../state/filter";
import { SelectValue } from "antd/lib/select";
import { getContainerViewportFromId, getDefaultRegionViewportFromId, S3Key } from "../../utils";
import { mapActions } from "../../state/map";
import { clientInfoOperations } from "../../state/clientInfo";
import { Button } from "../filter/styled";
import _, { isEqual } from "lodash";
import { leftModalActions } from "../../state/left-modal";
import { DefaultRegionNoViewport } from "../analytics";
import { ComponentFilter } from "../filter/ComponentFilter";

const FunnelWhiteIcon = S3Key + "funnel-white.png";
const FunnelGreyIcon = S3Key + "funnel-grey.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const users = useSelectFromRedux((state) => state.clientInfo.users);
    const reportsSetting = useSelectFromRedux((state) => state.reports.reportsSetting);
    const colors = useSelectFromRedux((state) => state.color);
    const userProfile = useSelectFromRedux((state) => state.cuser.userProfile);
    const unconfirmedAlerts = useSelectFromRedux((state) => state.clientInfo.unconfirmedAlerts);
    const unaddressedAlerts = useSelectFromRedux((state) => state.clientInfo.unaddressedAlerts);
    const clientUsers = useSelectFromRedux((state) => state.clientInfo.users);
    const dispatch = useDispatch();
    const [filterOpen, updateFilterOpen] = useState<boolean>(false);
    const cuser = useSelectFromRedux((state) => state.cuser);
    const current_container = useSelectFromRedux((state) => state.cuser.userProfile?.current_container);
    const defaultRegions = useSelectFromRedux((state) => state.cuser.defaultRegions);
    const containers = useSelectFromRedux((state) => state.cuser.containers);
    const currentFilter = useSelectFromRedux((state) => state.filter);
    const tooltipOpen = useSelectFromRedux((state) => state.map.showTooltip);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const cuserTeam = teams.filter((team) => team.id === cuser?.userProfile?.team_id)[0] ?? null;

    const [defaultTeams, updateDefaultTeams] = useState<Team[]>(teams);
    const [violentChecked, updateViolentChecked] = useState<boolean>(currentFilter.violentIncluded);
    const [nonviolentChecked, updateNonviolentChecked] = useState<boolean>(currentFilter.nonviolentIncluded);
    const [hazardChecked, updateHazardChecked] = useState<boolean>(currentFilter.hazardIncluded);
    const [otherChecked, updateOtherChecked] = useState<boolean>(currentFilter.otherIncluded);
    const [filteredUsers, updateFilteredUsers] = useState<number[]>(currentFilter.filteredUsers);
    const defaultUsers = Array.from(new Set(clientUsers.map((profile: UserProfile) => profile.id)));
    const [incidentsChecked, updateIncidentsChecked] = useState<boolean>(currentFilter.incidentsIncluded);
    const [sosChecked, updateSosChecked] = useState<boolean>(currentFilter.sosIncluded);
    const [myReportsChecked, updateMyReportsChecked] = useState<boolean>(currentFilter.myReportsIncluded);
    const [myTeamChecked, updateMyTeamChecked] = useState<boolean>(currentFilter.myTeamIncluded ? currentFilter.myTeamIncluded : true);
    const [myTeamCheckedApplied, updateMyTeamCheckedApplied] = useState<boolean>(currentFilter.myTeamIncluded ? currentFilter.myTeamIncluded : true);
    const [myOrganizationChecked, updateMyOrganizationChecked] = useState<boolean>(currentFilter.myOrganizationIncluded);

    // applied changes
    const [regionChange, updateRegionChange] = useState<number>(currentFilter.selectedRegion);
    const [mapRegionChange, updateMapRegionChange] = useState<number>(currentFilter.selectedRegion);
    const [violentCheckedApplied, updateViolentCheckedApplied] = useState<boolean>(currentFilter.violentIncluded);
    const [nonviolentCheckedApplied, updateNonviolentCheckedApplied] = useState<boolean>(currentFilter.nonviolentIncluded);
    const [hazardCheckedApplied, updateHazardCheckedApplied] = useState<boolean>(currentFilter.hazardIncluded);
    const [otherCheckedApplied, updateOtherCheckedApplied] = useState<boolean>(currentFilter.otherIncluded);
    const [filteredUsersApplied, updateFilteredUsersApplied] = useState<number[]>(currentFilter.filteredUsers);
    const [incidentsCheckedApplied, updateIncidentsCheckedApplied] = useState<boolean>(currentFilter.incidentsIncluded);
    const [sosCheckedApplied, updateSosCheckedApplied] = useState<boolean>(currentFilter.sosIncluded);
    const [myReportsCheckedApplied, updateMyReportsCheckedApplied] = useState<boolean>(currentFilter.myReportsIncluded);
    const [myOrganizationCheckedApplied, updateMyOrganizationCheckedApplied] = useState<boolean>(currentFilter.myOrganizationIncluded);
    const [teamFilterApplied, updateTeamFilterApplied] = useState<number | null>(currentFilter.teamFilter ? currentFilter.teamFilter : null);
    const [teamAutofillValue, updateTeamAutofillValue] = useState<string | undefined>();
    const [userAutofillValue, updateUserAutofillValue] = useState<string | undefined>(undefined);
    const [individualUserChecked, updateIndividualUserChecked] = useState<boolean>(false);
    const [individualTeamChecked, updateIndividualTeamChecked] = useState<boolean>(false);
    const [teamFilter, updateTeamFilter] = useState<number | null>(currentFilter.teamFilter ? currentFilter.teamFilter : null);
    const [eventTypeSearchOpen, updateEventTypeSearchOpen] = useState<boolean>(false);
    const [selectedEventTypes, updateSelectedEventTypes] = useState<string[]>(currentFilter.eventTypeApplied ?? []);
    const [appliedEventTypes, updateAppliedEventTypes] = useState<string[]>(currentFilter.eventTypeApplied ?? []);
    const [eventChecked, updateEventChecked] = useState<boolean>(false);

    // Front-end options for each dropdown – reflect filterState but with 'All' instead of null for display
    const [selectedTimePeriod, updateTimePeriod] = useState<string>(currentFilter.timePeriod === null ? "All Time" : currentFilter.timePeriod);
    const [sort, updateSort] = useState<"Time Submitted" | "Time Occurred">("Time Occurred");

    const userNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    users
                        .filter((user) => user.id !== cuser.userProfile?.id)
                        .map((user: UserProfile) => ({
                            value: user.first_name + " " + user.last_name + " (" + user.email + ")",
                            key: user.id
                        }))
                )
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [users]
    );

    useEffect(() => {
        if (!(cuser.userProfile?.team_id === null && cuser.userProfile.role === 1)) {
            let filteredTeams;

            if ((cuserTeam !== null && cuserTeam.permissions === 0) || cuser.userProfile?.team_id === null) {
                filteredTeams = defaultTeams.filter((team) => team.permissions === 0 || team.id === cuser.userProfile?.team_id);
            }

            if (cuserTeam !== null && cuserTeam.permissions === 1) {
                filteredTeams = defaultTeams.filter((team) => team.id === cuserTeam.id || team.id === cuser.userProfile?.team_id);
            }

            if (filteredTeams) {
                updateDefaultTeams(filteredTeams);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let teamNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    defaultTeams.map((team: Team) => ({
                        value: team.name,
                        key: team.id
                    }))
                )
            ),
        [defaultTeams]
    );

    if (cuserTeam !== null) {
        teamNames = teamNames.filter((teamName) => teamName.key !== cuserTeam.id);
        teamNames = [{ value: "Principal Group", key: 0 }, ...teamNames];
    }

    const [selectedTimePeriodApplied, updateTimePeriodApplied] = useState<string>(currentFilter.timePeriod === null ? "All Time" : currentFilter.timePeriod);
    const [sortApplied, updateSortApplied] = useState<"Time Submitted" | "Time Occurred">("Time Occurred");

    const removeFromFilteredUsers = (id: number) => {
        const userString = userNames.find((userName) => userName.key === id);
        updateUserAutofillValue(userString?.value);
        const usersArray = Array.from(new Set(clientUsers.map((profile: UserProfile) => profile.id)));
        updateFilteredUsers(usersArray.filter((value) => value !== id));
        updateMyReportsChecked(false);
        updateMyOrganizationChecked(false);
    };

    useEffect(() => {
        if (filteredUsers.length !== 0) {
            const allUsers = Array.from(new Set(clientUsers.map((profile: UserProfile) => profile.id)));
            const difference = _.difference(allUsers, filteredUsers);
            const selectedUser = userNames.find((userName) => userName.key === difference[0]);
            updateUserAutofillValue(selectedUser?.value);
        }
    }, [clientUsers, filteredUsers, userNames]);

    useEffect(() => {
        if (teamFilter !== null) {
            const teamString = teamNames.find((teamName) => teamName.key === teamFilter);
            updateTeamAutofillValue(teamString?.value);
        }
    }, [teamFilter, teamNames]);

    const setTeamFilter = (id: number) => {
        const teamString = teamNames.find((teamName) => teamName.key === id);
        updateTeamAutofillValue(teamString?.value);
        updateTeamFilter(id);
        updateMyReportsChecked(false);
        updateMyOrganizationChecked(false);
        updateUserAutofillValue(undefined);
        updateIndividualUserChecked(false);
    };

    const updateFilter = () => {
        if (currentContainer !== containerChange && currentContainer > -1) {
            setCurrentContainer(currentContainer);
        }

        if (currentContainer < 0) {
            updateContainerChange(currentContainer);
        }

        if (regionChange !== selectedRegion) {
            setSelectedRegion(selectedRegion);
            updateRegionChange(selectedRegion);
        }

        updateViolentCheckedApplied(violentChecked);
        updateNonviolentCheckedApplied(nonviolentChecked);
        updateHazardCheckedApplied(hazardChecked);
        updateOtherCheckedApplied(otherChecked);
        updateFilteredUsersApplied(filteredUsers.length < defaultUsers.length ? filteredUsers : []);
        updateIncidentsCheckedApplied(incidentsChecked);
        updateSosCheckedApplied(sosChecked);
        updateMyReportsCheckedApplied(myReportsChecked);
        updateMyOrganizationCheckedApplied(myOrganizationChecked);
        updateTimePeriodApplied(selectedTimePeriod);
        updateSortApplied(sort);
        updateIndividualUserChecked(false);
        updateTeamFilterApplied(teamFilter);
        updateMyTeamCheckedApplied(myTeamChecked);
        updateAppliedEventTypes(selectedEventTypes);

        if (isMobile && filterChanged) {
            setFilterOpen(false);
        }
    };

    const clearPostedBy = async () => {
        updateMyReportsChecked(false);
        updateMyOrganizationChecked(false);
        updateFilteredUsers([]);
        updateUserAutofillValue(undefined);
        updateIndividualUserChecked(false);
        updateTeamFilter(null);
        updateTeamAutofillValue(undefined);
        updateIndividualTeamChecked(false);
    };

    const [containerUpdatedFromComponent, updateContainerUpdatedFromComponent] = useState<boolean>(false);

    useEffect(() => {
        if (containerChange !== -2) {
            updateContainerChange(current_container ?? -2);
            updateCurrentContainer(current_container ?? -2);
            if (!containerUpdatedFromComponent) {
                updateSelectedRegion(0);
                setSelectedRegion(0);
                updateRegionChange(0);
            }
            updateContainerUpdatedFromComponent(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_container]);

    const resetFilter = () => {
        updateUserAutofillValue(undefined);
        updateSelectedRegion(0);
        setSelectedRegion(0);
        updateRegionChange(0);
        updateCurrentContainer(-2);
        updateContainerChange(-2);
        updateViolentChecked(false);
        updateNonviolentChecked(false);
        updateHazardChecked(false);
        updateOtherChecked(false);
        updateFilteredUsers([]);
        updateIncidentsChecked(false);
        updateSosChecked(false);
        updateMyReportsChecked(false);
        updateMyOrganizationChecked(false);
        updateTimePeriod("28 Day");
        updateSort("Time Occurred");
        updateViolentCheckedApplied(false);
        updateNonviolentCheckedApplied(false);
        updateHazardCheckedApplied(false);
        updateOtherCheckedApplied(false);
        updateFilteredUsersApplied([]);
        updateIncidentsCheckedApplied(false);
        updateSosCheckedApplied(false);
        updateMyReportsCheckedApplied(false);
        updateMyOrganizationCheckedApplied(false);
        updateTimePeriodApplied("28 Day");
        updateSortApplied("Time Occurred");
        updateIndividualUserChecked(false);
        updateTeamFilter(null);
        updateTeamFilterApplied(null);
        updateTeamAutofillValue(undefined);
        updateIndividualTeamChecked(false);
        updateMyTeamCheckedApplied(true);
        updateMyTeamChecked(true);
        updateAppliedEventTypes([]);
        updateSelectedEventTypes([]);

        if (isMobile && filterChangedFromDefault) {
            setFilterOpen(false);
        }
    };

    const updateMap = () => {
        if (mapFilterChanged) {
            if (tooltipOpen) {
                dispatch(mapActions.toggleTooltip());
            }
            dispatch(
                filterActions.updateFilter({
                    ...currentFilter,
                    violentIncluded: violentCheckedApplied,
                    nonviolentIncluded: nonviolentCheckedApplied,
                    hazardIncluded: hazardCheckedApplied,
                    otherIncluded: otherCheckedApplied,
                    selectedRegion: regionChange,
                    incidentsIncluded: incidentsCheckedApplied,
                    sosIncluded: sosCheckedApplied,
                    filteredUsers: filteredUsers.length < defaultUsers.length ? filteredUsers : [],
                    myOrganizationIncluded: myOrganizationCheckedApplied,
                    myReportsIncluded: myReportsCheckedApplied,
                    orionUserIncluded: false,
                    orionSourceIncluded: false,
                    openSourceIncluded: false,
                    timePeriod: selectedTimePeriodApplied as Filter_TimePeriod,
                    teamFilter: teamFilterApplied,
                    myTeamIncluded: myTeamCheckedApplied,
                    eventTypeApplied: appliedEventTypes
                })
            );

            updatemapContainerChange(containerChange);

            if (mapRegionChange !== regionChange && containerChange > -1) {
                updateMapRegionChange(regionChange);
                if (regionChange !== 0) {
                    const regionViewportInfo = getDefaultRegionViewportFromId(regionChange, availableRegions);
                    dispatch(mapActions.flyToPoint([regionViewportInfo.longitude, regionViewportInfo.latitude, regionViewportInfo.zoom]));
                } else {
                    if (!current_container || !containers) {
                        return;
                    }
                    const containerViewportInfo = getContainerViewportFromId(current_container, containers);
                    dispatch(mapActions.flyToPoint([containerViewportInfo.longitude, containerViewportInfo.latitude, containerViewportInfo.zoom]));
                }
                // TEMPORARY FIX: flyTo seems to interfere with filter, and this solves it
                dispatch(clientInfoOperations.fetchClientInfo());
            }

            if (current_container && mapContainerChange !== containerChange && mapRegionChange === regionChange) {
                const containerViewportPoint = getContainerViewportFromId(current_container, availableContainers);
                dispatch(mapActions.flyToPoint([containerViewportPoint.longitude, containerViewportPoint.latitude, containerViewportPoint.zoom]));
            }
        }
    };

    const initCurrentContainer = cuser.userProfile?.current_container;
    if (!initCurrentContainer) {
        console.error("Could not get current container in drawer.");
        return null;
    }
    const [currentContainer, updateCurrentContainer] = useState<number>(-2);
    const availableRegions = defaultRegions.filter((region) => region.container_id === currentContainer);
    if (!availableRegions || !current_container) {
        console.error("Container has no regions? Returning");
        return null;
    }
    const defaultAllRegion: DefaultRegionNoViewport = {
        id: 0,
        name: "All Areas",
        container_id: 0
    };
    const regionSelections = [defaultAllRegion, ...availableRegions];
    const [selectedRegion, updateSelectedRegion] = useState<number>(0);
    const [containerChange, updateContainerChange] = useState<number>(-2);
    const [mapContainerChange, updatemapContainerChange] = useState<number>(-2);

    const updateCurrentContainerAndRegions = (container: number) => {
        updateCurrentContainer(container);
        updateSelectedRegion(0);
    };

    const availableContainers = useSelectFromRedux((state) => state.cuser.containers);
    if (!availableContainers) {
        console.error("Could not get containers in drawer.");
        return null;
    }

    const setCurrentContainer = (value: SelectValue) => {
        if (typeof value !== "number") {
            console.error("Invalid container id selected: " + value);
        }
        updateContainerUpdatedFromComponent(true);
        updateContainerChange(value as number);
    };

    const setSelectedRegion = (value: number) => {
        updateSelectedRegion(value as number);
    };

    const [filterChanged, updateFilterChanged] = useState<boolean>(false);

    const [filterChangedFromDefault, updateFilterChangedFromDefault] = useState<boolean>(false);

    const [mapFilterChanged, updateMapFilterChanged] = useState<boolean>(false);

    const [appliedFilterChangedFromDefault, updateAppliedFilterChangedFromDefault] = useState<boolean>(false);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            incidentsIncluded: incidentsCheckedApplied,
            sosIncluded: sosCheckedApplied,
            myReportsIncluded: myReportsCheckedApplied,
            violentIncluded: violentCheckedApplied,
            nonviolentIncluded: nonviolentCheckedApplied,
            hazardIncluded: hazardCheckedApplied,
            otherIncluded: otherCheckedApplied,
            selectedRegion: regionChange,
            filteredUsers: filteredUsers.length < defaultUsers.length ? filteredUsers : [],
            myOrganizationIncluded: myOrganizationCheckedApplied,
            teamFilter: teamFilterApplied,
            myTeamIncluded: myTeamCheckedApplied,
            eventTypeApplied: appliedEventTypes
        };

        const defaultFilter: FilterState = {
            ...currentFilter,
            incidentsIncluded: false,
            sosIncluded: false,
            myReportsIncluded: false,
            violentIncluded: false,
            nonviolentIncluded: false,
            hazardIncluded: false,
            otherIncluded: false,
            selectedRegion: 0,
            filteredUsers: [],
            myOrganizationIncluded: false,
            teamFilter: null,
            myTeamIncluded: true,
            eventTypeApplied: []
        };
        updateAppliedFilterChangedFromDefault(!isEqual(changedFilter, defaultFilter));
    }, [
        currentFilter,
        filteredUsers,
        filteredUsersApplied,
        hazardChecked,
        hazardCheckedApplied,
        incidentsChecked,
        incidentsCheckedApplied,
        myOrganizationChecked,
        myOrganizationCheckedApplied,
        myReportsChecked,
        myReportsCheckedApplied,
        nonviolentChecked,
        nonviolentCheckedApplied,
        otherChecked,
        otherCheckedApplied,
        regionChange,
        selectedRegion,
        sosChecked,
        sosCheckedApplied,
        violentChecked,
        violentCheckedApplied,
        defaultUsers,
        teamFilter,
        teamFilterApplied,
        myTeamChecked,
        myTeamCheckedApplied,
        appliedEventTypes
    ]);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            incidentsIncluded: incidentsChecked,
            sosIncluded: sosChecked,
            myReportsIncluded: myReportsChecked,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            otherIncluded: otherChecked,
            selectedRegion,
            filteredUsers: filteredUsers.length < defaultUsers.length ? filteredUsers : [],
            myOrganizationIncluded: myOrganizationChecked,
            timePeriod: selectedTimePeriod as Filter_TimePeriod,
            teamFilter,
            myTeamIncluded: myTeamChecked,
            eventTypeApplied: appliedEventTypes
        };

        const defaultFilter: FilterState = {
            ...currentFilter,
            incidentsIncluded: false,
            sosIncluded: false,
            myReportsIncluded: false,
            violentIncluded: false,
            nonviolentIncluded: false,
            hazardIncluded: false,
            otherIncluded: false,
            selectedRegion: 0,
            filteredUsers: [],
            myOrganizationIncluded: false,
            timePeriod: "28 Day",
            myTeamIncluded: true,
            eventTypeApplied: []
        };
        updateFilterChangedFromDefault(!isEqual(changedFilter, defaultFilter) || sort !== "Time Occurred");
    }, [
        currentFilter,
        filteredUsers,
        hazardChecked,
        incidentsChecked,
        myOrganizationChecked,
        myReportsChecked,
        nonviolentChecked,
        otherChecked,
        selectedRegion,
        selectedTimePeriod,
        sort,
        sosChecked,
        violentChecked,
        defaultUsers,
        teamFilter,
        myTeamChecked,
        appliedEventTypes
    ]);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            incidentsIncluded: incidentsChecked,
            sosIncluded: sosChecked,
            myReportsIncluded: myReportsChecked,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            otherIncluded: otherChecked,
            selectedRegion,
            filteredUsers: filteredUsers.length < defaultUsers.length ? filteredUsers : [],
            myOrganizationIncluded: myOrganizationChecked,
            timePeriod: selectedTimePeriod as Filter_TimePeriod,
            teamFilter,
            myTeamIncluded: myTeamChecked,
            eventTypeApplied: selectedEventTypes
        };

        const existingFilter: FilterState = {
            ...currentFilter,
            incidentsIncluded: incidentsCheckedApplied,
            sosIncluded: sosCheckedApplied,
            myReportsIncluded: myReportsCheckedApplied,
            violentIncluded: violentCheckedApplied,
            nonviolentIncluded: nonviolentCheckedApplied,
            hazardIncluded: hazardCheckedApplied,
            otherIncluded: otherCheckedApplied,
            selectedRegion: regionChange,
            filteredUsers: filteredUsersApplied,
            myOrganizationIncluded: myOrganizationCheckedApplied,
            timePeriod: selectedTimePeriodApplied as Filter_TimePeriod,
            teamFilter: teamFilterApplied,
            myTeamIncluded: myTeamCheckedApplied,
            eventTypeApplied: appliedEventTypes
        };

        updateFilterChanged(!isEqual(changedFilter, existingFilter) || currentContainer !== containerChange || sort !== sortApplied);
    }, [
        selectedEventTypes,
        containerChange,
        currentContainer,
        currentFilter,
        current_container,
        filteredUsers,
        filteredUsersApplied,
        hazardChecked,
        hazardCheckedApplied,
        incidentsChecked,
        incidentsCheckedApplied,
        myOrganizationChecked,
        myOrganizationCheckedApplied,
        myReportsChecked,
        myReportsCheckedApplied,
        nonviolentChecked,
        nonviolentCheckedApplied,
        otherChecked,
        otherCheckedApplied,
        regionChange,
        selectedRegion,
        selectedTimePeriod,
        selectedTimePeriodApplied,
        sort,
        sortApplied,
        sosChecked,
        sosCheckedApplied,
        violentChecked,
        violentCheckedApplied,
        defaultUsers,
        teamFilter,
        teamFilterApplied,
        myTeamChecked,
        myTeamCheckedApplied,
        appliedEventTypes
    ]);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            incidentsIncluded: incidentsCheckedApplied,
            sosIncluded: sosCheckedApplied,
            violentIncluded: violentCheckedApplied,
            nonviolentIncluded: nonviolentCheckedApplied,
            hazardIncluded: hazardCheckedApplied,
            otherIncluded: otherCheckedApplied,
            selectedRegion: regionChange,
            filteredUsers: filteredUsersApplied.length < defaultUsers.length ? filteredUsersApplied : [],
            myOrganizationIncluded: myOrganizationCheckedApplied,
            myReportsIncluded: myReportsCheckedApplied,
            orionUserIncluded: false,
            orionSourceIncluded: false,
            openSourceIncluded: false,
            timePeriod: selectedTimePeriodApplied as Filter_TimePeriod,
            teamFilter: teamFilterApplied,
            myTeamIncluded: myTeamCheckedApplied,
            eventTypeApplied: appliedEventTypes
        };

        updateMapFilterChanged(!isEqual(changedFilter, currentFilter) || containerChange !== mapContainerChange);
    }, [
        mapContainerChange,
        currentFilter,
        current_container,
        filteredUsers,
        filteredUsersApplied,
        hazardChecked,
        hazardCheckedApplied,
        incidentsChecked,
        incidentsCheckedApplied,
        myReportsChecked,
        myReportsCheckedApplied,
        nonviolentChecked,
        nonviolentCheckedApplied,
        otherChecked,
        otherCheckedApplied,
        regionChange,
        selectedRegion,
        sosChecked,
        sosCheckedApplied,
        violentChecked,
        violentCheckedApplied,
        myOrganizationCheckedApplied,
        containerChange,
        selectedTimePeriodApplied,
        defaultUsers,
        teamFilter,
        teamFilterApplied,
        myTeamChecked,
        myTeamCheckedApplied,
        appliedEventTypes
    ]);

    const setFilterOpen = (value: boolean) => {
        updateFilterOpen(value);
        dispatch(leftModalActions.setFilterOpen(value));
    };

    const setIndividualUserChecked = () => {
        if (individualUserChecked === true) {
            updateUserAutofillValue(undefined);
        } else {
            updateMyReportsChecked(false);
            updateMyTeamChecked(false);
            updateTeamAutofillValue(undefined);
            updateIndividualTeamChecked(false);
        }
        updateIndividualUserChecked(!individualUserChecked);
    };

    const setIndividualTeamChecked = () => {
        if (individualTeamChecked === true) {
            updateTeamAutofillValue(undefined);
        } else {
            updateMyReportsChecked(false);
            updateMyTeamChecked(false);
            updateUserAutofillValue(undefined);
            updateIndividualUserChecked(false);
        }
        updateIndividualTeamChecked(!individualTeamChecked);
    };

    const setSelectedEventTypes = (value: string, operation: string) => {
        if (operation === "add") {
            if (!selectedEventTypes.includes(value)) {
                const newSelectedEventTypes = selectedEventTypes.concat(value);
                updateSelectedEventTypes(newSelectedEventTypes);
            }
        } else if (operation === "delete") {
            if (selectedEventTypes.includes(value)) {
                const selectedEventTypesCopy = [...selectedEventTypes];
                const index = selectedEventTypesCopy.indexOf(value);
                selectedEventTypesCopy.splice(index, 1);
                updateSelectedEventTypes(selectedEventTypesCopy);
            }
        } else if (operation === "clear") {
            updateSelectedEventTypes([]);
        }
    };

    return (
        <>
            <div
                style={{
                    height: "60px",
                    minHeight: "60px",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    // marginBottom: isSafari || isIOS ? "50px" : "-10px",
                    // marginBottom: "50px",
                    filter: filterOpen && isMobile ? "blur(4px)" : "none"
                }}
            >
                <FormFieldContainer background={colors.scheme.selectorBackground} style={{ width: "calc(100% - 40px)" }}>
                    <RadioGroup
                        defaultValue="reports"
                        value={reportsSetting}
                        buttonStyle="solid"
                        textColor={colors.scheme.selectorLightText}
                        selectedTextColor={colors.scheme.selectorDarkText}
                        selectedColor={colors.scheme.selectedOptionBackground}
                    >
                        <RadioButton
                            value="reports"
                            onClick={() =>
                                dispatch(
                                    reportsActions.toggleReportsSetting({
                                        reportsSetting: "reports"
                                    })
                                )
                            }
                        >
                            INCIDENTS
                        </RadioButton>
                        <RadioButton
                            value="alert-reports"
                            onClick={() =>
                                dispatch(
                                    reportsActions.toggleReportsSetting({
                                        reportsSetting: "alert-reports"
                                    })
                                )
                            }
                        >
                            <Badge
                                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                                color={"error"}
                                badgeContent={unconfirmedAlerts + unaddressedAlerts}
                                variant={"dot"}
                            >
                                <div style={{ marginTop: "-5px" }}>SOS</div>
                            </Badge>
                        </RadioButton>
                    </RadioGroup>
                </FormFieldContainer>
                {userProfile?.features?.filtering !== false && (
                    <Dot
                        background={colors.scheme.submitButtonBackground}
                        anchorOrigin={{ horizontal: "left", vertical: "top" }}
                        variant={"dot"}
                        color={"primary"}
                        invisible={!appliedFilterChangedFromDefault}
                        style={{
                            position: "absolute",
                            right: "20px",
                            top: "50px"
                        }}
                    >
                        <img
                            src={colors.mode === "light" ? FunnelGreyIcon : FunnelWhiteIcon}
                            alt="Filter"
                            style={{
                                height: "35px",
                                cursor: "pointer",
                                filter: filterOpen && isMobile ? "blur(4px)" : "none"
                            }}
                            onClick={() => setFilterOpen(true)}
                        />
                    </Dot>
                )}
            </div>
            <div
                style={{
                    width: "calc(100% + 40px)",
                    filter: filterOpen && isMobile ? "blur(4px)" : "none",
                    background: colors.scheme.modalBottomBar,
                    margin: "0 -20px -20px -20px",
                    borderRadius: "0 0 14px 14px"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignSelf: "center",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: "5px",
                        marginBottom: "5px"
                    }}
                >
                    <Button
                        style={{ width: "150px" }}
                        onClick={() => {
                            updateMap();
                        }}
                        active={mapFilterChanged}
                        color={mapFilterChanged ? colors.scheme.submitButtonText : "#4C4C4C"}
                        background={mapFilterChanged ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag}
                    >
                        Apply To Map
                    </Button>
                </div>
            </div>
            {filterOpen && (
                <ComponentFilter
                    setFilterOpen={setFilterOpen}
                    currentContainer={currentContainer}
                    updateCurrentContainerAndRegions={updateCurrentContainerAndRegions}
                    regionSelections={regionSelections}
                    selectedRegion={selectedRegion}
                    updateSelectedRegion={updateSelectedRegion}
                    clearPostedBy={clearPostedBy}
                    individualUserChecked={individualUserChecked}
                    removeFromFilteredUsers={removeFromFilteredUsers}
                    updateIndividualUserChecked={updateIndividualUserChecked}
                    userAutofillValue={userAutofillValue}
                    individualTeamChecked={individualTeamChecked}
                    updateIndividualTeamChecked={updateIndividualTeamChecked}
                    setTeamFilter={setTeamFilter}
                    teamAutofillValue={teamAutofillValue}
                    myReportsChecked={myReportsChecked}
                    myTeamChecked={myTeamChecked}
                    violentChecked={violentChecked}
                    nonviolentChecked={nonviolentChecked}
                    hazardChecked={hazardChecked}
                    otherChecked={otherChecked}
                    updateMyReportsChecked={updateMyReportsChecked}
                    updateMyTeamChecked={updateMyTeamChecked}
                    updateUserAutofillValue={updateUserAutofillValue}
                    updateTeamAutofillValue={updateTeamAutofillValue}
                    setIndividualUserChecked={setIndividualUserChecked}
                    setIndividualTeamChecked={setIndividualTeamChecked}
                    updateViolentChecked={updateViolentChecked}
                    updateNonviolentChecked={updateNonviolentChecked}
                    updateHazardChecked={updateHazardChecked}
                    updateOtherChecked={updateOtherChecked}
                    eventTypeSearchOpen={eventTypeSearchOpen}
                    setSelectedEventTypes={setSelectedEventTypes}
                    updateEventTypeSearchOpen={updateEventTypeSearchOpen}
                    selectedEventTypes={selectedEventTypes}
                    eventChecked={eventChecked}
                    updateEventChecked={updateEventChecked}
                    selectedTimePeriod={selectedTimePeriod}
                    updateTimePeriod={updateTimePeriod}
                    sort={sort}
                    updateSort={updateSort}
                    filterChanged={filterChanged}
                    filterChangedFromDefault={filterChangedFromDefault}
                    updateFilter={updateFilter}
                    resetFilter={resetFilter}
                />
            )}
        </>
    );
};
