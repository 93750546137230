import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import { buttonOrange } from "../../utils";

interface SnackbarProps {
    background: string;
}

export const Error = styled(Snackbar)`
    align-content: center;
    text-align: center;
    justify-content: center;
    .MuiSnackbarContent-root {
        background: ${buttonOrange} !important;
    }

    .MuiSnackbarContent-root {
        background: ${(props: SnackbarProps) => props.background} !important;
    }
`;
