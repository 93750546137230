import { PolygonLayer, GeoJsonLayer, IconLayer, PathLayer } from "@deck.gl/layers";
import chroma from "chroma-js";
import moment from "moment";
import store from "../../../state/store";
import { visualizationsActions } from "../../../state/visualizations";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import { DrawLineStringMode, ViewMode } from "@nebula.gl/edit-modes";
import { getMatch } from "./utils";
import { filterGridByRiskIndex, RISK_INDEX_COLORS } from "../../visualizations/utils";
import geojson2h3 from "geojson2h3";

var smooth = require("chaikin-smooth");

export default function renderLayers({
    data: { gridData, zoneData, safeRouteData, originData, destinationData },
    // data: { gridData, safeRouteData, originData, destinationData },
    filter: { day, timeWindow, selectedRoute, riskIndexFilter },
    visible,
    onClick,
    source
}) {
    const scale = chroma.scale(RISK_INDEX_COLORS);
    // var zoneScale = chroma.scale(["#5A1846", "#900C3F", "#C70039"].reverse());

    const layers = [];

    // for (let x = 0; x < 7; x++) {
    //     layers.push(
    //         new PolygonLayer({
    //             id: `hotspot-zone-${x}`,
    //             data: zoneData.filter((d) => moment(d.properties.ra_date).weekday() === x),
    //             getPolygon: (d) => d.geometry.coordinates,
    //             opacity: 0.1,
    //             visible: visible && x === day,
    //             pickable: true,
    //             stroked: true,
    //             extruded: false,
    //             filled: true,
    //             getFillColor: (d) => zoneScale(d.properties.risk_score_pct).rgb(),
    //             //onClick, (commenting this out blocks hotspot tooltips, which is the desired behavior)
    //             lineWidthUnits: "pixels",
    //             getLineColor: [255, 0, 0],
    //             getLineWidth: 1
    //         })
    //     );
    // }
    // // @ MIKELEV crime grid render layer, note that gridData is equal to "response.data.features"
    // for (let x = 0; x < 7; x++) {
    //     layers.push(
    //         new PolygonLayer({
    //             id: `hotspot-grid-${x}`,
    //             data: gridData.filter((d) => moment(d.properties.ra_date).weekday() === x),
    //             getPolygon: (d) => d.geometry.coordinates,
    //             opacity: 0.3,
    //             visible: x === day,
    //             pickable: true,
    //             stroked: true,
    //             extruded: false,
    //             filled: true,
    //             getFillColor: (d) => scale(d.properties.risk_score_pct).rgb(),
    //             onClick,
    //             lineWidthUnits: "pixels",
    //             getLineColor: [255, 0, 0],
    //             lineWidthScale: 12,
    //             getLineWidth: (d) => d.properties.risk_score_pct - 0.75
    //         })
    //     );
    // }

    // let count = 0;
    // layers.push(
    //     new PolygonLayer({
    //         id: `hotspot-grid-${1}`,
    //         data: gridData.filter((d) => {
    //             // count++;
    //             // const id = Number(d.properties.id);
    //             // return id >= 379346 && id <= 379346 + 100
    //             if (moment(d.properties.date_time).local().day() === 2 && moment(d.properties.date_time).local().hour() === 4) {
    //                 count++;
    //                 return true;
    //             }
    //             return false;
    //         }),
    //         getPolygon: (d) => d.geometry.coordinates,
    //         opacity: 0.1,
    //         visible: true,
    //         pickable: true,
    //         stroked: true,
    //         extruded: false,
    //         filled: true,
    //         getFillColor: (d) => scale(Number(d.properties.risk_score)).rgb(),
    //         onClick,
    //         lineWidthUnits: "pixels",
    //         getLineColor: [255, 0, 0],
    //         lineWidthScale: 12,
    //         getLineWidth: (d) => Number(d.properties.risk_score) - 0.75
    //     })
    // );
    // console.log("POLYGON COUNT: " + count);

    function getWidth(riskindex) {
        if (riskindex == 5) return 0.25;
        else {
            return 0;
        }
    }

    if (gridData.length !== 0) {
        layers.push(
            new PolygonLayer({
                id: `hotspot-grid-${1}`,
                data: filterGridByRiskIndex(gridData[day][timeWindow], riskIndexFilter),
                getPolygon: (d) => geojson2h3.h3ToFeature(d.h3_cell).geometry.coordinates,
                opacity: 0.1,
                visible: true,
                pickable: true,
                stroked: true,
                extruded: false,
                filled: true,
                //getFillColor: (d) => scale(Number(d.risk_score * 1.6667)).rgb(),
                getFillColor: (d) => scale(Number(d.risk_index * 0.2)).rgb(),
                onClick,
                lineWidthUnits: "pixels",
                getLineColor: [255, 0, 0],
                lineWidthScale: 12,
                //getLineWidth: (d) => (d.risk_index_tmp === 5 ? 0.25 : d.risk_index_tmp === 4 ? 0.1 : 0)
                //getLineWidth: (d) => (d.risk_index === 5 ? 0.25 : d.risk_index === 4 ? 0.1 : 0)
                getLineWidth: (d) => getWidth(d.risk_index)
            })
        );
    }

    safeRouteData.forEach((route, i) => {
        const { features } = route.hotspot_segments;
        const id = i + 1;
        const smoothedRoute = {
            ...route,
            geometry: {
                ...route.geometry,
                coordinates: smooth(route.geometry.coordinates)
            }
        };

        layers.push(
            new GeoJsonLayer({
                id: `safeRoute-base-${id}`,
                data: {
                    type: "FeatureCollection",
                    features: [smoothedRoute]
                },
                opacity: id === selectedRoute ? 1 : 0.2,
                pickable: true,
                stroked: true,
                extruded: false,
                filled: true,
                getLineColor: [110, 158, 239],
                getLineWidth: 7,
                lineWidthUnits: "pixels",
                onClick: (d) => store.dispatch(visualizationsActions.updateSelectedRoute(id))
            })
        );

        for (let x = 0; x < 7; x++) {
            layers.push(
                new GeoJsonLayer({
                    id: `safeRoute-${id}-day-${x}`,
                    data: {
                        type: "FeatureCollection",
                        features: features.filter((d) => moment(d.properties.ra_date).weekday() === x)
                    },
                    visible: x === day,
                    opacity: id === selectedRoute ? 1 : 0.2,
                    pickable: true,
                    stroked: true,
                    extruded: false,
                    filled: false,
                    getLineColor: (d) => scale(d.properties.risk_score_pct).rgb(),
                    getLineWidth: 7,
                    lineWidthUnits: "pixels",
                    onClick: (d) => store.dispatch(visualizationsActions.updateSelectedRoute(id))
                })
            );
        }
    });

    for (let endpoint of [originData, destinationData]) {
        if (endpoint.coordinates.length) {
            layers.push(
                new IconLayer({
                    id: endpoint.id,
                    data: [endpoint],
                    getPosition: (d) => d.coordinates,
                    pickable: true,
                    iconAtlas:
                        endpoint.id === "origin"
                            ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-theft-marker.png"
                            : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-assault-marker.png",
                    iconMapping: {
                        endpoint: {
                            x: 0,
                            y: 0,
                            width: 60,
                            height: 71,
                            anchorY: 71,
                            mask: false
                        }
                    },
                    getIcon: (d) => "endpoint",
                    sizeUnits: "meters",
                    sizeMinPixels: 65,
                    onClick: (e) => {
                        store.dispatch(
                            visualizationsActions.updateCoordinates({
                                endpoint: e.object.id,
                                coordinates: [],
                                clicked: true
                            })
                        );
                        if (source.current) {
                            source.current.cancel("Canceled");
                        }
                        store.dispatch(visualizationsActions.updateSafeRouteData([]));
                    }
                })
            );
        }
    }

    const { fuzzyRouteInput } = store.getState().visualizations;

    const fuzzyRouteInputGeojson = {
        type: "FeatureCollection",
        features: fuzzyRouteInput
    };

    const fuzzyRouteOutput = store.getState().visualizations.fuzzyRouteOutput;

    layers.push(
        new EditableGeoJsonLayer({
            id: "fuzzyRouteInput",
            data: fuzzyRouteInputGeojson,
            visible: fuzzyRouteOutput.length === 0,
            mode: fuzzyRouteInput.length === 0 && store.getState().visualizations.fuzzyRouteEnabled ? DrawLineStringMode : ViewMode,
            selectedFeatureIndexes: [0],
            onEdit: ({ editType, updatedData }) => {
                store.dispatch(visualizationsActions.updateFuzzyRoute(updatedData));
                if (editType === "addFeature") {
                    store.dispatch(visualizationsActions.toggleFuzzyRouteEnabled());
                    var coords = updatedData.features[0].geometry.coordinates;
                    var newCoords = coords.join(";");
                    getMatch(newCoords);
                }
            }
        })
    );

    layers.push(
        new PathLayer({
            id: `fuzzyRouteOutput`,
            data: [fuzzyRouteOutput],
            pickable: true,
            stroked: true,
            extruded: false,
            filled: false,
            getPath: (d) => d,
            getColor: [255, 0, 0],
            getWidth: 7,
            widthUnits: "pixels"
        })
    );

    return layers;
}
