import React, { useEffect, useRef } from "react";
import { DefaultRegion } from "../../types/geo";
import { Dropdown } from "../filter/styled";
import { useSelectFromRedux } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import Select from "antd/lib/select";
import { cuserActions } from "../../state/cuser";
import { filterActions } from "../../state/filter";
import { mapActions } from "../../state/map";
import { getContainerViewportFromId, getDefaultRegionViewportFromId } from "../../utils";
import { forecastingAvailableContainers, getDefaultRegionBearing } from "./utils";
import { visualizationsActions } from "../../state/visualizations";

const { Option } = Select;

export default function ContainerFilter() {
    const colors = useSelectFromRedux((state) => state.color);
    const [currentContainer, defaultRegions, containers, filter] = useSelectFromRedux((state) => [
        state.cuser.userProfile?.current_container,
        state.cuser.defaultRegions,
        state.cuser.containers,
        state.filter
    ]);

    const { selectedRegion } = filter;

    const dispatch = useDispatch();

    const availableRegions = defaultRegions.filter((region) => region.container_id === currentContainer);

    useDidMountEffect(() => {
        if (selectedRegion !== 0 && selectedRegion !== null && availableRegions.length) {
            const regionViewportInfo = getDefaultRegionViewportFromId(selectedRegion, availableRegions);
            const { longitude, latitude, zoom } = regionViewportInfo;
            const bearing = getDefaultRegionBearing(selectedRegion, availableRegions);
            dispatch(mapActions.flyToPointWithBearing([longitude, latitude, zoom, bearing]));
        } else {
            const containerViewportInfo = getContainerViewportFromId(currentContainer!, containers);
            const { longitude, latitude, zoom } = containerViewportInfo;
            dispatch(mapActions.flyToPoint([longitude, latitude, zoom]));
        }
    }, [selectedRegion, currentContainer, containers, dispatch]);

    return (
        <>
            <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", width: "100%", textAlign: "center" }}>Region</div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: "10px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        textAlign: "left",
                        borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText),
                        marginRight: "15px"
                    }}
                >
                    <Dropdown
                        defaultValue={currentContainer || undefined}
                        value={currentContainer}
                        onChange={(value) => {
                            dispatch(visualizationsActions.updateHasStartedForecasting(false));
                            dispatch(cuserActions.updateCurrentContainer(value as number));
                            dispatch(filterActions.updateFilter({ ...filter, selectedRegion: 0 }));
                        }}
                        showArrow={true}
                        color={colors.scheme.cardLightCaptionText}
                        background={"transparent"}
                        bordered={false}
                        arrowcolor={colors.scheme.primaryText}
                        style={{ textAlign: "left" }}
                    >
                        {containers
                            .filter((container) => forecastingAvailableContainers.includes(container.name))
                            .map((container) => {
                                return (
                                    <Option key={container.id} value={container.id}>
                                        {container.name}{" "}
                                    </Option>
                                );
                            })}
                    </Dropdown>
                </div>
                <div
                    style={{
                        display: "flex",
                        borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText),
                        marginLeft: "15px"
                    }}
                >
                    <Dropdown
                        autoFocus={selectedRegion === 0 ? true : false}
                        value={selectedRegion === 0 ? "Select an Area" : selectedRegion}
                        defaultOpen={selectedRegion === 0 ? true : false}
                        onChange={(value) => dispatch(filterActions.updateFilter({ ...filter, selectedRegion: value as number }))}
                        showArrow={true}
                        color={colors.scheme.cardLightCaptionText}
                        arrowcolor={colors.scheme.primaryText}
                        background={"transparent"}
                        bordered={false}
                        style={{ textAlign: "left" }}
                    >
                        {availableRegions.map((region: DefaultRegion) => {
                            return (
                                <Option key={region.id} value={region.id}>
                                    {region.name}{" "}
                                </Option>
                            );
                        })}
                    </Dropdown>
                </div>
            </div>
            <div
                style={{
                    width: "calc(100% + 40px)",
                    display: "flex",
                    alignSelf: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                    background: colors.scheme.filterModalBottomBar,
                    margin: "10px -20px -20px -20px",
                    borderRadius: "0 0 5px 5px"
                }}
            ></div>
        </>
    );
}

const useDidMountEffect = (func: any, deps: any[]) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
