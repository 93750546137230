import LogRocket from "logrocket";

export let logRocketInitialized: boolean = false;

export const initLogRocket = () => {
    const env = process.env.REACT_APP_ENV;
    if (process.env.NODE_ENV !== "development") {
        switch (env) {
            case "local":
                console.log("LogRocket disabled in local development environment.");
                return;
            case "development":
                LogRocket.init("arcturus-intelligence/orion-dev-doo3e");
                logRocketInitialized = true;
                return;
            case "staging":
                LogRocket.init("arcturus-intelligence/orion-staging-yj98r");
                logRocketInitialized = true;
                return;
            case "production":
                LogRocket.init("arcturus-intelligence/orion-production-7l6qe");
                logRocketInitialized = true;
                return;
            default:
                throw new Error("Cannot initialize LogRocket for invalid environment: " + env);
        }
    } else {
        console.log("LogRocket disabled in CRA development environment.");
    }
};
