import React from "react";
import { TooltipTitle, GridTooltipContainer, TooltipText } from "./styled";
import { useSelectFromRedux } from "../../../utils/_hooks";
import { RISK_INDEX_COLORS } from "../../visualizations/utils";
// import moment from "moment";

export default function GridTooltip() {
    const [{ selectedMarker }] = useSelectFromRedux((state) => [state.map]);

    const colors = useSelectFromRedux((state) => state.color);

    return (
        <GridTooltipContainer>
            <TooltipTitle color={colors.scheme.primaryText} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                Risk Index: {parseInt(selectedMarker.risk_index)}
                <div
                    style={{
                        background: RISK_INDEX_COLORS[selectedMarker.risk_index-1] ?? "#FFC300",
                        height: "16px",
                        width: "16px",
                        borderRadius: "4px",
                        marginLeft: "5px"
                    }}
                />
                </TooltipTitle>
            {/* <TooltipTitle color={colors.scheme.primaryText}>
                Date: {moment(selectedMarker.properties.ra_date).format("LLLL")}
            </TooltipTitle> */}
            {/*<TooltipTitle style={{ fontWeight: 400, fontSize: "14px" }} color={colors.scheme.primaryText}>
                H3 Cell: {selectedMarker.h3_cell}
        </TooltipTitle>*/}
            <div style={{ fontWeight: 400, fontSize: "14px" }} color={colors.scheme.primaryText}>
                <b>Most Likely Report Type: {selectedMarker.mostcommon_rt[0]}</b>
            </div>
            {selectedMarker.mostcommon_rt.length > 1 &&
            <div>
                Other report types may include: {selectedMarker.mostcommon_rt.slice(1).toString().replaceAll(',', ', ')}
            </div>
            }
            {/*<TooltipText style={{ fontWeight: 400, fontSize: "14px" }} color={colors.scheme.primaryText}>
                Safety Score: {(selectedMarker.safety_score * 100).toFixed(2)}% ({selectedMarker.safety_index_label})
        </TooltipText>*/}
        </GridTooltipContainer>
    );
}

