import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import LogRocket from "logrocket";

export const initializeSentry = () => {
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.toLowerCase() !== "local") {
        LogRocket.getSessionURL((sessionURL) => {
            Sentry.configureScope((scope) => {
                scope.setExtra("sessionURL", sessionURL);
            });
        });

        Sentry.init({
            dsn: "https://13e24176b2fe4f5a8bf5c763006181e8@o1141902.ingest.sentry.io/6262392",
            integrations: [new BrowserTracing()],
            // To set a uniform sample rate
            tracesSampleRate: 1.0,
            environment: process.env.REACT_APP_ENV,
            normalizeDepth: 10,
            beforeSend(event) {
                const logRocketSession = LogRocket.sessionURL;
                if (logRocketSession !== null) {
                    const logRocketObject = {
                        extra: [logRocketSession]
                    };
                    event.extra = logRocketObject;
                    return event;
                } else {
                    return event;
                }
            }
        });
    }
};
