import React from "react";

import { isMobile } from "react-device-detect";

import { useSelectFromRedux } from "../../utils/_hooks";
import ContainerFilter from "./container-filter";
import SevenDayFilter from "./seven-day-filter";
import RiskIndexFilter from "./risk-index-filter";
import { useDispatch } from "react-redux";
import { visualizationsActions } from "../../state/visualizations";
import Info from "./info";
import { filterActions } from "../../state/filter";
import { SubmitButton } from "../add-report/styled";
import { toastActions } from "../../state/toast";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

// SafeRouting Disabled
// import NavForm from "./nav-form";
// import SafeRouteInfo from "./safe-route-info";
// import CreateRouteButton from "./create-route-button";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: any) => {
    const dispatch = useDispatch();
    // SafeRouting Disabled
    // const [colors, { showZones, routeProfile }] = useSelectFromRedux((state) => [state.color, state.visualizations]);
    const [colors, { hasStartedForecasting }] = useSelectFromRedux((state) => [state.color, state.visualizations]);
    const showDetails = useSelectFromRedux((state) => state.filter.showRiskForecastingDetails);
    const selectedRegion = useSelectFromRedux((state) => state.filter.selectedRegion);
    const loadingMessage = useSelectFromRedux((state) => state.visualizations.loadingRiskForecastsMessage);
    const { riskIndexInfoOpen } = useSelectFromRedux((state) => state.visualizations);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignSelf: "flex-start",
                    marginBottom: "10px",
                    filter: riskIndexInfoOpen && isMobile ? "blur(4px)" : "none"
                }}
            >
                {/* SafeRouting Disabled */}
                {/*<Toggle*/}
                {/*    style={{ marginLeft: 24 }}*/}
                {/*    checked={routeProfile === "walking"}*/}
                {/*    unCheckedChildren={"Driving"}*/}
                {/*    checkedChildren={"Walking"}*/}
                {/*    onClick={(x) => {*/}
                {/*        x*/}
                {/*            ? dispatch(visualizationsActions.updateRouteProfile("walking"))*/}
                {/*            : dispatch(visualizationsActions.updateRouteProfile("driving"));*/}
                {/*    }}*/}
                {/*    background={colors.scheme.submitButtonBackground}*/}
                {/*/>*/}
            </div>
            {!showDetails && (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onClick={() => dispatch(filterActions.toggleShowRiskForecastingDetails(true))}
                >
                    <img
                        src={
                            colors.mode === "light"
                                ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/chevron-down-grey.png"
                                : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/chevron-down-white.png"
                        }
                        alt="Down"
                        style={{
                            width: "30px",
                            cursor: "pointer"
                        }}
                    />
                </div>
            )}
            {showDetails && (
                <div
                    style={{
                        filter: riskIndexInfoOpen && isMobile ? "blur(4px)" : "none",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <ContainerFilter />
                    {hasStartedForecasting && loadingMessage === null && (
                        <>
                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        width: "100%",*/}
                            {/*        display: "flex",*/}
                            {/*        alignItems: "center",*/}
                            {/*        justifyContent: "space-between",*/}
                            {/*        marginTop: "30px",*/}
                            {/*        color: colors.scheme.primaryText,*/}
                            {/*        fontWeight: "bold"*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <div>*/}
                            {/*        Hotspots*/}
                            {/*        <img*/}
                            {/*            src={*/}
                            {/*                colors.mode === "light"*/}
                            {/*                    ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/info-grey.png"*/}
                            {/*                    : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/info-white.png"*/}
                            {/*            }*/}
                            {/*            alt="Filter"*/}
                            {/*            style={{*/}
                            {/*                height: "16px",*/}
                            {/*                cursor: "pointer",*/}
                            {/*                marginLeft: "5px"*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*    <Toggle*/}
                            {/*        style={{ marginLeft: 24 }}*/}
                            {/*        checked={showZones}*/}
                            {/*        onClick={() => dispatch(visualizationsActions.toggleZones())}*/}
                            {/*        background={colors.scheme.submitButtonBackground}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <SevenDayFilter theme={colors} />
                            <RiskIndexFilter theme={colors} />
                        </>
                    )}
                    {hasStartedForecasting && loadingMessage !== null && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "240px",
                                marginTop: "20px",
                                background: colors.scheme.modalBackground
                            }}
                        >
                            <Player
                                autoplay
                                loop
                                src={
                                    colors.mode === "light"
                                        ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/triangular-loading-grey.json"
                                        : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/triangular-loading-white.json"
                                }
                                background="transparent"
                                style={{ height: "200px", width: "200px" }}
                            >
                                <Controls visible={false} />
                            </Player>
                            <div>{loadingMessage}</div>
                        </div>
                    )}
                    {!hasStartedForecasting && (
                        <SubmitButton
                            onClick={() => {
                                if (selectedRegion === 0) {
                                    dispatch(toastActions.openError("Please select an area to start forecasting."));
                                } else {
                                    dispatch(visualizationsActions.updateHasStartedForecasting(true));
                                }
                            }}
                            color={colors.scheme.submitButtonText}
                            background={selectedRegion === 0 ? colors.scheme.submitButtonBackgroundDisabled : colors.scheme.submitButtonBackground}
                            style={{
                                textTransform: "none",
                                fontWeight: "normal",
                                width: "100%",
                                margin: "15px 0 20px",
                                marginTop: "30px"
                            }}
                        >
                            <img
                                src="https://orion-assets-s3.s3.us-east-2.amazonaws.com/submit-checkmark.png"
                                alt="submit"
                                style={{
                                    height: "20px",
                                    marginRight: "10px"
                                }}
                            />
                            Begin Forecasting
                        </SubmitButton>
                    )}
                    {isMobile && (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px"
                            }}
                        >
                            <img
                                src={
                                    colors.mode === "light"
                                        ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/chevron-up-grey.png"
                                        : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/chevron-up-white.png"
                                }
                                alt="up"
                                style={{
                                    width: "30px",
                                    cursor: "pointer"
                                }}
                                onClick={() => dispatch(filterActions.toggleShowRiskForecastingDetails(false))}
                            />
                        </div>
                    )}
                </div>
            )}
            <Info infoOpen={riskIndexInfoOpen} />
            {/* SafeRouting Disabled */}
            {/*<NavForm source={props.source} />*/}
            {/*<SafeRouteInfo />*/}
            {/*<CreateRouteButton />*/}
        </>
    );
};
