import React from "react";
import styled from "styled-components";
import { S3Key } from "../../utils";
import { useSelectFromRedux } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import { leftModalActions } from "../../state/left-modal";
import { updateReportActions } from "../../state/update-report";
import { filterActions } from "../../state/filter";
import { reportsActions } from "../../state/reports";
import { mapActions } from "../../state/map";
import { teamsActions } from "../../state/teams";
import { analyticsActions } from "../../state/analytics";
const BackGreyIcon = S3Key + "back-dark.png";
const BackWhiteIcon = S3Key + "back-white.png";
const CloseGreyIcon = S3Key + "close-dark.png";
const CloseWhiteIcon = S3Key + "close-white.png";

export const ModalHeader = () => {
    const dispatch = useDispatch();
    const { modalContent } = useSelectFromRedux((state) => state.leftModal);
    const reportType = useSelectFromRedux((state) => state.individualReport.currentReportType);
    const userProfile = useSelectFromRedux((state) => state.cuser.userProfile);
    const colors = useSelectFromRedux((state) => state.color);
    const tooltipOpen = useSelectFromRedux((state) => state.map.showTooltip);
    const createTeamMode = useSelectFromRedux((state) => state.teams.createTeamMode);

    const backAndResetFilter = () => {
        dispatch(leftModalActions.setFilterOpen(false));
        if (modalContent === "groups") {
            if (createTeamMode) {
                dispatch(teamsActions.toggleCreateTeamMode(!createTeamMode));
            } else {
                dispatch(teamsActions.toggleDisplayFullUserView(false));
                dispatch(teamsActions.toggleCreateTeamMode(false));
                dispatch(teamsActions.updateCreateTeamName(null));
                dispatch(teamsActions.updateFullViewUserId(null));
                dispatch(teamsActions.toggleModifyUserMode(false));
                dispatch(teamsActions.updateModifyUserRole(undefined));
                dispatch(teamsActions.updateModifyUserTeam(undefined));
                dispatch(
                    leftModalActions.setModalContent({
                        modalContent: "menu"
                    })
                );
            }
        } else if (modalContent === "visualizations") {
            dispatch(
                leftModalActions.setModalContent({
                    modalContent: "menu"
                })
            );
            dispatch(filterActions.toggleShowRiskForecastingDetails(true));
        } else if (modalContent === "individual-report" && reportType) {
            if (reportType === "client") {
                if (userProfile?.features?.teamReportsMenu !== false) {
                    dispatch(
                        leftModalActions.setModalContent({
                            modalContent: "reports"
                        })
                    );
                    dispatch(
                        reportsActions.toggleReportsSetting({
                            reportsSetting: "reports"
                        })
                    );
                } else {
                    dispatch(
                        leftModalActions.setModalContent({
                            modalContent: "menu"
                        })
                    );
                }
            } else if (reportType === "client-alert") {
                if (userProfile?.features?.teamReportsMenu !== false) {
                    dispatch(
                        leftModalActions.setModalContent({
                            modalContent: "reports"
                        })
                    );
                    dispatch(
                        reportsActions.toggleReportsSetting({
                            reportsSetting: "alert-reports"
                        })
                    );
                } else {
                    dispatch(
                        leftModalActions.setModalContent({
                            modalContent: "menu"
                        })
                    );
                }
            } else {
                if (userProfile?.features?.newsfeedMenu !== false) {
                    dispatch(
                        leftModalActions.setModalContent({
                            modalContent: "newsfeed"
                        })
                    );
                } else {
                    dispatch(
                        leftModalActions.setModalContent({
                            modalContent: "menu"
                        })
                    );
                }
            }
            dispatch(updateReportActions.setFullReportId(null));
            if (tooltipOpen) {
                dispatch(mapActions.toggleTooltip());
            }
        } else if (modalContent === "billing") {
            dispatch(
                leftModalActions.setModalContent({
                    modalContent: "user"
                })
            );
        } else {
            dispatch(
                leftModalActions.setModalContent({
                    modalContent: "menu"
                })
            );
        }
    };

    const exitAndResetFilter = () => {
        if (modalContent === "analytics") {
            dispatch(filterActions.resetFilter());
            dispatch(analyticsActions.setData(null));
        }
        dispatch(leftModalActions.toggleModal());
        dispatch(leftModalActions.setFilterOpen(false));
        if (tooltipOpen) {
            dispatch(mapActions.toggleTooltip());
        }
        if (modalContent === "groups") {
            dispatch(teamsActions.toggleCreateTeamMode(false));
            dispatch(teamsActions.updateCreateTeamName(null));
        }
    };

    const getHeaderTitleFromModalContent = () => {
        const titles = {
            newsfeed: "Newsfeed",
            reports: "Reports",
            analytics: "Analytics",
            visualizations: "Risk Forecasting",
            filter: "Map Filter",
            user: "Settings",
            "individual-report": "Public Report",
            insights: "Forecasting Insights",
            groups: "Groups",
            "add-user": "Add User",
            menu: "",
            billing: "Billing",
            "": ""
        };

        return titles[modalContent] ?? "";
    };

    const getIconFromModalContent = () => {
        if (modalContent === "newsfeed") {
            if (colors.mode === "light") {
                return NewsfeedDarkIcon;
            } else {
                return NewsfeedLightIcon;
            }
        } else if (modalContent === "visualizations") {
            if (colors.mode === "light") {
                return RiskForecastingDarkIcon;
            } else {
                return RiskForecastingLightIcon;
            }
        } else if (modalContent === "filter") {
            if (colors.mode === "light") {
                return MapFilterDarkIcon;
            } else {
                return MapFilterLightIcon;
            }
        } else if (modalContent === "groups") {
            if (colors.mode === "light") {
                return GroupsDarkIcon;
            } else {
                return GroupsLightIcon;
            }
        } else if (modalContent === "add-user") {
            if (colors.mode === "light") {
                return AddUserDarkIcon;
            } else {
                return AddUserLightIcon;
            }
        } else if (modalContent === "user") {
            if (colors.mode === "light") {
                return AccountSettingsDarkIcon;
            } else {
                return AccountSettingsLightIcon;
            }
        } else if (modalContent === "reports") {
            if (colors.mode === "light") {
                return ReportsDarkIcon;
            } else {
                return ReportsLightIcon;
            }
        } else if (modalContent === "analytics") {
            if (colors.mode === "light") {
                return AnalyticsDarkIcon;
            } else {
                return AnalyticsLightIcon;
            }
        } else if (modalContent === "insights") {
            if (colors.mode === "light") {
                return InsightsDarkIcon;
            } else {
                return InsightsLightIcon;
            }
        } else if (modalContent === "individual-report") {
            if (colors.mode === "light") {
                return IndividualReportDarkIcon;
            } else {
                return IndividualReportLightIcon;
            }
        } else if (modalContent === "billing") {
            if (colors.mode === "light") {
                return BillingDarkIcon;
            } else {
                return BillingLightIcon;
            }
        }
    };

    return (
        <ModalHeaderContainer>
            {modalContent !== "menu" && (
                <HeaderIconContainer onClick={() => backAndResetFilter()}>
                    <img src={colors.mode === "light" ? BackGreyIcon : BackWhiteIcon} alt="Back" height="100%" />
                </HeaderIconContainer>
            )}
            <HeaderTitle>
                {modalContent !== "menu" && <img src={getIconFromModalContent()} alt={modalContent} width="18px" style={{ marginRight: "12px" }} />}
                {getHeaderTitleFromModalContent()}
            </HeaderTitle>
            <HeaderIconContainer onClick={() => exitAndResetFilter()}>
                <img src={colors.mode === "light" ? CloseGreyIcon : CloseWhiteIcon} alt="Close" height="100%" />
            </HeaderIconContainer>
        </ModalHeaderContainer>
    );
};

const ModalHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    margin-bottom: 10px;
`;

const HeaderTitle = styled.div`
    padding-top: 10px;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.primaryText};
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const HeaderIconContainer = styled.div`
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const NewsfeedLightIcon = S3Key + "newsfeed-white.png";
const NewsfeedDarkIcon = S3Key + "newsfeed-dark.png";
const RiskForecastingLightIcon = S3Key + "risk-forecasting-white.png";
const RiskForecastingDarkIcon = S3Key + "risk-forecasting-grey.png";
const MapFilterLightIcon = S3Key + "filter-white.png";
const MapFilterDarkIcon = S3Key + "filter-dark.png";
const GroupsLightIcon = S3Key + "teams-white.png";
const GroupsDarkIcon = S3Key + "teams-grey.png";
const AddUserLightIcon = S3Key + "add-user-white.png";
const AddUserDarkIcon = S3Key + "add-user-grey.png";
const AccountSettingsLightIcon = S3Key + "gear-white.png";
const AccountSettingsDarkIcon = S3Key + "gear-grey.png";
const ReportsLightIcon = S3Key + "reports-white.png";
const ReportsDarkIcon = S3Key + "reports-dark.png";
const AnalyticsLightIcon = S3Key + "analytics-white-new.png";
const AnalyticsDarkIcon = S3Key + "analytics-dark-new.png";
const InsightsLightIcon = S3Key + "insights-white.png";
const InsightsDarkIcon = S3Key + "insights-grey.png";
const IndividualReportLightIcon = S3Key + "individual-report-light.svg";
const IndividualReportDarkIcon = S3Key + "individual-report-dark.svg";
const BillingLightIcon = S3Key + "billing-dark.svg";
const BillingDarkIcon = S3Key + "billing-light.svg";
