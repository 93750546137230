import React from "react";

// redux
import { useSelectFromRedux } from "../../utils/_hooks";
import { ModalContentOptions } from "../../state/left-modal";

// components
import { ModalContainer } from "./styled";
import Newsfeed from "../newsfeed";
import Reports from "../reports";
import Analytics from "../analytics";
import Visualizations from "../visualizations";
import { Menu } from "../menu";
import Filter from "../filter";
import User from "../user";
import IndividualReport from "../individual-report";
import PublicInsights from "../public-insights";
import Teams from "../teams";
import { AddUser } from "../teams/AddUser";
import { ModalHeader } from "../_shared/modal-header";
import { Billing } from "../user/billing";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: any) => {
    const content = useSelectFromRedux((state) => state.leftModal);
    const colors = useSelectFromRedux((state) => state.color);

    const displayModalContent = (content: ModalContentOptions) => {
        switch (content.modalContent) {
            case "newsfeed":
                return <Newsfeed />;
            case "reports":
                return <Reports />;
            case "analytics":
                return <Analytics />;
            case "visualizations":
                return <Visualizations source={props.source} />;
            case "menu":
                return <Menu />;
            case "filter":
                return <Filter />;
            case "user":
                return <User />;
            case "individual-report":
                return <IndividualReport />;
            case "insights":
                return <PublicInsights />;
            case "groups":
                return <Teams />;
            case "add-user":
                return <AddUser />;
            case "billing":
                return <Billing />;
        }
    };

    return (
        <ModalContainer background={colors.scheme.modalBackground} color={colors.scheme.primaryText}>
            <ModalHeader></ModalHeader>
            {/* {content.modalContent !== "menu" && (
                <BackButton
                    src={
                        colors.mode === "light"
                            ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/back-dark.png"
                            : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/back-white.png"
                    }
                    style={{
                        filter:
                            (content.filterOpen ||
                                (displayFullUserView && content.modalContent === "groups") ||
                                (editMode && content.modalContent === "user")) &&
                            isMobile
                                ? "blur(4px)"
                                : "none"
                    }}
                    alt="Back"
                    onClick={() => backAndResetFilter()}
                />
            )}
            <CloseButton
                src={
                    colors.mode === "light"
                        ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/close-dark.png"
                        : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/close-white.png"
                }
                alt="Exit"
                style={{
                    filter:
                        (content.filterOpen || (displayFullUserView && content.modalContent === "groups") || (editMode && content.modalContent === "user")) &&
                        isMobile
                            ? "blur(4px)"
                            : "none"
                }}
                onClick={() => exitAndResetFilter()}
            /> */}
            {displayModalContent(content)}
        </ModalContainer>
    );
};
