import React, { useState, useEffect } from "react";
import { useDispatchPromise, useSelectFromRedux } from "../../utils/_hooks";
import { teamsActions, teamsOperations } from "../../state/teams";
import { SingleLineTextInput, LoadingButton } from "./styled";
import { Dropdown } from "./styled";
import { useDispatch } from "react-redux";
import Select from "antd/lib/select";
import { SelectValue } from "antd/lib/select";
import { Team } from "../../types/client";
import { PhoneNumberInput, PhoneLoadingContainer } from "../signup/styled";
import Geocode from "react-geocode";
import { LoadingSpinner } from "../_shared";
import { GoogleGeocoderAPIKey } from "../../utils";
import { toastActions } from "../../state/toast";

const { Option } = Select;

export const AddNewUserForm = () => {
    const dispatch = useDispatch();
    const promiseDispatch = useDispatchPromise();
    const colors = useSelectFromRedux((state) => state.color);
    const { addNewUserRole } = useSelectFromRedux((state) => state.teams);
    const { addNewUserTeam } = useSelectFromRedux((state) => state.teams);
    const { addNewUserUnique } = useSelectFromRedux((state) => state.teams);
    const { loginOptionValue } = useSelectFromRedux((state) => state.teams);
    const cuser = useSelectFromRedux((state) => state.cuser.userProfile);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const cuserTeam = teams.filter((team) => team.id === cuser?.team_id)[0];
    const [defaultPhoneNumberCountryCode, updateDefaultPhoneNumberCountryCode] = useState<string>("us");
    const [phoneNumberCountryCodeLoading, updatePhoneNumberCountryCodeLoading] = useState<boolean>(true);
    const [defaultTeams, updateDefaultTeams] = useState<Team[]>(teams);
    const [isSendingInvite, updateIsSendingInvite] = useState<boolean>(false);
    const { addNewUserFirstName } = useSelectFromRedux((state) => state.teams);
    const { addNewUserLastName } = useSelectFromRedux((state) => state.teams);
    // const { addNewUserDesignation } = useSelectFromRedux((state) => state.teams);

    let teamNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    defaultTeams.map((team: Team) => ({
                        value: team.name,
                        key: team.id
                    }))
                )
            ),
        [defaultTeams]
    );

    // const clearAndCloseCreateTeamOrUser = () => {
    //     dispatch(teamsActions.toggleCreateTeamMode(false));
    //     dispatch(teamsActions.updateCreateTeamName(null));
    //     dispatch(teamsActions.updateAddNewUserDesignation(null));
    //     dispatch(teamsActions.updateAddNewUserFirstName(""));
    //     dispatch(teamsActions.updateAddNewUserLastName(""));
    //     dispatch(teamsActions.updateAddNewUserRole(null));
    //     dispatch(teamsActions.updateAddNewUserTeam(null));
    //     dispatch(teamsActions.updateAddNewUserUnique(""));
    // };

    useEffect(() => {
        if (cuserTeam && cuserTeam.permissions === Number(1)) {
            dispatch(teamsActions.updateAddNewUserTeam(cuserTeam.id));
            let filteredTeams;
            filteredTeams = defaultTeams.filter((team) => team.id === cuserTeam.id);
            updateDefaultTeams(filteredTeams);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    Geocode.fromLatLng("" + lat, "" + lng, GoogleGeocoderAPIKey)
                        .then((response) => {
                            updateDefaultPhoneNumberCountryCode(response.results[0].address_components[6].short_name.toLowerCase());
                            updatePhoneNumberCountryCodeLoading(false);
                        })
                        .catch((error) => {
                            updatePhoneNumberCountryCodeLoading(false);
                            console.log(error);
                        });
                },
                (error) => {
                    updatePhoneNumberCountryCodeLoading(false);
                    console.log(error);
                }
            );
        } else {
            updatePhoneNumberCountryCodeLoading(false);
        }

        setTimeout(() => {
            updatePhoneNumberCountryCodeLoading(false);
        }, 5000);

        // eslint-disable-next-line
    }, []);

    const readyToSendInvite = () => {
        return (loginOptionValue === "email" && addNewUserUnique.includes("@")) || (loginOptionValue === "phone" && addNewUserUnique.length > 7);
    };

    const sendInvite = () => {
        updateIsSendingInvite(true);
        promiseDispatch(teamsOperations.addPendingUser()).then((success) => {
            updateIsSendingInvite(false);
            if (success) {
                dispatch(toastActions.openSuccess("Invite sent"));
            }
        });
    };

    return (
        <div style={{ padding: "20px 20px 0" }}>
            <div
                style={{
                    marginBottom: "5px",
                    textTransform: "uppercase",
                    color: colors.scheme.primaryText
                }}
            >
                Invite New User
            </div>
            {loginOptionValue === "email" && (
                <SingleLineTextInput
                    value={addNewUserUnique}
                    maxLength={60}
                    placeholder="EMAIL"
                    placeholdercolor={colors.scheme.secondaryHeaderText}
                    color={colors.scheme.submitButtonText}
                    background={colors.scheme.selectorBackground}
                    onChange={(e) => dispatch(teamsActions.updateAddNewUserUnique(e.target.value))}
                    style={{ height: "37px" }}
                />
            )}
            {loginOptionValue === "phone" && phoneNumberCountryCodeLoading && (
                <PhoneLoadingContainer
                    style={{
                        paddingLeft: "10px",
                        paddingTop: "5px",
                        background: colors.scheme.selectorBackground,
                        width: "100%",
                        margin: "0"
                    }}
                >
                    <LoadingSpinner background={"white"} />
                </PhoneLoadingContainer>
            )}
            {loginOptionValue === "phone" && !phoneNumberCountryCodeLoading && (
                <PhoneLoadingContainer style={{ background: colors.scheme.selectorBackground, width: "100%", margin: "0" }}>
                    <PhoneNumberInput
                        country={defaultPhoneNumberCountryCode}
                        value={addNewUserUnique}
                        onChange={(phone) => dispatch(teamsActions.updateAddNewUserUnique(phone))}
                        preferredCountries={["us", "za", "ng", "ke"]}
                    />
                </PhoneLoadingContainer>
            )}
            {loginOptionValue === "email" ? (
                <div
                    onClick={() => {
                        dispatch(teamsActions.updateLoginOptionValue("phone"));
                        dispatch(teamsActions.updateAddNewUserUnique(""));
                    }}
                    style={{ marginTop: "4px", width: "100%", textAlign: "center" }}
                >
                    <u style={{ cursor: "pointer" }}>Sign up with phone</u>?
                </div>
            ) : (
                <div
                    onClick={() => {
                        dispatch(teamsActions.updateLoginOptionValue("email"));
                        dispatch(teamsActions.updateAddNewUserUnique(""));
                    }}
                    style={{ marginTop: "4px", width: "100%", textAlign: "center" }}
                >
                    <u style={{ cursor: "pointer" }}>Sign up with email</u>?
                </div>
            )}
            <SingleLineTextInput
                value={addNewUserFirstName}
                maxLength={30}
                placeholder="FIRST NAME (OPTIONAL)"
                placeholdercolor={colors.scheme.secondaryHeaderText}
                color={colors.scheme.submitButtonText}
                background={colors.scheme.selectorBackground}
                onChange={(e) => dispatch(teamsActions.updateAddNewUserFirstName(e.target.value))}
                style={{ margin: "10px 0 0" }}
            />
            <SingleLineTextInput
                value={addNewUserLastName}
                maxLength={30}
                placeholder="LAST NAME (OPTIONAL)"
                placeholdercolor={colors.scheme.secondaryHeaderText}
                color={colors.scheme.submitButtonText}
                background={colors.scheme.selectorBackground}
                onChange={(e) => dispatch(teamsActions.updateAddNewUserLastName(e.target.value))}
                style={{ margin: "10px 0 0" }}
            />
            {cuser?.role === 1 && (
                <Dropdown
                    value={addNewUserRole ?? undefined}
                    dropdownMatchSelectWidth={false}
                    placeholder="ROLE (OPTIONAL)"
                    showArrow={true}
                    onChange={(e: SelectValue) => dispatch(teamsActions.updateAddNewUserRole(e as number))}
                    style={{ margin: "10px 0 0" }}
                >
                    <Option value={1}>Admin</Option>
                    <Option value={0}>Staff</Option>
                </Dropdown>
            )}
            {/*<Dropdown*/}
            {/*    value={addNewUserDesignation ?? undefined}*/}
            {/*    color={colors.scheme.primaryText}*/}
            {/*    background={colors.scheme.selectorBackground}*/}
            {/*    dropdownMatchSelectWidth={false}*/}
            {/*    arrowcolor={colors.scheme.primaryText}*/}
            {/*    placeholder="DESIGNATION (OPTIONAL)"*/}
            {/*    showArrow={true}*/}
            {/*    onChange={(e: SelectValue) => dispatch(teamsActions.updateAddNewUserDesignation(e as string))}*/}
            {/*    style={{ margin: "10px 0 0" }}*/}
            {/*>*/}
            {/*    <Option value="Medic">Medic</Option>*/}
            {/*    <Option value="None">None</Option>*/}
            {/*</Dropdown>*/}
            {cuser?.role === 1 && (
                <Dropdown
                    value={addNewUserTeam ?? undefined}
                    dropdownMatchSelectWidth={false}
                    placeholder="TEAM (OPTIONAL)"
                    showArrow={true}
                    onChange={(e: SelectValue) => dispatch(teamsActions.updateAddNewUserTeam(e as number))}
                    style={{ margin: "10px 0 0" }}
                >
                    {teamNames.map((team) => (
                        <Option value={team.key} key={team.key}>
                            {team.value}
                        </Option>
                    ))}
                </Dropdown>
            )}
            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "8px" }}>
                <LoadingButton
                    style={{ width: "100%" }}
                    color={readyToSendInvite() ? "white" : "#4C4C4C"}
                    loading={isSendingInvite}
                    disabled={!readyToSendInvite()}
                    background={readyToSendInvite() ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag}
                    onClick={() => sendInvite()}
                >
                    SEND INVITE
                </LoadingButton>
            </div>
        </div>
    );
};
