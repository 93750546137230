import React, { useState, useEffect } from "react";
import { isMobile, isIOS } from "react-device-detect";

// redux
import { useSelectFromRedux } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import { Filter_TimePeriod, filterActions, getInitialState, FilterState } from "../../state/filter";
import { mapActions } from "../../state/map";

// components
import { Button, Dropdown, StyledTag, ShowScrollbarDiv } from "./styled";
import Select from "antd/lib/select";
import { Row } from "../_shared";
import { SelectValue } from "antd/lib/select";
import { Toggle } from "../user/styled";
import { ShortTextInput, ShortTextInputAutoComplete } from "../update-report/styled";

// icons
import { getContainerViewportFromId, getDefaultRegionViewportFromId, S3Key } from "../../utils";
import { DefaultRegion } from "../../types/geo";
import { AllReportTypes } from "../../types/reports";
import { Team, UserProfile } from "../../types/client";
import { clientInfoOperations } from "../../state/clientInfo";
import _, { isEqual } from "lodash";
import { DefaultRegionNoViewport } from "../analytics";
import { leftModalActions } from "../../state/left-modal";
// import _ from "lodash";

const { Option } = Select;
const CloseWhiteIcon = S3Key + "close-white.png";
const SearchGreyIcon = S3Key + "search-grey.png";
const SearchWhiteIcon = S3Key + "search-white.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const dispatch = useDispatch();

    const [currentFilter, cuser] = useSelectFromRedux((state) => [state.filter, state.cuser]);
    const current_container = useSelectFromRedux((state) => state.cuser.userProfile?.current_container);
    const colors = useSelectFromRedux((state) => state.color);
    const defaultRegions = useSelectFromRedux((state) => state.cuser.defaultRegions);
    const containers = useSelectFromRedux((state) => state.cuser.containers);
    const clientUsers = useSelectFromRedux((state) => state.clientInfo.users);
    const tooltipOpen = useSelectFromRedux((state) => state.map.showTooltip);
    const users = useSelectFromRedux((state) => state.clientInfo.users);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const cuserTeam = teams.filter((team) => team.id === cuser?.userProfile?.team_id)[0] ?? null;

    const [defaultTeams, updateDefaultTeams] = useState<Team[]>(teams);

    // Front-end options for each dropdown – reflect filterState but with 'All' instead of null for display
    const timePeriodOptions = ["All Time", "28 Day", "7 Day", "24 Hour"];

    // Set component state accordingly – 'All' instead of null for no filter set
    const userNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    users
                        .filter((user) => user.id !== cuser.userProfile?.id)
                        .map((user: UserProfile) => ({
                            value: user.first_name + " " + user.last_name + " (" + user.email + ")",
                            key: user.id
                        }))
                )
            ),
        [users, cuser.userProfile]
    );

    useEffect(() => {
        if (!(cuser.userProfile?.team_id === null && cuser.userProfile.role === 1)) {
            let filteredTeams;

            if ((cuserTeam !== null && cuserTeam.permissions === 0) || cuser.userProfile?.team_id === null) {
                filteredTeams = defaultTeams.filter((team) => team.permissions === 0 || team.id === cuser.userProfile?.team_id);
            }

            if (cuserTeam !== null && cuserTeam.permissions === 1) {
                filteredTeams = defaultTeams.filter((team) => team.id === cuserTeam.id || team.id === cuser.userProfile?.team_id);
            }

            if (filteredTeams) {
                updateDefaultTeams(filteredTeams);
            }
        }
        // only want to filter teams by permissions on render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let teamNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    defaultTeams.map((team: Team) => ({
                        value: team.name,
                        key: team.id
                    }))
                )
            ),
        [defaultTeams]
    );

    let eventTypeNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    AllReportTypes.map((reportType: string) => ({
                        value: reportType,
                        key: reportType
                    }))
                )
            ),
        []
    );

    if (cuserTeam !== null) {
        teamNames = teamNames.filter((teamName) => teamName.key !== cuserTeam.id);
        teamNames = [{ value: "Principal Group", key: 0 }, ...teamNames];
    }

    const [regionChange, updateRegionChange] = useState<number>(0);
    const [selectedTimePeriod, updateTimePeriod] = useState<string>(
        currentFilter.timePeriod === null ? "All Time" : currentFilter.timePeriod === "28 Day" ? "28 Day" : currentFilter.timePeriod
    );
    const [markerClustering, updateMarkerClustering] = useState<boolean>(currentFilter.markerClustering);

    const [incidentsChecked, updateIncidentsChecked] = useState<boolean>(currentFilter.incidentsIncluded);
    const [sosChecked, updateSosChecked] = useState<boolean>(currentFilter.sosIncluded);
    const [filteredUsers, updateFilteredUsers] = useState<number[]>(currentFilter.filteredUsers);
    const [teamFilter, updateTeamFilter] = useState<number | null>(currentFilter.teamFilter ? currentFilter.teamFilter : null);
    const defaultUsers = Array.from(new Set(clientUsers.map((profile: UserProfile) => profile.id)));
    const [violentChecked, updateViolentChecked] = useState<boolean>(currentFilter.violentIncluded);
    const [nonviolentChecked, updateNonviolentChecked] = useState<boolean>(currentFilter.nonviolentIncluded);
    const [hazardChecked, updateHazardChecked] = useState<boolean>(currentFilter.hazardIncluded);
    const [otherChecked, updateOtherChecked] = useState<boolean>(currentFilter.otherIncluded);

    const [orionSourceChecked, updateOrionSourceChecked] = useState<boolean>(currentFilter.orionSourceIncluded);
    const [orionUserChecked, updateOrionUserChecked] = useState<boolean>(currentFilter.orionUserIncluded);
    const [openSourceChecked, updateOpenSourceChecked] = useState<boolean>(currentFilter.openSourceIncluded);
    const [myOrganizationChecked, updateMyOrganizationChecked] = useState<boolean>(currentFilter.myOrganizationIncluded);
    const [myReportsChecked, updateMyReportsChecked] = useState<boolean>(currentFilter.myReportsIncluded);
    const [individualUserChecked, updateIndividualUserChecked] = useState<boolean>(false);
    const [individualTeamChecked, updateIndividualTeamChecked] = useState<boolean>(false);
    const [verifiedChecked, updateVerifiedChecked] = useState<boolean>(currentFilter.verifiedIncluded);
    const [unverifiedChecked, updateUnverifiedChecked] = useState<boolean>(currentFilter.verifiedIncluded);

    const [filterChanged, updateFilterChanged] = useState<boolean>(false);

    const [filterChangedFromDefault, updateFilterChangedFromDefault] = useState<boolean>(false);
    const [userAutofillValue, updateUserAutofillValue] = useState<string | undefined>();
    const [teamAutofillValue, updateTeamAutofillValue] = useState<string | undefined>();
    const [eventTypeSearchOpen, updateEventTypeSearchOpen] = useState<boolean>(false);
    const [selectedEventTypes, updateSelectedEventTypes] = useState<string[]>(currentFilter.eventTypeApplied ?? []);

    const [eventChecked, updateEventChecked] = useState<boolean>(false);

    const [myTeamChecked, updateMyTeamChecked] = useState<boolean>(currentFilter.myTeamIncluded ? currentFilter.myTeamIncluded : false);

    const initCurrentContainer = cuser.userProfile?.current_container;
    if (!initCurrentContainer) {
        console.error("Could not get current container in drawer.");
        return null;
    }
    const [currentContainer, updateCurrentContainer] = useState<number>(initCurrentContainer);
    const availableRegions = defaultRegions.filter((region) => region.container_id === currentContainer);
    if (!availableRegions || !current_container) {
        console.error("Container has no regions? Returning");
        return null;
    }
    const defaultAllRegion: DefaultRegionNoViewport = {
        id: 0,
        name: "All Areas",
        container_id: 0
    };
    const regionSelections = [defaultAllRegion, ...availableRegions];
    const [selectedRegion, updateSelectedRegion] = useState<number>(currentFilter.selectedRegion);

    useEffect(() => {
        if (filteredUsers.length !== 0) {
            const allUsers = Array.from(new Set(clientUsers.map((profile: UserProfile) => profile.id)));
            const difference = _.difference(allUsers, filteredUsers);
            const selectedUser = userNames.find((userName) => userName.key === difference[0]);
            updateUserAutofillValue(selectedUser?.value);
        }
    }, [clientUsers, filteredUsers, userNames]);

    useEffect(() => {
        if (teamFilter !== null) {
            const teamString = teamNames.find((teamName) => teamName.key === teamFilter);
            updateTeamAutofillValue(teamString?.value);
        }
    }, [teamFilter, teamNames]);

    useEffect(() => {
        if (current_container) {
            updateCurrentContainer(current_container);
        }
    }, [current_container]);

    const clearPostedBy = async () => {
        updateMyReportsChecked(false);
        updateOrionSourceChecked(false);
        updateOrionUserChecked(false);
        updateOpenSourceChecked(false);
        updateMyOrganizationChecked(false);
        updateFilteredUsers(getInitialState().filteredUsers);
        updateTeamFilter(null);
        updateUserAutofillValue(undefined);
        updateIndividualUserChecked(false);
        updateTeamAutofillValue(undefined);
        updateIndividualTeamChecked(false);
    };
    useEffect(() => {
        const timePeriod = selectedTimePeriod === "All Time" ? null : selectedTimePeriod;

        const changedFilter: FilterState = {
            ...currentFilter,
            timePeriod: timePeriod === "28 Day" ? "28 Day" : (timePeriod as Filter_TimePeriod),
            markerClustering,
            incidentsIncluded: incidentsChecked,
            sosIncluded: sosChecked,
            myOrganizationIncluded: myOrganizationChecked,
            myReportsIncluded: myReportsChecked,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            otherIncluded: otherChecked,
            orionSourceIncluded: orionSourceChecked,
            orionUserIncluded: orionUserChecked,
            openSourceIncluded: openSourceChecked,
            selectedRegion,
            filteredUsers: filteredUsers.length < defaultUsers.length ? filteredUsers : [],
            verifiedIncluded: verifiedChecked,
            unverifiedIncluded: unverifiedChecked,
            teamFilter,
            myTeamIncluded: myTeamChecked,
            eventTypeApplied: selectedEventTypes
        };
        updateFilterChangedFromDefault(!isEqual(changedFilter, getInitialState()));
    }, [
        selectedTimePeriod,
        markerClustering,
        incidentsChecked,
        sosChecked,
        myOrganizationChecked,
        myReportsChecked,
        violentChecked,
        nonviolentChecked,
        hazardChecked,
        otherChecked,
        orionSourceChecked,
        orionUserChecked,
        openSourceChecked,
        selectedRegion,
        filteredUsers,
        verifiedChecked,
        unverifiedChecked,
        currentFilter,
        defaultUsers,
        teamFilter,
        myTeamChecked,
        selectedEventTypes
    ]);

    useEffect(() => {
        const timePeriod = selectedTimePeriod === "All Time" ? null : selectedTimePeriod;

        const changedFilter: FilterState = {
            ...currentFilter,
            timePeriod: timePeriod === "28 Day" ? "28 Day" : (timePeriod as Filter_TimePeriod),
            markerClustering,
            incidentsIncluded: incidentsChecked,
            sosIncluded: sosChecked,
            myOrganizationIncluded: myOrganizationChecked,
            myReportsIncluded: myReportsChecked,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            otherIncluded: otherChecked,
            orionSourceIncluded: orionSourceChecked,
            orionUserIncluded: orionUserChecked,
            openSourceIncluded: openSourceChecked,
            selectedRegion,
            filteredUsers: filteredUsers.length < defaultUsers.length ? filteredUsers : [],
            verifiedIncluded: verifiedChecked,
            unverifiedIncluded: unverifiedChecked,
            teamFilter,
            myTeamIncluded: myTeamChecked,
            eventTypeApplied: selectedEventTypes
        };
        updateFilterChanged(!isEqual(changedFilter, currentFilter) || currentContainer !== current_container);
    }, [
        selectedTimePeriod,
        markerClustering,
        incidentsChecked,
        sosChecked,
        myOrganizationChecked,
        myReportsChecked,
        violentChecked,
        nonviolentChecked,
        hazardChecked,
        otherChecked,
        orionSourceChecked,
        orionUserChecked,
        openSourceChecked,
        selectedRegion,
        filteredUsers,
        currentFilter,
        currentContainer,
        current_container,
        verifiedChecked,
        unverifiedChecked,
        defaultUsers,
        teamFilter,
        myTeamChecked,
        selectedEventTypes
    ]);

    const updateFilter = () => {
        updateIndividualUserChecked(false);
        if (filterChanged) {
            const timePeriod = selectedTimePeriod === "All Time" ? null : selectedTimePeriod;
            if (tooltipOpen) {
                dispatch(mapActions.toggleTooltip());
            }
            if (isMobile) {
                dispatch(leftModalActions.toggleModal());
            }

            dispatch(
                filterActions.updateFilter({
                    timePeriod: timePeriod === "28 Day" ? "28 Day" : (timePeriod as Filter_TimePeriod),
                    markerClustering,
                    incidentsIncluded: incidentsChecked,
                    sosIncluded: sosChecked,
                    myOrganizationIncluded: myOrganizationChecked,
                    myReportsIncluded: myReportsChecked,
                    violentIncluded: violentChecked,
                    nonviolentIncluded: nonviolentChecked,
                    hazardIncluded: hazardChecked,
                    otherIncluded: otherChecked,
                    orionSourceIncluded: orionSourceChecked,
                    orionUserIncluded: orionUserChecked,
                    openSourceIncluded: openSourceChecked,
                    selectedRegion,
                    filteredUsers,
                    verifiedIncluded: verifiedChecked,
                    unverifiedIncluded: unverifiedChecked,
                    teamFilter,
                    myTeamIncluded: myTeamChecked,
                    eventTypeApplied: selectedEventTypes
                })
            );

            if (currentContainer !== current_container && regionChange === selectedRegion) {
                setCurrentContainer(currentContainer);
            }

            if (regionChange !== selectedRegion) {
                setSelectedRegion(selectedRegion);
                updateRegionChange(selectedRegion);
            }
        }
    };

    const updateCurrentContainerAndRegions = (container: number) => {
        updateCurrentContainer(container);
        updateSelectedRegion(0);
    };

    const availableContainers = useSelectFromRedux((state) => state.cuser.containers);
    if (!availableContainers) {
        console.error("Could not get containers in drawer.");
        return null;
    }

    const setCurrentContainer = (value: SelectValue) => {
        if (typeof value !== "number") {
            console.error("Invalid container id selected: " + value);
        }
    };

    const setSelectedRegion = (value: number) => {
        if (value !== 0) {
            const regionViewportInfo = getDefaultRegionViewportFromId(value as number, availableRegions);
            dispatch(mapActions.flyToPoint([regionViewportInfo.longitude, regionViewportInfo.latitude, regionViewportInfo.zoom]));
        } else {
            if (!current_container || !containers) {
                return;
            }
            const containerViewportInfo = getContainerViewportFromId(current_container, containers);
            dispatch(mapActions.flyToPoint([containerViewportInfo.longitude, containerViewportInfo.latitude, containerViewportInfo.zoom]));
        }
        // TEMPORARY FIX: flyTo seems to interfere with filter, and this solves it
        dispatch(clientInfoOperations.fetchClientInfo());
    };

    const resetFilter = () => {
        updateTimePeriod("28 Day");
        updateUserAutofillValue(undefined);
        updateIndividualUserChecked(false);
        updateTeamAutofillValue(undefined);
        updateIndividualTeamChecked(false);
        updateRegionChange(0);
        updateSelectedRegion(0);

        // TODO SB-232 the above fields should also be set based on the filter's initial state
        updateMarkerClustering(getInitialState().markerClustering);
        updateSosChecked(getInitialState().sosIncluded);
        updateIncidentsChecked(getInitialState().incidentsIncluded);
        updateMyOrganizationChecked(getInitialState().myOrganizationIncluded);
        updateMyReportsChecked(getInitialState().myReportsIncluded);
        updateViolentChecked(getInitialState().violentIncluded);
        updateNonviolentChecked(getInitialState().nonviolentIncluded);
        updateHazardChecked(getInitialState().hazardIncluded);
        updateOtherChecked(getInitialState().otherIncluded);
        updateOrionSourceChecked(getInitialState().orionSourceIncluded);
        updateOrionUserChecked(getInitialState().orionUserIncluded);
        updateOpenSourceChecked(getInitialState().openSourceIncluded);
        updateFilteredUsers(getInitialState().filteredUsers);
        updateVerifiedChecked(getInitialState().verifiedIncluded);
        updateUnverifiedChecked(getInitialState().unverifiedIncluded);
        updateSelectedEventTypes([]);
        updateEventTypeSearchOpen(false);
        updateTeamFilter(null);
        updateMyTeamChecked(false);

        dispatch(filterActions.resetFilter());
    };

    // const addToFilteredUsers = (id: number) => {
    //     updateFilteredUsers([id]);
    //     if (filteredUsers.length >= clientUsers.length - 2) {
    //         updateMyOrganizationChecked(false);
    //     }
    // };

    const removeFromFilteredUsers = (id: number) => {
        const userString = userNames.find((userName) => userName.key === id);
        updateUserAutofillValue(userString?.value);
        const usersArray = Array.from(new Set(clientUsers.map((profile: UserProfile) => profile.id)));
        updateFilteredUsers(usersArray.filter((value) => value !== id));
        updateOrionSourceChecked(false);
        updateOrionUserChecked(false);
        updateOpenSourceChecked(false);
        updateMyReportsChecked(false);
        updateMyTeamChecked(false);
        updateTeamAutofillValue(undefined);
        updateIndividualTeamChecked(false);
    };

    const setTeamFilter = (id: number) => {
        const teamString = teamNames.find((teamName) => teamName.key === id);
        updateTeamAutofillValue(teamString?.value);
        updateTeamFilter(id);
        updateOrionSourceChecked(false);
        updateOrionUserChecked(false);
        updateOpenSourceChecked(false);
        updateMyReportsChecked(false);
        updateMyTeamChecked(false);
        updateUserAutofillValue(undefined);
        updateIndividualUserChecked(false);
    };

    const setIndividualUserChecked = () => {
        if (individualUserChecked === true) {
            updateUserAutofillValue(undefined);
        } else {
            updateOrionSourceChecked(false);
            updateOrionUserChecked(false);
            updateOpenSourceChecked(false);
            updateMyReportsChecked(false);
            updateMyOrganizationChecked(false);
            updateTeamAutofillValue(undefined);
            updateIndividualTeamChecked(false);
            updateMyTeamChecked(false);
        }
        updateIndividualUserChecked(!individualUserChecked);
    };

    const setIndividualTeamChecked = () => {
        if (individualTeamChecked === true) {
            updateTeamAutofillValue(undefined);
        } else {
            updateOrionSourceChecked(false);
            updateOrionUserChecked(false);
            updateOpenSourceChecked(false);
            updateMyReportsChecked(false);
            updateMyOrganizationChecked(false);
            updateUserAutofillValue(undefined);
            updateIndividualUserChecked(false);
            updateMyTeamChecked(false);
        }
        updateIndividualTeamChecked(!individualTeamChecked);
    };

    const setSelectedEventTypes = (value: string, operation: string) => {
        if (operation === "add") {
            if (!selectedEventTypes.includes(value)) {
                const newSelectedEventTypes = selectedEventTypes.concat(value);
                updateSelectedEventTypes(newSelectedEventTypes);
            }
        } else if (operation === "delete") {
            if (selectedEventTypes.includes(value)) {
                const selectedEventTypesCopy = [...selectedEventTypes];
                const index = selectedEventTypesCopy.indexOf(value);
                selectedEventTypesCopy.splice(index, 1);
                updateSelectedEventTypes(selectedEventTypesCopy);
            }
        } else if (operation === "clear") {
            updateSelectedEventTypes([]);
        }
    };

    useEffect(() => {
        if (selectedEventTypes.length > 0) {
            updateViolentChecked(false);
            updateNonviolentChecked(false);
            updateHazardChecked(false);
            updateOtherChecked(false);
        }
    }, [selectedEventTypes, updateViolentChecked, updateNonviolentChecked, updateHazardChecked, updateOtherChecked]);

    useEffect(() => {
        if (violentChecked === true || nonviolentChecked === true || hazardChecked === true || otherChecked === true) {
            setSelectedEventTypes("all", "clear");
            updateEventTypeSearchOpen(false);
        }
        // don't want it to update every time setSelectedEventTypes does
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [violentChecked, nonviolentChecked, hazardChecked, otherChecked]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    paddingLeft: "3%",
                    paddingRight: "3%"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <div style={{ color: colors.scheme.primaryText, fontWeight: "bold" }}>Region</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "10px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                textAlign: "left",
                                borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                            }}
                        >
                            <Dropdown
                                // disabled={isMobile && userProfile?.role !== 1}
                                defaultValue={currentContainer || undefined}
                                value={currentContainer}
                                onChange={(value) => updateCurrentContainerAndRegions(value as number)}
                                // showArrow={!isMobile || userProfile?.role === 1}
                                showArrow={true}
                                color={colors.scheme.cardLightCaptionText}
                                background={"transparent"}
                                bordered={false}
                                arrowcolor={colors.scheme.primaryText}
                                style={{ textAlign: "left" }}
                            >
                                {availableContainers.map((container) => {
                                    return (
                                        <Option key={container.id} value={container.id}>
                                            {container.name}{" "}
                                        </Option>
                                    );
                                })}
                            </Dropdown>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                            }}
                        >
                            <Dropdown
                                defaultValue={selectedRegion || undefined}
                                value={selectedRegion}
                                onChange={(value) => updateSelectedRegion(value as number)}
                                showArrow={true}
                                color={colors.scheme.cardLightCaptionText}
                                arrowcolor={colors.scheme.primaryText}
                                background={"transparent"}
                                bordered={false}
                                style={{ textAlign: "left" }}
                            >
                                {regionSelections.map((region: DefaultRegion | DefaultRegionNoViewport) => {
                                    return (
                                        <Option key={region.id} value={region.id}>
                                            {region.name}{" "}
                                        </Option>
                                    );
                                })}
                            </Dropdown>
                        </div>
                    </div>
                    <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", marginBottom: "5px" }}>Report Type</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "5px"
                        }}
                    >
                        <StyledTag
                            fontcolor={incidentsChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={incidentsChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                            checked={incidentsChecked}
                            onChange={(checked) => updateIncidentsChecked(!incidentsChecked)}
                        >
                            <div>Incident</div>
                            {incidentsChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={sosChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={sosChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                            checked={sosChecked}
                            onChange={(checked) => updateSosChecked(!sosChecked)}
                        >
                            <div>SOS Report</div>
                            {sosChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                    </div>
                    <Row style={{ justifyContent: "space-between" }}>
                        <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>Posted by</div>
                        <div
                            onClick={clearPostedBy}
                            style={{
                                position: "relative",
                                color: colors.scheme.secondaryHeaderText,
                                fontWeight: "bold",
                                cursor: "pointer",
                                marginTop: "10px",
                                marginRight: "14px",
                                textTransform: "uppercase",
                                fontSize: "12px"
                            }}
                        >
                            Clear
                        </div>
                    </Row>
                    {individualUserChecked && (
                        <ShortTextInputAutoComplete
                            options={userNames}
                            filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            color={colors.scheme.primaryText}
                            background={colors.scheme.selectorBackground}
                            onSelect={(value: string, option) => {
                                updateIndividualUserChecked(false);
                                removeFromFilteredUsers(option.key as number);
                            }}
                            style={{ margin: "0 0 10px", width: "calc(100% - 8px)" }}
                            allowClear={isMobile && isIOS ? false : true}
                            placeholdercolor={colors.scheme.secondaryHeaderText}
                        >
                            <ShortTextInput maxLength={30} placeholder="Find A User" value={userAutofillValue} />
                        </ShortTextInputAutoComplete>
                    )}
                    {individualTeamChecked && (
                        <ShortTextInputAutoComplete
                            options={teamNames}
                            filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            color={colors.scheme.primaryText}
                            background={colors.scheme.selectorBackground}
                            onSelect={(value: string, option) => {
                                updateIndividualTeamChecked(false);
                                setTeamFilter(option.key as number);
                            }}
                            style={{ margin: "0 0 10px", width: "calc(100% - 8px)" }}
                            allowClear={isMobile && isIOS ? false : true}
                            placeholdercolor={colors.scheme.secondaryHeaderText}
                        >
                            <ShortTextInput maxLength={30} placeholder="Find A Group" value={teamAutofillValue} />
                        </ShortTextInputAutoComplete>
                    )}
                    <ShowScrollbarDiv
                        color={colors.scheme.primaryText}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "5px",
                            width: "100%",
                            maxHeight: "153px",
                            overflowY: "scroll",
                            scrollSnapType: "y mandatory",
                            scrollSnapStop: "always"
                        }}
                    >
                        {/* <StyledTag
                            fontcolor={orionSourceChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={
                                orionSourceChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag
                            }
                            checked={orionSourceChecked}
                            onChange={(checked) => {
                                updateOrionSourceChecked(!orionSourceChecked);
                                updateIndividualUserChecked(false);
                                updateUserAutofillValue(undefined);
                                updateIndividualTeamChecked(false);
                                updateTeamAutofillValue(undefined);
                            }}
                        >
                            <div>Orion Asset</div>
                            {orionSourceChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={orionUserChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={
                                orionUserChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag
                            }
                            checked={orionUserChecked}
                            onChange={(checked) => {
                                updateOrionUserChecked(!orionUserChecked);
                                updateIndividualUserChecked(false);
                                updateUserAutofillValue(undefined);
                                updateIndividualTeamChecked(false);
                                updateTeamAutofillValue(undefined);
                            }}
                        >
                            <div>Orion User</div>
                            {orionUserChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={openSourceChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={
                                openSourceChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag
                            }
                            checked={openSourceChecked}
                            onChange={(checked) => {
                                updateOpenSourceChecked(!openSourceChecked);
                                updateIndividualUserChecked(false);
                                updateUserAutofillValue(undefined);
                                updateIndividualTeamChecked(false);
                                updateTeamAutofillValue(undefined);
                            }}
                        >
                            <div>Open Source</div>
                            {openSourceChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag> */}
                        <StyledTag
                            fontcolor={myReportsChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={myReportsChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                            checked={myReportsChecked}
                            onChange={(checked) => {
                                updateMyReportsChecked(!myReportsChecked);
                                updateMyTeamChecked(false);
                                updateIndividualUserChecked(false);
                                updateUserAutofillValue(undefined);
                                updateIndividualTeamChecked(false);
                                updateTeamAutofillValue(undefined);
                            }}
                        >
                            <div style={{ overflow: "hidden" }}>My Reports</div>
                            {myReportsChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        {/* <StyledTag
                            fontcolor={myOrganizationChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={
                                myOrganizationChecked
                                    ? colors.scheme.filterSelectedTag
                                    : colors.scheme.filterUnselectedTag
                            }
                            checked={myOrganizationChecked}
                            onChange={(checked) => {
                                updateMyOrganizationChecked(!myOrganizationChecked);
                                updateIndividualUserChecked(false);
                                updateUserAutofillValue(undefined);
                                updateIndividualTeamChecked(false);
                                updateTeamAutofillValue(undefined);
                            }}
                        >
                            <div>My Organization</div>
                            {myOrganizationChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag> */}
                        {/* <StyledTag
                            fontcolor={myOrganizationChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={
                                myReportsChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag
                            }
                            checked={myReportsChecked}
                            onChange={(checked) => {
                                updateMyReportsChecked(!myReportsChecked);
                                updateIndividualUserChecked(false);
                                updateUserAutofillValue(undefined);
                            }}
                        >
                            <div style={{ overflow: "hidden" }}>My Reports</div>
                            {myReportsChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag> */}
                        {(!!(cuserTeam === null) || (!!(cuserTeam !== null) && !!(cuserTeam.permissions !== 1))) && (
                            <StyledTag
                                fontcolor={myTeamChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={myTeamChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                                checked={myTeamChecked}
                                onChange={(checked) => {
                                    updateOrionUserChecked(false);
                                    updateOpenSourceChecked(false);
                                    updateOrionSourceChecked(false);
                                    updateMyTeamChecked(!myTeamChecked);
                                    updateMyReportsChecked(false);
                                    updateIndividualUserChecked(false);
                                    updateUserAutofillValue(undefined);
                                    updateIndividualTeamChecked(false);
                                    updateTeamAutofillValue(undefined);
                                }}
                            >
                                <div style={{ width: "90%" }}>My Group</div>
                                {myTeamChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            height: "12px"
                                        }}
                                    />
                                )}
                            </StyledTag>
                        )}
                        <StyledTag
                            fontcolor={userAutofillValue ? "#FFFFFF" : "#4C4C4C"}
                            background={userAutofillValue ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                            checked={individualUserChecked}
                            onChange={(checked) => setIndividualUserChecked()}
                        >
                            <div style={{ overflow: "hidden", display: "flex", alignItems: "center" }}>
                                {userAutofillValue ? userAutofillValue : "Search Members"}
                                <img
                                    src={colors.mode === "light" ? SearchGreyIcon : SearchWhiteIcon}
                                    alt={"search"}
                                    style={{
                                        height: "12px",
                                        marginLeft: "5px"
                                    }}
                                />
                            </div>
                        </StyledTag>
                        {(!!(cuserTeam === null) || (!!(cuserTeam !== null) && !!(cuserTeam.permissions !== 1))) && (
                            <StyledTag
                                fontcolor={teamAutofillValue ? "#FFFFFF" : "#4C4C4C"}
                                background={teamAutofillValue ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                                checked={individualTeamChecked}
                                onChange={(checked) => setIndividualTeamChecked()}
                            >
                                <div style={{ overflow: "hidden", display: "flex", alignItems: "center" }}>
                                    {teamAutofillValue ? teamAutofillValue : "Search Groups"}
                                    <img
                                        src={colors.mode === "light" ? SearchGreyIcon : SearchWhiteIcon}
                                        alt={"search"}
                                        style={{
                                            height: "12px",
                                            marginLeft: "5px"
                                        }}
                                    />
                                </div>
                            </StyledTag>
                        )}
                        <StyledTag
                            fontcolor={orionSourceChecked && orionUserChecked && openSourceChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={
                                orionSourceChecked && orionUserChecked && openSourceChecked
                                    ? colors.scheme.filterSelectedTag
                                    : colors.scheme.filterUnselectedTag
                            }
                            checked={orionSourceChecked && orionUserChecked && openSourceChecked}
                            onChange={(checked) => {
                                updateOrionUserChecked(!orionUserChecked);
                                updateOpenSourceChecked(!openSourceChecked);
                                updateOrionSourceChecked(!orionSourceChecked);
                                updateIndividualUserChecked(false);
                                updateUserAutofillValue(undefined);
                                updateMyTeamChecked(false);
                            }}
                        >
                            <div>Public Reports</div>
                            {orionSourceChecked && orionUserChecked && openSourceChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                    </ShowScrollbarDiv>
                    <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>Category of Incident</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "5px",
                            width: "100%"
                        }}
                    >
                        <StyledTag
                            fontcolor={violentChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={violentChecked ? colors.scheme.filterViolentTag : colors.scheme.filterUnselectedTag}
                            checked={violentChecked}
                            onChange={(checked) => updateViolentChecked(!violentChecked)}
                        >
                            <div>Violent</div>
                            {violentChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={nonviolentChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={nonviolentChecked ? colors.scheme.filterNonViolentTag : colors.scheme.filterUnselectedTag}
                            checked={nonviolentChecked}
                            onChange={(checked) => updateNonviolentChecked(!nonviolentChecked)}
                        >
                            <div>Nonviolent</div>
                            {nonviolentChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={hazardChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={hazardChecked ? colors.scheme.filterHazardTag : colors.scheme.filterUnselectedTag}
                            checked={hazardChecked}
                            onChange={(checked) => updateHazardChecked(!hazardChecked)}
                        >
                            <div>Hazard</div>
                            {hazardChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={otherChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={otherChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                            checked={otherChecked}
                            onChange={(checked) => updateOtherChecked(!otherChecked)}
                        >
                            <div>Other</div>
                            {otherChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                    </div>
                    <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>Type of Event</div>
                    {eventTypeSearchOpen && (
                        <ShortTextInputAutoComplete
                            options={eventTypeNames}
                            filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            color={colors.scheme.primaryText}
                            background={colors.scheme.selectorBackground}
                            onSelect={(value: string, option) => setSelectedEventTypes(value, "add")}
                            style={{ margin: "0 0 10px", width: "calc(100% - 8px)" }}
                            allowClear={isMobile && isIOS ? false : true}
                            placeholdercolor={colors.scheme.secondaryHeaderText}
                        >
                            <ShortTextInput maxLength={30} placeholder="Find An Event Type" />
                        </ShortTextInputAutoComplete>
                    )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "5px",
                            width: "100%"
                        }}
                    >
                        {selectedEventTypes.length === 0 && (
                            <StyledTag
                                fontcolor={"#4C4C4C"}
                                background={colors.scheme.filterUnselectedTag}
                                checked={eventTypeSearchOpen}
                                onChange={(checked) => updateEventTypeSearchOpen(!eventTypeSearchOpen)}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    Search Event Types
                                    <img
                                        src={colors.mode === "light" ? SearchGreyIcon : SearchWhiteIcon}
                                        alt={"search"}
                                        style={{
                                            height: "12px",
                                            marginLeft: "5px"
                                        }}
                                    />
                                </div>
                            </StyledTag>
                        )}
                        {selectedEventTypes.map((eventType) => (
                            <StyledTag
                                fontcolor={"#FFFFFF"}
                                background={colors.scheme.filterSelectedTag}
                                checked={eventChecked}
                                key={eventType}
                                onChange={() => {
                                    updateEventChecked(!eventChecked);
                                    setSelectedEventTypes(eventType, "delete");
                                }}
                            >
                                <div>{eventType}</div>
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            </StyledTag>
                        ))}
                    </div>
                    <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>Verification</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "5px"
                        }}
                    >
                        <StyledTag
                            fontcolor={verifiedChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={verifiedChecked ? colors.general.standardGreen : colors.scheme.filterUnselectedTag}
                            checked={verifiedChecked}
                            onChange={(checked) => updateVerifiedChecked(!verifiedChecked)}
                        >
                            <div>Verified</div>
                            {verifiedChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={unverifiedChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={unverifiedChecked ? colors.general.amber : colors.scheme.filterUnselectedTag}
                            checked={unverifiedChecked}
                            onChange={(checked) => updateUnverifiedChecked(!unverifiedChecked)}
                        >
                            <div>Unverified</div>
                            {unverifiedChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                    </div>
                    <span style={{ color: colors.scheme.primaryText, fontWeight: "bold", marginTop: "5px" }}>Time Period</span>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "10px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                textAlign: "left",
                                borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                            }}
                        >
                            <Dropdown
                                onChange={(value: any) => {
                                    value = parseInt(value);
                                    updateTimePeriod(timePeriodOptions[value]);
                                }}
                                value={timePeriodOptions.indexOf(selectedTimePeriod)}
                                defaultValue={timePeriodOptions.indexOf(selectedTimePeriod)}
                                showArrow={true}
                                color={colors.scheme.cardLightCaptionText}
                                background={"transparent"}
                                bordered={false}
                                arrowcolor={colors.scheme.primaryText}
                                style={{ textAlign: "left" }}
                            >
                                {timePeriodOptions.map((el, index) => {
                                    return (
                                        <Select.Option value={index} key={index}>
                                            {el}
                                        </Select.Option>
                                    );
                                })}
                            </Dropdown>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >
                        <span style={{ color: colors.scheme.primaryText, marginTop: "10px", fontWeight: "bold" }}>Marker Clustering</span>
                        {/* Typescript for some reason not ok with event.target.value being a number? */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "150px",
                                marginLeft: "7px",
                                marginTop: "16px"
                            }}
                        >
                            <Toggle
                                checked={markerClustering}
                                onClick={(checked) => updateMarkerClustering(checked)}
                                background={colors.scheme.toggleBackground}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: "calc(100% + 40px)",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    background: colors.scheme.modalBottomBar,
                    margin: "10px -20px -20px -20px",
                    borderRadius: "0 0 14px 14px"
                }}
            >
                <Button
                    onClick={() => {
                        updateFilter();
                    }}
                    active={filterChanged}
                    color={filterChanged ? colors.scheme.submitButtonText : "#4C4C4C"}
                    background={filterChanged ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag}
                >
                    Apply
                </Button>
                <Button
                    onClick={() => {
                        resetFilter();
                    }}
                    active={filterChangedFromDefault}
                    color={filterChangedFromDefault ? colors.scheme.submitButtonText : "#4C4C4C"}
                    background={filterChangedFromDefault ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag}
                >
                    Reset
                </Button>
            </div>
        </>
    );
};
