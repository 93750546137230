import { Operation, PromiseOperation } from "..";
import { NewTeam, TeamUpdate, UserUpdate } from "../../types/client";
import { ConnectionError, ErrorResponse } from "../../types/api";
import { StatusCode } from "../../types/api";
import * as client from "../../types/api/client";
import { toastActions } from "../toast";
import { fetchClientInfo } from "../clientInfo/operations";
import { cuserOperations } from "../cuser";
import { teamsActions } from "./index";

export const modifyUser: Operation = () => async (dispatch, getState) => {
    const state = getState();

    const userId = state.teams.fullViewUserId;
    const newRole = state.teams.modifyUserRole as number;
    const newTeam = state.teams.modifyUserTeam === "Default Group" ? null : (state.teams.modifyUserTeam as number);
    const newDesignation = state.teams.modifyUserDesignation as string;

    if (userId) {
        const userUpdate: UserUpdate = {
            id: userId
        };

        if (newRole !== undefined) {
            userUpdate.role = newRole;
        }

        if (newTeam !== undefined) {
            userUpdate.team_id = newTeam === 0 ? null : newTeam;
        }

        if (newDesignation !== undefined) {
            userUpdate.designation = newDesignation;
        }

        // console.log(userUpdate);

        try {
            await client.modifyUser(userUpdate);
            dispatch(teamsActions.updateAccordianCurrentTeam(userUpdate.team_id === null ? "default" : userUpdate.team_id?.toString()));
            dispatch(cuserOperations.getCuserProfile());
            dispatch(fetchClientInfo());
        } catch (error) {
            if (error instanceof ErrorResponse) {
                console.log("Error response: " + error.message);
                if (error.statusCode === StatusCode.NotAllowed) {
                    dispatch(toastActions.openError("You don't have permission to update this profile."));
                } else {
                    dispatch(toastActions.openError("Error updating profile."));
                }
            } else if (error instanceof ConnectionError) {
                console.log("Connection error: " + error.message);
                dispatch(toastActions.openError("You don't seem to be connected to the internet. Check your connection and try again."));
            } else {
                console.error("Unknown error occurred in submitClientReport: ", error);
                dispatch(toastActions.openError("Failed to update user. Please try again."));
            }
        }
    } else {
        throw new Error("Missing userId!");
    }
};

export const createNewTeam: PromiseOperation<void> = () => async (dispatch, getState) => {
    const state = getState();

    const clientId = state.cuser.userProfile?.client_id;
    const newTeamName = state.teams.createTeamName;

    if (clientId && newTeamName) {
        const newTeamToInsert: NewTeam = {
            name: newTeamName
        };

        try {
            await client.createTeam(newTeamToInsert);
            dispatch(fetchClientInfo());
        } catch (error) {
            if (error instanceof ErrorResponse) {
                console.log("Error response: " + error.message);
            } else if (error instanceof ConnectionError) {
                console.log("Connection error: " + error.message);
            } else {
                console.error("Unknown error occurred in submitClientReport: ", error);
            }
            dispatch(toastActions.openError("Failed to create team. Please ensure you're an admin and try again."));
        }
    } else {
        throw new Error("Missing clientId or newTeamName");
    }
};

export const modifyTeam: Operation = (teamUpdate: TeamUpdate) => async (dispatch) => {
    try {
        await client.updateTeam(teamUpdate);
        // dispatch(teamsActions.updateAccordianCurrentTeam(userUpdate.team_id?.toString()));
        // dispatch(cuserOperations.getCuserProfile());
        dispatch(fetchClientInfo());
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
            // if (error.statusCode === StatusCode.NotAllowed) {
            //     dispatch(errorActions.openError("You don't have permission to update this profile."));
            // } else {
            //     dispatch(errorActions.openError("Error updating profile."));
            // }
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
            dispatch(toastActions.openError("You don't seem to be connected to the internet. Check your connection and try again."));
        } else {
            console.error("Unknown error occurred in submitTeamUpdate: ", error);
            dispatch(toastActions.openError("Failed to update team. Please try again."));
        }
    }
};

/**
 * Boolean promise operation returns TRUE on success response, FALSE on error response or other error
 */
export const addPendingUser: PromiseOperation<boolean> = () => async (dispatch, getState) => {
    const state = getState();

    const firstName = state.teams.addNewUserFirstName;
    const lastName = state.teams.addNewUserLastName;
    const uid = state.teams.addNewUserUnique;
    const designation = state.teams.addNewUserDesignation;
    const role = state.teams.addNewUserRole ?? 0;
    const team = state.teams.addNewUserTeam;
    const loginOption = state.teams.loginOptionValue;

    const newPendingUser: any = {
        role
    };

    if (firstName.length > 0) {
        newPendingUser.first_name = firstName;
    }

    if (lastName.length > 0) {
        newPendingUser.last_name = lastName;
    }

    if (uid.includes("@")) {
        newPendingUser.email = uid;
    } else {
        newPendingUser.phone_primary = uid;
    }

    if (designation !== null) {
        newPendingUser.designation = designation;
    }

    if (team !== null) {
        newPendingUser.team_id = team;
    }

    if (loginOption === "email" && !uid.includes("@")) {
        dispatch(toastActions.openError("Please enter a valid email."));
    } else {
        try {
            await client.inviteUser(newPendingUser);
            dispatch(teamsActions.resetAddPendingUserForm());
            return true;
        } catch (error) {
            if (error instanceof ErrorResponse) {
                console.log("Error response: " + error.message);
                if (error.statusCode === StatusCode.NotAllowed) {
                    dispatch(toastActions.openError("You don't have permission to invite users. Please restart the app and try again."));
                } else if (error.statusCode === StatusCode.ClientLogicError) {
                    dispatch(toastActions.openError(error.message ?? "A user with this email/phone has already been registered."));
                } else if (error.message && error.message.includes("Unable to send SMS")) {
                    dispatch(
                        toastActions.openError(
                            "We weren't able to send a text invite to this phone number. Is this number active and ready to receive texts? \n(You can still sign up with this number by navigating to the Sign Up page and entering your registered phone number)"
                        )
                    );
                } else {
                    dispatch(toastActions.openError("Error inviting user. Please restart the app and try again."));
                }
            } else if (error instanceof ConnectionError) {
                console.log("Connection error: " + error.message);
                dispatch(toastActions.openError("You don't seem to be connected to the internet. Check your connection and try again."));
            } else {
                console.error("Unknown error occurred in client.inviteUser: ", error);
                dispatch(toastActions.openError("Failed to invite user. Please try again."));
            }
        }
    }
    return false;
};

export const disableUser: PromiseOperation<void> = (user_id: number, enable: boolean) => async (dispatch, getState) => {
    try {
        await client.disableUser(user_id, enable);
        await dispatch(fetchClientInfo());
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
            dispatch(toastActions.openError(error.message ?? "Unknown error occurred. Please try again."));
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
            dispatch(toastActions.openError("You don't seem to be connected to the internet. Check your connection and try again."));
        } else {
            console.error("Unknown error occurred in submitTeamUpdate: ", error);
            dispatch(toastActions.openError("Failed to update team. Please try again."));
        }
    }
};
