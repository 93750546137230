import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelectFromRedux } from "../../utils/_hooks";
import { createMarkersFromReports } from "./utils";
import { StaticMap, _MapContext as MapContext } from "react-map-gl";
// import DeckGL from "deck.gl";
import { renderMenuLayers, renderAnalyticsLayers, renderVisLayers } from "./renderLayers";
import TimeComponent from "./keplerComponents/components/time-component";
import { isEqual } from "lodash";
import VisApiComponent from "./apiComponents/vis-api-component";
import MyController from "./controller";

// utils
import { MapboxAPIKey, S3Key } from "../../utils";

// redux
import { visualizationsActions } from "../../state/visualizations";
import { mapActions, Marker } from "../../state/map";

// components
import { CurrentLocationButton, RoundButton, SearchButtonContainer } from "./styled";
import Tooltip from "./tooltip";
import { FilterButtonContainer } from "../home/styled";
import { filterActions, getInitialState } from "../../state/filter";
import { leftModalActions } from "../../state/left-modal";
import { Button } from "../filter/styled";
import { isMobile } from "react-device-detect";
import { getGridCenter } from "../visualizations/utils";
import { teamsActions } from "../../state/teams";
import { useQuery } from "react-query";
import * as publicApi from "../../types/api/public";
const Geosuggest = require("react-geosuggest").default;
const DeckGL = require("deck.gl").default;

const CloseWhiteIcon = S3Key + "close-white.png";
const CloseGreyIcon = S3Key + "close-dark.png";
const SearchGreyIcon = S3Key + "search-grey.png";
const SearchWhiteIcon = S3Key + "search-white.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: any) => {
    const [
        userProfile,
        { showTooltip },
        clientUsers,
        filter,
        { modalContent, isOpen },
        { timeFilter },
        selectedMarker,
        { day, timeWindow, showZones, focusedInput, fuzzyRouteEnabled, hasStartedForecasting, loadingRiskForecastsMessage }
    ] = useSelectFromRedux((state) => [
        state.cuser.userProfile,
        state.map,
        state.clientInfo.users,
        state.filter,
        state.leftModal,
        state.timeFilter,
        state.map.selectedMarker,
        state.visualizations
    ]);
    const [showSearchBar, updateShowSearchBar] = useState<boolean>(false);
    const current_container = useSelectFromRedux((state) => state.cuser.userProfile?.current_container);

    const timePeriodNumber = filter && filter.timePeriod ? Number(filter.timePeriod.split(" ")[0]) : 250;

    const getNumberFromTimePeriod = (timePeriod: string | null) => {
        if (timePeriod === "24 Hour") {
            return 1;
        } else if (timePeriod === "7 Day") {
            return 7;
        } else if (timePeriod === "28 Day") {
            return 28;
        } else {
            return 250;
        }
    };

    const publicReportsQuery = useQuery(["public-reports", current_container, timePeriodNumber], () =>
        publicApi.getReportsBounded(current_container ?? 2, getNumberFromTimePeriod(filter.timePeriod))
    );

    const { showRiskForecastingDetails } = filter;

    const colors = useSelectFromRedux((state) => state.color);

    const mapStyle = colors.mode === "dark" ? "mapbox://styles/nbowden/ckrl2x11p0w1t17qvh33d6u3y" : "mapbox://styles/verstaan/ckagi73k30pdi1ip5rlfn4cz0";

    const dispatch = useDispatch();

    const mapRef = useRef<any>(null);
    const geosuggestEl = useRef<any>(null);

    const onClick = (selected: any, viewport?: any) => {
        if (selected.object.device_fingerprint) {
            dispatch(teamsActions.updateFullViewUserId(selected.object.user_id));
            dispatch(teamsActions.updateAccordianCurrentTeam(selected.object.team_id));
            dispatch(teamsActions.toggleDisplayFullUserView(true));
            if (!isOpen) {
                dispatch(leftModalActions.toggleModal());
            }
            dispatch(leftModalActions.setModalContent({ modalContent: "groups" }));
            return;
        }
        if (selected.object) {
            if (isMobile && selected.object.geometry.coordinates && viewport && viewport.zoom) {
                dispatch(mapActions.recenterOnPoint({ point: selected.object.geometry.coordinates, zoom: viewport.zoom }));
            }

            // Zoom to crime grid square on mobile
            if (isMobile && selected.object.mgrs && selected.object.geometry.coordinates) {
                dispatch(mapActions.recenterOnPoint({ point: getGridCenter(selected.object.geometry.coordinates), zoom: 13 }));
            }

            if (!showTooltip) {
                dispatch(mapActions.toggleTooltip());
                if (window.analytics) {
                    window.analytics.track("Map Marker Clicked");
                }
            } else if (selected.object.cluster && isEqual(selected.object, selectedMarker)) {
                dispatch(mapActions.toggleTooltip());
                dispatch(
                    mapActions.flyToPoint([selected.object.geometry.coordinates[0], selected.object.geometry.coordinates[1], selected.expansionZoom * 1.2])
                );
            }
            return dispatch(mapActions.renderTooltip(selected.object));
        }
    };

    // FIXME userprofile should not be optional
    if (!userProfile) {
        return null;
    }

    const { viewport } = useSelectFromRedux((state) => {
        return state.map;
    });

    const [filterApplied, updateFilterApplied] = useState<boolean>(false);
    useEffect(() => {
        updateFilterApplied(
            !isEqual({ ...filter, showTimeFilter: false }, getInitialState()) &&
                !isEqual({ ...filter, showTimeFilter: true }, getInitialState()) &&
                !isEqual({ ...filter, showRiskForecastingDetails: true }, getInitialState()) &&
                !isEqual({ ...filter, showRiskForecastingDetails: false }, getInitialState())
        );
    }, [filter]);

    const [gridData, setGridData] = useState([]);
    const [zoneData, setZoneData] = useState([]);
    const { safeRouteData, origin, destination, selectedRoute, riskIndexFilter } = useSelectFromRedux((state) => state.visualizations);
    // const [showText, setShowText] = useState<boolean>(viewport.zoom > 8);

    const markers = createMarkersFromReports(publicReportsQuery.data ?? [], userProfile, clientUsers, filter);

    const _renderLayers: any = () => {
        switch (modalContent) {
            case "menu":
                return renderMenuLayers({
                    data: markers,
                    onClick,
                    filter,
                    colors
                });
            case "analytics":
                return renderAnalyticsLayers({ data: markers, onClick, filter, colors, timeFilter });
            case "visualizations":
                return renderVisLayers({
                    data: {
                        gridData,
                        zoneData,
                        safeRouteData,
                        originData: { id: "origin", coordinates: origin },
                        destinationData: { id: "destination", coordinates: destination }
                    },
                    filter: { day, timeWindow, selectedRoute, riskIndexFilter },
                    visible: showZones,
                    onClick,
                    source: props.source
                });
            default:
                return renderMenuLayers({
                    data: markers,
                    onClick,
                    filter,
                    colors
                });
        }
    };

    const mapOnClick = (e: any) => {
        if (focusedInput) {
            if (document.getElementById(focusedInput)) document.getElementById(focusedInput)!.blur();
            dispatch(
                visualizationsActions.updateCoordinates({
                    endpoint: focusedInput,
                    coordinates: e.coordinate,
                    clicked: true
                })
            );
        }

        if (showTooltip && e.layer === null) {
            dispatch(mapActions.toggleTooltip());
        }
    };

    const onSuggestSelect = (location: any) => {
        dispatch(mapActions.flyToPoint([location.lng, location.lat, 13]));
        geosuggestEl.current.clear();
        geosuggestEl.current.blur();
        updateShowSearchBar(false);
    };

    return (
        <>
            <DeckGL
                ContextProvider={MapContext.Provider}
                layers={_renderLayers()}
                initialViewState={viewport}
                // controller contains onMouseDown function
                controller={MyController as any}
                onClick={mapOnClick}
                width="100vw"
                height="100vh"
                getCursor={() => (fuzzyRouteEnabled ? "crosshair" : "auto")}
                onViewStateChange={(viewState: any) => {
                    // if (viewState.viewState.zoom > 8) {
                    //     setShowText(true);
                    // } else setShowText(false);
                    if (
                        showRiskForecastingDetails === true &&
                        isMobile &&
                        modalContent === "visualizations" &&
                        hasStartedForecasting &&
                        loadingRiskForecastsMessage === null
                    )
                        dispatch(filterActions.toggleShowRiskForecastingDetails(false));
                }}
            >
                <StaticMap
                    reuseMaps
                    ref={mapRef}
                    width={window.innerWidth}
                    height={window.innerHeight}
                    mapStyle={mapStyle}
                    preventStyleDiffing={true}
                    mapboxApiAccessToken={MapboxAPIKey}
                />
                {isMobile ? (
                    <Geosuggest
                        ref={geosuggestEl}
                        placeholder="Search Atlas"
                        onSuggestSelect={(e: any) => onSuggestSelect(e.location)}
                        style={{ background: "purple" }}
                    />
                ) : (
                    <>
                        <SearchButtonContainer>
                            <RoundButton onClick={(e) => updateShowSearchBar(!showSearchBar)}>
                                {showSearchBar ? (
                                    <img src={colors.mode === "light" ? CloseWhiteIcon : CloseGreyIcon} alt="Close" width="18px" />
                                ) : (
                                    <img src={colors.mode === "light" ? SearchWhiteIcon : SearchGreyIcon} alt="Search" width="18px" />
                                )}
                            </RoundButton>
                        </SearchButtonContainer>
                        {showSearchBar && (
                            <Geosuggest ref={geosuggestEl} placeholder="Search Atlas" onSuggestSelect={(e: any) => onSuggestSelect(e.location)} />
                        )}
                    </>
                )}
                {filterApplied && userProfile?.features?.filtering !== false && modalContent !== "visualizations" && (
                    <FilterButtonContainer>
                        <Button
                            background={colors.scheme.submitButtonBackground}
                            color={colors.scheme.submitButtonText}
                            style={{
                                width: "120px",
                                height: "25px",
                                borderRadius: "50px",
                                fontSize: isMobile ? "12px" : "13px",
                                textTransform: "none",
                                zIndex: 0
                            }}
                            onClick={() => {
                                dispatch(leftModalActions.setModalContent({ modalContent: "filter" }));
                                if (!isOpen) {
                                    dispatch(leftModalActions.toggleModal());
                                    // While it may not seem like it, this line needs to be here, or else this function doesn't work
                                    dispatch(leftModalActions.setModalContent({ modalContent: "filter" }));
                                }
                            }}
                        >
                            <img
                                src={"https://orion-assets-s3.s3.us-east-2.amazonaws.com/filter-check.png"}
                                alt="Filter Applied"
                                style={{
                                    height: "20px",
                                    marginRight: "3px",
                                    marginBottom: "1px"
                                }}
                            />
                            Filter Applied
                            {/* Filter Changed */}
                        </Button>
                    </FilterButtonContainer>
                )}
                <MapContext.Consumer>
                    {(outerContext) => (
                        <MapContext.Provider
                            value={{
                                map: mapRef.current ? mapRef.current.getMap() : null,
                                ...outerContext
                            }}
                        >
                            <CurrentLocationButton positionOptions={{ enableHighAccuracy: true }} trackUserLocation={true} />
                        </MapContext.Provider>
                    )}
                </MapContext.Consumer>
                <Tooltip />
            </DeckGL>
            <VisApiComponent setGridData={setGridData} setZoneData={setZoneData} />
            <TimeComponent data={markers?.filter((marker: Marker) => marker.containerId === current_container)} />
        </>
    );
};
