// this is meant to be a .js file. please don't change it. ty

export const lightTheme = {
    modalBackground: "#F9F9F9",
    primaryText: "#4C4C4C",
    primaryButton: "#757575",
    primaryButtonAct: "#4C4C4C",
    primaryButtonBkg: "rgb(179, 181, 187)",
    primaryButtonActBkg: "rgb(225, 225, 227)",
    innerIcon: "#FEFEFEcho",
    generalCard: "rgb(225, 225, 227)",
    cardLightCaptionText: "989A9E",
    secondaryHeaderText: "#757575",
    selectorBackground: "rgb(179, 181, 187)",
    selectorLightText: "#FEFEFE",
    selectedOptionBackground: "#FFFFFF",
    selectorDarkText: "#4C4C4C",
    submitButtonBackground: "#0A84FF",
    submitButtonBackgroundDisabled: "rgba(62,88,127,0.56)",
    submitButtonText: "#FFFFFF",
    toggleBackground: "#99F9D7",
    analyticsPieHazard: "#D4A864",
    analyticsPieViolent: "#B46363",
    analyticsPieNonviolent: "#668D9E",
    analyticsPieOther: "#DBDADD",
    analyticsGraphBase: "#324276",
    analyticsPercentageIncrease: "#9F0000",
    analyticsPercentageDecrease: "#084C8B",
    filterHazardTag: "#D4A864",
    filterViolentTag: "#B46363",
    filterNonViolentTag: "#668D9E",
    filterUnselectedTag: "#EEEEEE",
    filterSelectedTag: "#274B6E",
    filterModal: "#FFFFFF",
    filterModalBottomBar: "#F8F8F8",
    modalBottomBar: "#F3F3F3",
    sosCountdown: "#AA020190",
    sosReportModal: "#B01918",
    confirmationNotification: "#3A6852",
    sosAlertNotification: "#AA0201",
    generalNotification: "#41454E",
    addReportButton: "#1B1B1F",
    sosAlertButton: "linear-gradient(#CB0D0C, #A30404)",
    sosSelectorBackground: "#98100F",
    standardGreen: "#43A978",
    amber: "#E99F2D"
};

export const darkTheme = {
    modalBackground: "#1B1B1F",
    primaryText: "#FFFFFF",
    primaryButton: "#ADAFB5",
    primaryButtonAct: "#FFFFFF",
    primaryButtonBkg: "#31343F",
    primaryButtonActBkg: "#4D515F",
    innerIcon: "#FFFFFF",
    generalCard: "#4D515F",
    cardLightCaptionText: "#FFFFFF",
    secondaryHeaderText: "#ADAFB5",
    selectorBackground: "#31343F",
    selectorLightText: "#FEFEFE",
    selectedOptionBackground: "#E8E8EA",
    selectorDarkText: "#606369",
    submitButtonBackground: "#0A84FF",
    submitButtonBackgroundDisabled: "rgba(62,88,127,0.56)",
    submitButtonText: "#FFFFFF",
    toggleBackground: "#2D9077",
    analyticsPieHazard: "#D4A864",
    analyticsPieViolent: "#B46363",
    analyticsPieNonviolent: "#668D9E",
    analyticsPieOther: "#DBDADD",
    analyticsGraphBase: "#E5E5E5",
    analyticsPercentageIncrease: "#FF8F53",
    analyticsPercentageDecrease: "#44F9B8",
    filterHazardTag: "#D4A864",
    filterViolentTag: "#B46363",
    filterUnselectedTag: "#EEEEEE",
    filterSelectedTag: "#3B4F63",
    filterNonViolentTag: "#77A9BF",
    filterModal: "#7A808F",
    filterModalBottomBar: "#9CA1AE",
    modalBottomBar: "#2B2F3E",
    sosCountdown: "#AA020190",
    sosReportModal: "#B01918",
    confirmationNotification: "#3A6852",
    sosAlertNotification: "#AA0201",
    generalNotification: "#41454E",
    addReportButton: "#1B1B1F",
    sosAlertButton: "linear-gradient(#CB0D0C, #A30404)",
    sosSelectorBackground: "#98100F",
    standardGreen: "#43A978",
    amber: "#E99F2D"
};
