import styled from "styled-components";

interface ReportItemContainerProps {
    background: string;
}

export const ReportItemContainer = styled.div`
    width: 100%;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 15px;
    background: ${(props: ReportItemContainerProps) => props.background};
`;

export const ReportItemHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5px;
`;

export const EventType = styled.div`
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
`;

export const EventDate = styled.div`
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ReportContentItem = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-bottom: 0px;
`;

export const IconContainer = styled.div`
    width: 14px;
    display: flex;
    justify-content: center;
    margin-right: 7px;
    align-items: center;
`;

export const TextWithIconContainer = styled.div`
    width: calc(100% - 14px);
    align-items: center;
`;

export const AdditionalDetailsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 5px 0;
`;

export const FieldTitle = styled.div`
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 5px;
`;

export const FieldContent = styled.div`
    font-size: 14px;
`;

interface DetailsButtonProps {
    color: string;
}

export const DetailsButton = styled.div`
    text-align: center;
    color: ${(props: DetailsButtonProps) => props.color};
    margin-bottom: -5px;
    margin-top: 5px;

    :hover {
        cursor: pointer;
    }
`;
