import styled from "styled-components";
import { device, boxShadow } from "../../utils/index";

interface ModalContainerProps {
    color: string;
    background: string;
}

export const ModalContainer = styled.div`
    z-index: 25;
    width: 400px;
    max-height: calc(100vh - 100px);
    min-height: 100px;
    position: absolute;
    top: 70px;
    left: 20px;
    background: ${(props: ModalContainerProps) => props.background};
    border-radius: 14px;
    padding: 20px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.8s;
    color: ${(props: ModalContainerProps) => props.color};
    box-shadow: 0px 4px 6px ${boxShadow};

    @media ${device.mobile} {
        width: 100vw;
        max-height: calc(100vh - 150px);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0px 0px 14px 14px;
    }
`;

export const CloseButton = styled.img`
    position: absolute;
    right: 17px;
    top: 17px;
    padding: 3px;
    cursor: pointer;
    height: 28px;
    transition: 0.3s;

    :active {
        opacity: 0.4;
    }
`;

export const EditButton = styled.img`
    position: absolute;
    right: 55px;
    top: 20px;
    cursor: pointer;
    height: 18px;
    transition: 0.3s;

    :active {
        opacity: 0.4;
    }
`;

export const AddButton = styled.img`
    position: absolute;
    right: 55px;
    top: 21px;
    cursor: pointer;
    height: 18px;

    :active {
        opacity: 0.4;
    }
`;

export const BackButton = styled.img`
    position: absolute;
    left: 20px;
    top: 17px;
    cursor: pointer;
    padding: 5px;
    height: 28px;
    transition: 0.3s

    :active {
        opacity: 0.4;
    }
`;
