import React, { useState, useEffect } from "react";
// components
import { FormField, FullScreenImage, LogoAndFormContainer, SubmitButton, VerstaanLogo, OtherCompanyLogo } from "../_shared/auth";
import { MessageContainer, MessageContent, MessageTitle, PhoneNumberInput, PhoneLoadingContainer } from "./styled";
// import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastActions } from "../../state/toast";
import { getPendingUserByPhone, getPendingUserByEmail, getPendingUserByToken } from "../../types/api/auth";
import { PendingUser } from "../../types/client";
import { ConnectionError, ErrorResponse } from "../../types/api";
import { StatusCode } from "../../types/api";
import { PendingUserSignUp } from "./pendingUserSignUp";
import { Verify } from "./Verify";
import { Error } from "../login/styled";
import { useSelectFromRedux } from "../../utils/_hooks";
import { Link, useParams } from "react-router-dom";
import { fadedRed, GoogleGeocoderAPIKey, S3Key } from "../../utils";
import Geocode from "react-geocode";
import { Alert, LoadingSpinner } from "../_shared";
import { GetDeviceFingerprint } from "../../state/cuser/operations";
import { SmallSpacer } from "../_shared/layout";
import { SmallIcon } from "../_shared/typography";

export const SignUp = () => {
    // TODO: SB-1011
    const { token } = useParams();
    const dispatch = useDispatch();
    const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);
    const errorContent = useSelectFromRedux((state) => state.error.currentText);
    const colors = useSelectFromRedux((state) => state.color);
    const toastIsError = useSelectFromRedux((state) => state.error.isError);

    const [email, updateEmail] = useState<string>("");
    const [phoneNumber, updatePhoneNumber] = useState<string>("");
    const [pendingUser, setPendingUser] = useState<PendingUser | null>(null);
    const [verificationNeeded, setVerificationNeeded] = useState<boolean>(false);
    const [defaultPhoneNumberCountryCode, updateDefaultPhoneNumberCountryCode] = useState<string>("us");
    const [phoneNumberCountryCodeLoading, updatePhoneNumberCountryCodeLoading] = useState<boolean>(true);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    Geocode.fromLatLng("" + lat, "" + lng, GoogleGeocoderAPIKey)
                        .then((response) => {
                            updateDefaultPhoneNumberCountryCode(response.results[0].address_components[6].short_name.toLowerCase());
                            updatePhoneNumberCountryCodeLoading(false);
                        })
                        .catch((error) => {
                            updatePhoneNumberCountryCodeLoading(false);
                            console.log(error);
                        });
                },
                (error) => {
                    updatePhoneNumberCountryCodeLoading(false);
                    console.log(error);
                }
            );
        } else {
            updatePhoneNumberCountryCodeLoading(false);
        }

        setTimeout(() => {
            updatePhoneNumberCountryCodeLoading(false);
        }, 5000);

        GetDeviceFingerprint()
            .then((fingerprint => {
                console.log("Got fingerprint: " + fingerprint);
                localStorage.setItem("fingerprint", fingerprint);
            }))
            .catch((err) => {
                console.log("Failed to calculate user fingerprint, please try again.");
            })

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (token && token.length === 36) {
            getPendingUserByToken(token)
                .then((pendingUser) => {
                    if (pendingUser) {
                        setPendingUser(pendingUser);
                    }
                })
                .catch((err: ErrorResponse | Error) => {
                    if (err instanceof ErrorResponse) {
                        if (err.statusCode === StatusCode.ClientLogicError) {
                            dispatch(toastActions.openError("Error: " + err.message));
                        } else {
                            dispatch(toastActions.openError("This sign up link is invalid or expired."));
                        }
                        console.log("Error response: " + err.message);
                    } else if (err instanceof ConnectionError) {
                        console.log("Connection error: " + err.message);
                        dispatch(toastActions.openError("Connection error. Please check your internet and try again."));
                    } else {
                        console.error("Unknown error occurred in getPendingUserByToken: ", err);
                        dispatch(toastActions.openError("Something went wrong. Please check your internet connection and try again."));
                    }
                });
        }
        // only perform on load
        // eslint-disable-next-line
    }, []);

    const submit = () => {
        const fingerprint = localStorage.getItem("fingerprint");
        if (!fingerprint) {
            dispatch(toastActions.openError("Failed to validate device. Please refresh and try again."));
            return;
        }

        if (email.length > 0) {
            // if (email.includes("@")) {
            // Connect for email @mike
            getPendingUserByEmail(email, fingerprint)
                .then((pendingUser) => {
                    if (pendingUser) {
                        setPendingUser(pendingUser);
                    } else {
                        setVerificationNeeded(true);
                    }
                })
                .catch((err: ErrorResponse | Error) => {
                    if (err instanceof ErrorResponse) {
                        if (err.statusCode === StatusCode.ClientLogicError) {
                            dispatch(toastActions.openError("Error: " + err.message));
                        } else {
                            dispatch(toastActions.openError("Server toast. Please try again or contact support."));
                        }
                        console.log("Error response: " + err.message);
                    } else if (err instanceof ConnectionError) {
                        console.log("Connection error: " + err.message);
                        dispatch(toastActions.openError("Connection error. Please check your internet and try again."));
                    } else {
                        console.error("Unknown error occurred in getPendingUserByPhone: ", err);
                        dispatch(toastActions.openError("Something went wrong. Please check your internet connection and try again."));
                    }
                });
            // } else {
            //     dispatch(errorActions.openError("Please enter a valid email."));
            // }
        }

        if (phoneNumber.length > 0) {
            const phoneNumberNumber = Number(phoneNumber);
            if (!isNaN(phoneNumberNumber)) {
                getPendingUserByPhone(phoneNumber, fingerprint)
                    .then((pendingUser) => {
                        setPendingUser(pendingUser);
                    })
                    .catch((err: ErrorResponse | Error) => {
                        if (err instanceof ErrorResponse) {
                            if (err.statusCode === StatusCode.ClientLogicError) {
                                dispatch(toastActions.openError("Error: " + err.message));
                            } else {
                                dispatch(toastActions.openError("Please try again or contact support."));
                            }
                            console.log("Error response: " + err.message);
                        } else if (err instanceof ConnectionError) {
                            console.log("Connection error: " + err.message);
                            dispatch(toastActions.openError("Please check your internet and try again."));
                        } else {
                            console.error("Unknown error occurred in getPendingUserByPhone: ", err);
                            dispatch(toastActions.openError("Something went wrong. Please check your internet connection and try again."));
                        }
                    });
            } else {
                dispatch(toastActions.openError("Please only use numbers."));
            }
        }
    };

    const handleErrorClose = () => {
        dispatch(toastActions.closeToast());
    };

    if (pendingUser) {
        return (
            <PendingUserSignUp
                pendingUser={pendingUser}
                defaultPhoneNumberCountryCode={defaultPhoneNumberCountryCode}
                phoneNumberCountryCodeLoading={phoneNumberCountryCodeLoading}
            />
        );
    }

    if (window.location.hostname === "kenyanationalguard.arcturus.us.com" || window.location.hostname === "kenyanationalguard.arcturus.co")
        localStorage.setItem("coBranding", "kenya");

    const coBrandingApplied = localStorage.getItem("coBranding");

    if (verificationNeeded) {
        return <Verify email={email} setPendingUser={setPendingUser} />;
    }
    return (
        <FullScreenImage>
            <LogoAndFormContainer>
                {window.location.hostname === "kenyanationalguard.arcturus.us.com" ||
                    window.location.hostname === "kenyanationalguard.arcturus.co" ||
                    coBrandingApplied === "kenya" ? (
                    <OtherCompanyLogo src="https://idylworld-static.sgp1.digitaloceanspaces.com/kngForAuth.png" alt="Company Logo" />
                ) : (
                    <VerstaanLogo src="https://orion-assets-s3.s3.us-east-2.amazonaws.com/orion-logo.png" alt="Verstaan Logo" />
                )}
                <MessageContainer>
                    <MessageTitle>Sign Up for Atlas</MessageTitle>
                </MessageContainer>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    <MessageContainer>
                        <MessageContent>Enter your work email for your organization’s registered domain</MessageContent>
                    </MessageContainer>
                    <FormField
                        type="text"
                        name="email"
                        placeholder="EMAIL"
                        id="email"
                        value={email}
                        onChange={(e) => updateEmail(e.target.value)}
                        onFocus={() => updatePhoneNumber("")}
                    />
                    <MessageContainer>
                        <MessageTitle>OR</MessageTitle>
                    </MessageContainer>
                    <MessageContainer>
                        <MessageContent>Choose your country code and enter your registered phone number</MessageContent>
                    </MessageContainer>
                    <div
                        style={{
                            margin: "10px",
                            width: "254px",
                            height: "37px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {phoneNumberCountryCodeLoading ? (
                            <PhoneLoadingContainer style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                                <LoadingSpinner background={"white"} />
                            </PhoneLoadingContainer>
                        ) : (
                            <PhoneLoadingContainer>
                                <PhoneNumberInput
                                    country={defaultPhoneNumberCountryCode}
                                    value={phoneNumber}
                                    onChange={(phone) => updatePhoneNumber(phone)}
                                    preferredCountries={["us", "za", "ng", "ke"]}
                                />
                            </PhoneLoadingContainer>
                        )}
                    </div>
                    <SubmitButton type="button" style={{ marginTop: "20px" }} onClick={submit}>
                        Sign Up
                    </SubmitButton>
                    <Link to="/login">
                        <MessageContainer>
                            <MessageContent>
                                Already have an account? <u style={{ cursor: "pointer" }}>Log in</u>.
                            </MessageContent>
                        </MessageContainer>
                    </Link>
                </form>
            </LogoAndFormContainer>
            {errorIsOpen && errorContent && (
                <Error
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    onClose={handleErrorClose}
                    autoHideDuration={10000}
                    background={toastIsError ? colors.scheme.filterViolentTag : colors.general.standardGreen}
                >
                    <Alert style={{ background: toastIsError ? fadedRed : colors.general.standardGreen }}>
                        {
                            toastIsError ?
                                <SmallIcon src={S3Key + "alert-white.svg"} alt="alert" />
                                : <SmallIcon src={S3Key + "check-white.svg"} alt="checkmark" />
                        }
                        <SmallSpacer />
                        {errorContent}
                    </Alert>
                </Error>
            )}
        </FullScreenImage>
    );
};
