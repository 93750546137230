import React from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { teamsActions, teamsOperations } from "../../state/teams";
import { useDispatch } from "react-redux";

// components
import { SingleLineTextInput, Button } from "./styled";
import { AddNewUserForm } from "./AddNewUserForm";
import { toastActions } from "../../state/toast";

export const AddUser = () => {
    const dispatch = useDispatch();
    const dispatchPromise = useDispatchPromise();

    const cuser = useSelectFromRedux((state) => state.cuser.userProfile);
    const { createTeamName } = useSelectFromRedux((state) => state.teams);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const colors = useSelectFromRedux((state) => state.color);

    const cuserTeam = teams.filter((team) => team.id === cuser?.team_id)[0];

    const submitCreateTeam = () => {
        dispatchPromise(teamsOperations.createNewTeam()).then(() => {
            dispatch(teamsActions.updateCreateTeamName(null));
            dispatch(toastActions.openSuccess("New team created successfully."));
        });
        dispatch(teamsActions.toggleCreateTeamMode(false));
    };

    return (
        <>
            {cuser && cuser.role === 1 && (!!(cuser.team_id === null) || !!(cuserTeam?.permissions === Number(0))) && (
                <div style={{ width: "100%" }}>
                    <div style={{ padding: "0 20px" }}>
                        <div
                            style={{
                                marginBottom: "5px",
                                textTransform: "uppercase",
                                color: colors.scheme.primaryText
                            }}
                        >
                            Create New Group
                        </div>
                        <SingleLineTextInput
                            maxLength={30}
                            color={colors.scheme.primaryText}
                            background={colors.scheme.selectorBackground}
                            onChange={(e) => dispatch(teamsActions.updateCreateTeamName(e.target.value))}
                            placeholder="NEW GROUP NAME"
                            placeholdercolor={colors.scheme.secondaryHeaderText}
                            value={createTeamName ?? ""}
                        />
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "5px"
                            }}
                        >
                            <Button
                                color={createTeamName && createTeamName.length > 0 ? "white" : "#4C4C4C"}
                                background={
                                    createTeamName && createTeamName.length > 0 ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag
                                }
                                onClick={() => submitCreateTeam()}
                            >
                                CREATE
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <AddNewUserForm />
        </>
    );
};
