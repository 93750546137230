import styled from "styled-components";
import { gradientTopBlue, gradientBottomBlue } from "../../utils";

export const FullScreenImage = styled.div`
    background: linear-gradient(${gradientTopBlue}, ${gradientBottomBlue});
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LogoAndFormContainer = styled.div`
    height: 700px;
    width: 254px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const VerstaanLogo = styled.img`
    width: 140px;
    margin: 30px;
    margin-top: 0px;
`;

export const OtherCompanyLogo = styled.img`
    width: 320px;
    margin: 30px;
    margin-top: 0px;
`;

interface FormFieldProps {
    highlightcolor?: string;
}

export const FormField = styled.input`
    font-family: IBM Plex Sans;
    width: 254px;
    height: 37px;
    background-color: #8a8c96;
    color: white;
    border: none;
    border-radius: 8px;
    margin: 10px;
    text-align: center;
    font-size: 14px;
    outline: none;

    ::placeholder {
        color: white;
        opacity: 0.7;
        letter-spacing: 1px;
    }

    :focus {
        border: 2px solid ${(props: FormFieldProps) => props.highlightcolor} !important;
    }
`;

export const SubmitButton = styled.button`
    font-family: IBM Plex Sans;
    background-color: rgba(237, 159, 78, 0.08);
    width: 254px;
    height: 37px;
    text-transform: uppercase;
    border-radius: 18px;
    color: #cc662f;
    margin: 10px;
    margin-bottom: 0px;
    font-size: 14px;
    letter-spacing: 1.5px;
    cursor: pointer;
    outline: none;
    border: 1px solid #cc662f;
    font-weight: bold;
    :hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;
