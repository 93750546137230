import React from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { PerCategoryStats, CategoryStats } from "../../types/analytics";
import { Legend, EntryName, LegendEntry, EntryCases, EntryDelta } from "./styled";
import { lightenDarkenColor } from "../../utils";
import { reportType, CategoryTypes, ReportCategories } from "../../types/reports";

export const Stats = () => {
    const colors = useSelectFromRedux((state) => state.color);
    const { timeRange, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, categoricalDataArray, appliedEventTypes } = useSelectFromRedux(
        (state) => state.analytics
    );

    let violentCount = 0;
    let nonviolentCount = 0;
    let hazardCount = 0;
    let otherCount = 0;

    const filterPerCategoryStats = (stats: PerCategoryStats | CategoryStats): boolean => {
        let result = true;

        if (stats.category === "Other") {
            result = false;
        }

        if (!(!violentCheckedApplied && !nonviolentCheckedApplied && !hazardCheckedApplied)) {
            if (!violentCheckedApplied) {
                if (stats.category === "Violent") {
                    result = false;
                }
            }

            if (!nonviolentCheckedApplied) {
                if (stats.category === "Non-Violent") {
                    result = false;
                }
            }

            if (!hazardCheckedApplied) {
                if (stats.category === "Hazard") {
                    result = false;
                }
            }
        }

        if (appliedEventTypes.length > 0 && stats.category) {
            if (!appliedEventTypes.includes(stats.category)) {
                result = false;
            }
        }

        return result;
    };

    const getColor = (name: reportType | CategoryTypes | undefined) => {
        if (name) {
            if (name === "Violent") {
                return colors.scheme.analyticsPieViolent;
            } else if (name === "Non-Violent") {
                return colors.scheme.analyticsPieNonviolent;
            } else if (name === "Hazard") {
                return colors.scheme.analyticsPieHazard;
            } else if (name === "Other") {
                return colors.scheme.analyticsPieOther;
            } else if (ReportCategories.Violent.includes(name)) {
                let startingColor = lightenDarkenColor(colors.scheme.analyticsPieViolent, -20);
                let color = lightenDarkenColor(startingColor, 20 * violentCount);
                violentCount += 1;
                return color;
            } else if (ReportCategories["Non-Violent"].includes(name)) {
                let color = lightenDarkenColor(colors.scheme.analyticsPieNonviolent, 20 * nonviolentCount);
                nonviolentCount += 1;
                return color;
            } else if (ReportCategories.Hazard.includes(name)) {
                let color = lightenDarkenColor(colors.scheme.analyticsPieHazard, 20 * hazardCount);
                hazardCount += 1;
                return color;
            } else if (ReportCategories.Other.includes(name)) {
                let color = lightenDarkenColor(colors.scheme.analyticsPieOther, 20 * otherCount);
                otherCount += 1;
                return color;
            } else {
                return colors.scheme.analyticsPieOther;
            }
        } else {
            return colors.scheme.analyticsPieOther;
        }
    };

    return (
        <Legend>
            {categoricalDataArray
                ?.filter((entry: CategoryStats) => filterPerCategoryStats(entry))
                .map((entry: CategoryStats, index: number) => {
                    return (
                        <LegendEntry
                            key={`legendentry-${index}`}
                            style={{
                                alignContent: "center"
                            }}
                        >
                            <EntryName color={colors.scheme.primaryText}>
                                <div
                                    style={{
                                        backgroundColor: getColor(entry.category),
                                        height: "13px",
                                        width: "13px",
                                        marginTop: "-1px",
                                        marginRight: "5px"
                                    }}
                                />
                                {entry.category}
                            </EntryName>
                            <EntryCases color={colors.scheme.primaryText} style={{ marginTop: "0px" }}>
                                {entry.count + " reports"}
                            </EntryCases>
                            {timeRange && (
                                <EntryDelta
                                    style={{
                                        color: (entry.change ?? 0) <= 0 ? colors.scheme.analyticsPercentageDecrease : colors.scheme.analyticsPercentageIncrease,
                                        textAlign: "center"
                                    }}
                                >
                                    {(entry.change ?? 0) > 0 ? (
                                        <img
                                            src={
                                                colors.mode === "light"
                                                    ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/updelta.png"
                                                    : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/updelta-dark.png"
                                            }
                                            style={{ height: "10px", width: "15px" }}
                                            alt="+"
                                        />
                                    ) : (entry.change ?? 0) <= 0 ? (
                                        <img
                                            src={
                                                colors.mode === "light"
                                                    ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/downdelta.png"
                                                    : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/downdelta-dark.png"
                                            }
                                            style={{ height: "10px", width: "15px" }}
                                            alt="-"
                                        />
                                    ) : (
                                        ""
                                    )}{" "}
                                    {Math.floor(Math.abs((entry.change ?? 0) * 100)) + "%"}
                                </EntryDelta>
                            )}
                        </LegendEntry>
                    );
                })}
        </Legend>
    );
};
