import React from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { DetailsButton } from "../_shared/report-summary/styled";
import { getContainerViewportFromId } from "../../utils";
import { forecastingAvailableContainers } from "../visualizations/utils";
import { MenuLink } from "./menu-link";
import styled from "styled-components";
import { Dispatch } from "react";

export const Menu = () => {
    const colors = useSelectFromRedux((state) => state.color);
    const unconfirmedAlerts = useSelectFromRedux((state) => state.clientInfo.unconfirmedAlerts);
    const unaddressedAlerts = useSelectFromRedux((state) => state.clientInfo.unaddressedAlerts);
    const userProfile = useSelectFromRedux((state) => state.cuser.userProfile);
    const cuser = useSelectFromRedux((state) => state.cuser.userProfile);
    const newInsight = useSelectFromRedux((state) => state.publicInfo.newInsight);
    const containers = useSelectFromRedux((state) => state.cuser.containers);
    const containerNames = Array.from(new Set(containers.map((container) => container.name)));
    const containerOverlap = containerNames.filter((x) => forecastingAvailableContainers.includes(x));
    const containerOverlapId = containers.find((container) => container.name === containerOverlap[0]);
    const containerViewportInfo = containerOverlapId ? getContainerViewportFromId(containerOverlapId.id, containers) : null;
    const currentContainerId = useSelectFromRedux((state) => state.cuser.userProfile?.current_container);
    const currentContainer = containers.find((container) => container.id === currentContainerId);

    return (
        <MenuContainer>
            {userProfile?.role !== -1 && userProfile?.features?.teamReportsMenu === true && (
                <MenuLink type="Reports" reportsBadgeContent={unconfirmedAlerts + unaddressedAlerts} />
            )}
            {userProfile?.features?.newsfeedMenu !== false && <MenuLink type="Newsfeed" />}
            {userProfile?.features?.publicInsightsMenu === true && (
                <MenuLink type="Forecasting" />
            )}
            {userProfile?.role !== -1 && userProfile?.features?.analyticsMenu === true && <MenuLink type="Analytics" />}
            {/* for prod this has to be 3 */}
            {containerOverlapId &&
                containerOverlap.length > 0 &&
                containerViewportInfo &&
                currentContainer &&
                userProfile?.features?.forecastingMenu === true && (
                    <MenuLink
                        type="Risk Forecasting"
                        riskForecastingCurrentContainer={currentContainer}
                        riskForecastingContainerViewportInfo={containerViewportInfo}
                        riskForecastingContainerOverlapId={containerOverlapId}
                    />
                )}
            {userProfile?.features?.filtering !== false && <MenuLink type="Map Filter" />}
            {userProfile?.features?.teamsMenu !== false && cuser?.role === 1 && <MenuLink type="Groups" />}
            {userProfile?.features?.teamsMenu !== false && <MenuLink type="Add User" />}
            <MenuLink type="Account Settings" />
            <DetailsButton
                color={colors.scheme.secondaryHeaderText}
                style={{
                    width: "100%",
                    marginTop: "-20px",
                    fontSize: "13px",
                    marginBottom: "-10px"
                }}
            >
                Powered by Arcturus
            </DetailsButton>
        </MenuContainer>
    );
};

const MenuContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 20px;
`;