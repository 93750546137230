import { PromiseOperation } from "..";
import { ConnectionError, ErrorResponse } from "../../types/api";
import { clientInfoActions, clientInfoOperations } from ".";
import { UserProfile, Team, NewTeam, TeamUpdate, UserUpdate, Profile, AdminUserSelfUpdate } from "../../types/client";
import * as client from "../../types/api/client";
import { cuserOperations } from "../cuser";
import { Device } from "../../types/auth";

export const fetchClientInfo: PromiseOperation<void> = (goToLatestReport?: boolean) => async (dispatch, getState) => {
    // Load client users
    try {
        const users: UserProfile[] = await client.getClientUsers();
        dispatch(clientInfoActions.loadUsers(users));
        // Load client devices (requires users)
        try {
            const devices: Device[] = await client.getClientDevices();
            dispatch(clientInfoActions.loadClientDevices(devices));
        } catch (error) {
            if (error instanceof ErrorResponse) {
                console.log("Error response: " + error.message);
            } else if (error instanceof ConnectionError) {
                console.log("Connection error: " + error.message);
            } else {
                console.error("Unknown error occurred while fetching client users: ", error);
            }
        }
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred while fetching client users: ", error);
        }
    }

    // Load client teams
    try {
        const teams: Team[] = await client.getTeams();
        dispatch(clientInfoActions.loadTeams(teams));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred while fetching client users: ", error);
        }
    }
};

/**
 * Redundant implementation of teams operation
 * @param team
 */
export const createTeam: PromiseOperation<void> = (team: NewTeam) => async (dispatch) => {
    try {
        await client.createTeam(team);
        dispatch(fetchClientInfo());
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in createTeam: ", error);
        }
    }
};

export const updateTeam: PromiseOperation<void> = (teamUpdate: TeamUpdate) => async (dispatch) => {
    try {
        await client.updateTeam(teamUpdate);
        dispatch(fetchClientInfo());
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in updateTeam: ", error);
        }
    }
};

/**
 * Redundant implementation of teams operation
 * @param userUpdate
 */
export const modifyUser: PromiseOperation<void> = (userUpdate: UserUpdate) => async (dispatch) => {
    try {
        await client.modifyUser(userUpdate);
        dispatch(fetchClientInfo());
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in userUpdate: ", error);
        }
    }
};

export const updateProfile: PromiseOperation<void> = (update: Profile) => async (dispatch) => {
    try {
        await client.updateProfile(update);
        dispatch(clientInfoOperations.fetchClientInfo());
        dispatch(cuserOperations.getCuserProfile());
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in confirmAlert: ", error);
        }
    }
};

export const updateSelfAdmin: PromiseOperation<void> = (update: AdminUserSelfUpdate) => async (dispatch) => {
    try {
        await client.updateSelfAdmin(update);
        dispatch(cuserOperations.getCuserProfile());
        dispatch(fetchClientInfo());
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in confirmAlert: ", error);
        }
    }
};
