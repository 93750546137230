import { CategoryStats, PerCategoryStats, ThirtyDayCount, AnalyticsSummary } from "../../types/analytics";
import { AllReportTypes } from "../../types/reports";

export const filterOutOtherCategory = (data: CategoryStats[], appliedEventTypes: string[]): CategoryStats[] => {
    var result: CategoryStats[] = [];

    // data.forEach((entry: CategoryStats) => {
    //     if (entry?.category === "Violent") result.push(entry);
    // });
    // data.forEach((entry: CategoryStats) => {
    //     if (entry?.category === "Non-Violent") result.push(entry);
    // });
    // data.forEach((entry: CategoryStats) => {
    //     if (entry?.category === "Hazard") result.push(entry);
    // });

    if (appliedEventTypes.length === 0) {
        data.forEach((entry: CategoryStats) => {
            if (entry?.category === "Violent" || entry?.category === "Non-Violent" || entry?.category === "Hazard") result.push(entry);
        });
    } else {
        data.forEach((entry: CategoryStats) => {
            result.push(entry);
        });
    }

    return result;
};

export const filterPerCategoryStats = (
    stats: PerCategoryStats | CategoryStats,
    violentCheckedApplied: boolean,
    nonviolentCheckedApplied: boolean,
    hazardCheckedApplied: boolean,
    appliedEventTypes: string[]
): boolean => {
    let result = true;

    if (stats.category === "Other") {
        result = false;
    }

    if (!(!violentCheckedApplied && !nonviolentCheckedApplied && !hazardCheckedApplied)) {
        if (!violentCheckedApplied) {
            if (stats.category === "Violent") {
                result = false;
            }
        }

        if (!nonviolentCheckedApplied) {
            if (stats.category === "Non-Violent") {
                result = false;
            }
        }

        if (!hazardCheckedApplied) {
            if (stats.category === "Hazard") {
                result = false;
            }
        }
    }

    if (appliedEventTypes.length > 0 && stats.category) {
        // @ts-ignore
        if (!appliedEventTypes.includes(stats.category) || stats.isCategory === true) {
            result = false;
        }
    }

    // @ts-ignore
    if (appliedEventTypes.length === 0 && stats.category && stats.isCategory === false) {
        result = false;
    }

    return result;
};

export const filterTimeRangeArray = (
    newTimeRange: "24 Hour" | "7 Day" | "28 Day" | null,
    data: AnalyticsSummary | null,
    violentCheckedApplied: boolean,
    nonviolentCheckedApplied: boolean,
    hazardCheckedApplied: boolean,
    appliedEventTypes: string[]
): ThirtyDayCount[] => {
    var result: ThirtyDayCount[] = [];

    let dataUsed = data?.perCategoryStats;

    if (newTimeRange === "24 Hour") {
        dataUsed = data?.pastDayHourlyCategoryStats;
    } else if (newTimeRange === "7 Day") {
        const numberOfReportTypes = AllReportTypes.length;
        const numberOfCategories = 4;
        const uniqueItemsPerDay = numberOfCategories + numberOfReportTypes;
        dataUsed = data?.perCategoryStats?.slice(data?.perCategoryStats.length - 7 * uniqueItemsPerDay, data?.perCategoryStats.length);
    } else if (newTimeRange === "28 Day") {
        dataUsed = data?.perCategoryStats;
    } else {
        dataUsed = data?.allTimeCategoryStatsPerWeek;
    }

    dataUsed
        ?.filter((entry) => filterPerCategoryStats(entry, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, appliedEventTypes))
        .forEach((entry: PerCategoryStats) => {
            if (result.some((data: ThirtyDayCount) => entry.dateTime === data.dateTime)) {
                const existingEntry = result.filter((data: ThirtyDayCount) => entry.dateTime === data.dateTime)[0];
                if (!existingEntry.count) {
                    existingEntry.count = entry.count ?? 0;
                } else {
                    existingEntry.count += entry.count ?? 0;
                }
            } else {
                result.push({
                    count: entry.count,
                    dateTime: entry.dateTime,
                    dayName: entry.day,
                    checkInfo: () => true
                });
            }
        });
    return result;
};
