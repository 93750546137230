import { produce } from "immer";
import { simpleAction, actionFactory, ActionUnion } from "reductser";

const actionMap = {
    togglePanel: simpleAction()
};

export const cuserPanelActions = actionFactory(actionMap, "CUSER_PANEL");

export type CuserPanelAction = ActionUnion<typeof cuserPanelActions>;

// space to describe specific types of actions

export interface CuserPanelState {
    isOpen: boolean;
}

export const getInitialState = (): CuserPanelState => ({
    isOpen: false
});

const CuserPanelReducer = (state = getInitialState(), action: CuserPanelAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "CUSER_PANEL") {
            switch (action.type) {
                case "togglePanel":
                    draftState.isOpen = !draftState.isOpen;
                    return;
                default:
                    return;
            }
        }
    });

export default CuserPanelReducer;
