import React, { useState, useEffect } from "react";
// components
import { FormField, FullScreenImage, LogoAndFormContainer, SubmitButton, VerstaanLogo } from "../_shared/auth";
import { MessageContainer, MessageContent, PhoneLoadingContainer, PhoneNumberInput } from "../signup/styled";
import { useDispatch } from "react-redux";
import { toastActions } from "../../state/toast";
import { Error } from "../home/styled";
import { useDispatchPromise, useSelectFromRedux } from "../../utils/_hooks";
import { Alert, LoadingSpinner } from "../_shared";
import { fadedRed, GoogleGeocoderAPIKey, S3Key } from "../../utils";
import { cuserOperations } from "../../state/cuser";
import Geocode from "react-geocode";
import { ResetPassword } from "./resetPassword";
import { SmallSpacer } from "../_shared/layout";
import { SmallIcon } from "../_shared/typography";

let emailOrPhone: string;

export const ForgotPassword = () => {
    const dispatch = useDispatch();
    const dispatchPromise = useDispatchPromise();
    const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);
    const errorContent = useSelectFromRedux((state) => state.error.currentText);
    const toastIsError = useSelectFromRedux((state) => state.error.isError);
    const colors = useSelectFromRedux((state) => state.color);

    const [loginOptionValue, updateLoginOptionValue] = useState<string>("email");
    const [email, setEmail] = useState<string>("");
    const [defaultPhoneNumberCountryCode, updateDefaultPhoneNumberCountryCode] = useState<string>("us");
    const [phoneNumberCountryCodeLoading, updatePhoneNumberCountryCodeLoading] = useState<boolean>(true);
    const [phone, updatePhone] = useState<string>("");
    const [verificationSent, setVerificationSent] = useState<boolean>(false);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    Geocode.fromLatLng("" + lat, "" + lng, GoogleGeocoderAPIKey)
                        .then((response) => {
                            updateDefaultPhoneNumberCountryCode(response.results[0].address_components[6].short_name.toLowerCase());
                            updatePhoneNumberCountryCodeLoading(false);
                        })
                        .catch((error) => {
                            updatePhoneNumberCountryCodeLoading(false);
                            console.log(error);
                        });
                },
                (error) => {
                    updatePhoneNumberCountryCodeLoading(false);
                    console.log(error);
                }
            );
        } else {
            updatePhoneNumberCountryCodeLoading(false);
        }

        setTimeout(() => {
            updatePhoneNumberCountryCodeLoading(false);
        }, 5000);

        // eslint-disable-next-line
    }, []);

    const submit = () => {

        emailOrPhone = email;
        if (loginOptionValue !== "email") {
            emailOrPhone = phone;
        }

        dispatchPromise(cuserOperations.forgotPassword(emailOrPhone, loginOptionValue !== "email")).then((success) => {
            if (success) {
                setVerificationSent(true);
            }
        });
    };

    const handleErrorClose = () => {
        dispatch(toastActions.closeToast());
    };

    if (verificationSent) {
        return <ResetPassword emailOrPhone={emailOrPhone} isPhone={loginOptionValue !== "email"} />;
    }

    return (
        <FullScreenImage>
            <LogoAndFormContainer>
                <VerstaanLogo src="https://orion-assets-s3.s3.us-east-2.amazonaws.com/orion-logo.png" alt="Verstaan Logo" />
                <MessageContainer style={{ margin: "0 0 24px", width: "120%", justifyContent: "center", display: "flex", alignItems: "center" }}>
                    Please enter the email or phone number you used to register. You'll receive a code to reset your password.
                </MessageContainer>
                {loginOptionValue === "email" && (
                    <FormField
                        id="email"
                        type="text"
                        name="email"
                        placeholder="EMAIL"
                        className="inputs"
                        value={email}
                        onChange={(event) => setEmail(event.target.value.replace(/\s/g, ""))}
                    />
                )}
                {loginOptionValue === "phone" && phoneNumberCountryCodeLoading && (
                    <PhoneLoadingContainer style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                        <LoadingSpinner background={"white"} />
                    </PhoneLoadingContainer>
                )}
                {loginOptionValue === "phone" && !phoneNumberCountryCodeLoading && (
                    <div style={{ margin: "10px", width: "254px", height: "37px", display: "flex" }}>
                        <PhoneLoadingContainer style={{ margin: "0" }}>
                            <PhoneNumberInput
                                country={defaultPhoneNumberCountryCode}
                                value={phone}
                                onChange={(phone) => updatePhone(phone)}
                                preferredCountries={["us", "za", "ng", "ke"]}
                            />
                        </PhoneLoadingContainer>
                    </div>
                )}
                {loginOptionValue === "email" ? (
                    <MessageContainer style={{ margin: "0 0 10px" }}>
                        <MessageContent
                            onClick={() => {
                                updateLoginOptionValue("phone");
                                setEmail("");
                            }}
                        >
                            <u style={{ cursor: "pointer" }}>Registered with phone</u>?
                        </MessageContent>
                    </MessageContainer>
                ) : (
                    <MessageContainer style={{ margin: "0 0 10px" }}>
                        <MessageContent
                            onClick={() => {
                                updateLoginOptionValue("email");
                                updatePhone("");
                            }}
                        >
                            <u style={{ cursor: "pointer" }}>Registered with email</u>?
                        </MessageContent>
                    </MessageContainer>
                )}
                <SubmitButton type="button" style={{ marginTop: "20px" }} onClick={submit}>
                    {loginOptionValue === "email" ? "Send Code via Email" : "Send Code via SMS"}
                </SubmitButton>
            </LogoAndFormContainer>
            {errorIsOpen && errorContent && (
                <Error
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    onClose={handleErrorClose}
                    autoHideDuration={10000}
                    background={toastIsError ? colors.scheme.filterViolentTag : colors.general.standardGreen}
                >
                    <Alert style={{ background: toastIsError ? fadedRed : colors.general.standardGreen }}>
                        {
                            toastIsError ?
                                <SmallIcon src={S3Key + "alert-white.svg"} alt="alert" />
                                : <SmallIcon src={S3Key + "check-white.svg"} alt="checkmark" />
                        }
                        <SmallSpacer />
                        {errorContent}
                    </Alert>
                </Error>
            )}
        </FullScreenImage>
    );
};
