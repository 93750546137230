import React, { useState, useEffect } from "react";

// components
import { FullScreenImage, LogoAndFormContainer, VerstaanLogo, FormField, SubmitButton } from "../_shared/auth";
import { MessageContainer, MessageTitle, EmailFormField, MessageContent, PhoneNumberInput, PhoneLoadingContainer } from "./styled";
import { PendingUser, UserSignUp } from "../../types/client";
import { toastActions } from "../../state/toast";
import { useDispatch } from "react-redux";
import { cuserOperations } from "../../state/cuser";
import { useSelectFromRedux } from "../../utils/_hooks";
import { Redirect } from "react-router-dom";
import { Error } from "../login/styled";
import { Alert, LoadingSpinner } from "../_shared";
import { fadedRed, S3Key } from "../../utils";
import { SmallSpacer } from "../_shared/layout";
import { SmallIcon } from "../_shared/typography";

interface Props {
    pendingUser: PendingUser;
    defaultPhoneNumberCountryCode: string;
    phoneNumberCountryCodeLoading: boolean;
}

export const PendingUserSignUp = (props: Props) => {
    const dispatch = useDispatch();
    const { signedIn } = useSelectFromRedux((state) => state.cuser);
    const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);
    const errorContent = useSelectFromRedux((state) => state.error.currentText);
    const [passwordIsValid, updatePasswordIsValid] = useState<boolean>(false);
    const [passwordIsTheSame, updatePasswordIsTheSame] = useState<boolean>(false);
    const colors = useSelectFromRedux((state) => state.color);
    const toastIsError = useSelectFromRedux((state) => state.error.isError);

    const unique = props.pendingUser.email ?? props.pendingUser.phone_primary;
    const isEmail = unique?.includes("@") ? true : false;
    // eslint-disable-next-line
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const [firstName, updateFirstName] = useState<string>(props.pendingUser.first_name ?? "");
    const [lastName, updateLastName] = useState<string>(props.pendingUser.last_name ?? "");
    const [phone, updatePhone] = useState<string>("");
    const [password, updatePassword] = useState<string>("");
    const [confirmPassword, updateConfirmPassword] = useState<string>("");

    const submit = () => {
        if (password !== confirmPassword) {
            dispatch(toastActions.openError("Passwords do not match, please try again."));
            return;
        }

        if (!password.match(format) || /\d/.test(password) === false) {
            dispatch(toastActions.openError("Password does not include one of the following: special character, number."));
            return;
        }

        if (password.length < 8) {
            dispatch(toastActions.openError("Password is less than 8 characters. Please try again."));
            return;
        }

        if (!firstName || !lastName) {
            dispatch(toastActions.openError("Please enter first and last name."));
            return;
        }

        let signUpInfo: UserSignUp = {
            first_name: firstName,
            last_name: lastName,
            password
        };

        if (phone.length > 0) {
            signUpInfo = { ...signUpInfo, phone_primary: phone };
        }

        if (isEmail) {
            signUpInfo = { ...signUpInfo, email: unique };
        }

        const pendingUser = props.pendingUser;
        if ((!pendingUser.first_name && !signUpInfo.first_name) || (!pendingUser.last_name && !signUpInfo.last_name)) {
            dispatch(toastActions.openError("Please enter first and last name."));
            return;
        }

        dispatch(cuserOperations.createAccountAndSignIn(props.pendingUser.uuid, signUpInfo));
    };

    const handleErrorClose = () => {
        dispatch(toastActions.closeToast());
    };

    useEffect(() => {
        if (password.match(format) && /\d/.test(password) && password.length >= 8) {
            updatePasswordIsValid(true);
        } else {
            updatePasswordIsValid(false);
        }
    }, [password, format]);

    if (signedIn) {
        return <Redirect to="/" />;
    }

    return (
        <FullScreenImage>
            <LogoAndFormContainer>
                <div style={{ height: 110 }}>
                    <VerstaanLogo src="https://orion-assets-s3.s3.us-east-2.amazonaws.com/orion-logo.png" alt="Verstaan Logo" />
                </div>
                <div style={{ height: 900 }}>
                    <MessageContainer>
                        <MessageTitle>Fill In Your Profile Details</MessageTitle>
                    </MessageContainer>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                        }}
                        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                    >
                        {isEmail ? (
                            <EmailFormField type="text" value={unique} readOnly />
                        ) : (
                            <div style={{ margin: "10px", marginLeft: "40px" }}>
                                <PhoneNumberInput value={unique} disableDropdown disabled />
                            </div>
                        )}
                        <FormField type="text" placeholder="FIRST NAME" value={firstName} onChange={(e) => updateFirstName(e.target.value)} />
                        <FormField type="text" placeholder="LAST NAME" value={lastName} onChange={(e) => updateLastName(e.target.value)} />
                        {isEmail && (
                            <div style={{ margin: "10px" }}>
                                {props.phoneNumberCountryCodeLoading ? (
                                    <PhoneLoadingContainer style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                                        <LoadingSpinner background={"white"} />
                                    </PhoneLoadingContainer>
                                ) : (
                                    <PhoneLoadingContainer>
                                        <PhoneNumberInput
                                            country={props.defaultPhoneNumberCountryCode}
                                            value={phone}
                                            onChange={(phone) => updatePhone(phone)}
                                            preferredCountries={["us", "za", "ng", "ke"]}
                                        />
                                    </PhoneLoadingContainer>
                                )}
                            </div>
                        )}
                        <MessageContainer>

                            <MessageContent>
                                <div style={{ fontSize: 11 }}>
                                    Password must be at least 8 characters, and must include a special character and a number.
                                </div>
                            </MessageContent>

                        </MessageContainer>
                        <FormField
                            type="password"
                            placeholder="PASSWORD"
                            onChange={(e) => updatePassword(e.target.value)}
                            highlightcolor={passwordIsValid ? "#3A6852" : "#AA0201"}
                        />
                        <FormField
                            type="password"
                            placeholder="VERIFY PASSWORD"
                            onChange={(e) => {
                                updateConfirmPassword(e.target.value);
                                if (e.target.value === password) {
                                    updatePasswordIsTheSame(true);
                                } else {
                                    updatePasswordIsTheSame(false);
                                }
                            }}
                            highlightcolor={passwordIsValid && passwordIsTheSame ? "#3A6852" : "#AA0201"}
                        />
                        <SubmitButton type="button" style={{ marginTop: "20px" }} onClick={submit}>
                            Create My Account
                        </SubmitButton>
                    </form>
                </div>
            </LogoAndFormContainer>
            {errorIsOpen && errorContent && (
                <Error
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    onClose={handleErrorClose}
                    autoHideDuration={10000}
                    background={toastIsError ? colors.scheme.filterViolentTag : colors.general.standardGreen}
                >
                    <Alert style={{ background: toastIsError ? fadedRed : colors.general.standardGreen }}>
                        {
                            toastIsError ?
                                <SmallIcon src={S3Key + "alert-white.svg"} alt="alert" />
                                : <SmallIcon src={S3Key + "check-white.svg"} alt="checkmark" />
                        }
                        <SmallSpacer />
                        {errorContent}
                    </Alert>
                </Error>
            )}
        </FullScreenImage>
    );
};
