import styled from "styled-components";

import { lightFadedRed, counterBorderRed, counterFontRed, device } from "../../utils";

interface NotificationProps {
    background: string;
}
export const NotificationContainer = styled.div`
    position: fixed;
    width: 600px;
    left: calc(50vw - 300px);
    top: 10%;
    padding: 10px;
    opacity: 0.9;

    background: ${(props: NotificationProps) => props.background};
    border-radius: 10px;
    z-index: 12; /* display over everything else that overlays map */

    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;

    @media ${device.tablet} {
        top: 90px;
        width: 50%;
        left: 25%;
    }

    @media ${device.mobile} {
        top: 90px;
        width: 90%;
        left: 5%;
    }
`;

export const MobileNotificationContainer = styled.div`
    position: fixed;
    align-self: center;
    padding: 10px;
    opacity: 0.9;
    top: calc(100% - 80px);
    width: 50%;
    margin-left: -100px;
    left: 50%;

    background: ${(props: NotificationProps) => props.background};
    border-radius: 10px;
    z-index: 12; /* display over everything else that overlays map */

    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
`;

export const MobileNotificationArrow = styled.div`
    content: "";
    position: absolute;
    bottom: 100%;
    left: calc(50% - 10px);
    background: ${(props: NotificationProps) => props.background};
    width: 20px;
    height: 10px;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
`;

export const NotificationsCounter = styled.div`
    position: fixed;
    width: 37px;
    height: 37px;
    top: 3%;
    left: 50%;
    margin-left: -320px;

    z-index: 13; /* Display over notification */

    background: #ffffff;
    border: 1px solid ${counterBorderRed};
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: 18px;
    text-align: center;

    color: ${counterFontRed};

    @media ${device.mobile} {
        left: calc(5% - 10px);
        margin-left: 0;
        top: 75px;
    }
`;

export const Headline = styled.div`
    font-weight: 600;
    font-size: 18px;
    padding: 3px;
    width: 100%;

    color: #ffffff;

    @media ${device.mobile} {
        font-size: 15px;

        display: flex;
        flex-direction: column;
    }
`;

interface DetailsPromptProps {
    bold?: boolean;
}
export const DetailsPrompt = styled.span`
    font-size: 13px;
    text-decoration: underline;
    :hover {
        cursor: pointer;
    }
    font-weight: ${(props: DetailsPromptProps) => (props.bold ? 400 : 300)};
`;

export const Details = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    margin: 20px;

    color: #ffffff;

    @media ${device.mobile} {
        flex-direction: column;
        align-items: center;
    }
`;

export const DetailsColumn = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.mobile} {
        width: 100%;
        margin-right: 40px;
    }
`;

export const ConfirmButton = styled.div`
    width: 229px;
    height: 52px;

    background: ${lightFadedRed};
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 35px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 15px;
    margin-right: 40px;

    :hover {
        cursor: pointer;
    }

    @media ${device.mobile} {
        align-self: center;
        margin-right: 0px;
    }
`;

export const ConfirmText = styled.div`
    font-size: 18px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Entry = styled.p`
    margin: 4px;
`;

export const Key = styled.span`
    font-weight: 400;
`;

interface ValueProps {
    underlined?: boolean;
}
export const Value = styled.span`
    font-weight: 800;

    text-decoration: ${(props: ValueProps) => (props.underlined ? "underline" : "none")};
`;

interface HeaderProps {
    color: string;
}

export const Header = styled.div`
    color: ${(props: HeaderProps) => props.color};
    text-transform: uppercase;
    font-size: 16px;
`;
