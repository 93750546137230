import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion, simpleAction } from "reductser";
import moment from "moment";
import { getCurrentTimeBin } from "../../app/visualizations/utils";

const actionMap = {
    updateHasStartedForecasting: payloadAction<boolean>(),
    updateCoordinates: payloadAction<{ coordinates: number[]; endpoint: Endpoint; clicked?: boolean }>(),
    updateSafeRouteData: payloadAction<any[]>(),
    updateDay: payloadAction<number>(),
    updateTimeWindow: payloadAction<number>(),
    updateLoadingMessage: payloadAction<string | null>(),
    toggleZones: simpleAction(),
    updateFocusedInput: payloadAction<Endpoint>(),
    updateSelectedRoute: payloadAction<number | null>(),
    updateFuzzyRoute: payloadAction<any>(),
    updateFuzzyRouteOutput: payloadAction<any>(),
    toggleFuzzyRouteEnabled: simpleAction(),
    updateRouteProfile: payloadAction<RouteProfile>(),
    updateLoadingRiskForecastsMessage: payloadAction<string | null>(),
    toggleRiskIndexInfoOpen: payloadAction<boolean>(),
    updateRiskIndexFilter: payloadAction<number[]>()
};

export const visualizationsActions = actionFactory(actionMap, "VISUALIZATIONS");

export type VisualizationsActions = ActionUnion<typeof visualizationsActions>;

export type Endpoint = "origin" | "destination" | null;

export type RouteProfile = "driving" | "walking";

export interface VisualizationsState {
    hasStartedForecasting: boolean;
    origin: number[];
    destination: number[];
    safeRouteData: any[];
    day: number;
    timeWindow: number;
    loadingMessage: string | null;
    showZones: boolean;
    focusedInput: Endpoint;
    clicked: { origin: boolean; destination: boolean };
    selectedRoute: number | null;
    fuzzyRouteInput: any[];
    fuzzyRouteOutput: any[];
    fuzzyRouteEnabled: boolean;
    routeProfile: RouteProfile;
    loadingRiskForecastsMessage: string | null;
    riskIndexInfoOpen: boolean;
    riskIndexFilter: number[];
}

export const getInitialState = (): VisualizationsState => ({
    hasStartedForecasting: false,
    origin: [],
    destination: [],
    safeRouteData: [],
    day: moment().weekday(),
    timeWindow: getCurrentTimeBin(),
    loadingMessage: null,
    showZones: false,
    focusedInput: null,
    clicked: { origin: false, destination: false },
    selectedRoute: null,
    fuzzyRouteInput: [],
    fuzzyRouteOutput: [],
    fuzzyRouteEnabled: false,
    routeProfile: "driving",
    loadingRiskForecastsMessage: null,
    riskIndexInfoOpen: false,
    riskIndexFilter: [1, 5]
});

const VisualizationsReducer = (state = getInitialState(), action: VisualizationsActions) =>
    produce(state, (draftState) => {
        if (action.reducer === "VISUALIZATIONS") {
            switch (action.type) {
                case "updateHasStartedForecasting":
                    draftState.hasStartedForecasting = action.payload;
                    return;
                case "updateCoordinates":
                    if (action.payload.endpoint === null) return;
                    else draftState[action.payload.endpoint] = action.payload.coordinates;
                    if (action.payload.clicked && draftState.safeRouteData.length) draftState.safeRouteData = [];
                    draftState.clicked[action.payload.endpoint] = !!action.payload.clicked;
                    return;
                case "updateSafeRouteData":
                    draftState.safeRouteData = action.payload;
                    action.payload.length ? (draftState.selectedRoute = 1) : (draftState.selectedRoute = null);
                    return;
                case "updateDay":
                    draftState.day = action.payload;
                    return;
                case "updateTimeWindow":
                    draftState.timeWindow = action.payload;
                    return;
                case "updateLoadingMessage":
                    draftState.loadingMessage = action.payload;
                    return;
                case "toggleZones":
                    draftState.showZones = !draftState.showZones;
                    return;
                case "updateFocusedInput":
                    draftState.focusedInput = action.payload;
                    if (action.payload) draftState.clicked[action.payload] = false;
                    return;
                case "updateSelectedRoute":
                    draftState.selectedRoute = action.payload;
                    return;
                case "updateFuzzyRoute":
                    draftState.fuzzyRouteInput = action.payload.features;
                    return;
                case "updateFuzzyRouteOutput":
                    draftState.fuzzyRouteOutput = action.payload;
                    return;
                case "toggleFuzzyRouteEnabled":
                    draftState.fuzzyRouteEnabled = !draftState.fuzzyRouteEnabled;
                    return;
                case "updateRouteProfile":
                    draftState.routeProfile = action.payload;
                    return;
                case "updateLoadingRiskForecastsMessage":
                    draftState.loadingRiskForecastsMessage = action.payload;
                    return;
                case "toggleRiskIndexInfoOpen":
                    draftState.riskIndexInfoOpen = action.payload;
                    return;
                case "updateRiskIndexFilter":
                    draftState.riskIndexFilter = action.payload;
                    return;
                default:
                    return;
            }
        }
    });

export default VisualizationsReducer;
