import styled from "styled-components";

export const AdditionalDetailsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin: 0 0 10px;
    overflow-y: scroll;
`;

export const FieldTitle = styled.div`
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
`;

interface FieldProps {
    color: string;
}

export const FieldContent = styled.div`
    margin-bottom: 15px;
    font-size: 14px;
`;

export const NewFieldTitle = styled.div`
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin-left: 10px;
    color: ${(props: FieldProps) => props.color};
`;

export const NewFieldContent = styled.div`
    margin-bottom: 15px;
    font-size: 14px;
    color: ${(props: FieldProps) => props.color};
    margin-left: 10px;
`;

export const PhotoDisplay = styled.img`
    border-radius: 3px;
    margin: 10px 0;
    width: 100%;
    height: 100%;
`;
