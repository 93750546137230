import React, { useMemo, useState } from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { UserProfile, Team, TeamUpdate } from "../../types/client";
import { S3Key } from "../../utils";

// components
import { UserRowContainer, NameContainer, RoleContainer, StatusContainer, Dot } from "./styled";
import { useDispatch } from "react-redux";
import { teamsActions, teamsOperations } from "../../state/teams";
import { SubmitButton } from "../_shared/auth";
import { ShortTextInput } from "../update-report/styled";
import { Dropdown } from "./styled";
import { Button } from "./styled";
import Select from "antd/lib/select";

const { Option } = Select;
const MedicWhiteIcon = S3Key + "medic-white.png";
const MedicGreyIcon = S3Key + "medic-grey.png";
const StarGreyIcon = S3Key + "star-grey.png";
const StarWhiteIcon = S3Key + "star-white.png";
const ErrorGreyIcon = S3Key + "error-grey.png";
const ErrorWhiteIcon = S3Key + "error-white.png";

interface TitleRowProps {
    teamName: string | number | undefined;
    users: UserProfile[];
    team?: Team;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ teamName, users, team }: TitleRowProps) => {
    const dispatch = useDispatch();
    const colors = useSelectFromRedux((state) => state.color);
    const cuserId = useSelectFromRedux((state) => state.cuser.userProfile?.id);
    const cuser = useSelectFromRedux((state) => state.cuser);
    const fullViewUserId = useSelectFromRedux((state) => state.teams.fullViewUserId);
    const clientUsers = useSelectFromRedux((state) => state.clientInfo.users);
    const primaryEmail = useSelectFromRedux((state) => state.cuser.clientProfile?.primary_email);
    const modifyTeamMode = useSelectFromRedux((state) => state.teams.modifyTeamMode);
    const [teamNameValue, updateTeamNameValue] = useState<string | null>(team && team.name ? team.name : null);
    const [teamPermissionsValue, updateTeamPermissionsValue] = useState<number>(team && team.permissions ? team.permissions + 1 : 1);

    const openFullUserView = (userId: number) => {
        dispatch(teamsActions.toggleModifyUserMode(false));
        dispatch(teamsActions.updateFullViewUserId(userId));
        dispatch(teamsActions.toggleDisplayFullUserView(true));
    };

    const fullUserProfile = useMemo(() => {
        const fullUserProfile = clientUsers.find((clientUser) => clientUser.id === fullViewUserId);
        return fullUserProfile;
    }, [clientUsers, fullViewUserId]);

    const submitEditTeam = () => {
        dispatch(teamsActions.toggleModifyTeamMode(false));
        if (team && team.id) {
            const teamUpdate: TeamUpdate = {
                id: team.id
            };

            if (teamNameValue !== null) {
                teamUpdate.name = teamNameValue;
            }

            if (teamPermissionsValue === 1) {
                teamUpdate.permissions = 0;
            } else if (teamPermissionsValue === 2) {
                teamUpdate.permissions = 1;
            }

            dispatch(teamsOperations.modifyTeam(teamUpdate));
        }
    };

    const filteredUsersBySearch = users.filter((user) => user.id === fullUserProfile?.id);

    return (
        <>
            {!modifyTeamMode && users.length === 0 && (
                <div style={{ color: colors.scheme.primaryText, textAlign: "center" }}>
                    Add users to this team by selecting their profile and tapping the Edit button.
                </div>
            )}
            {/* {users.map((user) => (
                <UserRowContainer key={user.id}>
                    <NameContainer
                        textColor={colors.scheme.primaryText}
                        onClick={() => openFullUserView(user.id)}
                        cuser={user.id === fullViewUserId}
                        highlight={fullUserProfile?.id === user.id}
                    >
                        {user.first_name + " " + user.last_name}
                        {user.id === cuserId && <div style={{ marginLeft: "5px" }}>(you)</div>}
                        {user.role === 1 && <RoleContainer>ADMIN</RoleContainer>}
                        {user.email === primaryEmail && (
                            <img
                                src={colors.mode === "light" ? StarGreyIcon : StarWhiteIcon}
                                alt="star"
                                style={{ height: "16px", marginLeft: "5px" }}
                            />
                        )}
                        {user.designation === "Medic" && (
                            <img
                                src={colors.mode === "light" ? MedicGreyIcon : MedicWhiteIcon}
                                alt="medic"
                                style={{ height: "16px", marginLeft: "5px" }}
                            />
                        )}
                    </NameContainer>
                    <StatusContainer textColor={colors.scheme.primaryText}>
                        <Dot
                            background={
                                activeSOS(user.id) ? colors.general.sosReportModal : colors.general.standardGreen
                            }
                            style={{ cursor: activeSOS(user.id) ? "pointer" : "default" }}
                            onClick={activeSOS(user.id) ? () => viewFullReport(user.id) : () => null} */}
            {!modifyTeamMode && users.length > 20 && filteredUsersBySearch.length === 0 && (
                <div style={{ color: colors.scheme.primaryText, textAlign: "center" }}>
                    Too many users to display. Please use the search to find a user in this group.
                </div>
            )}
            {!modifyTeamMode && users.length > 20 && filteredUsersBySearch.length > 0 && (
                <>
                    <UserRowContainer>
                        <NameContainer style={{ fontStyle: "italic" }} textColor={colors.scheme.secondaryHeaderText} cuser={false} highlight={false}>
                            Name
                        </NameContainer>
                        <StatusContainer style={{ fontStyle: "italic" }} textColor={colors.scheme.secondaryHeaderText}>
                            Status
                        </StatusContainer>
                    </UserRowContainer>
                    {filteredUsersBySearch.map((user) => (
                        <UserRowContainer key={user.id}>
                            <NameContainer
                                textColor={colors.scheme.primaryText}
                                onClick={() => openFullUserView(user.id)}
                                cuser={user.id === fullViewUserId}
                                highlight={fullUserProfile?.id === user.id}
                            >
                                {user.first_name + " " + (user.last_name ? user.last_name : "")}
                                {user.id === cuserId && <div style={{ marginLeft: "5px" }}>(you)</div>}
                                {user.role === 1 && <RoleContainer>ADMIN</RoleContainer>}
                                {user.email === primaryEmail && (
                                    <img
                                        src={colors.mode === "light" ? StarGreyIcon : StarWhiteIcon}
                                        alt="star"
                                        style={{ height: "16px", marginLeft: "5px" }}
                                    />
                                )}
                                {user.designation === "Medic" && (
                                    <img
                                        src={colors.mode === "light" ? MedicGreyIcon : MedicWhiteIcon}
                                        alt="medic"
                                        style={{ height: "16px", marginLeft: "5px" }}
                                    />
                                )}
                            </NameContainer>
                            <StatusContainer textColor={colors.scheme.primaryText}>
                                {user.current_container === null ? null : user.disabled ? (
                                    <img src={colors.mode === "light" ? ErrorGreyIcon : ErrorWhiteIcon} alt="not logged in" style={{ height: "12px" }} />
                                ) : (
                                    <Dot background={colors.general.standardGreen} />
                                )}
                            </StatusContainer>
                        </UserRowContainer>
                    ))}
                </>
            )}
            {!modifyTeamMode && users.length > 0 && users.length <= 20 && (
                <>
                    <UserRowContainer>
                        <NameContainer style={{ fontStyle: "italic" }} textColor={colors.scheme.secondaryHeaderText} cuser={false} highlight={false}>
                            Name
                        </NameContainer>
                        <StatusContainer style={{ fontStyle: "italic" }} textColor={colors.scheme.secondaryHeaderText}>
                            Status
                        </StatusContainer>
                    </UserRowContainer>
                    {users.map((user) => (
                        <UserRowContainer key={user.id}>
                            <NameContainer
                                textColor={colors.scheme.primaryText}
                                onClick={() => openFullUserView(user.id)}
                                cuser={user.id === fullViewUserId}
                                highlight={fullUserProfile?.id === user.id}
                            >
                                {user.first_name + " " + (user.last_name ? user.last_name : "")}
                                {user.id === cuserId && <div style={{ marginLeft: "5px" }}>(you)</div>}
                                {user.role === 1 && <RoleContainer>ADMIN</RoleContainer>}
                                {user.email === primaryEmail && (
                                    <img
                                        src={colors.mode === "light" ? StarGreyIcon : StarWhiteIcon}
                                        alt="star"
                                        style={{ height: "16px", marginLeft: "5px" }}
                                    />
                                )}
                                {user.designation === "Medic" && (
                                    <img
                                        src={colors.mode === "light" ? MedicGreyIcon : MedicWhiteIcon}
                                        alt="medic"
                                        style={{ height: "16px", marginLeft: "5px" }}
                                    />
                                )}
                            </NameContainer>
                            <StatusContainer textColor={colors.scheme.primaryText}>
                                {user.current_container === null ? null : user.disabled ? (
                                    <img src={colors.mode === "light" ? ErrorGreyIcon : ErrorWhiteIcon} alt="not logged in" style={{ height: "12px" }} />
                                ) : (
                                    <Dot background={colors.general.standardGreen} />
                                )}
                            </StatusContainer>
                        </UserRowContainer>
                    ))}
                </>
            )}
            {modifyTeamMode && (
                <>
                    <div style={{ marginBottom: "10px", width: "100%" }}>
                        <div
                            style={{
                                marginBottom: "5px",
                                textTransform: "uppercase",
                                color: colors.scheme.primaryText
                            }}
                        >
                            Team Name
                        </div>
                        <ShortTextInput maxLength={30} onChange={(e) => updateTeamNameValue(e.target.value)} value={teamNameValue || ""} />
                    </div>
                    <div style={{ marginBottom: "10px", width: "100%" }}>
                        <div
                            style={{
                                marginBottom: "5px",
                                textTransform: "uppercase",
                                color: colors.scheme.primaryText
                            }}
                        >
                            Permissions
                        </div>
                        <Dropdown
                            value={teamPermissionsValue || 1}
                            dropdownMatchSelectWidth={false}
                            showArrow={true}
                            onChange={(e) => updateTeamPermissionsValue(e as number)}
                        >
                            <Option value={1}>Public</Option>
                            <Option value={2}>Private</Option>
                        </Dropdown>
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "5px" }}>
                        <Button
                            color={"#4C4C4C"}
                            background={colors.scheme.filterUnselectedTag}
                            onClick={() => dispatch(teamsActions.toggleModifyTeamMode(false))}
                        >
                            Cancel
                        </Button>
                        <Button color={"white"} background={colors.scheme.submitButtonBackground} onClick={() => submitEditTeam()}>
                            Submit
                        </Button>
                    </div>
                </>
            )}
            {!modifyTeamMode && cuser.userProfile?.role === 1 && cuser.userProfile?.team_id === null && teamName !== "Principal Group" && (
                <SubmitButton
                    onClick={() => dispatch(teamsActions.toggleModifyTeamMode(true))}
                    color={colors.scheme.submitButtonText}
                    style={{ margin: "20px 10% 0px" }}
                >
                    Edit Group
                </SubmitButton>
            )}
        </>
    );
};
