import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";
import { ReportBase, ReportTemplate } from "../../types/reports";
import * as updateReportOperations from "./operations";
import { unreachableCode } from "..";

interface UpdateReportSettingOptions {
    updateReportSetting: "Land" | "Sea";
}

const actionMap = {
    toggleUpdateReportSetting: payloadAction<UpdateReportSettingOptions>(),
    setUpdateReportSetting: payloadAction<UpdateReportSettingOptions>(),
    setQuickId: payloadAction<number | null>(),
    setSosId: payloadAction<number | null>(),
    setReport: payloadAction<ReportBase>(),
    editReport: payloadAction<ReportBase>(),
    setFullReportId: payloadAction<number | null>(),
    setReportTemplate: payloadAction<ReportTemplate>(),
    setMistReportId: payloadAction<number | null>()
};

export const updateReportActions = actionFactory(actionMap, "UPDATE_REPORT");

export type UpdateReportAction = ActionUnion<typeof updateReportActions>;

export interface UpdateReportState {
    report: ReportBase | null;
    reportTemplate: ReportTemplate | null;
    updateReportSetting: "Land" | "Sea" | undefined;
    quickId: number | null;
    sosId: number | null;
    fullReportId: number | null;
    mistReportId: number | null;
}

export const getInitialState = (): UpdateReportState => ({
    report: null,
    updateReportSetting: undefined,
    quickId: null,
    sosId: null,
    fullReportId: null,
    reportTemplate: null,
    mistReportId: null
});

const updateReportReducer = (state = getInitialState(), action: UpdateReportAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "UPDATE_REPORT") {
            switch (action.type) {
                case "editReport":
                    draftState.report = draftState.report
                        ? {
                              ...draftState.report,
                              ...action.payload
                          }
                        : action.payload;
                    return;
                case "setReport":
                    draftState.report = action.payload;
                    return;
                case "toggleUpdateReportSetting":
                    draftState.updateReportSetting = action.payload.updateReportSetting;
                    draftState.report = {};
                    return;
                case "setUpdateReportSetting":
                    draftState.updateReportSetting = action.payload.updateReportSetting;
                    return;
                case "setQuickId":
                    draftState.quickId = action.payload;
                    return;
                case "setSosId":
                    draftState.sosId = action.payload;
                    return;
                case "setFullReportId":
                    draftState.fullReportId = action.payload;
                    return;
                case "setReportTemplate":
                    draftState.reportTemplate = action.payload;
                    return;
                case "setMistReportId":
                    draftState.mistReportId = action.payload;
                    return;
                default:
                    unreachableCode(action);
                    break;
            }
        }
    });

export { updateReportOperations };
export default updateReportReducer;
