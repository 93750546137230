import { produce } from "immer";
import { unreachableCode } from "..";
import { simpleAction, payloadAction, actionFactory, ActionUnion } from "reductser";

export interface ModalContentOptions {
    modalContent:
        | "newsfeed"
        | "reports"
        | "analytics"
        | "menu"
        | "visualizations"
        | "filter"
        | "user"
        | "individual-report"
        | "insights"
        | "groups"
        | "add-user"
        | "billing"
        | "";
}

const actionMap = {
    toggleModal: simpleAction(),
    setModalContent: payloadAction<ModalContentOptions>(),
    setFilterOpen: payloadAction<boolean>()
};

export const leftModalActions = actionFactory(actionMap, "LEFT_MODAL");

export type LeftModalAction = ActionUnion<typeof leftModalActions>;

export interface LeftModalState {
    isOpen: boolean;
    modalContent:
        | "newsfeed"
        | "reports"
        | "analytics"
        | "menu"
        | "visualizations"
        | "filter"
        | "user"
        | "individual-report"
        | "insights"
        | "groups"
        | "add-user"
        | "billing"
        | "";
    filterOpen: boolean;
}

export const getInitialState = (): LeftModalState => ({
    isOpen: false,
    modalContent: "menu",
    filterOpen: false
});

const leftModalReducer = (state = getInitialState(), action: LeftModalAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "LEFT_MODAL") {
            switch (action.type) {
                case "toggleModal":
                    draftState.isOpen = !draftState.isOpen;
                    draftState.modalContent = "menu";
                    return;
                case "setModalContent":
                    draftState.modalContent = action.payload.modalContent;
                    return;
                case "setFilterOpen":
                    draftState.filterOpen = action.payload;
                    return;
                default:
                    unreachableCode(action);
            }
        }
    });

export default leftModalReducer;
