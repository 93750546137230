import styled from "styled-components";
import { device, activeSubmitButtonBackground } from "../../utils";
import Select from "antd/lib/select";
import Tag from "antd/lib/tag";

const { CheckableTag } = Tag;

export const NavBar = styled.div`
    display: flex;
    flex-direct: row;
    align-items: top;
    padding: 10px;
    height: 55px;
    width: 100%;
    background: linear-gradient(180deg, rgba(203, 203, 203, 0.13) 0%, rgba(196, 196, 196, 0) 100%);
`;

export const BackButton = styled.img`
    height: 30px;

    :hover {
        cursor: pointer;
    }
`;

export const NavHeader = styled.p`
    color: white;
    font-size: 20px;
    margin-left: 30%;
`;

interface ButtonProps {
    background: string;
    color: string;
    active?: boolean;
}

export const Button = styled.button`
    width: 30%;
    margin: 10px 10px 10px;

    background-color: ${(props: ButtonProps) => props.background};
    height: 37px;
    text-transform: uppercase;
    border-radius: 18px;
    border: none;
    color: ${(props: ButtonProps) => props.color};
    outline: none;

    :hover {
        cursor: pointer;
    }

    :active {
        background: ${(props: ButtonProps) => (props.active === true || props.active === undefined ? activeSubmitButtonBackground : props.background)};
    }
`;

interface DropdownProps {
    color: string;
    background: string;
    arrowcolor: string;
}

export const Dropdown = styled(Select)`
    width: 140px;
    color: ${(props: DropdownProps) => props.color};
    font-size: 14px;
    text-align: center;

    .ant-select-selector {
        background: ${(props: DropdownProps) => props.background} !important;
        border: none !important;
        border-radius: 8px !important;
    }

    .ant-select-arrow {
        color: ${(props: DropdownProps) => props.arrowcolor};
        pointer-events: none;
    }
`;

interface StyledTagProps {
    fontcolor: string;
    background: string;
}

export const StyledTag = styled(CheckableTag)`
    border-radius: 50px;
    scroll-snap-align: start;
    width: 45%;
    min-width: 45%;
    margin-bottom: 7px;
    padding-top: 2px;
    padding-left: 10px;
    color: ${(props: StyledTagProps) => props.fontcolor};
    background: ${(props: StyledTagProps) => props.background};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    :hover {
        cursor: pointer;
    }

    @media ${device.mobile} {
        :hover {
            opacity: 1;
            color: ${(props: StyledTagProps) => props.fontcolor} !important;
        }
    }
`;

interface ShowScrollbarDivProps {
    color: string;
}

export const ShowScrollbarDiv = styled.div`
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${(props: ShowScrollbarDivProps) => props.color};
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
`;

interface FilterSectionHeaderProps {
    color: string;
    space: boolean;
}

export const FilterSectionHeader = styled.div`
    color: ${(props: FilterSectionHeaderProps) => props.color};
    font-weight: bold;
    margin: ${(props: FilterSectionHeaderProps) => (props.space ? "5px 0" : "0")};
`;
