import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelectFromRedux } from "../../utils/_hooks";
import { clientInfoActions } from "../../state/clientInfo";
import { notificationActions } from "../../state/notification";
import { publicInfoOperations } from "../../state/publicInfo";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// components
import { NotificationContainer, Headline, DetailsPrompt, NotificationsCounter, Header } from "./styled";
import { PublicReport } from "../../types/reports";
import { UserProfile } from "../../types/client";
import { mapActions } from "../../state/map";
import { leftModalActions } from "../../state/left-modal";
import { individualReportActions } from "../../state/individual-report";
import { DetailsButton } from "../_shared/report-summary/styled";
import { isMobile } from "react-device-detect";

// notification interface for UI
export interface Notification {
    id: number;
    type: "alert" | "confirmation" | "report" | "cancel" | "confirmBoard";
    who: string;
    location: string;
    lat: number;
    long: number;
    time: string;
    date: string;
    needStatus: string;
    happeningStatus: string;
}

// const getNotificationWidth = () => {
//     if (isMobile) {
//         return "90%";
//     } else if (isTablet) {
//         return "50%";
//     } else {
//         return "400px";
//     }
// };

// const getNotificationLeft = () => {
//     if (isMobile) {
//         return "5%";
//     } else if (isTablet) {
//         return "25%";
//     } else {
//         return "calc(50vw - 200px)";
//     }
// };

/**
 * Determine which alert reports to display notifications for
 * @param alertReports - client's alert reports
 * @param currentUser - current user profile
 * @param clientUsers - array of other users in client
 */
const getNotifications = (currentUser: UserProfile, clientUsers: UserProfile[]): Notification[] => {
    const notifications: Notification[] = [];
    return notifications;
};

// Notifications container
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [showAlertNotificationDetails, clientUsers, userProfile] = useSelectFromRedux((state) => [
        state.notifications.showAlertNotificationDetails,
        state.clientInfo.users,
        // FIXME userProfile should not be optional/null
        state.cuser.userProfile
    ]);
    const reportToast = useSelectFromRedux((state) => state.notifications.toast);
    const bulletinToast = useSelectFromRedux((state) => state.notifications.bulletinToast);
    const confirmationToast = useSelectFromRedux((state) => state.notifications.confirmationToast);
    const cancelToast = useSelectFromRedux((state) => state.notifications.cancelToast);
    const modalState = useSelectFromRedux((state) => state.leftModal);
    const alertConfirmBoard = useSelectFromRedux((state) => state.notifications.alertConfirmBoard);
    const unconfirmedAlerts = useSelectFromRedux((state) => state.clientInfo.unconfirmedAlerts);
    const showTooltip = useSelectFromRedux((state) => state.map.showTooltip);
    const quickReportConfirmationNotification = useSelectFromRedux((state) => state.notifications.quickReportConfirmationNotification);
    const changePasswordConfirmation = useSelectFromRedux((state) => state.notifications.changePasswordConfirmation);
    const modalOpen = useSelectFromRedux((state) => state.leftModal.isOpen);
    const editUserProfile = useSelectFromRedux((state) => state.notifications.editUserProfile);
    const { addPendingUserConfirmation } = useSelectFromRedux((state) => state.notifications);
    const colors = useSelectFromRedux((state) => state.color);

    if (!userProfile) {
        console.error("Can't get user id in notifications!");
        return null;
    }

    const notifications = getNotifications(userProfile, clientUsers);

    const dispatch = useDispatch();

    const toastClose = (): void => {
        setToastType("");
        if (editUserProfile === true) {
            dispatch(notificationActions.toggleEditUserProfile(false));
        }
        if (addPendingUserConfirmation === true) {
            dispatch(notificationActions.toggleAddPendingUserConfirmation(false));
        }
    };

    const [toastType, setToastType] = useState<
        "" | "confirmation" | "report" | "cancel" | "confirmBoard" | "quickReportConfirmation" | "changePasswordConfirmation" | "addPendingUserConfirmation" | "bulletin"
    >("");

    const getSourceFromToast = () => {
        const source = (reportToast?.report as PublicReport)?.source_type ?? "";
        if (reportToast?.report.hasOwnProperty("source_type")) {
            if (source === "VAMP") {
                return "Atlas Source";
                // } else if (source === "AAMP") {
                //     return "Atlas Asset";
            } else if (source === "Client") {
                return "Atlas Source";
            } else if (source === "AAMP") {
                return "Atlas Source";
            } else if (source === "Open Source") {
                return "Atlas Source";
            } else {
                return "Atlas Source";
            }
        }
    };

    const showIndividualReport = () => {
        if (reportToast === null) {
            return;
        }
        if (!modalState.isOpen) dispatch(leftModalActions.toggleModal());
        dispatch(individualReportActions.setCurrentReportId(Number(reportToast.report.id)));
        if (reportToast.report_type.includes("Quick")) {
            dispatch(individualReportActions.setCurrentReportType("client"));
        } else if (reportToast.report_type.includes("Alert")) {
            dispatch(individualReportActions.setCurrentReportType("client-alert"));
        } else {
            dispatch(individualReportActions.setCurrentReportType("public"));
        }
        dispatch(
            leftModalActions.setModalContent({
                modalContent: "individual-report"
            })
        );
    };

    const goToReport = () => {
        toastClose();
        dispatch(
            mapActions.flyToPoint([reportToast?.report.point.coordinates[0] ?? 0, (reportToast?.report.point.coordinates[1] ?? 0) + (isMobile ? 0.003 : 0), 15])
        );
        showIndividualReport();
        dispatch(
            mapActions.renderTooltip({
                geometry: {
                    coordinates: reportToast?.report.point.coordinates ?? [0, 0]
                },
                properties: {
                    cluster: false,
                    title: reportToast?.report.report_type ?? "Event",
                    postedBy: getSourceFromToast(),
                    verified: reportToast?.report.hasOwnProperty("verified") ? (reportToast?.report as PublicReport).verified : 0
                }
            })
        );
        if (!showTooltip) {
            dispatch(mapActions.toggleTooltip());
        }
    };

    const goToBulletin = () => {
        dispatch(publicInfoOperations.getPublicInsights());
        toastClose();
        if (modalOpen == false) {
            dispatch(
                leftModalActions.toggleModal()
            );
        }
        dispatch(
            leftModalActions.setModalContent({
                modalContent: "insights"
            })
        );
    }

    const getReportToastMessage = (): string => {
        if (!reportToast) {
            return "";
        }
        let message = "";
        switch (reportToast.report_type) {
            case "Quick":
                message = `New report added by teammate.`;
                break;
            case "New-Quick":
                message = "Report successfully added.";
                break;
            case "Public":
                message = "New public report received.";
                break;
        }
        return message;
    };

    useEffect(() => {
        if (bulletinToast && toastType !== "confirmBoard") {
            console.log("Bulletin Toast set")
            setToastType("bulletin");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulletinToast]);

    useEffect(() => {
        if (cancelToast && toastType !== "confirmBoard") {
            setToastType("cancel");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelToast]);

    useEffect(() => {
        if (confirmationToast && toastType !== "confirmBoard") {
            setToastType("confirmation");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationToast]);

    useEffect(() => {
        if (reportToast && toastType !== "confirmBoard") {
            setToastType("report");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportToast]);

    useEffect(() => {
        if (alertConfirmBoard) {
            setToastType("confirmBoard");
        }
        if (unconfirmedAlerts < 1) {
            setToastType("");
        }
    }, [alertConfirmBoard, unconfirmedAlerts]);

    useEffect(() => {
        if (quickReportConfirmationNotification) {
            setToastType("quickReportConfirmation");
        }
    }, [quickReportConfirmationNotification]);

    useEffect(() => {
        if (changePasswordConfirmation) {
            setToastType("changePasswordConfirmation");
        }
    }, [changePasswordConfirmation]);

    useEffect(() => {
        if (addPendingUserConfirmation) {
            setToastType("addPendingUserConfirmation");
        }
    }, [addPendingUserConfirmation]);

    const showIndividualReportQuick = () => {
        if (!modalOpen) {
            dispatch(leftModalActions.toggleModal());
        }
        dispatch(individualReportActions.setCurrentReportId(quickReportConfirmationNotification));
        dispatch(individualReportActions.setCurrentReportType("client"));
        dispatch(leftModalActions.setModalContent({ modalContent: "individual-report" }));
        dispatch(individualReportActions.flyTo(true));
    };

    // FIXME should take an alert report instead notification
    const generateTopNotification = (notificationArray: Notification[]) => {
        let i = 0;
        let notification: Notification = notificationArray[0];
        while (localStorage.getItem("alert-" + notification.id)) {
            i++;
            notification = notificationArray[i];
            if (i >= notificationArray.length) {
                return;
            }
        }
        const closeAlert = () => {
            dispatch(clientInfoActions.confirmAlertNotification(notification.id));
        };
        if (userProfile.role !== 1) {
            setTimeout(() => {
                closeAlert();
            }, 15000);
        }

        return <></>;
    };
    const generateNotitification = () => {
        return (
            <>
                {alertConfirmBoard && toastType === "confirmBoard" && unconfirmedAlerts > 0 && userProfile.role === 1 && (
                    <NotificationContainer
                        background={colors.general.sosAlertNotification}
                        style={{
                            width: "100vw",
                            height: "100vh",
                            marginLeft: "0",
                            top: "0",
                            left: "0",
                            zIndex: 100,
                            alignItems: "center",
                            overflow: "scroll",
                            borderRadius: "0"
                        }}
                    >
                        <Headline>
                            <Header color={"#FFFFFF"}>Confirm your existing alert reports</Header>
                            <div>
                                You have {unconfirmedAlerts} SOS alert{unconfirmedAlerts === 1 ? "" : "s"} to Verify
                            </div>
                        </Headline>
                        <div style={{ overflowY: "scroll", maxHeight: "100%", width: "100%" }}></div>
                    </NotificationContainer>
                )}
                {reportToast && toastType === "report" && (
                    <NotificationContainer background={colors.general.generalNotification}>
                        <div style={{ right: "10px", top: "10px", position: "absolute" }}>
                            <CountdownCircleTimer isPlaying duration={15} colors={[["#FFFFFF", 0]]} onComplete={toastClose} size={30} strokeWidth={4} />
                        </div>
                        <Headline>
                            <Header color={"#FFFFFF"}>Report Notification</Header>
                            <div>{getReportToastMessage()}</div>
                            <DetailsPrompt bold={!showAlertNotificationDetails} onClick={goToReport}>
                                Click for more details.
                            </DetailsPrompt>
                        </Headline>
                    </NotificationContainer>
                )}
                {bulletinToast && toastType === "bulletin" && (
                    <NotificationContainer background={colors.general.generalNotification}>
                        <div style={{ right: "10px", top: "10px", position: "absolute" }}>
                            <CountdownCircleTimer isPlaying duration={15} colors={[["#FFFFFF", 0]]} onComplete={toastClose} size={30} strokeWidth={4} />
                        </div>
                        <Headline>
                            <Header color={"#FFFFFF"}>{bulletinToast.text}</Header>
                            <div>{bulletinToast.title}</div>
                            <DetailsPrompt onClick={goToBulletin}>
                                Click for more details.
                            </DetailsPrompt>
                        </Headline>
                    </NotificationContainer>
                )}
                {confirmationToast > 0 && toastType === "confirmation" && (
                    <NotificationContainer background={colors.general.confirmationNotification}>
                        <div style={{ right: "10px", top: "10px", position: "absolute" }}>
                            <CountdownCircleTimer isPlaying duration={15} colors={[["#FFFFFF", 0]]} onComplete={toastClose} size={30} strokeWidth={4} />
                        </div>
                        <Headline>
                            <Header color={"#FFFFFF"}>SOS Alert Confirmation</Header>
                            <div>Your alert was confirmed. Help is on the way.</div>
                        </Headline>
                    </NotificationContainer>
                )}
                {cancelToast > 0 && toastType === "cancel" && (
                    <NotificationContainer background={colors.general.sosAlertNotification}>
                        <div style={{ right: "10px", top: "10px", position: "absolute" }}>
                            <CountdownCircleTimer isPlaying duration={15} colors={[["#FFFFFF", 0]]} onComplete={toastClose} size={30} strokeWidth={4} />
                        </div>
                        <Headline>
                            <Header color={"#FFFFFF"}>SOS Alert Cancelled</Header>
                            <div>An active alert was cancelled.</div>
                            <DetailsPrompt onClick={goToReport}>Click for more details.</DetailsPrompt>
                        </Headline>
                    </NotificationContainer>
                )}
                {quickReportConfirmationNotification > -1 && toastType === "quickReportConfirmation" && (
                    <NotificationContainer
                        background={colors.general.confirmationNotification}
                        // style={{
                        //     width: getNotificationWidth(),
                        //     left: getNotificationLeft()
                        // }}
                    >
                        <div style={{ right: "10px", top: "10px", position: "absolute" }}>
                            <CountdownCircleTimer isPlaying duration={7} colors={[["#FFFFFF", 0]]} onComplete={toastClose} size={30} strokeWidth={4} />
                        </div>
                        <Headline>
                            <Header style={{ textTransform: "none" }} color={"#FFFFFF"}>
                                Report Submitted.
                            </Header>
                            {quickReportConfirmationNotification > 0 && (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "left",
                                        color: colors.scheme.primaryText
                                    }}
                                >
                                    <DetailsButton
                                        color={"#FFFFFF"}
                                        onClick={() => showIndividualReportQuick()}
                                        style={{
                                            fontSize: "16px",
                                            textDecoration: "underline",
                                            alignSelf: "center"
                                        }}
                                    >
                                        View Full Report
                                    </DetailsButton>
                                </div>
                            )}
                        </Headline>
                    </NotificationContainer>
                )}
                {changePasswordConfirmation > 0 && toastType === "changePasswordConfirmation" && (
                    <NotificationContainer
                        background={colors.general.confirmationNotification}
                        // style={{
                        //     width: getNotificationWidth(),
                        //     left: getNotificationLeft()
                        // }}
                    >
                        <div style={{ right: "10px", top: "10px", position: "absolute" }}>
                            <CountdownCircleTimer isPlaying duration={7} colors={[["#FFFFFF", 0]]} onComplete={toastClose} size={30} strokeWidth={4} />
                        </div>
                        <Headline>
                            <Header style={{ textTransform: "none" }} color={"#FFFFFF"}>
                                Password Successfully Changed.
                            </Header>
                        </Headline>
                    </NotificationContainer>
                )}
                {toastType === "addPendingUserConfirmation" && (
                    <NotificationContainer
                        background={colors.general.confirmationNotification}
                        // style={{
                        //     width: isMobile ? "90%" : "400px",
                        //     marginLeft: isMobile ? "0px" : "-200px"
                        // }}
                    >
                        <div style={{ right: "10px", top: "10px", position: "absolute" }}>
                            <CountdownCircleTimer isPlaying duration={7} colors={[["#FFFFFF", 0]]} onComplete={toastClose} size={30} strokeWidth={4} />
                        </div>
                        <Headline>
                            <Header style={{ textTransform: "none" }} color={"#FFFFFF"}>
                                User Successfully Invited.
                            </Header>
                        </Headline>
                    </NotificationContainer>
                )}
                {editUserProfile && (
                    <NotificationContainer
                        background={colors.general.confirmationNotification}
                        // style={{
                        //     width: getNotificationWidth(),
                        //     left: getNotificationLeft()
                        // }}
                    >
                        <div style={{ right: "10px", top: "10px", position: "absolute" }}>
                            <CountdownCircleTimer isPlaying duration={7} colors={[["#FFFFFF", 0]]} onComplete={toastClose} size={30} strokeWidth={4} />
                        </div>
                        <Headline>
                            <Header style={{ textTransform: "none" }} color={"#FFFFFF"}>
                                User Profile Updated.
                            </Header>
                        </Headline>
                    </NotificationContainer>
                )}
            </>
        );
    };

    return (
        <>
            {(reportToast ||
                bulletinToast ||
                confirmationToast ||
                cancelToast ||
                alertConfirmBoard ||
                quickReportConfirmationNotification ||
                changePasswordConfirmation ||
                addPendingUserConfirmation) &&
            notifications.length === 0
                ? generateNotitification()
                : null}
            {notifications.length > 0 ? generateTopNotification(notifications) : null}
            {notifications.filter((notification) => !localStorage.getItem("alert-" + notification.id)).length > 1 && (
                <>
                    <NotificationsCounter>
                        {notifications.filter((notification) => !localStorage.getItem("alert-" + notification.id)).length}
                    </NotificationsCounter>
                </>
            )}
        </>
    );
};