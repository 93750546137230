import { produce } from "immer";
import { unreachableCode } from "..";
import { simpleAction, payloadAction, actionFactory, ActionUnion } from "reductser";

interface DrawerPageLabels {
    label?: "analytics" | "filter" | ""; // add more page labels here
}

const actionMap = {
    openDrawer: simpleAction(),
    toggleDrawer: payloadAction<boolean>(),
    togglePage: payloadAction<DrawerPageLabels>(),
    closePage: simpleAction()
};

export const leftDrawerActions = actionFactory(actionMap, "LEFT_DRAWER");

export type LeftDrawerAction = ActionUnion<typeof leftDrawerActions>;

interface DrawerPages {
    isOpen: boolean;
    current?: string;
}
export interface LeftDrawerState {
    isOpen: boolean;
    drawerPages: DrawerPages;
}

export const getInitialState = (): LeftDrawerState => ({
    isOpen: false, // hardcoded for dev
    drawerPages: {
        isOpen: false, // hardcoded for dev
        current: "" // hardcoded for dev
    }
});

const leftDrawerReducer = (state = getInitialState(), action: LeftDrawerAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "LEFT_DRAWER") {
            switch (action.type) {
                case "openDrawer":
                    draftState.isOpen = true;
                    return;
                case "toggleDrawer":
                    draftState.isOpen = action.payload;
                    return;
                case "togglePage":
                    draftState.drawerPages.isOpen = !draftState.drawerPages.isOpen;
                    draftState.drawerPages.current = action.payload.label;
                    return;
                case "closePage":
                    draftState.drawerPages.isOpen = false;
                    return;
                default:
                    unreachableCode(action);
            }
        }
    });

export default leftDrawerReducer;
