import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";

interface ReportsSettingOptions {
    reportsSetting: "alert-reports" | "reports";
}

const actionMap = {
    toggleReportsSetting: payloadAction<ReportsSettingOptions>()
};

export const reportsActions = actionFactory(actionMap, "REPORTS");

export type ReportsAction = ActionUnion<typeof reportsActions>;

export interface ReportsState {
    reportsSetting: "alert-reports" | "reports";
}

export const getInitialState = (): ReportsState => ({
    reportsSetting: "reports"
});

const reportsReducer = (state = getInitialState(), action: ReportsAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "REPORTS") {
            switch (action.type) {
                case "toggleReportsSetting":
                    draftState.reportsSetting = action.payload.reportsSetting;
                    return;
                default:
                    break;
            }
        }
    });

export default reportsReducer;
