import styled, { keyframes } from "styled-components";
import Select from "antd/lib/select";
import { device } from "../../utils/index";
import { Button } from "../filter/styled";

const shadowPulse = keyframes`
    0% {
        box-shadow: 0 0 0 0px rgba(10, 132, 255, 0.2);
    }
    100% {
        box-shadow: 0 0 0 5px rgba(10, 132, 255, 0.2);
    }
`;

export const BackButton = styled.img`
    height: 25px;
    :hover {
        cursor: pointer;
    }
`;

export const Legend = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

interface EntryProps {
    color: string;
}

export const EntryName = styled.div`
    width: 110px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    color: ${(props: EntryProps) => props.color};
`;

export const LegendEntry = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
`;

export const EntryCases = styled.div`
    width: 110px;
    text-align: center;
    color: ${(props: EntryProps) => props.color};
    @media ${device.mobile} {
        margin-top: 20px;
    }
`;

export const EntryDelta = styled.div`
    width: 90px;
`;

interface AreaChartHeadingProps {
    color: string;
}

export const AreaChartHeading = styled.div`
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 10px;
    color: ${(props: AreaChartHeadingProps) => props.color};
    font-weight: 700;
`;

export const Arrow = styled.img`
    margin-top: 80px;
    :hover {
        cursor: pointer;
    }
`;

interface ErrorDisplayProps {
    color: string;
}

export const ErrorDisplay = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: ${(props: ErrorDisplayProps) => props.color};
    margin-top: 10px;
`;

interface DropdownProps {
    background: string;
    color: string;
}

export const Dropdown = styled(Select)`
    width: 100px;
    margin-right: 10px;
    color: ${(props: DropdownProps) => props.color};
    font-size: 14px;
    text-align: center;
    .ant-select-selector {
        background: ${(props: DropdownProps) => props.background} !important;
        border: none !important;
        border-radius: 8px !important;
    }
`;

interface TimeRangeToggleProps {
    selected: boolean;
    selectedColor: string;
    color: string;
    mode: string;
}

export const TimeRangeToggle = styled.div<TimeRangeToggleProps>`
    text-align: right;
    margin: 15px 0;
    font-weight: 600;
    font-size: 12px;
    width: 65px;
    border-bottom: ${(props: TimeRangeToggleProps) => (props.selected ? "2px solid ".concat(props.mode === "light" ? "#4C4C4C" : props.selectedColor) : "")};
    color: ${(props: TimeRangeToggleProps) => (props.selected ? props.selectedColor : props.color)};
    :hover {
        cursor: pointer;
    }
`;

interface ErrorMessageProps {
    color: string;
}

export const ErrorMessage = styled.div`
    font-size: 14px;
    color: ${(props: ErrorMessageProps) => props.color};
    width: 215px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 15px;
`;

interface CuserDisplayContentProps {
    color: string;
}

export const CuserDisplayContent = styled.div`
    font-size: 14px;
    color: ${(props: CuserDisplayContentProps) => props.color};
    margin-bottom: 5px;
`;

interface SpaceBetweenFlexContainerProps {
    width?: string;
    height?: string;
}

export const SpaceBetweenFlexContainer = styled.div`
    width: ${(props: SpaceBetweenFlexContainerProps) => props.width ?? "100%"};
    min-height: ${(props: SpaceBetweenFlexContainerProps) => props.height ?? "40px"};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

interface CenteredFlexContainerProps {
    width?: string;
    height?: string;
}

export const CenteredFlexContainer = styled.div`
    width: ${(props: CenteredFlexContainerProps) => props.width ?? "100%"};
    min-height: ${(props: CenteredFlexContainerProps) => props.height ?? "40px"};
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface BottomBarProps {
    background: string;
    filter: string;
}

export const BottomBar = styled.div`
    width: calc(100% + 40px);
    filter: ${(props: BottomBarProps) => props.filter};
    background: ${(props: BottomBarProps) => props.background};
    margin: 0 -20px -20px -20px;
    border-radius: 0 0 14px 14px;
`;

interface ApplyToMapButtonProps {
    background: string;
    color: string;
    active?: boolean;
    pulsing?: boolean;
}

export const ApplyToMapButton = styled(Button)`
    width: 30%;
    margin: 10px 10px 10px;

    background-color: ${(props: ApplyToMapButtonProps) => props.background};
    height: 37px;
    text-transform: uppercase;
    border-radius: 18px;
    border: none;
    color: ${(props: ApplyToMapButtonProps) => props.color};
    outline: none;
    animation-name: ${(props: ApplyToMapButtonProps) => (props.pulsing ? shadowPulse : "none")};
    animation-duration: ${(props: ApplyToMapButtonProps) => (props.pulsing ? "1s" : "0")};
    animation-iteration-count: 5;

    :hover {
        cursor: pointer;
    }

    :active {
        background: ${(props: ApplyToMapButtonProps) => (props.active === true || props.active === undefined ? "#0A84FF" : props.background)};
    }
`;
