import React, { useEffect, useState } from "react";
import { useSelectFromRedux, useIsMobile, useDispatchPromise } from "../../utils/_hooks";
import { Redirect } from "react-router-dom";
import { getContainerViewportFromId, getDataSuccessful, dataSuccessfullyGotten, distanceBetween } from "../../utils";
import { isMobile } from "react-device-detect";
import { setToken, signOut, Global } from "../../state/cuser/operations";
import { auth } from "../../types/firebase";

// redux
import { useDispatch } from "react-redux";
import { cuserOperations, cuserActions } from "../../state/cuser";
import { clientInfoOperations } from "../../state/clientInfo";
import { publicInfoOperations, publicInfoActions } from "../../state/publicInfo";
import { mapActions } from "../../state/map";
import { toastActions } from "../../state/toast";
import { colorActions } from "../../state/color";

// components
import { LoadingSpinner } from "../_shared";
import { FullScreenImage } from "../_shared/auth";
import { GeoAttribution } from "../../types/geo";
import moment from "moment";

export default ({ children }: any) => {
    const [signedIn, userProfile, client, containers, insights, current_container] = useSelectFromRedux((state) => [
        state.cuser.signedIn,
        state.cuser.userProfile,
        state.cuser.clientProfile,
        state.cuser.containers,
        state.publicInfo.insights,
        state.cuser.userProfile?.current_container
    ]);

    // const { viewport } = useSelectFromRedux((state) => state.map);
    const device = useIsMobile();
    const dispatch = useDispatch();
    const dispatchPromise = useDispatchPromise();

    const [firstTimeSignIn, setFirstTimeSignIn] = useState<boolean>(true);
    const [timeOutSet, updateTimeOutSet] = useState<boolean>(false);
    localStorage.setItem("oldLat", "");
    localStorage.setItem("oldLong", "");

    // Sets current container to first available client container in case of current container being null
    if (typeof current_container !== "number" && containers.length > 0) dispatch(cuserActions.updateCurrentContainer(containers[0].id))


    useEffect(() => {
        if (window.analytics) {
            window.analytics.page();
        }
        // start segment page tracking on render only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (signedIn) {
            Global.unsubscribeAuthStateListener = auth.onAuthStateChanged((user) => {
                if (user) {
                    console.log("[AUTH] State change for current user: ");
                    console.log(user);
                } else {
                    if (signedIn) {
                        console.log("Token cached without valid firebase authentication. Was this user disabled?");
                        setTimeout(() => {
                            dispatch(toastActions.openError("Session expired. Please sign in again."));
                        }, 1000);
                        dispatch(signOut());
                        dataSuccessfullyGotten();
                    } else {
                        console.warn("Auth state changed but no valid user, already signed out?");
                    }
                }
            });

            Global.unsubscribeIdTokenListener = auth.onIdTokenChanged((user) => {
                if (user) {
                    user.getIdToken()
                        .then((idToken) => {
                            console.log("Firebase token updated");
                            dispatch(setToken(idToken));
                        })
                        .catch((err: Error) => {
                            setTimeout(() => {
                                dispatch(toastActions.openError("Session expired. Please sign in again."));
                            }, 1000);
                            console.error("Error getting user idToken on auth state change: ", err);
                            dispatch(signOut());
                            dataSuccessfullyGotten();
                        });
                }
            });

            console.log("Loading user data on successful sign in.");
            dispatch(cuserOperations.getCuserProfile());
            dispatch(cuserOperations.getCuserClient());
            dispatch(cuserOperations.getCuserContainers());
            dispatch(cuserOperations.getDevices());
            dispatch(clientInfoOperations.fetchClientInfo());
            dispatch(publicInfoOperations.getPublicInsights());
            if (!localStorage.getItem("fingerprint")) {
                console.log("No fingerprint set, getting one...");
                cuserOperations
                    .GetDeviceFingerprint()
                    .then((fingerprint) => {
                        localStorage.setItem("fingerprint", fingerprint);
                    })
                    .catch((err) => {
                        console.error("Could not get device fingerprint: ", err);
                        toastActions.openError("Could not get device fingerprint.");
                    });
            } else {
                console.log("Fingerprint for this device is already set.");
            }

            if (!localStorage.getItem("militaryMode")) {
                localStorage.setItem("militaryMode", "false");
                console.log("Military Mode not set, defaulting to false");
            } else {
                dispatch(cuserActions.updateMilitaryMode(localStorage.getItem("militaryMode") === "true"));
                console.log("Military Mode set as ".concat(localStorage.getItem("militaryMode") as string));
            }

            if (!localStorage.getItem("theme")) {
                localStorage.setItem("theme", "light");
                console.log("Theme not set, defaulting to light");
            } else {
                dispatch(colorActions.changeColorScheme(localStorage.getItem("theme") as "light" | "dark"));
                console.log("Theme set as ".concat(localStorage.getItem("theme") as "light" | "dark"));
            }
        }
    }, [dispatch, signedIn]);

    // On first time sign in, wait for backend to set current_container through fetchPublicReportsFromContainer

    if (signedIn && userProfile && client && containers.length > 0 && userProfile.current_container && device) {
        if (firstTimeSignIn) {
            if (device === "desktop") {
                if (userProfile.current_container) {
                    const containerViewportInfo = getContainerViewportFromId(userProfile.current_container, containers);
                    dispatch(
                        mapActions.handleViewportChange({
                            width: "100vw",
                            height: "100vh",
                            ...containerViewportInfo,
                            bearing: 0,
                            pitch: 0
                        })
                    );
                } else {
                    console.warn("No current container set, something went wrong. Trying to refresh profile...");
                    dispatch(cuserOperations.getCuserProfile());
                }
            } else {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        dispatch(
                            mapActions.handleViewportChange({
                                width: "100vw",
                                height: "100vh",
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                                zoom: 13,
                                bearing: 0,
                                pitch: 0
                            })
                        );
                    },
                    (error) => {
                        if (userProfile.current_container) {
                            const containerViewportInfo = getContainerViewportFromId(userProfile.current_container, containers);
                            dispatch(
                                mapActions.handleViewportChange({
                                    width: "100vw",
                                    height: "100vh",
                                    ...containerViewportInfo,
                                    bearing: 0,
                                    pitch: 0
                                })
                            );
                        } else {
                            console.warn("No current container set, something went wrong. Trying to refresh profile...");
                            dispatch(cuserOperations.getCuserProfile());
                        }
                    }
                );
            }

            dataSuccessfullyGotten();

            /**
             * Handle public report fetch based on current container for mobile devices
             */
            if (isMobile) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        localStorage.setItem("oldLong", "" + position.coords.longitude);
                        localStorage.setItem("oldLat", "" + position.coords.latitude);
                        dispatch(cuserActions.updateLocationServices(true));
                        dispatch(
                            cuserActions.updatePoint({
                                type: "Point",
                                coordinates: [position.coords.longitude, position.coords.latitude]
                            })
                        );
                        const geoAttribution: GeoAttribution | null = await dispatchPromise(cuserOperations.sendLocationUpdate(position));

                        // If there's a valid geoAttribution, update current_container and fetch reports
                        if (geoAttribution && geoAttribution.container_id) {
                            const foundContainer = containers.find((c) => c.id === geoAttribution.container_id);
                            if (foundContainer) {
                                dispatch(cuserActions.updateCurrentContainer(geoAttribution.container_id));
                            } else {
                                dispatch(
                                    toastActions.openError(
                                        "Atlas network access is disabled for your current region, public reports are unavailable. Please contact your administrator or Arcturus billing for support."
                                    )
                                );
                            }
                        } else {
                            console.log("No existing geoAttribution, keeping db current container...");
                            if (geoAttribution === null) {
                                dispatch(toastActions.openError("Atlas network does not yet support your current region. Public reports are unavailable."));
                            }
                        }
                    },
                    (error) => {
                        console.warn("Error fetching current position: " + error);
                    }
                );
                navigator.geolocation.watchPosition(
                    (position) => {
                        if (localStorage.getItem("oldLong") === "" || localStorage.getItem("oldLat") === "" || distanceBetween(position) > 100) {
                            localStorage.setItem("oldLong", "" + position.coords.longitude);
                            localStorage.setItem("oldLat", "" + position.coords.latitude);
                            dispatch(cuserActions.updateLocationServices(true));
                            dispatch(
                                cuserActions.updatePoint({
                                    type: "Point",
                                    coordinates: [position.coords.longitude, position.coords.latitude]
                                })
                            );
                            dispatch(cuserOperations.sendLocationUpdate(position));
                        }
                    },
                    (error) => {
                        dispatch(cuserActions.updateLocationServices(false));
                        dispatch(toastActions.openError("Location Permissions Disabled. Some Features May Be Innaccessible."));
                    },
                    {
                        enableHighAccuracy: true
                    }
                );
            } else {
                navigator.geolocation.getCurrentPosition((position) => {
                    localStorage.setItem("oldLong", "" + position.coords.longitude);
                    localStorage.setItem("oldLat", "" + position.coords.latitude);
                    dispatch(
                        cuserActions.updatePoint({
                            type: "Point",
                            coordinates: [position.coords.longitude, position.coords.latitude]
                        })
                    );
                    dispatch(cuserOperations.sendLocationUpdate(position));
                    dispatch(cuserActions.updateLocationServices(true));
                });
            }
            // FIXME need a check here to see if alertReports have been loaded, as empty array could indicate never loaded
            // or loaded and none found
            const latestInsight = insights.filter((insight) => insight.container_ids.includes(current_container || -1))[0];
            if (latestInsight) {
                if (localStorage.getItem("lastInsightAccess")) {
                    if (
                        moment(new Date()).diff(latestInsight.created_at, "hours") < 24 &&
                        moment(JSON.parse(localStorage.getItem("lastInsightAccess") ?? "")).diff(latestInsight.created_at, "milliseconds") < 0
                    ) {
                        if (userProfile?.features?.publicInsightsMenu === true) dispatch(publicInfoActions.setNewInsight(true));
                    }
                } else {
                    if (moment(new Date()).diff(latestInsight.created_at, "hours") < 24) {
                        if (userProfile?.features?.publicInsightsMenu === true) dispatch(publicInfoActions.setNewInsight(true));
                    }
                }
            }
            if (userProfile.designation === "Medic") {
                dispatch(cuserActions.updateMilitaryMode(true));
            }
            setFirstTimeSignIn(false);
        }
        return <>{children}</>;
    } else if (signedIn) {
        if (!timeOutSet) {
            setTimeout(() => {
                if (!getDataSuccessful) {
                    console.log("THE RIGHT SIGN OUT");
                    dispatch(signOut());
                    dispatch(toastActions.openError("Session timed out. Please sign in again."));
                }
            }, 60000);
            updateTimeOutSet(true);
        }
        return (
            <FullScreenImage>
                <LoadingSpinner background={"white"} size="large" />
            </FullScreenImage>
        );
    } else {
        return <Redirect to="/login" />;
    }
};
