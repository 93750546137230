import styled from "styled-components";
import { device } from "../../utils/index";
import Select from "antd/lib/select";
import Collapse from "antd/lib/collapse";
import Input from "antd/lib/input";
import InputNumber from "antd/lib/input-number";
import Autocomplete from "antd/lib/auto-complete";

const { TextArea } = Input;
const { Panel } = Collapse;

interface TextInputProps {
    background: string;
    color: string;
}

export const TextInput = styled(TextArea)`
    border-radius: 8px !important;
    background: ${(props: TextInputProps) => props.background} !important;
    border: none;
    color: ${(props: TextInputProps) => props.color};
`;

interface ExpandProps {
    headerColor: string;
    color: string;
}

export const Expand = styled(Collapse)`
    background: transparent !important;
    border: none;

    .ant-collapse {
        background: transparent !important;
    }

    .ant-collapse-item {
        border-bottom: none;
    }

    .ant-collapse-header {
        color: ${(props: ExpandProps) => props.headerColor} !important;
        font-size: 16px;
    }

    .ant-collapse-content {
        background: transparent !important;
        border-top: none;
        color: ${(props: ExpandProps) => props.color};
    }
`;

export const ExpandItem = styled(Panel)`
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 16px !important;
    }
`;

interface NumberInputProps {
    background: string;
    color: string;
}

export const NumberInput = styled(InputNumber)`
    border-radius: 8px !important;
    background: ${(props: NumberInputProps) => props.background} !important;
    border: none;
    color: ${(props: NumberInputProps) => props.color};
    width: 80%;
`;

export const ContentContainer = styled.div`
    overflow-y: scroll;
    max-height: 60vh;

    @media ${device.mobile} {
        max-height: 45vh;
    }
`;

interface FormFieldContainerProps {
    background: string;
}

export const FormFieldContainer = styled.div`
    width: 100%;
    min-height: 40px;
    background: ${(props: FormFieldContainerProps) => props.background};
    border-radius: 8px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: hidden;
`;

interface DropdownProps {
    color: string;
    background: string;
}

export const Dropdown = styled(Select)`
    width: 100%;
    color: ${(props: DropdownProps) => props.color};
    font-size: 14px;

    .ant-select-selector {
        background: ${(props: DropdownProps) => props.background} !important;
        border: none !important;
        border-radius: 8px !important;
    }
`;

interface TextInputProps {
    placeholdercolor?: string;
}

export const ShortTextInput = styled(Input)`
    border-radius: 8px !important;
    background: ${({ theme }) => theme.selectorBackground} !important;
    border: none;
    color: ${({ theme }) => theme.primaryText};
    .ant-select-selection__placeholder {
        color: grey !important;
    }
`;

interface ShortTextInputProps {
    placeholdercolor: string;
}

export const ShortTextInputWithPlaceholderColor = styled(ShortTextInput)<ShortTextInputProps>`
    border-radius: 8px !important;
    background: ${({ theme }) => theme.selectorBackground} !important;
    border: none;
    color: ${({ theme }) => theme.primaryText};
    .ant-select-selection__placeholder {
        color: grey !important;
    }

    ::placeholder {
        color: ${({ placeholdercolor, theme }) => (placeholdercolor ? placeholdercolor : theme.primaryText)} !important;
    }
`;

export const ShortTextInputAutoComplete = styled(Autocomplete)`
    border-radius: 8px !important;
    width: 100%;
    background: ${(props: TextInputProps) => props.background} !important;
    border: none;
    color: ${(props: TextInputProps) => props.color};

    ::placeholder {
        color: ${(props: TextInputProps) => props.placeholdercolor} !important;
    }

    .ant-select-clear {
        color: ${(props: TextInputProps) => props.color};
        background: ${(props: TextInputProps) => props.background} !important;
    }
`;
