import styled from "styled-components";
import { FormField } from "../_shared/auth";
import Select from "antd/lib/select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";

export const MessageContainer = styled.div`
    text-align: center;
    color: white;
    letter-spacing: 1px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
`;

export const MessageTitle = styled.div`
    text-transform: uppercase;
    font-size: 14px;
`;

export const MessageContent = styled.div`
    font-size: 13px;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const EmailFormField = styled(FormField)`
    background-color: transparent;
    font-size: 16px;
    font-weight: bold;
`;

export const Dropdown = styled(Select)`
    width: 60px;
    color: white;
    font-size: 14px;
    text-align: center;

    .ant-select-selector {
        background: #8a8c96 !important;
        border: none !important;
        border-right: 1px solid #171f2e !important;
        border-radius: 8px 0px 0px 8px !important;
        padding-top: 1px !important;
    }
`;

export const SquareInput = styled.input`
    font-family: IBM Plex Sans;
    width: 40px;
    height: 40px;
    background-color: #8a8c96;
    color: white;
    border: none;
    border-radius: 8px;
    margin: 10px;
    text-align: center;
    font-size: 18px;
    outline: none;

    ::placeholder {
        color: white;
        letter-spacing: 1px;
    }
`;

export const PhoneNumberInput = styled(PhoneInput)`
    ::placeholder {
        color: white;
        opacity: 0.8;
    }
`;

export const PhoneLoadingContainer = styled.div`
    background: #8a8c96;
    height: 37px;
    width: 254px;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;
    align-items: center;
`;
