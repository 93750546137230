import React, { useState, useEffect, useCallback } from "react";

// redux
import { useSelectFromRedux } from "../../utils/_hooks";

// components
import { BottomGradient, Dot } from "../_shared";
import ReportSummary from "../_shared/report-summary";

// icons
import { PublicReport, ReportCategories } from "../../types/reports";
import { isMobile } from "react-device-detect";
import { Button } from "../filter/styled";
import { getContainerViewportFromId, getDefaultRegionViewportFromId, S3Key } from "../../utils";
import { mapActions } from "../../state/map";
import { useDispatch } from "react-redux";
import { clientInfoOperations } from "../../state/clientInfo";
import { filterActions, FilterState, Filter_TimePeriod } from "../../state/filter";
import { isEqual } from "lodash";
import { leftModalActions } from "../../state/left-modal";
import moment from "moment";
import { DefaultRegionNoViewport } from "../analytics";
import { ComponentFilter } from "../filter/ComponentFilter";
import { useQuery } from "react-query";
import * as publicApi from "../../types/api/public";

const FunnelWhiteIcon = S3Key + "funnel-white.png";
const FunnelGreyIcon = S3Key + "funnel-grey.png";
const NoDataWhiteIcon = S3Key + "no-data-white.png";
const NoDataGreyIcon = S3Key + "no-data-grey.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const publicReports = useSelectFromRedux((state) => state.publicInfo.reports);
    const colors = useSelectFromRedux((state) => state.color);
    const userProfile = useSelectFromRedux((state) => state.cuser.userProfile);
    const [filterOpen, updateFilterOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const current_container = useSelectFromRedux((state) => state.cuser.userProfile?.current_container);
    const defaultRegions = useSelectFromRedux((state) => state.cuser.defaultRegions);
    const containers = useSelectFromRedux((state) => state.cuser.containers);
    const currentFilter = useSelectFromRedux((state) => state.filter);
    const tooltipOpen = useSelectFromRedux((state) => state.map.showTooltip);
    const [orionSourceChecked, updateOrionSourceChecked] = useState<boolean>(currentFilter.orionSourceIncluded);
    const [orionUserChecked, updateOrionUserChecked] = useState<boolean>(currentFilter.orionUserIncluded);
    const [openSourceChecked, updateOpenSourceChecked] = useState<boolean>(currentFilter.openSourceIncluded);
    const [violentChecked, updateViolentChecked] = useState<boolean>(currentFilter.violentIncluded);
    const [nonviolentChecked, updateNonviolentChecked] = useState<boolean>(currentFilter.nonviolentIncluded);
    const [hazardChecked, updateHazardChecked] = useState<boolean>(currentFilter.hazardIncluded);
    const [otherChecked, updateOtherChecked] = useState<boolean>(currentFilter.otherIncluded);

    const timePeriodNumber = currentFilter && currentFilter.timePeriod ? Number(currentFilter.timePeriod.split(" ")[0]) : 250;

    const getNumberFromTimePeriod = (timePeriod: string | null) => {
        if (timePeriod === "24 Hour") {
            return 1;
        } else if (timePeriod === "7 Day") {
            return 7;
        } else if (timePeriod === "28 Day") {
            return 28;
        } else {
            return 250;
        }
    };

    const publicReportsQuery = useQuery(["public-reports", current_container, timePeriodNumber], () =>
        publicApi.getReportsBounded(current_container ?? 2, getNumberFromTimePeriod(currentFilter.timePeriod))
    );

    //applied changes
    const [regionChange, updateRegionChange] = useState<number>(currentFilter.selectedRegion);
    const [mapRegionChange, updateMapRegionChange] = useState<number>(currentFilter.selectedRegion);
    const [orionSourceCheckedApplied, updateOrionSourceCheckedApplied] = useState<boolean>(currentFilter.orionSourceIncluded);
    const [orionUserCheckedApplied, updateOrionUserCheckedApplied] = useState<boolean>(currentFilter.orionUserIncluded);
    const [openSourceCheckedApplied, updateOpenSourceCheckedApplied] = useState<boolean>(currentFilter.openSourceIncluded);
    const [violentCheckedApplied, updateViolentCheckedApplied] = useState<boolean>(currentFilter.violentIncluded);
    const [nonviolentCheckedApplied, updateNonviolentCheckedApplied] = useState<boolean>(currentFilter.nonviolentIncluded);
    const [hazardCheckedApplied, updateHazardCheckedApplied] = useState<boolean>(currentFilter.hazardIncluded);
    const [otherCheckedApplied, updateOtherCheckedApplied] = useState<boolean>(currentFilter.otherIncluded);

    // Front-end options for each dropdown – reflect filterState but with 'All' instead of null for display
    const [selectedTimePeriod, updateTimePeriod] = useState<string>(currentFilter.timePeriod === null ? "All Time" : currentFilter.timePeriod);
    const [verifiedChecked, updateVerifiedChecked] = useState<boolean>(currentFilter.verifiedIncluded);
    const [unverifiedChecked, updateUnverifiedChecked] = useState<boolean>(currentFilter.unverifiedIncluded);

    //applied changes
    const [selectedTimePeriodApplied, updateTimePeriodApplied] = useState<string>(currentFilter.timePeriod === null ? "All Time" : currentFilter.timePeriod);
    const [verifiedCheckedApplied, updateVerifiedCheckedApplied] = useState<boolean>(currentFilter.verifiedIncluded);
    const [unverifiedCheckedApplied, updateUnverifiedCheckedApplied] = useState<boolean>(currentFilter.unverifiedIncluded);

    const [eventTypeSearchOpen, updateEventTypeSearchOpen] = useState<boolean>(false);
    const [selectedEventTypes, updateSelectedEventTypes] = useState<string[]>(currentFilter.eventTypeApplied ?? []);
    const [appliedEventTypes, updateAppliedEventTypes] = useState<string[]>(currentFilter.eventTypeApplied ?? []);
    const [eventChecked, updateEventChecked] = useState<boolean>(false);

    const filterReport = useCallback(
        (report: PublicReport): boolean => {
            let result = true;

            if (regionChange > 0) {
                if (report.default_region_id !== regionChange) {
                    result = false;
                }
            }

            if (appliedEventTypes.length > 0) {
                if (!appliedEventTypes.includes(report.report_type)) {
                    result = false;
                }
            }

            if (!(!orionSourceCheckedApplied && !orionUserCheckedApplied && !openSourceCheckedApplied)) {
                if (!orionSourceCheckedApplied) {
                    if (report.source_type === "AAMP" || report.source_type === "VAMP") {
                        result = false;
                    }
                }

                if (!orionUserCheckedApplied) {
                    if (report.source_type === "Client") {
                        result = false;
                    }
                }

                if (!openSourceCheckedApplied) {
                    if (report.source_type === "Open Source") {
                        result = false;
                    }
                }
            }

            const daysSinceReport = moment(new Date()).diff(report.date_time, "hours");
            switch (selectedTimePeriodApplied) {
                case "28 Day":
                    if (!(daysSinceReport <= 30 * 24)) {
                        result = false;
                    }
                    break;
                case "7 Day":
                    if (!(daysSinceReport <= 7 * 24)) {
                        result = false;
                    }
                    break;
                case "24 Hour":
                    if (!(daysSinceReport <= 24)) {
                        result = false;
                    }
                    break;
            }

            if (!(!nonviolentCheckedApplied && !violentCheckedApplied && !hazardCheckedApplied && !otherCheckedApplied)) {
                if (!violentCheckedApplied) {
                    if (ReportCategories["Violent"].includes(report.report_type)) {
                        result = false;
                    }
                }

                if (!nonviolentCheckedApplied) {
                    if (ReportCategories["Non-Violent"].includes(report.report_type)) {
                        result = false;
                    }
                }

                if (!hazardCheckedApplied) {
                    if (ReportCategories["Hazard"].includes(report.report_type)) {
                        result = false;
                    }
                }

                if (!otherCheckedApplied) {
                    if (ReportCategories["Other"].includes(report.report_type)) {
                        result = false;
                    }
                }
            }

            if (!(!verifiedCheckedApplied && !unverifiedCheckedApplied)) {
                if (!verifiedCheckedApplied) {
                    if (report.verified > 0) {
                        result = false;
                    }
                }

                if (!unverifiedCheckedApplied) {
                    if (report.verified < 1) {
                        result = false;
                    }
                }
            }

            return result;
        },
        [
            hazardCheckedApplied,
            nonviolentCheckedApplied,
            openSourceCheckedApplied,
            orionSourceCheckedApplied,
            orionUserCheckedApplied,
            otherCheckedApplied,
            regionChange,
            selectedTimePeriodApplied,
            unverifiedCheckedApplied,
            verifiedCheckedApplied,
            violentCheckedApplied,
            appliedEventTypes
        ]
    );

    const updateFilter = () => {
        if (regionChange !== selectedRegion) {
            setSelectedRegion(selectedRegion);
            updateRegionChange(selectedRegion);
        }

        updateOrionSourceCheckedApplied(orionSourceChecked);
        updateOrionUserCheckedApplied(orionUserChecked);
        updateOpenSourceCheckedApplied(openSourceChecked);
        updateViolentCheckedApplied(violentChecked);
        updateNonviolentCheckedApplied(nonviolentChecked);
        updateHazardCheckedApplied(hazardChecked);
        updateOtherCheckedApplied(otherChecked);
        updateTimePeriodApplied(selectedTimePeriod);
        updateVerifiedCheckedApplied(verifiedChecked);
        updateUnverifiedCheckedApplied(unverifiedChecked);
        updateAppliedEventTypes(selectedEventTypes);

        if (isMobile && filterChanged) {
            setFilterOpen(false);
        }
    };

    useEffect(() => {
        updateSelectedRegion(currentFilter.selectedRegion);
        setSelectedRegion(currentFilter.selectedRegion);
        updateRegionChange(currentFilter.selectedRegion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_container]);

    const resetFilter = () => {
        updateSelectedRegion(0);
        setSelectedRegion(0);
        updateRegionChange(0);
        updateOrionSourceChecked(false);
        updateOrionUserChecked(false);
        updateOpenSourceChecked(false);
        updateViolentChecked(false);
        updateNonviolentChecked(false);
        updateHazardChecked(false);
        updateOtherChecked(false);
        updateVerifiedChecked(false);
        updateUnverifiedChecked(false);
        updateTimePeriod("28 Day");
        updateOrionSourceCheckedApplied(false);
        updateOrionUserCheckedApplied(false);
        updateOpenSourceCheckedApplied(false);
        updateViolentCheckedApplied(false);
        updateNonviolentCheckedApplied(false);
        updateHazardCheckedApplied(false);
        updateOtherCheckedApplied(false);
        updateVerifiedCheckedApplied(false);
        updateUnverifiedCheckedApplied(false);
        updateTimePeriodApplied("28 Day");
        updateAppliedEventTypes([]);
        updateSelectedEventTypes([]);

        if (isMobile && filterChangedFromDefault) {
            setFilterOpen(false);
        }
    };

    const updateMap = () => {
        if (mapFilterChanged) {
            if (tooltipOpen) {
                dispatch(mapActions.toggleTooltip());
            }
            dispatch(
                filterActions.updateFilter({
                    ...currentFilter,
                    violentIncluded: violentCheckedApplied,
                    nonviolentIncluded: nonviolentCheckedApplied,
                    hazardIncluded: hazardCheckedApplied,
                    otherIncluded: otherCheckedApplied,
                    orionSourceIncluded: true,
                    orionUserIncluded: true,
                    openSourceIncluded: true,
                    selectedRegion: regionChange,
                    myOrganizationIncluded: false,
                    myReportsIncluded: false,
                    timePeriod: selectedTimePeriodApplied as Filter_TimePeriod,
                    verifiedIncluded: verifiedCheckedApplied,
                    unverifiedIncluded: unverifiedCheckedApplied,
                    filteredUsers: [],
                    teamFilter: null,
                    myTeamIncluded: false,
                    eventTypeApplied: appliedEventTypes
                })
            );

            if (mapRegionChange !== regionChange) {
                updateMapRegionChange(regionChange);
                if (regionChange !== 0) {
                    const regionViewportInfo = getDefaultRegionViewportFromId(regionChange, availableRegions);
                    dispatch(mapActions.flyToPoint([regionViewportInfo.longitude, regionViewportInfo.latitude, regionViewportInfo.zoom]));
                } else {
                    if (!current_container || !containers) {
                        return;
                    }
                    const containerViewportInfo = getContainerViewportFromId(current_container, containers);
                    dispatch(mapActions.flyToPoint([containerViewportInfo.longitude, containerViewportInfo.latitude, containerViewportInfo.zoom]));
                }
                // TEMPORARY FIX: flyTo seems to interfere with filter, and this solves it
                dispatch(clientInfoOperations.fetchClientInfo());
            }
        }
    };

    const availableRegions = defaultRegions.filter((region) => region.container_id === current_container);
    if (!availableRegions || !current_container) {
        console.error("Container has no regions? Returning");
        return null;
    }
    const defaultAllRegion: DefaultRegionNoViewport = {
        id: 0,
        name: "All Areas",
        container_id: 0
    };
    const regionSelections = [defaultAllRegion, ...availableRegions];
    const [selectedRegion, updateSelectedRegion] = useState<number>(currentFilter.selectedRegion);

    const availableContainers = useSelectFromRedux((state) => state.cuser.containers);
    if (!availableContainers) {
        console.error("Could not get containers in drawer.");
        return null;
    }

    const setSelectedRegion = (value: number) => {
        updateSelectedRegion(value as number);
    };

    const [filterChanged, updateFilterChanged] = useState<boolean>(false);

    const [filterChangedFromDefault, updateFilterChangedFromDefault] = useState<boolean>(false);

    const [mapFilterChanged, updateMapFilterChanged] = useState<boolean>(false);

    const [appliedFilterChangedFromDefault, updateAppliedFilterChangedFromDefault] = useState<boolean>(false);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            violentIncluded: violentCheckedApplied,
            nonviolentIncluded: nonviolentCheckedApplied,
            hazardIncluded: hazardCheckedApplied,
            otherIncluded: otherCheckedApplied,
            selectedRegion: regionChange,
            orionSourceIncluded: orionSourceCheckedApplied,
            orionUserIncluded: orionUserCheckedApplied,
            openSourceIncluded: openSourceCheckedApplied,
            eventTypeApplied: appliedEventTypes
        };

        const defaultFilter: FilterState = {
            ...currentFilter,
            violentIncluded: false,
            nonviolentIncluded: false,
            hazardIncluded: false,
            otherIncluded: false,
            orionSourceIncluded: false,
            orionUserIncluded: false,
            openSourceIncluded: false,
            selectedRegion: 0,
            eventTypeApplied: []
        };
        updateAppliedFilterChangedFromDefault(!isEqual(changedFilter, defaultFilter));
    }, [
        currentFilter,
        hazardChecked,
        hazardCheckedApplied,
        nonviolentChecked,
        nonviolentCheckedApplied,
        openSourceChecked,
        openSourceCheckedApplied,
        orionSourceChecked,
        orionSourceCheckedApplied,
        orionUserChecked,
        orionUserCheckedApplied,
        otherChecked,
        otherCheckedApplied,
        regionChange,
        selectedRegion,
        violentChecked,
        violentCheckedApplied,
        appliedEventTypes
    ]);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            otherIncluded: otherChecked,
            selectedRegion,
            orionSourceIncluded: orionSourceChecked,
            orionUserIncluded: orionUserChecked,
            openSourceIncluded: openSourceChecked,
            timePeriod: selectedTimePeriod as Filter_TimePeriod,
            verifiedIncluded: verifiedChecked,
            unverifiedIncluded: unverifiedChecked,
            eventTypeApplied: appliedEventTypes
        };

        const defaultFilter: FilterState = {
            ...currentFilter,
            violentIncluded: false,
            nonviolentIncluded: false,
            hazardIncluded: false,
            otherIncluded: false,
            orionSourceIncluded: false,
            orionUserIncluded: false,
            openSourceIncluded: false,
            selectedRegion: 0,
            timePeriod: "28 Day",
            verifiedIncluded: false,
            unverifiedIncluded: false,
            eventTypeApplied: []
        };
        updateFilterChangedFromDefault(!isEqual(changedFilter, defaultFilter));
    }, [
        currentFilter,
        hazardChecked,
        nonviolentChecked,
        openSourceChecked,
        orionSourceChecked,
        orionUserChecked,
        otherChecked,
        selectedRegion,
        selectedTimePeriod,
        unverifiedChecked,
        verifiedChecked,
        violentChecked,
        appliedEventTypes
    ]);

    const [extendValue, updateExtendValue] = useState<number>(0);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            otherIncluded: otherChecked,
            selectedRegion,
            orionSourceIncluded: orionSourceChecked,
            orionUserIncluded: orionUserChecked,
            openSourceIncluded: openSourceChecked,
            timePeriod: selectedTimePeriod as Filter_TimePeriod,
            verifiedIncluded: verifiedChecked,
            unverifiedIncluded: unverifiedChecked,
            eventTypeApplied: selectedEventTypes
        };

        const existingFilter: FilterState = {
            ...currentFilter,
            violentIncluded: violentCheckedApplied,
            nonviolentIncluded: nonviolentCheckedApplied,
            hazardIncluded: hazardCheckedApplied,
            otherIncluded: otherCheckedApplied,
            selectedRegion: regionChange,
            orionSourceIncluded: orionSourceCheckedApplied,
            orionUserIncluded: orionUserCheckedApplied,
            openSourceIncluded: openSourceCheckedApplied,
            timePeriod: selectedTimePeriodApplied as Filter_TimePeriod,
            verifiedIncluded: verifiedCheckedApplied,
            unverifiedIncluded: unverifiedCheckedApplied,
            eventTypeApplied: appliedEventTypes
        };

        updateFilterChanged(!isEqual(changedFilter, existingFilter));
    }, [
        currentFilter,
        hazardChecked,
        hazardCheckedApplied,
        nonviolentChecked,
        nonviolentCheckedApplied,
        openSourceChecked,
        openSourceCheckedApplied,
        orionSourceChecked,
        orionSourceCheckedApplied,
        orionUserChecked,
        orionUserCheckedApplied,
        otherChecked,
        otherCheckedApplied,
        regionChange,
        selectedRegion,
        selectedTimePeriod,
        selectedTimePeriodApplied,
        unverifiedChecked,
        unverifiedCheckedApplied,
        verifiedChecked,
        verifiedCheckedApplied,
        violentChecked,
        violentCheckedApplied,
        appliedEventTypes,
        selectedEventTypes
    ]);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            violentIncluded: violentCheckedApplied,
            nonviolentIncluded: nonviolentCheckedApplied,
            hazardIncluded: hazardCheckedApplied,
            otherIncluded: otherCheckedApplied,
            selectedRegion: regionChange,
            myOrganizationIncluded: false,
            myReportsIncluded: false,
            timePeriod: selectedTimePeriodApplied as Filter_TimePeriod,
            verifiedIncluded: verifiedCheckedApplied,
            unverifiedIncluded: unverifiedCheckedApplied,
            eventTypeApplied: appliedEventTypes
        };
        updateMapFilterChanged(
            !isEqual(changedFilter, currentFilter) ||
                (currentFilter.orionSourceIncluded === false && currentFilter.orionUserIncluded === false && currentFilter.openSourceIncluded === false)
        );
    }, [
        currentFilter,
        hazardChecked,
        hazardCheckedApplied,
        nonviolentChecked,
        nonviolentCheckedApplied,
        openSourceCheckedApplied,
        orionSourceCheckedApplied,
        orionUserCheckedApplied,
        otherChecked,
        otherCheckedApplied,
        regionChange,
        selectedRegion,
        selectedTimePeriodApplied,
        unverifiedCheckedApplied,
        verifiedCheckedApplied,
        violentChecked,
        violentCheckedApplied,
        appliedEventTypes
    ]);

    const setFilterOpen = (value: boolean) => {
        updateFilterOpen(value);
        dispatch(leftModalActions.setFilterOpen(value));
    };

    const publicReportsData = publicReportsQuery.data;

    const publicReportsFiltered = publicReportsData ? publicReportsData.filter((report) => filterReport(report)) : [];

    const setSelectedEventTypes = (value: string, operation: string) => {
        if (operation === "add") {
            if (!selectedEventTypes.includes(value)) {
                const newSelectedEventTypes = selectedEventTypes.concat(value);
                updateSelectedEventTypes(newSelectedEventTypes);
            }
        } else if (operation === "delete") {
            if (selectedEventTypes.includes(value)) {
                const selectedEventTypesCopy = [...selectedEventTypes];
                const index = selectedEventTypesCopy.indexOf(value);
                selectedEventTypesCopy.splice(index, 1);
                updateSelectedEventTypes(selectedEventTypesCopy);
            }
        } else if (operation === "clear") {
            updateSelectedEventTypes([]);
        }
    };

    return (
        <>
            <div
            // style={{ marginBottom: isSafari ? "0" : "0px" }}
            >
                <div
                    style={{
                        cursor: "pointer",
                        fontWeight: "lighter",
                        fontSize: "14px",
                        padding: "10px 0",
                        filter: filterOpen && isMobile ? "blur(4px)" : "none"
                    }}
                >
                    Region: {containers.filter((container) => container.id === current_container)[0].name} ({publicReportsFiltered.length} reports)
                </div>
                {userProfile?.features?.filtering !== false && (
                    <Dot
                        background={colors.scheme.submitButtonBackground}
                        anchorOrigin={{ horizontal: "left", vertical: "top" }}
                        variant={"dot"}
                        color={"primary"}
                        invisible={!appliedFilterChangedFromDefault}
                        style={{
                            position: "absolute",
                            right: "20px",
                            top: "50px"
                        }}
                    >
                        <img
                            src={colors.mode === "light" ? FunnelGreyIcon : FunnelWhiteIcon}
                            alt="Filter"
                            style={{
                                height: "35px",
                                cursor: "pointer",
                                filter: filterOpen && isMobile ? "blur(4px)" : "none"
                            }}
                            onClick={() => setFilterOpen(true)}
                        />
                    </Dot>
                )}
            </div>
            {publicReports && (
                <>
                    <div
                        style={{
                            filter: filterOpen && isMobile ? "blur(4px)" : "none",
                            overflowY: "scroll",
                            maxHeight: "90%",
                            // marginTop: "10px",
                            width: "100%"
                        }}
                    >
                        {publicReportsFiltered.length > 0 ? (
                            <>
                                {publicReportsFiltered.slice(0, 101 + extendValue * 100).map((publicReport) => (
                                    <div style={{ background: colors.scheme.generalCard, borderRadius: "12px" }} key={publicReport.id}>
                                        <ReportSummary
                                            id={publicReport.id}
                                            reportType={publicReport.report_type}
                                            dateTime={publicReport.date_time}
                                            address={publicReport.address}
                                            source={publicReport.source_type}
                                            point={publicReport.point}
                                            verified={publicReport.verified}
                                            onClick={() => updateMap()}
                                            clientId={publicReport.client_id}
                                        />
                                    </div>
                                ))}
                                {publicReportsFiltered.length > 101 + extendValue * 100 && (
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            alignSelf: "center",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            marginTop: "5px",
                                            marginBottom: "105px"
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                updateExtendValue(extendValue + 1);
                                            }}
                                            style={{ width: "200px" }}
                                            active={publicReportsFiltered.length > 101 + extendValue * 100}
                                            color={colors.scheme.submitButtonText}
                                            background={
                                                publicReportsFiltered.length > 101 + extendValue * 100
                                                    ? colors.scheme.submitButtonBackground
                                                    : colors.general.generalNotification
                                            }
                                        >
                                            See next 100
                                        </Button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div style={{ textAlign: "center", padding: "20px" }}>
                                <img
                                    src={colors.mode === "light" ? NoDataGreyIcon : NoDataWhiteIcon}
                                    style={{ height: "22px", marginRight: "10px" }}
                                    alt="no data"
                                />
                                No newsfeed to show.
                            </div>
                        )}
                    </div>
                    <>
                        {colors.mode === "dark" && publicReportsFiltered.slice(0, 101).length > 3 && (
                            <BottomGradient style={{ bottom: "67px" }} background={colors.scheme.modalBackground} />
                        )}
                        <div
                            style={{
                                width: "calc(100% + 40px)",
                                filter: filterOpen && isMobile ? "blur(4px)" : "none",
                                background: colors.scheme.modalBottomBar,
                                margin: "0 -20px -20px -20px",
                                borderRadius: "0 0 14px 14px"
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignSelf: "center",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    marginTop: "5px",
                                    marginBottom: "5px"
                                }}
                            >
                                <Button
                                    style={{ width: "150px" }}
                                    onClick={() => {
                                        updateMap();
                                    }}
                                    active={mapFilterChanged}
                                    color={mapFilterChanged ? colors.scheme.submitButtonText : "#4C4C4C"}
                                    background={mapFilterChanged ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag}
                                >
                                    Apply To Map
                                </Button>
                            </div>
                        </div>
                    </>
                </>
            )}
            {filterOpen && (
                <ComponentFilter
                    setFilterOpen={setFilterOpen}
                    regionSelections={regionSelections}
                    selectedRegion={selectedRegion}
                    updateSelectedRegion={updateSelectedRegion}
                    violentChecked={violentChecked}
                    nonviolentChecked={nonviolentChecked}
                    hazardChecked={hazardChecked}
                    otherChecked={otherChecked}
                    updateViolentChecked={updateViolentChecked}
                    updateNonviolentChecked={updateNonviolentChecked}
                    updateHazardChecked={updateHazardChecked}
                    updateOtherChecked={updateOtherChecked}
                    eventTypeSearchOpen={eventTypeSearchOpen}
                    setSelectedEventTypes={setSelectedEventTypes}
                    updateEventTypeSearchOpen={updateEventTypeSearchOpen}
                    selectedEventTypes={selectedEventTypes}
                    eventChecked={eventChecked}
                    updateEventChecked={updateEventChecked}
                    selectedTimePeriod={selectedTimePeriod}
                    updateTimePeriod={updateTimePeriod}
                    filterChanged={filterChanged}
                    filterChangedFromDefault={filterChangedFromDefault}
                    updateFilter={updateFilter}
                    resetFilter={resetFilter}
                    verifiedChecked={verifiedChecked}
                    updateVerifiedChecked={updateVerifiedChecked}
                    unverifiedChecked={unverifiedChecked}
                    updateUnverifiedChecked={updateUnverifiedChecked}
                />
            )}
        </>
    );
};
