import styled from "styled-components";

export const InsightContentItem = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 0px;
`;

interface TextProps {
    color: string;
}

export const InsightContentTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    color: ${(props: TextProps) => props.color};
`;

export const InsightContentContent = styled.div`
    font-size: 14px;
    font-weight: normal;
    white-space: pre-line;
    color: ${(props: TextProps) => props.color};
`;

export const InsightRegion = styled.div`
    position: relative;
    color: ${(props: TextProps) => props.color};
    font-size: 13px;
    font-style: italic;
    text-align: right;
    margin-bottom: -5px;
    width: 100%;
    padding-right: 10px;
`;

export const InsightsRegionHeader = styled.div`
    position: absolute;
    height: 35px;
    left: 0px;
    width: 100%;
    text-align: -webkit-center;
    top: 50px;
    cursor: pointer;
    font-weight: lighter;
    font-size: 14px;
`;

export const InsightsContainer = styled.div`
    overflow-y: scroll;
    max-height: calc(90% - 60px);
    width: 100%;
    overflow-x: hidden;
`;
