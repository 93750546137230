import React from "react";
import ClusterTooltip from "./clusterTooltip";
import IndividualTooltip from "./individualTooltip";
import GridTooltip from "./gridTooltip";
import { Tooltip } from "./styled";
import { useDispatch } from "react-redux";
import { mapActions } from "../../../state/map";
import { centerOfMass } from "@turf/turf";
import {cellToLatLng} from "h3-js";

import { useSelectFromRedux } from "../../../utils/_hooks";

export default function TooltipComponent() {
    const [{ selectedMarker, showTooltip }] = useSelectFromRedux((state) => [state.map]);
    const colors = useSelectFromRedux((state) => state.color);

    let center = null;
    if (selectedMarker && selectedMarker.risk_index_tmp + 1) {
        center = centerOfMass(selectedMarker.geometry);
    }

    const dispatch = useDispatch();
    const closeTooltip = () => {
        return dispatch(mapActions.toggleTooltip());
    };

    if (!selectedMarker || (!selectedMarker.geometry?.coordinates) && (!selectedMarker.h3_cell)) {
        return null;
    }

    const switchTooltip = () => {
        if (selectedMarker.risk_index) {
            return <GridTooltip />;
        } else if (selectedMarker.properties.cluster) {
            return <ClusterTooltip />;
        } else {
            return <IndividualTooltip />;
        }
    };

    if (selectedMarker.h3_cell) {

        return (
            <>
                {showTooltip && selectedMarker && (
                    <Tooltip
                        latitude={cellToLatLng(selectedMarker.h3_cell)[0]}
                        longitude={cellToLatLng(selectedMarker.h3_cell)[1]}
                        onClose={closeTooltip}
                        offsetTop={-40}
                        background={colors.scheme.modalBackground}
                        color={colors.scheme.primaryText}
                        sidepadding={selectedMarker.cluster ? "0" : "20px"}
                    >
                        {switchTooltip()}
                    </Tooltip>
                )}
            </>
        );
    }

    // if (selectedMarker.h3_cell) {
    //     selectedMarker.geometry = geojson2h3.h3ToFeature(selectedMarker.h3_cell).geometry;
    // }

    return (
        <>
            {showTooltip && selectedMarker && (
                <Tooltip
                    latitude={center ? center.geometry!.coordinates[1] : selectedMarker.geometry.coordinates[1]}
                    longitude={center ? center.geometry!.coordinates[0] : selectedMarker.geometry.coordinates[0]}
                    onClose={closeTooltip}
                    offsetTop={-40}
                    background={colors.scheme.modalBackground}
                    color={colors.scheme.primaryText}
                    sidepadding={selectedMarker.cluster ? "0" : "20px"}
                >
                    {switchTooltip()}
                </Tooltip>
            )}
        </>
    );
}

