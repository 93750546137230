import React from "react";
import moment from "moment";
import { getUsernameFromId, S3Key } from "../../../utils";
import { Point } from "geojson";
import { isMobile } from "react-device-detect";
// redux
import { useSelectFromRedux } from "../../../utils/_hooks";
import { mapActions } from "../../../state/map";
import { useDispatch } from "react-redux";
import { individualReportActions } from "../../../state/individual-report";
import { leftModalActions } from "../../../state/left-modal";
// components
import { DetailsButton, EventDate, EventType, IconContainer, ReportContentItem, ReportItemContainer, ReportItemHeader, TextWithIconContainer } from "./styled";

const CalendarGreyIcon = S3Key + "calendar-grey.png";
const CalendarWhiteIcon = S3Key + "calendar-white.png";
const LocationGreyIcon = S3Key + "location-grey.png";
const LocationWhiteIcon = S3Key + "location-white.png";
const TimeGreyIcon = S3Key + "time-grey.png";
const TimeWhiteIcon = S3Key + "time-white.png";
const UserGreyIcon = S3Key + "user-dark.png";
const UserWhiteIcon = S3Key + "user-white.png";
const UnverifiedIcon = S3Key + "open-circle-amber.png";
const VerifiedIcon = S3Key + "check_mark.png";
const MedicWhiteIcon = S3Key + "medic-white.png";
const MedicGreyIcon = S3Key + "medic-grey.png";
const TeamsGreyIcon = S3Key + "teams-grey.png";
const TeamsWhiteIcon = S3Key + "teams-white.png";

interface ReportSummaryProps {
    id: number;
    reportType?: string;
    dateTime: Date;
    address?: string | undefined;
    source: string | number;
    point: Point;
    confirmed?: number;
    verified?: number;
    sos?: boolean;
    userId?: number;
    onClick: () => void;
    fromTeams?: boolean;
    nineLiner?: boolean;
    mistReports?: number;
    endedAt?: Date | null;
    confirmedAt?: Date | null;
    team_id?: number | null;
    clientId?: number | null;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
    id,
    reportType,
    dateTime,
    address,
    source,
    point,
    confirmed,
    verified,
    sos,
    userId,
    onClick,
    fromTeams,
    nineLiner,
    mistReports,
    endedAt,
    confirmedAt,
    team_id,
    clientId
}: ReportSummaryProps) => {
    const dispatch = useDispatch();
    const clientUsers = useSelectFromRedux((state) => state.clientInfo.users);
    const userProfile = useSelectFromRedux((state) => state.cuser.userProfile);
    const showTooltip = useSelectFromRedux((state) => state.map.showTooltip);
    const modalContent = useSelectFromRedux((state) => state.leftModal.modalContent);
    const reportsSetting = useSelectFromRedux((state) => state.reports.reportsSetting);
    const colors = useSelectFromRedux((state) => state.color);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const clientProfile = useSelectFromRedux((state) => state.cuser.clientProfile);

    const getSource = (source: string | number) => {
        if (clientProfile && clientProfile.id === clientId && typeof source === "number" && userProfile && clientUsers) {
            const username = getUsernameFromId(source, userProfile, clientUsers);
            return username;
        } else if (source === "VAMP") {
            return "Atlas Source";
        } else if (source === "Client") {
            if (clientProfile && clientProfile.id === clientId) {
                return clientProfile.name + " User";
            } else {
                return "Atlas Source";
            }
        } else if (source === "AAMP") {
            return "Atlas Source";
        } else if (source === "Open Source") {
            return "Atlas Source";
        } else {
            return "Atlas Source";
        }
    };

    const showIndividualReport = () => {
        onClick();
        dispatch(mapActions.flyToPoint([point.coordinates[0], point.coordinates[1] + (isMobile ? 0.003 : 0), 15]));
        dispatch(
            mapActions.renderTooltip({
                geometry: {
                    coordinates: point.coordinates
                },
                properties: {
                    cluster: false,
                    title: reportType ? (sos ? "SOS: " : "") + reportType : sos ? "SOS" : "Event",
                    postedBy: getSource(source),
                    verified
                }
            })
        );
        if (!showTooltip) {
            dispatch(mapActions.toggleTooltip());
        }
        if (!fromTeams) {
            if (modalContent === "reports") {
                if (reportsSetting === "reports") {
                    dispatch(individualReportActions.setCurrentReportId(Number(id)));
                    dispatch(individualReportActions.setCurrentReportType("client"));
                } else {
                    dispatch(individualReportActions.setCurrentReportId(Number(id)));
                    dispatch(individualReportActions.setCurrentReportType("client-alert"));
                }
            } else {
                dispatch(individualReportActions.setCurrentReportId(Number(id)));
                dispatch(individualReportActions.setCurrentReportType("public"));
            }
        }
        dispatch(leftModalActions.setModalContent({ modalContent: "individual-report" }));
    };

    const getTeamNameFromTeamId = (teamId: number | null | undefined) => {
        if (teamId === null || teamId === undefined) {
            return "Default Group";
        }
        const team = teams.filter((team) => team.id === teamId);
        if (team[0]) {
            if (team[0].permissions === 1 && userProfile?.team_id === null && userProfile.role === 1) {
                return team[0].name + " (Private)";
            } else {
                return team[0].name;
            }
        } else {
            return "Group Not Found";
        }
    };

    return (
        <ReportItemContainer key={id} background={colors.scheme.generalCard}>
            <ReportItemHeader>
                <EventType>{reportType ? (sos ? "SOS: " : "") + reportType : sos ? "SOS" : "Event"}</EventType>
                <EventDate>
                    <img src={colors.mode === "light" ? CalendarGreyIcon : CalendarWhiteIcon} alt="Calendar" style={{ height: "12px", marginRight: "7px" }} />
                    {moment(dateTime).format("MM.DD.YYYY")}
                </EventDate>
            </ReportItemHeader>
            <ReportContentItem onClick={() => showIndividualReport()} style={{ cursor: "pointer" }}>
                <IconContainer>
                    <img src={colors.mode === "light" ? LocationGreyIcon : LocationWhiteIcon} alt="Location" style={{ height: "12px" }} />
                </IconContainer>
                {address && <TextWithIconContainer style={{ textDecoration: "underline" }}>{address}</TextWithIconContainer>}
                {!address && <TextWithIconContainer style={{ textDecoration: "underline" }}>{point.coordinates.toLocaleString()}</TextWithIconContainer>}
            </ReportContentItem>
            <ReportContentItem>
                <IconContainer>
                    <img src={colors.mode === "light" ? TimeGreyIcon : TimeWhiteIcon} alt="Time" style={{ height: "12px" }} />
                </IconContainer>
                <TextWithIconContainer>{moment(dateTime).format("HH:mm")}</TextWithIconContainer>
            </ReportContentItem>
            <ReportContentItem>
                <IconContainer>
                    <img src={colors.mode === "light" ? UserGreyIcon : UserWhiteIcon} alt="User" style={{ height: "12px" }} />
                </IconContainer>
                <TextWithIconContainer>{getSource(source)}</TextWithIconContainer>
            </ReportContentItem>
            {team_id && (
                <ReportContentItem>
                    <IconContainer>
                        <img src={colors.mode === "light" ? TeamsGreyIcon : TeamsWhiteIcon} alt="User" style={{ height: "12px" }} />
                    </IconContainer>
                    <TextWithIconContainer>{getTeamNameFromTeamId(team_id as number)}</TextWithIconContainer>
                </ReportContentItem>
            )}
            {(Boolean(nineLiner) || Boolean(mistReports)) && (
                <ReportContentItem style={{ marginBottom: "0" }}>
                    <IconContainer>
                        <img src={colors.mode === "light" ? MedicGreyIcon : MedicWhiteIcon} alt="Medical" style={{ height: "12px" }} />
                    </IconContainer>
                    <TextWithIconContainer>
                        {nineLiner && !!mistReports && "Nine Liner & " + mistReports + " MIST Report(s)"}
                        {nineLiner && !!!mistReports && "Nine Liner"}
                        {!nineLiner && !!mistReports && mistReports + " MIST Report(s)"}
                    </TextWithIconContainer>
                </ReportContentItem>
            )}
            {(confirmed === 0 || verified === 0 || confirmed === -1) && (
                <>
                    {confirmed === -1 && (
                        <ReportContentItem style={{ marginBottom: "0" }}>
                            <IconContainer style={{ alignItems: "flex-start", marginTop: "5px" }}>
                                <div
                                    style={{
                                        borderRadius: "50%",
                                        height: "10px",
                                        width: "10px",
                                        background: "#EFA32D",
                                        marginTop: "1px"
                                    }}
                                />
                            </IconContainer>
                            <TextWithIconContainer>{endedAt ? "Ended at " + moment(endedAt).format("HH:mm") : "Ended"}</TextWithIconContainer>
                        </ReportContentItem>
                    )}
                    {sos && confirmed === 0 && (
                        <ReportContentItem style={{ marginBottom: "0" }}>
                            <IconContainer style={{ alignItems: "flex-start", marginTop: "5px" }}>
                                <div
                                    style={{
                                        borderRadius: "50%",
                                        height: "10px",
                                        width: "10px",
                                        background: "#e85454",
                                        marginTop: "1px"
                                    }}
                                />
                            </IconContainer>
                            <TextWithIconContainer>Active</TextWithIconContainer>
                        </ReportContentItem>
                    )}
                    {!sos && verified === 0 && (
                        <ReportContentItem style={{ marginBottom: "0" }}>
                            <IconContainer>
                                <img src={UnverifiedIcon} alt="Unverified" style={{ height: "15px", marginTop: "-2px" }} />
                            </IconContainer>
                            <TextWithIconContainer>Unverified</TextWithIconContainer>
                        </ReportContentItem>
                    )}
                </>
            )}
            {((confirmed ?? 0) >= 1 || (verified ?? 0) >= 1) && (
                <ReportContentItem style={{ marginBottom: "0" }}>
                    <IconContainer style={{ alignItems: "flex-start", marginTop: "5px" }}>
                        {sos && (confirmed ?? 0) >= 1 && (
                            <div
                                style={{
                                    borderRadius: "50%",
                                    height: "10px",
                                    width: "10px",
                                    background: colors.general.standardGreen,
                                    marginTop: "1px"
                                }}
                            />
                        )}
                        {!sos && (verified ?? 0) >= 1 && <img src={VerifiedIcon} alt="Verified" style={{ height: "15px", marginTop: "-2px" }} />}
                    </IconContainer>
                    <TextWithIconContainer>
                        {sos && userProfile && userId ? (
                            <>
                                {endedAt && "Ended at " + moment(endedAt).format("HH:mm")}
                                {endedAt && confirmed && confirmed > 1 && confirmedAt && <br />}
                                {confirmed &&
                                    (confirmed > 1 || confirmed === -1) &&
                                    confirmedAt &&
                                    "Confirmed by " + getUsernameFromId(confirmed, userProfile, clientUsers) + " at " + moment(confirmedAt).format("HH:mm")}
                                {endedAt === null && confirmedAt === null && "Ended by User"}
                            </>
                        ) : (
                            "Verified"
                        )}
                    </TextWithIconContainer>
                </ReportContentItem>
            )}
            <DetailsButton color={colors.scheme.secondaryHeaderText} onClick={() => showIndividualReport()}>
                tap for more details
            </DetailsButton>
        </ReportItemContainer>
    );
};
