import styled from "styled-components";

export const TitleText = styled.div`
    font-size: 22px;
    color: ${({ theme }) => theme.text};
    line-height: 1.2em;
`;

export const BoldTitleText = styled(TitleText)`
    font-weight: 600;
`;

export const SubTitleText = styled(TitleText)`
    font-size: 18px;
`;

export const LightSubTitleText = styled(SubTitleText)`
    opacity: 0.6;
`;

export const BoldSubTitleText = styled(SubTitleText)`
    font-weight: 600;
`;

export const ContentText = styled.div`
    font-size: 15px;
    color: ${({ theme }) => theme.text};
`;

export const CenteredContentText = styled(ContentText)`
    text-align: center;
`;

export const BoldContentText = styled(ContentText)`
    font-weight: 600;
`;

export const LightContentText = styled(ContentText)`
    font-size: 15px;
    opacity: 0.6;
`;

export const SmallText = styled.div`
    font-size: 13px;
    color: ${({ theme }) => theme.text};
    line-height: 1.2em;
`;

export const BoldSmallText = styled(SmallText)`
    font-weight: 600;
`;

export const LightSmallText = styled(SmallText)`
    opacity: 0.6;
`;

export const PurpleSmallText = styled(SmallText)`
    color: ${({ theme }) => theme.purpleText};
`;

export const ExtraSmallText = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.text};
`;

export const PurpleExtraSmallText = styled(ExtraSmallText)`
    color: ${({ theme }) => theme.purpleText};
    line-height: 1.2em;
`;

export const LargeText = styled.div`
    font-size: 36px;
    color: ${({ theme }) => theme.text};
    letter-spacing: -2px;
    margin-top: -5px;
    margin-bottom: -10px;
`;

export const MediumText = styled.div`
    font-size: 34px;
    color: ${({ theme }) => theme.text};
    letter-spacing: -2px;
    margin-top: -5px;
    margin-bottom: -10px;
`;

export const ExtraSmallIcon = styled.img`
    width: 14px;
`;

export const SmallIcon = styled.img`
    width: 16px;
`;

export const MediumIcon = styled.img`
    width: 19px;
`;

export const LargeIcon = styled.img`
    width: 22px;
`;

export const ExtraLargeIcon = styled.img`
    width: 25px;
`;
