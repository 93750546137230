import styled from "styled-components";

export const ExtraSmallSpacer = styled.div`
    min-width: 5px;
    width: 5px;
    min-height: 5px;
    height: 5px;
`;

export const SmallSpacer = styled.div`
    min-width: 10px;
    width: 10px;
    min-height: 10px;
    height: 10px;
`;

export const MediumSpacer = styled.div`
    min-width: 15px;
    width: 15px;
    min-height: 15px;
    height: 15px;
`;

export const LargeSpacer = styled.div`
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
`;

export const FlexCenterCenterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FlexStartCenterContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexBetweenCenterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ModalGhostContent = styled.div`
    padding: 0px 30px;
`;

export const Card = styled.div`
    background: ${({ theme }) => theme.generalCard};
    border-radius: 12px;
    margin: 12px 0px;
    padding: 13px 17px;

    :last-child {
        margin-bottom: 0px;
    }
`;
