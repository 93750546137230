import React from "react";
import { useDispatch } from "react-redux";
import { useSelectFromRedux } from "../../utils/_hooks";
import { makeStyles } from "@material-ui/core/styles";
import { visualizationsActions } from "../../state/visualizations";
import { isMobile } from "react-device-detect";
import { Slide } from "./styled";
import Slider, { SliderMarks } from "antd/lib/slider";
import styled from "styled-components";

const useStyles = makeStyles({
    root: {
        width: 300,
        display: "flex",
        marginTop: 12,
        flexDirection: "column",
        alignItems: "center"
    },
    text: {
        fontFamily: "IBM Plex Sans",
        color: (props: any) => props.theme.scheme.primaryText
    },
    markLabel: {
        color: (props: any) => props.theme.scheme.primaryText,
        fontFamily: "IBM Plex Sans"
    },
    rail: {
        color: (props: any) => props.theme.scheme.primaryButton
    }
});

const marks: SliderMarks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5"
};

export default function RiskIndexFilter(props: any) {
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const { riskIndexInfoOpen, riskIndexFilter } = useSelectFromRedux((state) => state.visualizations);
    const colors = useSelectFromRedux((state) => state.color);

    // Initialize local state with redux filter value to set default for slider component, but only on first render
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [initializeFilter, updateFilter] = useState<number[]>([riskIndexFilter[0], riskIndexFilter[1]]);

    const riskIndexMarks = [
        { value: 0, label: "1" },
        { value: 20, label: "2" },
        { value: 2, label: "3" },
        { value: 3, label: "4" },
        { value: 4, label: "5" }
    ];

    return (
        <>
            <div className={classes.root} style={{ padding: isMobile ? "20px 0 30px" : "20px 0 10px" }}>
                <div
                    style={{
                        color: colors.scheme.primaryText,
                        fontWeight: "bold",
                        marginBottom: "5px",
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    Risk Index
                    <img
                        src={
                            colors.mode === "light"
                                ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/info-grey.png"
                                : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/info-white.png"
                        }
                        alt="Filter"
                        style={{
                            height: "16px",
                            cursor: "pointer",
                            marginLeft: "5px"
                        }}
                        onClick={() => dispatch(visualizationsActions.toggleRiskIndexInfoOpen(!riskIndexInfoOpen))}
                    />
                </div>
                <Slidey
                    range
                    marks={marks}
                    min={1}
                    max={5}
                    defaultValue={[1, 5]}
                    step={null}
                    onChange={(e) => dispatch(visualizationsActions.updateRiskIndexFilter(e as number[]))}
                    tooltipVisible={false}
                />
            </div>
        </>
    );
}

const Slidey = styled(Slider)`
    width: 100%;

    .ant-slider-mark-text {
        color: ${({ theme }) => theme.primaryText} !important;
    }
`;
