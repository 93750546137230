import { rootReducer } from ".";
import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import * as Sentry from "@sentry/react";

// prettier-ignore
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  });

const composeEnhancers =
    // @ts-ignore
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        // prettier-ignore
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
    compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware), sentryReduxEnhancer));

export default store;
