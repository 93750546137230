import React, { useLayoutEffect, useState, useEffect } from "react";
import { AppState, PromiseDispatch } from "../state";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

interface RefObject<T> {
    readonly current: T | null;
}

export const useSelectFromRedux = <ReturnType>(selector: (state: AppState) => ReturnType) => useSelector<AppState, ReturnType>(selector, isEqual);

export const useDispatchPromise = () => useDispatch<PromiseDispatch>();

export const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: (event: React.ChangeEvent) => void) => {
    useEffect(() => {
        const listener = (event: any) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
};

export const useIsMobile = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    const screenWidth = size[0];
    if (screenWidth < 576) {
        return "mobile";
    } else {
        return "desktop";
    }
};
