import * as Redux from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ActionCreator } from "redux";

// import { default as someOtherModel, SomeOtherModelState, SomeOtherModelAction } from "./some-other-model";
import { default as map, MapState } from "./map";
import { default as leftDrawer, LeftDrawerState, LeftDrawerAction } from "./left-drawer";
import { default as cuser, CuserState, CuserAction } from "./cuser";
import { default as cuserPanel, CuserPanelState, CuserPanelAction } from "./cuser-panel";
import { default as clientInfo, ClientInfoState, ClientInfoActions } from "./clientInfo";
import { default as notifications, NotificationState, NotificationActions } from "./notification";
import { default as publicInfo, PublicInfoState, PublicInfoActions } from "./publicInfo";
import { default as filter, FilterState, FilterActions } from "./filter";
import { default as visualizations, VisualizationsState, VisualizationsActions } from "./visualizations";
import { default as error, ToastState, ToastAction } from "./toast";
import { default as leftModal, LeftModalState, LeftModalAction } from "./left-modal";
import { default as reports, ReportsState, ReportsAction } from "./reports";
import { default as timeFilter, TimeFilterState, TimeFilterAction } from "./time-filter";
import { default as updateReport, UpdateReportAction, UpdateReportState } from "./update-report";
import { default as individualReport, IndividualReportState, IndividualReportAction } from "./individual-report";
import { default as analytics, AnalyticsState, AnalyticsAction } from "./analytics";
import { default as color, ColorState, ColorAction } from "./color";
import { default as teams, TeamsState, TeamsAction } from "./teams";

// import { default as someOtherModel, SomeOtherModelState, SomeOtherModelAction } from "./some-other-model";

export type CustomAction =
    | LeftDrawerAction
    | CuserPanelAction
    | CuserAction
    | ClientInfoActions
    | NotificationActions
    | PublicInfoActions
    | FilterActions
    | VisualizationsActions
    | ToastAction
    | LeftModalAction
    | ReportsAction
    | UpdateReportAction
    | IndividualReportAction
    | ColorAction
    | TimeFilterAction
    | TeamsAction
    | AnalyticsAction;
// | SomeOtherModelAction

export interface AppState {
    map: MapState;
    leftDrawer: LeftDrawerState;
    cuser: CuserState;
    cuserPanel: CuserPanelState;
    clientInfo: ClientInfoState;
    notifications: NotificationState;
    publicInfo: PublicInfoState;
    filter: FilterState;
    visualizations: VisualizationsState;
    error: ToastState;
    leftModal: LeftModalState;
    reports: ReportsState;
    updateReport: UpdateReportState;
    individualReport: IndividualReportState;
    color: ColorState;
    timeFilter: TimeFilterState;
    teams: TeamsState;
    analytics: AnalyticsState;
    // someOtherModel: SomeOtherModelState;
}

const appReducer = Redux.combineReducers({
    map,
    leftDrawer,
    cuser,
    cuserPanel,
    clientInfo,
    notifications,
    publicInfo,
    filter,
    timeFilter,
    visualizations,
    error,
    leftModal,
    reports,
    updateReport,
    individualReport,
    color,
    teams,
    analytics
});

export const rootReducer = (state: any, action: any) => {
    if (action.type === "RESET_APP") {
        state = undefined;
    }

    return appReducer(state, action);
};

export const unreachableCode = (never: never): never => {
    throw new Error("This code should NEVER be called.");
};

export type AsyncAction = ThunkAction<void, AppState, never, CustomAction>;
// operations are async action creators
export type Operation = ActionCreator<AsyncAction>;

export type AsyncPromiseAction<T> = ThunkAction<Promise<T>, AppState, never, CustomAction>;
export type PromiseOperation<T> = ActionCreator<AsyncPromiseAction<T>>;
export type PromiseDispatch = ThunkDispatch<AppState, never, CustomAction>;
