import styled from "styled-components";
import { RoundButton } from "../_shared";
import { boxShadow, activeSubmitButtonBackground } from "../../utils";
import { device } from "../../utils/index";
import Select from "antd/lib/select";
import DatePicker from "antd/lib/date-picker";
import TimePicker from "antd/lib/time-picker";

export const AddReportCloseButton = styled.img`
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    height: 22px;
`;

interface AddReportButtonProps {
    background: string;
}

export const AddReportButton = styled(RoundButton)`
    background: ${(props: AddReportButtonProps) => props.background};
    width: 50px;
    height: 50px;
    bottom: 120px;
    right: 30px;

    :active {
        width: 52px;
        height: 52px;
        bottom: 119px;
        right: 29px;
    }

    @media ${device.mobile} {
        bottom: 25px;
        left: 25px;

        :active {
            width: 52px;
            height: 52px;
            bottom: 24px;
            right: 24px;
        }
    }
`;

export const PlusImage = styled.img`
    height: 27px;
`;

interface AddReportPanelProps {
    backgroundColor: string;
}

export const AddReportPanel = styled.div`
    width: 360px;
    max-height: calc(100vh - 190px);
    background: ${(props: AddReportPanelProps) => props.backgroundColor};
    position: fixed;
    bottom: 120px;
    right: 100px;
    z-index: 30;
    border-radius: 18px;
    padding: 0px 9px;
    display: flex;
    flex-direction: column;
    transition: 0.8s;
    box-shadow: 0px 4px 6px ${boxShadow};
    @media ${device.mobile} {
        width: 100vw;
        max-height: calc(100vh - 150px);
        border-radius: 0px 0px 14px 14px;
        position: absolute;
        top: 0;
        bottom: auto;
        left: 0;
        display: flex;
    }
`;

interface FormFieldHeadingProps {
    color: string;
}

export const FormFieldHeading = styled.div`
    font-size: 14px;
    color: ${(props: FormFieldHeadingProps) => props.color};
    width: 100%;
    margin-bottom: 15px;
`;

interface IconsContainerProps {
    background: string;
}

export const IconsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
    background: ${(props: IconsContainerProps) => props.background};
`;

interface IconButtonProps {
    selected: boolean;
}

export const RoundIconButton = styled.div`
    height: 45px;
    width: 45px;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    background: white;
    border: ${(props: IconButtonProps) => (props.selected ? "3px solid white" : "1px solid white")};
    box-shadow: ${(props: IconButtonProps) => (props.selected ? "0 0 10px white, 0 0 5px white, 0 0 3px white" : "none")};
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props: IconButtonProps) => (props.selected ? 1 : 1)};
`;

export const RoundIconImage = styled.img`
    height: 45px;
`;

export const DropdownContainer = styled.div`
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
`;

interface SubmitButtonProps {
    color: string;
    background: string;
    tapColor?: string;
}

export const SubmitButton = styled.div`
    display: flex;
    height: 40px;
    width: 80%;
    border-radius: 18px;
    background: ${(props: SubmitButtonProps) => props.background};
    color: ${(props: SubmitButtonProps) => props.color};
    margin: 30px 10% 15px;
    text-transform: uppercase;
    font-weight: 600;
    justify-content: center;
    align-items: center;

    :active {
        background: ${(props: SubmitButtonProps) => (props.tapColor ? props.tapColor : activeSubmitButtonBackground)};
    }

    :hover {
        cursor: pointer;
    }
`;

interface DatePickerProps {
    color: string;
}

export const StyledDatePicker = styled(DatePicker)`
    color: ${(props: DatePickerProps) => props.color};
    font-size; 14px;
    width: 45%;
    .ant-picker-input > input {
        color: ${(props: DatePickerProps) => props.color} !important;
        text-align: center;
    }
    ::placeholder {
        color: ${(props: DatePickerProps) => props.color} !important;
    }
    .anticon {
        display: none;
    }
`;

interface TimePickerProps {
    color: string;
}

export const StyledTimePicker = styled(TimePicker)`
    color: ${(props: TimePickerProps) => props.color};
    font-size; 14px;
    width: 45%;
    .ant-picker-input > input {
        color: ${(props: TimePickerProps) => props.color} !important;
        text-align: center;
    }
    ::placeholder {
        color: ${(props: TimePickerProps) => props.color} !important;
    }
    .anticon {
        display: none;
    }
`;

export const FormContainer = styled.div`
    max-height: calc(100vh-100px);
    overflow-y: scroll;
    padding-left: 19px;
    padding-right: 19px;
    margin-bottom: 10px;
    @media ${device.mobile} {
        width: 100%;
    }
`;

export const CloseButtonSpacer = styled.div`
    width: 100%;
    height: 20px;
`;

interface ClearContainerProps {
    color: string;
}

export const ClearContainer = styled.div`
    margin-top: 5px;
    color: ${(props: ClearContainerProps) => props.color};
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
`;

export const ErrorMessage = styled.div`
    font-size: 12px;
    color: red;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
`;

interface DropdownProps {
    background: string;
    color: string;
    arrowcolor?: string;
}

export const Dropdown = styled(Select)`
    width: 100px;
    margin-top: 2px;
    color: ${(props: DropdownProps) => props.color};
    font-size: 14px;
    text-align: center;
    .ant-select-selector {
        background: ${(props: DropdownProps) => props.background} !important;
        border: none !important;
        border-radius: 25px !important;
    }
    .ant-select-arrow {
        color: ${(props: DropdownProps) => props.arrowcolor ?? "white"};
        pointer-events: none;
    }
`;

export const IconSection = styled.div`
    margin-left: 20%;
    padding-left: 10px;
    scroll-snap-align: start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: calc(100% + 10px);
`;

export const MilitaryIconSection = styled.div`
    margin-left: 20%;
    padding-left: 10px;
    scroll-snap-align: start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 5px 30px 5px 30px;
    justify-content: space-evenly;
    height: 250px;
    align-items: center;
    min-width: calc(100% + 40px);
`;

interface UploadPhotoLabelProps {
    labelBackground: string;
}

export const UploadPhotoLabel = styled.label`
    background: ${(props: UploadPhotoLabelProps) => props.labelBackground};
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    color: #263245;
    border-radius: 22px;
`;

export const PhotoDisplayContainer = styled.div`
    width: 100%;
    background: #e8e8ea;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
