import React, { useState, useRef, useEffect } from "react";
import { AllReportTypes, ReportCategories, reportType } from "../../types/reports";
import { useSelectFromRedux } from "../../utils/_hooks";
import { DefaultRegion } from "../../types/geo";
import { S3Key } from "../../utils";
import { isMobile, isIOS } from "react-device-detect";
import { useDispatch } from "react-redux";
import { FilterContainer } from "../_shared";
import Select from "antd/lib/select";
import { Button, Dropdown, StyledTag } from "../filter/styled";
import { leftModalActions } from "../../state/left-modal";
import { PerCategoryStats, CategoryStats, ThirtyDayCount } from "../../types/analytics";
import { analyticsActions } from "../../state/analytics";
import { FilterState, filterActions, Filter_TimePeriod } from "../../state/filter";
import { isEqual } from "lodash";
import { ShortTextInputWithPlaceholderColor, ShortTextInputAutoComplete } from "../update-report/styled";
import RefreshWhiteIcon from "./refresh-white.png";
import { Toggle } from "../user/styled";
const { Option } = Select;

const CloseWhiteIcon = S3Key + "close-white.png";
const CloseGreyIcon = S3Key + "close-dark.png";
const SearchGreyIcon = S3Key + "search-grey.png";

export interface DefaultRegionNoViewport {
    id: number;
    name: string;
    container_id: 0;
}

export const Filter = () => {
    const dispatch = useDispatch();
    const colors = useSelectFromRedux((state) => state.color);
    const availableContainers = useSelectFromRedux((state) => state.cuser.containers);
    const { defaultRegions } = useSelectFromRedux((state) => state.cuser);
    const currentFilter = useSelectFromRedux((state) => state.filter);
    const defaultAllRegion: DefaultRegionNoViewport = {
        id: 0,
        name: "All Areas",
        container_id: 0
    };
    const currentAnalyticsContainer = useSelectFromRedux((state) => state.analytics.currentContainer);
    // eslint-disable-next-line
    const [containerChange, updateContainerChange] = useState<number>(currentAnalyticsContainer as number);
    const [newContainer, updateNewContainer] = useState<number>(currentAnalyticsContainer as number);
    const [filterChanged, updateFilterChanged] = useState<boolean>(false);
    const [filterChangedFromDefault, updateFilterChangedFromDefault] = useState<boolean>(false);
    const [eventTypeSearchOpen, updateEventTypeSearchOpen] = useState<boolean>(false);
    const [selectedEventTypes, updateSelectedEventTypes] = useState<string[]>(currentFilter.eventTypeApplied ?? []);
    const [eventChecked, updateEventChecked] = useState<boolean>(false);
    const [historicalChecked, updateHistoricalChecked] = useState<boolean>(currentFilter.showTimeFilter ?? false);
    const availableRegions = defaultRegions.filter((region) => region.container_id === currentAnalyticsContainer);
    const regionSelections = [defaultAllRegion, ...availableRegions];
    const cuser = useSelectFromRedux((state) => state.cuser);
    const { showTimeFilter } = useSelectFromRedux((state) => state.filter);

    const updateCurrentContainerAndRegions = (container: number) => {
        updateNewContainer(container);
        dispatch(analyticsActions.setSelectedRegion(0));
        updateNewRegion(0);
    };

    const {
        filterLocked,
        selectedRegion,
        data,
        timeRange,
        violentChecked,
        nonviolentChecked,
        hazardChecked,
        filterOpen,
        violentCheckedApplied,
        nonviolentCheckedApplied,
        hazardCheckedApplied,
        regionChange,
        timeRangeApplied,
        violentCheckedEffect,
        nonviolentCheckedEffect,
        hazardCheckedEffect,
        blockUpdate,
        totalReports,
        appliedEventTypes
    } = useSelectFromRedux((state) => state.analytics);
    const [newRegion, updateNewRegion] = useState<number>(selectedRegion);

    const violentCheckedAppliedRef = useRef(violentCheckedApplied);
    violentCheckedAppliedRef.current = violentCheckedApplied;
    const nonviolentCheckedAppliedRef = useRef(nonviolentCheckedApplied);
    nonviolentCheckedAppliedRef.current = nonviolentCheckedApplied;
    const hazardCheckedAppliedRef = useRef(hazardCheckedApplied);
    hazardCheckedAppliedRef.current = hazardCheckedApplied;
    const timeRangeRef = useRef(timeRange);
    timeRangeRef.current = timeRange;

    let eventTypeNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    AllReportTypes.filter((reportType: string) => reportType !== "Other").map((reportType: string) => ({
                        value: reportType,
                        key: reportType
                    }))
                )
            ),
        []
    );

    const filterPerCategoryStats = (stats: PerCategoryStats | CategoryStats): boolean => {
        let result = true;

        if (stats.category === "Other") {
            result = false;
        }

        if (!(!violentCheckedApplied && !nonviolentCheckedApplied && !hazardCheckedApplied)) {
            if (!violentCheckedApplied) {
                if (stats.category === "Violent") {
                    result = false;
                }
            }

            if (!nonviolentCheckedApplied) {
                if (stats.category === "Non-Violent") {
                    result = false;
                }
            }

            if (!hazardCheckedApplied) {
                if (stats.category === "Hazard") {
                    result = false;
                }
            }
        }

        if (appliedEventTypes.length > 0 && stats.category) {
            if (!appliedEventTypes.includes(stats.category)) {
                result = false;
            }
        }

        return result;
    };

    const filterTimeRangeArray = (newTimeRange: "24 Hour" | "7 Day" | "28 Day" | null): ThirtyDayCount[] => {
        var result: ThirtyDayCount[] = [];

        let dataUsed = data?.perCategoryStats;

        if (newTimeRange === "24 Hour") {
            dataUsed = data?.pastDayHourlyCategoryStats;
        } else if (newTimeRange === "7 Day") {
            dataUsed = data?.perCategoryStats?.slice(data?.perCategoryStats.length - 28, data?.perCategoryStats.length);
            if (Array.isArray(dataUsed) && dataUsed!.length > 1) {
                let dateString = data?.perCategoryStats![data?.perCategoryStats!.length - 1].dateTime!.replace(/\s/g, "T")!; // Get today's dateDtring from array
                let theDate: Date = new Date(dateString); // create new Date
                theDate.setDate(theDate.getDate() - 7); // get date from week ago
                dataUsed = data?.perCategoryStats?.filter((it) => new Date(it.dateTime!.replace(/\s/g, "T")!).getTime() > theDate.getTime()); // get list of stats for past week
            }
        } else if (newTimeRange === "28 Day") {
            dataUsed = data?.perCategoryStats;
        } else {
            dataUsed = data?.allTimeCategoryStatsPerWeek;
        }

        dataUsed
            ?.filter((entry) => filterPerCategoryStats(entry))
            .forEach((entry: PerCategoryStats) => {
                if (result.some((data: ThirtyDayCount) => entry.dateTime === data.dateTime)) {
                    const existingEntry = result.filter((data: ThirtyDayCount) => entry.dateTime === data.dateTime)[0];
                    if (!existingEntry.count) {
                        existingEntry.count = entry.count ?? 0;
                    } else {
                        existingEntry.count += entry.count ?? 0;
                    }
                } else {
                    result.push({
                        count: entry.count,
                        dateTime: entry.dateTime,
                        dayName: entry.day,
                        checkInfo: () => true
                    });
                }
            });
        return result;
    };

    const updateFilter = () => {
        if (historicalChecked !== showTimeFilter) {
            dispatch(filterActions.toggleHeatmap(historicalChecked));
            dispatch(
                filterActions.updateFilter({
                    ...currentFilter,
                    otherIncluded: false,
                    myOrganizationIncluded: false,
                    myReportsIncluded: false,
                    orionUserIncluded: false,
                    orionSourceIncluded: false,
                    openSourceIncluded: false,
                    timePeriod: timeRange as Filter_TimePeriod
                })
            );
        }

        if (filterChanged) {
            dispatch(analyticsActions.setData(null));
            dispatch(analyticsActions.updateTotalReports(0));
            dispatch(analyticsActions.setCurrentContainer(newContainer));
            dispatch(analyticsActions.setContainerChange(newContainer));
            dispatch(analyticsActions.setSelectedRegion(newRegion));
            dispatch(analyticsActions.setRegionChange(newRegion));
            dispatch(analyticsActions.updateAppliedEventTypes(selectedEventTypes));
            if (
                (regionChange !== selectedRegion ||
                    containerChange !== currentAnalyticsContainer ||
                    violentChecked !== violentCheckedApplied ||
                    nonviolentChecked !== nonviolentCheckedApplied ||
                    hazardChecked !== hazardCheckedApplied) &&
                !(violentChecked === !violentCheckedApplied && nonviolentChecked === !nonviolentCheckedApplied && hazardChecked === !hazardCheckedApplied)
            ) {
                setFilterLocked();
            }
            dispatch(analyticsActions.toggleViolentCheckedApplied(violentChecked));
            dispatch(analyticsActions.toggleNonviolentCheckedApplied(nonviolentChecked));
            dispatch(analyticsActions.toggleHazardCheckedApplied(hazardChecked));
            if (isMobile && filterChanged) {
                setFilterOpen(false);
            }
            if (violentChecked === !violentCheckedApplied && nonviolentChecked === !nonviolentCheckedApplied && hazardChecked === !hazardCheckedApplied) {
                dispatch(analyticsActions.toggleViolentCheckedEffect(violentChecked));
                dispatch(analyticsActions.toggleNonviolentCheckedEffect(nonviolentChecked));
                dispatch(analyticsActions.toggleHazardCheckedEffect(hazardChecked));
                dispatch(analyticsActions.toggleBlockUpdate(false));
            }
            dispatch(analyticsActions.setTimeRangeArray(filterTimeRangeArray(timeRangeApplied)));
            // fetchData();
        }
    };

    const resetFilter = () => {
        if (showTimeFilter !== true) {
            dispatch(filterActions.toggleHeatmap(true));
            dispatch(
                filterActions.updateFilter({
                    ...currentFilter,
                    otherIncluded: false,
                    myOrganizationIncluded: false,
                    myReportsIncluded: false,
                    orionUserIncluded: false,
                    orionSourceIncluded: false,
                    openSourceIncluded: false,
                    timePeriod: timeRange as Filter_TimePeriod
                })
            );
        }

        if (filterChangedFromDefault) {
            dispatch(analyticsActions.setData(null));
            dispatch(analyticsActions.updateTotalReports(0));
            dispatch(analyticsActions.setSelectedRegion(0));
            dispatch(analyticsActions.setRegionChange(0));
            updateNewRegion(0);
            dispatch(analyticsActions.updateAppliedEventTypes([]));
            updateEventTypeSearchOpen(false);
            updateSelectedEventTypes([]);
            dispatch(analyticsActions.setSelectedRegion(0));
            dispatch(analyticsActions.setRegionChange(0));
            dispatch(analyticsActions.toggleViolentChecked(false));
            dispatch(analyticsActions.toggleNonviolentChecked(false));
            dispatch(analyticsActions.toggleHazardChecked(false));
            dispatch(analyticsActions.toggleViolentCheckedApplied(false));
            dispatch(analyticsActions.toggleNonviolentCheckedApplied(false));
            dispatch(analyticsActions.toggleHazardCheckedApplied(false));

            if (!(violentCheckedApplied && hazardCheckedApplied && nonviolentCheckedApplied)) {
                setFilterLocked();
                dispatch(analyticsActions.toggleBlockUpdate(true));
            }

            if (isMobile && filterChangedFromDefault) {
                setFilterOpen(false);
            }
        }
    };

    const setFilterLocked = () => {
        dispatch(analyticsActions.toggleFilterLocked(true));
        setTimeout(
            () => {
                dispatch(analyticsActions.toggleFilterLocked(false));
                dispatch(analyticsActions.toggleTimeRangeApplied(timeRangeRef.current));
                dispatch(analyticsActions.toggleViolentCheckedEffect(violentCheckedAppliedRef.current));
                dispatch(analyticsActions.toggleNonviolentCheckedEffect(nonviolentCheckedAppliedRef.current));
                dispatch(analyticsActions.toggleHazardCheckedEffect(hazardCheckedAppliedRef.current));
                dispatch(analyticsActions.toggleBlockUpdate(false));
            },
            selectedRegion !== regionChange ? 6000 : 3000
        );
    };

    const setFilterOpen = (value: boolean) => {
        if ((!filterLocked || totalReports === 0) && data) {
            dispatch(analyticsActions.toggleFilterOpen(value));
            dispatch(leftModalActions.setFilterOpen(value));
        }
    };

    const setSelectedEventTypes = (value: string, operation: string) => {
        if (operation === "add") {
            if (!selectedEventTypes.includes(value)) {
                const newSelectedEventTypes = selectedEventTypes.concat(value);
                updateSelectedEventTypes(newSelectedEventTypes);
            }
        } else if (operation === "delete") {
            if (selectedEventTypes.includes(value)) {
                const selectedEventTypesCopy = [...selectedEventTypes];
                const index = selectedEventTypesCopy.indexOf(value);
                selectedEventTypesCopy.splice(index, 1);
                updateSelectedEventTypes(selectedEventTypesCopy);
            }
        } else if (operation === "clear") {
            updateSelectedEventTypes([]);
        }
    };

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            selectedRegion,
            eventTypeApplied: appliedEventTypes
        };

        const defaultFilter: FilterState = {
            ...currentFilter,
            violentIncluded: false,
            nonviolentIncluded: false,
            hazardIncluded: false,
            selectedRegion: 0,
            eventTypeApplied: []
        };
        if (!blockUpdate) {
            updateFilterChangedFromDefault(!isEqual(changedFilter, defaultFilter));
        }
    }, [
        currentFilter,
        violentChecked,
        nonviolentChecked,
        hazardChecked,
        selectedRegion,
        blockUpdate,
        currentAnalyticsContainer,
        newContainer,
        newRegion,
        appliedEventTypes
    ]);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            violentIncluded: violentChecked,
            nonviolentIncluded: nonviolentChecked,
            hazardIncluded: hazardChecked,
            selectedRegion,
            eventTypeApplied: appliedEventTypes
        };

        const existingFilter: FilterState = {
            ...currentFilter,
            violentIncluded: violentCheckedEffect,
            nonviolentIncluded: nonviolentCheckedEffect,
            hazardIncluded: hazardCheckedEffect,
            selectedRegion: regionChange,
            eventTypeApplied: selectedEventTypes
        };
        if (!blockUpdate) {
            updateFilterChanged(!isEqual(changedFilter, existingFilter) || currentAnalyticsContainer !== newContainer || selectedRegion !== newRegion);
        }
    }, [
        blockUpdate,
        containerChange,
        currentAnalyticsContainer,
        currentFilter,
        hazardChecked,
        hazardCheckedEffect,
        nonviolentChecked,
        nonviolentCheckedEffect,
        regionChange,
        selectedRegion,
        violentChecked,
        violentCheckedEffect,
        newContainer,
        newRegion,
        appliedEventTypes,
        selectedEventTypes
    ]);

    useEffect(() => {
        if (selectedEventTypes.length > 0) {
            dispatch(analyticsActions.toggleViolentChecked(false));
            dispatch(analyticsActions.toggleNonviolentChecked(false));
            dispatch(analyticsActions.toggleHazardChecked(false));
        }
    }, [selectedEventTypes, dispatch]);

    useEffect(() => {
        if (violentChecked === true || nonviolentChecked === true || hazardChecked === true) {
            setSelectedEventTypes("all", "clear");
            updateEventTypeSearchOpen(false);
        }
        // don't want it to close searchbar because category is checked
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [violentChecked, nonviolentChecked, hazardChecked]);

    useEffect(() => {
        updateSelectedEventTypes(appliedEventTypes);
    }, [appliedEventTypes]);

    useEffect(() => {
        updateNewContainer(cuser.userProfile?.current_container ?? 0);
        updateNewRegion(currentFilter.selectedRegion ?? 0);
        // this keeps the analytics filter in sync with any other changes to current container
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuser.userProfile?.current_container, currentFilter.selectedRegion]);

    const getColor = (name: reportType) => {
        if (ReportCategories.Violent.includes(name)) {
            return colors.scheme.analyticsPieViolent;
        } else if (ReportCategories["Non-Violent"].includes(name)) {
            return colors.scheme.analyticsPieNonviolent;
        } else if (ReportCategories.Hazard.includes(name)) {
            return colors.scheme.analyticsPieHazard;
        } else if (ReportCategories.Other.includes(name)) {
            return colors.scheme.analyticsPieOther;
        } else {
            return colors.scheme.analyticsPieOther;
        }
    };

    return (
        <>
            {filterOpen && (
                <FilterContainer
                    style={{ filter: "none", top: "0", pointerEvents: data ? "auto" : "none" }}
                    background={colors.scheme.filterModal}
                    boxshadow={colors.scheme.selectorBackground}
                >
                    <img
                        src={colors.mode === "light" ? CloseGreyIcon : CloseWhiteIcon}
                        alt="close"
                        style={{
                            position: "absolute",
                            right: "10px",
                            marginTop: "-10px",
                            height: "20px",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            dispatch(analyticsActions.toggleFilterOpen(false));
                            dispatch(leftModalActions.setFilterOpen(false));
                        }}
                    />
                    <span style={{ color: colors.scheme.primaryText, fontWeight: "bold" }}>Region</span>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "10px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                textAlign: "left",
                                borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                            }}
                        >
                            <Dropdown
                                // disabled={isMobile && cuser.userProfile?.role !== 1}
                                defaultValue={newContainer || undefined}
                                value={newContainer}
                                onChange={(value) => updateCurrentContainerAndRegions(value as number)}
                                // showArrow={!isMobile || cuser.userProfile?.role === 1}
                                showArrow={true}
                                color={colors.scheme.cardLightCaptionText}
                                background={"transparent"}
                                bordered={false}
                                arrowcolor={colors.scheme.primaryText}
                                style={{ textAlign: "left" }}
                            >
                                {availableContainers.map((container) => {
                                    return (
                                        <Option key={container.id} value={container.id}>
                                            {container.name}{" "}
                                        </Option>
                                    );
                                })}
                            </Dropdown>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                            }}
                        >
                            <Dropdown
                                defaultValue={newRegion || undefined}
                                value={newRegion}
                                onChange={(value) => updateNewRegion(value as number)}
                                showArrow={true}
                                color={colors.scheme.cardLightCaptionText}
                                arrowcolor={colors.scheme.primaryText}
                                background={"transparent"}
                                bordered={false}
                                style={{ textAlign: "left" }}
                                disabled={newContainer !== currentAnalyticsContainer ? true : false}
                            >
                                {regionSelections.map((region: DefaultRegion | DefaultRegionNoViewport) => {
                                    return (
                                        <Option key={region.id} value={region.id}>
                                            {region.name}{" "}
                                        </Option>
                                    );
                                })}
                            </Dropdown>
                        </div>
                    </div>
                    <span style={{ color: colors.scheme.primaryText, fontWeight: "bold", marginTop: "5px" }}>Category of Incident</span>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "5px",
                            width: "100%"
                        }}
                    >
                        <StyledTag
                            fontcolor={violentChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={violentChecked ? colors.scheme.filterViolentTag : colors.scheme.filterUnselectedTag}
                            checked={violentChecked}
                            onChange={(checked) => {
                                dispatch(analyticsActions.toggleViolentChecked(!violentChecked));
                            }}
                        >
                            <div>Violent</div>
                            {violentChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={nonviolentChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={nonviolentChecked ? colors.scheme.filterNonViolentTag : colors.scheme.filterUnselectedTag}
                            checked={nonviolentChecked}
                            onChange={(checked) => {
                                dispatch(analyticsActions.toggleNonviolentChecked(!nonviolentChecked));
                            }}
                        >
                            <div>Nonviolent</div>
                            {nonviolentChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={hazardChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={hazardChecked ? colors.scheme.filterHazardTag : colors.scheme.filterUnselectedTag}
                            checked={hazardChecked}
                            onChange={(checked) => {
                                dispatch(analyticsActions.toggleHazardChecked(!hazardChecked));
                            }}
                        >
                            <div>Hazard</div>
                            {hazardChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                    </div>
                    <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>Type of Event</div>
                    {eventTypeSearchOpen && (
                        <ShortTextInputAutoComplete
                            options={eventTypeNames}
                            filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            color={colors.scheme.primaryText}
                            background={colors.scheme.selectorBackground}
                            onSelect={(value: string, option) => setSelectedEventTypes(value, "add")}
                            style={{ margin: "0 0 10px", width: "calc(100% - 8px)" }}
                            allowClear={isMobile && isIOS ? false : true}
                            disabled={selectedEventTypes.length >= 3 ? true : false}
                        >
                            <ShortTextInputWithPlaceholderColor
                                placeholdercolor={colors.scheme.secondaryHeaderText}
                                maxLength={30}
                                placeholder="Find An Event Type (Max. 3 Types)"
                            />
                        </ShortTextInputAutoComplete>
                    )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "5px",
                            width: "100%"
                        }}
                    >
                        {selectedEventTypes.length === 0 && (
                            <StyledTag
                                fontcolor={"#4C4C4C"}
                                background={colors.scheme.filterUnselectedTag}
                                checked={eventTypeSearchOpen}
                                onChange={(checked) => updateEventTypeSearchOpen(!eventTypeSearchOpen)}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    Search Event Types
                                    <img
                                        src={SearchGreyIcon}
                                        alt={"search"}
                                        style={{
                                            height: "12px",
                                            marginLeft: "5px"
                                        }}
                                    />
                                </div>
                            </StyledTag>
                        )}
                        {selectedEventTypes.map((eventType) => (
                            <StyledTag
                                fontcolor={"#FFFFFF"}
                                background={getColor(eventType as reportType)}
                                checked={eventChecked}
                                key={eventType}
                                onChange={() => {
                                    updateEventChecked(!eventChecked);
                                    setSelectedEventTypes(eventType, "delete");
                                }}
                            >
                                <div>{eventType}</div>
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            </StyledTag>
                        ))}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >
                        <div style={{ color: colors.scheme.primaryText, fontWeight: "bold", margin: "5px 0" }}>Historical</div>
                        <Toggle
                            checked={historicalChecked}
                            onClick={(checked) => updateHistoricalChecked(checked)}
                            background={colors.scheme.toggleBackground}
                        />
                    </div>
                    <div
                        style={{
                            width: "calc(100% + 40px)",
                            display: "flex",
                            alignSelf: "center",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: colors.scheme.filterModalBottomBar,
                            margin: "10px -20px -20px -20px",
                            borderRadius: "0 0 5px 5px"
                        }}
                    >
                        <Button
                            onClick={() => {
                                updateFilter();
                            }}
                            active={
                                filterChanged ||
                                newContainer !== currentAnalyticsContainer ||
                                newRegion !== selectedRegion ||
                                historicalChecked !== showTimeFilter
                            }
                            color={
                                filterChanged ||
                                newContainer !== currentAnalyticsContainer ||
                                newRegion !== selectedRegion ||
                                historicalChecked !== showTimeFilter
                                    ? colors.scheme.submitButtonText
                                    : "#4C4C4C"
                            }
                            background={
                                filterChanged ||
                                newContainer !== currentAnalyticsContainer ||
                                newRegion !== selectedRegion ||
                                historicalChecked !== showTimeFilter
                                    ? colors.scheme.submitButtonBackground
                                    : colors.scheme.filterUnselectedTag
                            }
                        >
                            {isMobile ? "Apply" : "Update"}
                            {(filterChanged || newContainer !== currentAnalyticsContainer || newRegion !== selectedRegion) && (
                                <img src={RefreshWhiteIcon} alt="refresh" width="13px" style={{ marginLeft: "5px" }} />
                            )}
                        </Button>
                        <Button
                            onClick={() => {
                                resetFilter();
                            }}
                            active={filterChangedFromDefault}
                            color={filterChangedFromDefault ? colors.scheme.submitButtonText : "#4C4C4C"}
                            background={filterChangedFromDefault ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag}
                        >
                            Reset
                        </Button>
                    </div>
                </FilterContainer>
            )}
        </>
    );
};
