import styled from "styled-components";
import { borderBlueGrey, activeSubmitButtonBackground } from "../../utils";
import { VerticallyCenteredRow } from "../_shared";
import Input from "antd/lib/input";
import Switch from "antd/lib/switch";

export const CuserDisplayContainer = styled.div`
    padding: 0px 30px 0;
    position: absolute;
    top: 60px;
    height: 180px;
    width: 100%;
    border-bottom: 1px solid ${borderBlueGrey};
    display: flex;
    flex-direction: column;
`;

export const MenuLink = styled(VerticallyCenteredRow)`
    margin-bottom: 30px;

    :hover {
        cursor: pointer;
    }
`;

interface CuserDisplayHeaderProps {
    color: string;
}

export const CuserDisplayHeader = styled.div`
    top: 45px;
    font-size: 20px;
    color: ${(props: CuserDisplayHeaderProps) => props.color};
`;

interface CuserDisplayCircleProps {
    background: string;
}

export const CuserDisplayStatusCircle = styled.div`
    height: 15px;
    width: 15px;
    margin-left: 10px;
    background: ${(props: CuserDisplayCircleProps) => props.background};
    border-radius: 50%;
`;

interface CuserDisplayContent {
    color: string;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CuserDisplayContent = styled.div`
    font-size: 16px;
    color: ${(props: CuserDisplayContent) => props.color};
    margin-bottom: 5px;
`;

export const CuserDisplayContentWithBottomMargin = styled(CuserDisplayContent)`
    margin-bottom: 15px;
`;

export const ErrorMessage = styled.div`
    font-size: 12px;
    color: red;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
`;

interface PasswordInputProps {
    background: string;
    color: string;
}

export const PasswordInput = styled(Input.Password)`
    border-radius: 8px !important;
    background: ${(props: PasswordInputProps) => props.background} !important;
    border: none;
    color: ${(props: PasswordInputProps) => props.color};

    .ant-input {
        background: none !important;
        color: ${(props: PasswordInputProps) => props.color} !important;
    }
`;

interface SubmitButtonProps {
    background: string;
    color: string;
}

export const SubmitPasswordButton = styled.input`
    font-family: IBM Plex Sans;
    background-color: ${(props: SubmitButtonProps) => props.background};
    width: 254px;
    height: 37px;
    text-transform: uppercase;
    border-radius: 18px;
    color: ${(props: SubmitButtonProps) => props.color};
    border: none;
    margin: 10px;
    margin-bottom: 0px;
    font-size: 14px;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none;
    font-weight: 600;

    :active {
        background: ${activeSubmitButtonBackground};
    }
`;

interface ToggleProps {
    background: string;
}
export const Toggle = styled(Switch)`
    &.ant-switch.ant-switch-checked {
        background-color: ${(props: ToggleProps) => props.background} !important;
    }
`;
