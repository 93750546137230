import styled from "styled-components";
import { boxShadow } from "../../utils";
import { GeolocateControl } from "react-map-gl";
import { device } from "../../utils/index";

// components
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

export const FullScreenMapContainer = styled.div`
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
`;

export const SearchbarContainer = styled.div`
    background: purple;
    position: fixed;
    top: 22px;
    right: 22px;
    z-index: 10;
    box-shadow: 0px 4px 6px ${boxShadow};
    border-radius: 10px;

    @media ${device.mobile} {
        box-shadow: none;
        width: calc(100vw - 90px);
        border-radius: 10px;
    }
`;

export const CurrentLocationButton = styled(GeolocateControl)`
    position: fixed;
    right: 25px;
    bottom: 40px;
    box-shadow: none;
    z-index: 10;
    border-radius: 10%;
    .mapboxgl-ctrl-group:not(:empty) {
        box-shadow: none;
        border: none;
        outline: none;
    }

    @media ${device.mobile} {
        bottom: 105px;
    }
`;

interface EntryProps {
    color: string;
}

export const EntryName = styled.div`
    width: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    color: ${(props: EntryProps) => props.color};
`;

export const EntryCases = styled.div`
    width: 90px;
    text-align: center;
    color: ${(props: EntryProps) => props.color};
    @media ${device.mobile} {
        margin-top: 20px;
    }
`;

export const RoundButton = styled.div`
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: ${({ theme }) => theme.primaryText};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px ${boxShadow};
`;

export const SearchButtonContainer = styled.div`
    position: fixed;
    top: 22px;
    right: 75px;
    z-index: 20;
`;
