import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import { buttonOrange, boxShadow, device } from "../../utils";
import Menu from "antd/lib/menu";
import { Modal } from "antd";

export const Fullscreen = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
`;

interface SnackbarProps {
    background: string;
}

export const Toast = styled(Snackbar)`
    .MuiSnackbarContent-root {
        background: ${(props: SnackbarProps) => props.background} !important;
        justify-content: center;
    }
    @media ${device.mobile} {
        margin-top: 80px;
    }
`;

export const CurrentContainerButton = styled.div`
    z-index: 20;
    position: fixed;
    border-radius: 50%;
    box-shadow: 0px 4px 4px ${boxShadow};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${buttonOrange};
    width: 40px;
    height: 40px;
    top: 20px;
    right: 20px;
    color: white;
    font-size: 18px;

    @media ${device.mobile} {
        display: none;
    }
`;

interface DropdownContentProps {
    background: string;
    color: string;
}

export const DropdownContent = styled(Menu)`
    position: relative;
    transform: none;
    transition: none;
    width: 200px;
    text-weight: bold;
    font-size: 13px;
    padding: 10px;
    padding-top: 5px;
    border-radius: 5px;
    background: ${(props: DropdownContentProps) => props.background};
    color: ${(props: DropdownContentProps) => props.color};
`;

export const LogoContainer = styled.div`
    cursor: pointer;
    position: fixed;
    top: 16px;
    left: 20px;
    width: 40px;
    height: 37px;
    z-index: 20;

    :active {
        width: 42px;
        height: 39px;
        top: 15px;
        left: 19px;
    }

    @media ${device.mobile} {
        top: 29px;

        :active {
            top: 28px;
        }
    }
`;

export const OrionMenuButton = styled.img`
    width: 100%;
    height: auto;
`;

export const CurrentDisplayContainer = styled.div`
    z-index: 20;
    position: fixed;
    top: 70px;
    right: 20px;
`;

export const Error = styled(Snackbar)`
    align-content: center;
    text-align: center;
    justify-content: center;
    .MuiSnackbarContent-root {
        background: ${buttonOrange} !important;
    }

    .MuiSnackbarContent-root {
        background: ${(props: SnackbarProps) => props.background} !important;
    }
`;

export const ReportToast = styled(Snackbar)`
    .MuiSnackbarContent-root {
        background: ${(props: SnackbarProps) => props.background} !important;
    }
    @media ${device.mobile} {
        margin-top: 80px;
    }
`;

interface StyledMenuItemProps {
    background: string;
    hoverbackground: string;
    selected: boolean;
}

export const StyledMenuItem = styled(Menu.Item)`
    background-color: ${(props: StyledMenuItemProps) => (props.selected ? props.hoverbackground : props.background)};

    :hover {
        padding-left: 20px;
        background-color: ${(props: StyledMenuItemProps) => props.hoverbackground};
    }
`;

export const FilterButtonContainer = styled.div`
    position: fixed;
    bottom: 26px;
    cursor: pointer;

    @media ${device.mobile} {
        bottom: none;
        top: 70px;
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const ContentText = styled.div`
    font-size: 16px;
`;

export const BoldContentText = styled(ContentText)`
    font-weight: 600;
`;

export const PNModal = styled(Modal)`
    .ant-btn {
        border-radius: 4px;
    }

    .ant-modal-content {
        border-radius: 6px;
    }
`;
