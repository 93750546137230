import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { getContainerViewportFromId, getDefaultRegionViewportFromId, S3Key } from "../../utils";
import { isMobile } from "react-device-detect";
import { filterTimeRangeArray } from "./operations";
import { useDispatch } from "react-redux";
import { mapActions } from "../../state/map";
import { Filter_TimePeriod, filterActions, FilterState } from "../../state/filter";
import { ErrorDisplay, ErrorMessage, SpaceBetweenFlexContainer, CenteredFlexContainer, BottomBar, ApplyToMapButton } from "./styled";
import LineGraph from "./line-graph";
import { Dot } from "../_shared";
import { SelectValue } from "antd/lib/select";
import { isEqual } from "lodash";
import { leftModalActions } from "../../state/left-modal";
import { Filter } from "./Filter";
import { analyticsActions, analyticsOperations } from "../../state/analytics";
import { PieChart } from "./PieChart";
import { TimeRangePicker } from "./TimeRangePicker";
import { Stats } from "./Stats";
import Lottie from "react-lottie";
import MovingCirclesDarkLoading from "../../utils/lotties/movingCirclesDark.json";
import MovingCirclesLightLoading from "../../utils/lotties/movingCirclesLight.json";
import MovingCirclesBlueLoading from "../../utils/lotties/movingCirclesBlue.json";
import Tooltip from "antd/lib/tooltip";

const FunnelWhiteIcon = S3Key + "funnel-white.png";
const FunnelGreyIcon = S3Key + "funnel-grey.png";
const InsufficientDataGreyIcon = S3Key + "insufficient-data-dark.png";
const InsufficientDataWhiteIcon = S3Key + "insufficient-data-white.png";
const LocationWhiteIcon = S3Key + "location-white.png";
const LocationGreyIcon = S3Key + "location-grey.png";

export interface DefaultRegionNoViewport {
    id: number;
    name: string;
    container_id: 0;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const dispatch = useDispatch();

    const colors = useSelectFromRedux((state) => state.color);
    const cuser = useSelectFromRedux((state) => state.cuser);
    const containers = useSelectFromRedux((state) => state.cuser.containers);
    const defaultRegions = useSelectFromRedux((state) => state.cuser.defaultRegions);
    const currentAnalyticsContainer = useSelectFromRedux((state) => state.analytics.currentContainer);
    const availableContainers = useSelectFromRedux((state) => state.cuser.containers);
    const currentFilter = useSelectFromRedux((state) => state.filter);
    const tooltipOpen = useSelectFromRedux((state) => state.map.showTooltip);
    // const [submitButtonPulsing, updateSubmitButtonPulsing] = useState<boolean>(false);

    const {
        filterLocked,
        data,
        categoricalDataArray,
        error,
        timeRange,
        filterOpen,
        violentCheckedApplied,
        nonviolentCheckedApplied,
        hazardCheckedApplied,
        mapRegionChange,
        timeRangeApplied,
        violentCheckedEffect,
        nonviolentCheckedEffect,
        hazardCheckedEffect,
        blockUpdate,
        totalReports,
        timeRangeArray,
        containerChange,
        regionChange,
        appliedEventTypes
    } = useSelectFromRedux((state) => state.analytics);

    if (!availableContainers) {
        console.error("Could not get containers in drawer.");
        return null;
    }

    const violentCheckedAppliedRef = useRef(violentCheckedApplied);
    violentCheckedAppliedRef.current = violentCheckedApplied;
    const nonviolentCheckedAppliedRef = useRef(nonviolentCheckedApplied);
    nonviolentCheckedAppliedRef.current = nonviolentCheckedApplied;
    const hazardCheckedAppliedRef = useRef(hazardCheckedApplied);
    hazardCheckedAppliedRef.current = hazardCheckedApplied;
    const timeRangeRef = useRef(timeRange);
    timeRangeRef.current = timeRange;

    useEffect(() => {
        dispatch(
            analyticsActions.setTimeRangeArray(
                filterTimeRangeArray(timeRangeApplied, data, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, appliedEventTypes)
            )
        );
    }, [timeRangeApplied, data, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, dispatch, appliedEventTypes]);

    const availableRegions = defaultRegions.filter((region) => region.container_id === currentAnalyticsContainer);

    if (!availableRegions || !currentAnalyticsContainer) {
        console.error("Container has no regions? Returning");
        return null;
    }

    const drawerRef: any = useRef(null);

    // DON'T DELETE THIS
    // const updateShowHeatmap = (bool: boolean) => {
    //     dispatch(filterActions.toggleHeatmap(bool));
    //     dispatch(
    //         filterActions.updateFilter({
    //             ...currentFilter,
    //             otherIncluded: false,
    //             myOrganizationIncluded: false,
    //             myReportsIncluded: false,
    //             orionUserIncluded: false,
    //             orionSourceIncluded: false,
    //             openSourceIncluded: false,
    //             timePeriod: timeRange as Filter_TimePeriod
    //         })
    //     );
    // };

    const fetchData = useCallback(() => {
        if (regionChange === 0) {
            dispatch(analyticsOperations.fetchAnalyticsData("container", containerChange));
        } else {
            dispatch(analyticsOperations.fetchAnalyticsData("default_region", regionChange));
        }
        /**
         * If we have a region selected but the container has just changed, we need to reset to the default region
         * before loading the new container
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerChange, regionChange]);

    useEffect(() => {
        fetchData();
    }, [fetchData, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, appliedEventTypes]);

    useEffect(() => {
        if (categoricalDataArray !== null && categoricalDataArray.length > 0) {
            dispatch(analyticsOperations.getTotalReportsFromCategoricalDataArray());
        }
    }, [categoricalDataArray, dispatch]);

    const setCurrentContainer = (value: SelectValue) => {
        if (typeof value !== "number") {
            console.error("Invalid container id selected: " + value);
        }
    };

    useEffect(() => {
        dispatch(analyticsActions.toggleTimeRangeApplied(timeRange));
        dispatch(analyticsActions.toggleTimeRange(timeRange));
        dispatch(filterActions.updateFilter({ ...currentFilter, timePeriod: timeRange as Filter_TimePeriod }));
        // this function allows the timelapse feature to show up
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateMap = () => {
        if (mapFilterChanged) {
            dispatch(analyticsActions.toggleFilterOpen(false));
            if (tooltipOpen) {
                dispatch(mapActions.toggleTooltip());
            }
            dispatch(
                filterActions.updateFilter({
                    ...currentFilter,
                    violentIncluded: violentCheckedEffect,
                    nonviolentIncluded: nonviolentCheckedEffect,
                    hazardIncluded: hazardCheckedEffect,
                    selectedRegion: regionChange,
                    otherIncluded: false,
                    myOrganizationIncluded: false,
                    myReportsIncluded: false,
                    orionUserIncluded: false,
                    orionSourceIncluded: false,
                    openSourceIncluded: false,
                    timePeriod: timeRangeApplied as Filter_TimePeriod,
                    eventTypeApplied: appliedEventTypes
                })
            );

            if (containerChange !== cuser.userProfile?.current_container) {
                dispatch(analyticsActions.setMapRegionChange(regionChange));
                if (regionChange === 0) {
                    setCurrentContainer(containerChange);
                }
            }

            if (mapRegionChange !== regionChange && containerChange === cuser.userProfile?.current_container) {
                dispatch(analyticsActions.setMapRegionChange(regionChange));
                if (regionChange !== 0) {
                    const regionViewportInfo = getDefaultRegionViewportFromId(regionChange, availableRegions);
                    dispatch(mapActions.flyToPoint([regionViewportInfo.longitude, regionViewportInfo.latitude, regionViewportInfo.zoom]));
                } else {
                    if (!cuser.userProfile?.current_container || !containers) {
                        return;
                    }
                    const containerViewportInfo = getContainerViewportFromId(cuser.userProfile?.current_container, containers);
                    dispatch(mapActions.flyToPoint([containerViewportInfo.longitude, containerViewportInfo.latitude, containerViewportInfo.zoom]));
                }
            }
            if (isMobile) {
                dispatch(leftModalActions.toggleModal());
            }
        }
    };

    useEffect(() => {
        dispatch(analyticsActions.setData(null));
        dispatch(analyticsActions.updateTotalReports(0));
        dispatch(analyticsActions.setCurrentContainer(cuser.userProfile?.current_container ?? -2));
        dispatch(analyticsActions.setContainerChange(cuser.userProfile?.current_container ?? -2));
        // this keeps the analytics filter in sync with any other changes to current container
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuser.userProfile?.current_container]);

    const [mapFilterChanged, updateMapFilterChanged] = useState<boolean>(false);

    const [appliedFilterChangedFromDefault, updateAppliedFilterChangedFromDefault] = useState<boolean>(false);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            violentIncluded: violentCheckedEffect,
            nonviolentIncluded: nonviolentCheckedEffect,
            hazardIncluded: hazardCheckedEffect,
            selectedRegion: regionChange,
            eventTypeApplied: appliedEventTypes
        };

        const defaultFilter: FilterState = {
            ...currentFilter,
            violentIncluded: false,
            nonviolentIncluded: false,
            hazardIncluded: false,
            selectedRegion: 0,
            eventTypeApplied: []
        };
        if (!blockUpdate) {
            updateAppliedFilterChangedFromDefault(!isEqual(changedFilter, defaultFilter));
        }
    }, [blockUpdate, currentFilter, hazardCheckedEffect, nonviolentCheckedEffect, regionChange, violentCheckedEffect, appliedEventTypes]);

    useEffect(() => {
        const changedFilter: FilterState = {
            ...currentFilter,
            timePeriod: timeRangeApplied as Filter_TimePeriod,
            violentIncluded: violentCheckedEffect,
            nonviolentIncluded: nonviolentCheckedEffect,
            hazardIncluded: hazardCheckedEffect,
            otherIncluded: false,
            selectedRegion: regionChange,
            eventTypeApplied: appliedEventTypes
        };

        updateMapFilterChanged(!isEqual(changedFilter, currentFilter) || currentAnalyticsContainer !== cuser.userProfile?.current_container);
    }, [
        violentCheckedEffect,
        nonviolentCheckedEffect,
        hazardCheckedEffect,
        regionChange,
        appliedEventTypes,
        currentAnalyticsContainer,
        currentFilter,
        cuser.userProfile,
        timeRangeApplied
    ]);

    const setFilterOpen = (value: boolean) => {
        if ((!filterLocked || totalReports === 0) && data) {
            dispatch(analyticsActions.toggleFilterOpen(value));
            dispatch(leftModalActions.setFilterOpen(value));
        }
    };

    const getAnimationColor = () => {
        if (colors.mode === "light") {
            return MovingCirclesDarkLoading;
        } else if (colors.mode === "space-grey") {
            return MovingCirclesLightLoading;
        } else if (colors.mode === "dark") {
            return MovingCirclesBlueLoading;
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: getAnimationColor(),
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    // useEffect(() => {
    //     if (data && currentAnalyticsContainer !== cuser.userProfile?.current_container) {
    //         updateSubmitButtonPulsing(true);
    //     }
    // }, [data, currentAnalyticsContainer, cuser.userProfile?.current_container]);

    return (
        <div style={{ width: "100%" }}>
            <div style={{ filter: filterOpen && isMobile ? "blur(4px)" : "none" }}>
                {!error && (
                    <SpaceBetweenFlexContainer>
                        <div style={{ marginLeft: "20px" }}>
                            <img
                                src={colors.mode === "light" ? LocationGreyIcon : LocationWhiteIcon}
                                alt="Location"
                                style={{
                                    height: "16px",
                                    marginRight: "8px",
                                    marginBottom: "2px"
                                }}
                            />
                            {regionChange !== 0
                                ? defaultRegions.filter((region) => region.id === regionChange)[0].name
                                : containers.filter((container) => container.id === containerChange)[0].name}
                        </div>
                        {cuser.userProfile?.features?.filtering !== false && (
                            <Dot
                                background={colors.scheme.submitButtonBackground}
                                anchorOrigin={{ horizontal: "left", vertical: "top" }}
                                variant={"dot"}
                                color={"primary"}
                                invisible={!appliedFilterChangedFromDefault}
                                style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "53px",
                                    zIndex: 99
                                }}
                            >
                                <img
                                    src={colors.mode === "light" ? FunnelGreyIcon : FunnelWhiteIcon}
                                    alt="Filter"
                                    style={{
                                        height: "32px",
                                        zIndex: 98,
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setFilterOpen(true)}
                                />
                            </Dot>
                        )}
                    </SpaceBetweenFlexContainer>
                )}
                {error && <ErrorDisplay color={colors.scheme.primaryText}>No analytics available for this region.</ErrorDisplay>}
                {!data && !error && (
                    <CenteredFlexContainer style={{ margin: "40px 0" }}>
                        <Lottie options={defaultOptions} height={400} width={400} />
                    </CenteredFlexContainer>
                )}
                {data && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            maxHeight: "calc(100vh - 250px)"
                        }}
                    >
                        <div
                            ref={drawerRef}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "start",
                                height: "220px",
                                width: "100%"
                            }}
                        >
                            {totalReports > 0 ? (
                                <div style={{ height: "250px", marginRight: "5px" }}>
                                    <PieChart drawerRef={drawerRef} />
                                </div>
                            ) : (
                                <ErrorMessage color={colors.scheme.primaryText}>
                                    <img
                                        src={colors.mode === "light" ? InsufficientDataGreyIcon : InsufficientDataWhiteIcon}
                                        alt="Insufficent Data"
                                        style={{ height: "80px", width: "80px", marginBottom: "10px" }}
                                    />
                                    Insufficient Data
                                    <br /> for Analytics
                                </ErrorMessage>
                            )}
                            <TimeRangePicker />
                        </div>
                        {totalReports > 0 && (
                            <div>
                                <CenteredFlexContainer>
                                    <Stats />
                                </CenteredFlexContainer>
                                {timeRangeArray.length > 0 ? (
                                    <div
                                        style={{
                                            width: isMobile ? "100%" : "360px",
                                            height: "230px",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <LineGraph timeRangeArray={timeRangeArray} width={drawerRef ? drawerRef.current?.offsetWidth : null} height={220} />
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {data && (
                <BottomBar background={colors.scheme.modalBottomBar} filter={filterOpen && isMobile ? "blur(4px)" : "none"}>
                    <CenteredFlexContainer>
                        <Tooltip title="Showing analytics for a different region than is selected on the map. Tap here to update map.">
                            <ApplyToMapButton
                                style={{ width: "150px", cursor: "pointer" }}
                                onClick={() => updateMap()}
                                active={mapFilterChanged && !!data}
                                color={mapFilterChanged && !!data ? colors.scheme.submitButtonText : "#4C4C4C"}
                                background={mapFilterChanged && !!data ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag}
                                pulsing={mapFilterChanged && !!data}
                            >
                                Apply To Map
                            </ApplyToMapButton>
                        </Tooltip>
                    </CenteredFlexContainer>
                </BottomBar>
            )}
            <Filter />
        </div>
    );
};
