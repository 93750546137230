import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { FullScreenImage, LogoAndFormContainer, VerstaanLogo, SubmitButton } from "../_shared/auth";
import { MessageContainer, MessageTitle, SquareInput } from "./styled";
import { verifyEmail } from "../../types/api/auth";
import { ConnectionError, ErrorResponse } from "../../types/api";
import { StatusCode } from "../../types/api";
import { toastActions } from "../../state/toast";
import { useDispatch } from "react-redux";
import { PendingUser } from "../../types/client";
import { useSelectFromRedux } from "../../utils/_hooks";
import { Error } from "../login/styled";
import { fadedRed, S3Key } from "../../utils";
import { Alert } from "../_shared";
import { SmallSpacer } from "../_shared/layout";
import { SmallIcon } from "../_shared/typography";

interface Props {
    email: string;
    setPendingUser: Dispatch<SetStateAction<PendingUser | null>>;
}

export const Verify = (props: Props) => {
    const dispatch = useDispatch();
    const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);
    const errorContent = useSelectFromRedux((state) => state.error.currentText);
    const colors = useSelectFromRedux((state) => state.color);
    const toastIsError = useSelectFromRedux((state) => state.error.isError);

    const [verifyCodeOne, updateVerifyCodeOne] = useState<string>("");
    const [verifyCodeTwo, updateVerifyCodeTwo] = useState<string>("");
    const [verifyCodeThree, updateVerifyCodeThree] = useState<string>("");
    const [verifyCodeFour, updateVerifyCodeFour] = useState<string>("");
    const [verifyCodeFive, updateVerifyCodeFive] = useState<string>("");
    const [verifyCodeSix, updateVerifyCodeSix] = useState<string>("");

    const oneInput = useRef(document.createElement("input"));
    const twoInput = useRef(document.createElement("input"));
    const threeInput = useRef(document.createElement("input"));
    const fourInput = useRef(document.createElement("input"));
    const fiveInput = useRef(document.createElement("input"));
    const sixInput = useRef(document.createElement("input"));

    const submit = () => {
        const verif_string = verifyCodeOne + verifyCodeTwo + verifyCodeThree + verifyCodeFour + verifyCodeFive + verifyCodeSix;

        const verif_code = Number(verif_string);
        if (!isNaN(verif_code)) {
            console.log(verif_code);

            verifyEmail(props.email, verif_code)
                .then((pendingUser) => {
                    props.setPendingUser(pendingUser);
                })
                .catch((err: ErrorResponse | Error) => {
                    if (err instanceof ErrorResponse) {
                        if (err.statusCode === StatusCode.ClientLogicError) {
                            dispatch(toastActions.openError("Error: " + err.message));
                        } else {
                            dispatch(toastActions.openError("Server toast. Please try again or contact support."));
                        }
                        console.log("Error response: " + err.message);
                    } else if (err instanceof ConnectionError) {
                        console.log("Connection error: " + err.message);
                        dispatch(toastActions.openError("Connection error. Please check your internet and try again."));
                    } else {
                        console.error("Unknown error occurred in verifyEmail: ", err);
                        dispatch(toastActions.openError("Something went wrong. Please check your internet connection and try again."));
                    }
                });
        } else {
            dispatch(toastActions.openError("Invalid code: only numbers 0-9 allowed."));
        }
    };

    const handleErrorClose = () => {
        dispatch(toastActions.closeToast());
    };

    return (
        <FullScreenImage>
            <LogoAndFormContainer>
                <VerstaanLogo src="https://orion-assets-s3.s3.us-east-2.amazonaws.com/orion-logo.png" alt="Verstaan Logo" />
                <MessageContainer>
                    <MessageTitle>Verify Code</MessageTitle>
                </MessageContainer>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                    style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}
                >
                    <div style={{ display: "flex" }}>
                        <SquareInput
                            type="text"
                            value={verifyCodeOne}
                            autoFocus={true}
                            maxLength={1}
                            ref={oneInput}
                            onChange={(e) => {
                                updateVerifyCodeOne(e.target.value);
                                if (e.target.value.length > 0) {
                                    twoInput.current.focus();
                                }
                            }}
                        />
                        <SquareInput
                            type="text"
                            value={verifyCodeTwo}
                            maxLength={1}
                            ref={twoInput}
                            onChange={(e) => {
                                updateVerifyCodeTwo(e.target.value);
                                if (e.target.value.length > 0) {
                                    threeInput.current.focus();
                                } else {
                                    oneInput.current.focus();
                                }
                            }}
                        />
                        <SquareInput
                            type="text"
                            value={verifyCodeThree}
                            maxLength={1}
                            ref={threeInput}
                            onChange={(e) => {
                                updateVerifyCodeThree(e.target.value);
                                if (e.target.value.length > 0) {
                                    fourInput.current.focus();
                                } else {
                                    twoInput.current.focus();
                                }
                            }}
                        />
                        <SquareInput
                            type="text"
                            value={verifyCodeFour}
                            maxLength={1}
                            ref={fourInput}
                            onChange={(e) => {
                                updateVerifyCodeFour(e.target.value);
                                if (e.target.value.length > 0) {
                                    fiveInput.current.focus();
                                } else {
                                    threeInput.current.focus();
                                }
                            }}
                        />
                        <SquareInput
                            type="text"
                            value={verifyCodeFive}
                            maxLength={1}
                            ref={fiveInput}
                            onChange={(e) => {
                                updateVerifyCodeFive(e.target.value);
                                if (e.target.value.length > 0) {
                                    sixInput.current.focus();
                                } else {
                                    fourInput.current.focus();
                                }
                            }}
                        />
                        <SquareInput
                            type="text"
                            value={verifyCodeSix}
                            maxLength={1}
                            ref={sixInput}
                            onChange={(e) => {
                                updateVerifyCodeSix(e.target.value);
                                if (e.target.value.length === 0) {
                                    fiveInput.current.focus();
                                }
                            }}
                        />
                    </div>
                    <MessageContainer>
                        <MessageTitle style={{ textTransform: "none" }}>
                            A message with a verification code has been sent to your email. Please enter the code to continue.
                        </MessageTitle>
                    </MessageContainer>
                    <SubmitButton type="button" style={{ marginTop: "30px" }} onClick={submit}>
                        Verify
                    </SubmitButton>
                </form>
            </LogoAndFormContainer>
            {errorIsOpen && errorContent && (
                <Error
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    onClose={handleErrorClose}
                    autoHideDuration={10000}
                    background={toastIsError ? colors.scheme.filterViolentTag : colors.general.standardGreen}
                >
                    <Alert style={{ background: toastIsError ? fadedRed : colors.general.standardGreen }}>
                        {
                            toastIsError ?
                                <SmallIcon src={S3Key + "alert-white.svg"} alt="alert" />
                                : <SmallIcon src={S3Key + "check-white.svg"} alt="checkmark" />
                        }
                        <SmallSpacer />
                        {errorContent}
                    </Alert>
                </Error>
            )}
        </FullScreenImage>
    );
};
