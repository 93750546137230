import ClusterLayer from "../layers/cluster-layer";
import ZoomIconLayer from "../layers/zoom-icon-layer";
import { dataToGeojson } from "../utils";
// import { IconLayer, TextLayer } from "@deck.gl/layers";

export default function renderLayers({ data = [], onClick, filter: { markerClustering = true }, colors }) {
    // let clientDevicesText = [];

    // const clientDeviceSanitized = clientDevices.filter((device) => {
    //     if (device.point && typeof device.point.coordinates[0] === "number" && typeof device.point.coordinates[1] === "number") {
    //         return true;
    //     } else {
    //         // console.log("[Warning] Client user device with id " + device.id + " has invalid location. Rendering skipped.");
    //         return false;
    //     }
    // });
    //
    // const pointsEqual = (point1, point2) => {
    //     return Math.abs(point1.coordinates[0] - point2.coordinates[0]) < 0.0005 && Math.abs(point1.coordinates[1] - point2.coordinates[1]) < 0.0005;
    // };

    // for (let device of clientDeviceSanitized) {
    //     let sameLocation = clientDevicesText.findIndex((d) => pointsEqual(d.point, device.point));
    //     if (sameLocation !== -1) {
    //         clientDevicesText[sameLocation] = {
    //             ...clientDevicesText[sameLocation],
    //             user_name: clientDevicesText[sameLocation].user_name + "\n" + device.user_name
    //         };
    //     } else clientDevicesText.push(device);
    // }

    var layers = [
        // new IconLayer({
        //     id: (d) => `${d.user_id}-userlayer-${Math.random()}`,
        //     data: clientDeviceSanitized,
        //     getPosition: (d) => d.point.coordinates,
        //     pickable: true,
        //     alphaCutoff: 1,
        //     autoHighlight: true,
        //     mask: true,
        //     highlightColor: [0, 0, 0, 40],
        //     onClick,
        //     // Note: getIcon functional icon picker causes logrocket toast, atlas map workaround
        //     iconAtlas: "https://orion-assets-s3.s3.us-east-2.amazonaws.com/user-location-green.png",
        //     iconMapping: {
        //         marker: {
        //             x: 0,
        //             y: 0,
        //             width: 150,
        //             height: 150,
        //             anchorY: 150,
        //             mask: false
        //         }
        //     },
        //     getIcon: (d) => {
        //         return "marker";
        //     },
        //     sizeUnits: "meters",
        //     sizeMinPixels: 44,
        //     sizeScale: 1,
        //     visible: showClientDevices && userProfile?.features?.tracking === true
        // }),
        // new TextLayer({
        //     data: clientDevicesText,
        //     id: (d) => `${d.user_id}-userTextlayer-${Math.random()}`,
        //     getPosition: (d) => d.point.coordinates,
        //     getText: (d) => {
        //         return d.user_name;
        //     },
        //     pickable: true,
        //     onClick,
        //     getPixelOffset: (d) => [0, d.user_name.length],
        //     getSize: 20,
        //     clusterRadius: 13,
        //     visible: showClientDevices && userProfile?.features?.tracking === true && showText
        // }),
        new ClusterLayer({
            id: "clusterLayer",
            data,
            pickable: true,
            autoHighlight: false,
            getPosition: (d) => {
                return [d.longitude, d.latitude];
            },
            onClick,
            colors,
            clusterRadius: 40,
            maxZoom: 13,
            minClusterSize: 3,
            transitionRadius: 1300,
            visible: markerClustering
        }),
        new ZoomIconLayer({
            id: "smallMarkers",
            data: dataToGeojson(data),
            pickable: true,
            getPosition: (d) => d.geometry.coordinates,
            filled: true,
            radiusScale: 10,
            getRadius: 1,
            colors,
            onClick,
            getLineColor: [0, 0, 0],
            getLineWidth: 2,
            transitionZoom: 8.5,
            visible: !markerClustering
        })
    ];
    return layers;
}
