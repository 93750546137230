import React from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { S3Key } from "../../utils";
import { useDispatch } from "react-redux";
import { leftModalActions } from "../../state/left-modal";
import { MenuLinkContainer, MenuItemText, LargeIconContainer } from "./styled";
import { Badge } from "@material-ui/core";
import { analyticsOperations } from "../../state/analytics";
import { forecastingAvailableContainers } from "../visualizations/utils";
import { cuserActions } from "../../state/cuser";
import { mapActions } from "../../state/map";
import { Container, ViewportBase } from "../../types/geo";

interface MenuLinkProps {
    type: "Newsfeed" | "Risk Forecasting" | "Map Filter" | "Groups" | "Add User" | "Account Settings" | "Reports" | "Analytics" | "Insights" | "Forecasting";
    reportsBadgeContent?: number;
    riskForecastingCurrentContainer?: Container;
    riskForecastingContainerViewportInfo?: ViewportBase;
    riskForecastingContainerOverlapId?: Container;
}

export const MenuLink = ({
    type,
    reportsBadgeContent,
    riskForecastingCurrentContainer,
    riskForecastingContainerViewportInfo,
    riskForecastingContainerOverlapId
}: MenuLinkProps) => {
    const lowercaseType = type.toLowerCase().replace(" ", "-");
    const dispatch = useDispatch();
    const colors = useSelectFromRedux((state) => state.color);

    const trackMenuLinkClickWithSegment = () => {
        window.analytics.track(type + " Visited From Menu");
    };

    const getReduxStringFromType = () => {
        if (type === "Newsfeed") {
            return "newsfeed";
        } else if (type === "Risk Forecasting") {
            return "visualizations";
        //} else if (type === "Insights") {
            //return "insights";
        } else if (type === "Forecasting") {
            return "insights";
        } else if (type === "Map Filter") {
            return "filter";
        } else if (type === "Groups") {
            return "groups";
        } else if (type === "Add User") {
            return "add-user";
        } else if (type === "Account Settings") {
            return "user";
        } else if (type === "Reports") {
            return "reports";
        } else if (type === "Analytics") {
            return "analytics";
        } else return "";
    };

    const getIconFromType = () => {
        if (type === "Newsfeed") {
            if (colors.mode === "light") {
                return NewsfeedDarkIcon;
            } else {
                return NewsfeedLightIcon;
            }
        } else if (type === "Risk Forecasting") {
            if (colors.mode === "light") {
                return RiskForecastingDarkIcon;
            } else {
                return RiskForecastingLightIcon;
            }
        } else if (type === "Map Filter") {
            if (colors.mode === "light") {
                return MapFilterDarkIcon;
            } else {
                return MapFilterLightIcon;
            }
        } else if (type === "Groups") {
            if (colors.mode === "light") {
                return GroupsDarkIcon;
            } else {
                return GroupsLightIcon;
            }
        } else if (type === "Add User") {
            if (colors.mode === "light") {
                return AddUserDarkIcon;
            } else {
                return AddUserLightIcon;
            }
        } else if (type === "Account Settings") {
            if (colors.mode === "light") {
                return AccountSettingsDarkIcon;
            } else {
                return AccountSettingsLightIcon;
            }
        } else if (type === "Reports") {
            if (colors.mode === "light") {
                return ReportsDarkIcon;
            } else {
                return ReportsLightIcon;
            }
        } else if (type === "Forecasting") {
            if (colors.mode === "light") {
                return InsightsDarkIcon;
            } else {
                return InsightsLightIcon;
            }
        } else if (type === "Analytics") {
            if (colors.mode === "light") {
                return AnalyticsDarkIcon;
            } else {
                return AnalyticsLightIcon;
            }
        }
    };

    const getOnClickActionFromType = () => {
        trackMenuLinkClickWithSegment();

        if (lowercaseType === "analytics") {
            dispatch(analyticsOperations.initAnalytics());
        } else {
            dispatch(
                leftModalActions.setModalContent({
                    modalContent: getReduxStringFromType()
                })
            );
        }

        if (type === "Risk Forecasting" && riskForecastingCurrentContainer && riskForecastingContainerViewportInfo && riskForecastingContainerOverlapId) {
            if (!forecastingAvailableContainers.includes(riskForecastingCurrentContainer.name)) {
                dispatch(
                    mapActions.flyToPoint([
                        riskForecastingContainerViewportInfo.longitude,
                        riskForecastingContainerViewportInfo.latitude,
                        riskForecastingContainerViewportInfo.zoom
                    ])
                );
                dispatch(cuserActions.updateCurrentContainer(riskForecastingContainerOverlapId.id as number));
            }
        }
    };

    return (
        <MenuLinkContainer onClick={() => getOnClickActionFromType()}>
            {type === "Reports" ? (
                <LargeIconContainer>
                    <Badge anchorOrigin={{ horizontal: "right", vertical: "top" }} color={"error"} badgeContent={reportsBadgeContent}>
                        <img src={getIconFromType()} alt={type} width="22px" />
                    </Badge>
                </LargeIconContainer>
            ) : (
                <LargeIconContainer>
                    <img src={getIconFromType()} alt={type} width="22px" />
                </LargeIconContainer>
            )}
            <MenuItemText color={colors.scheme.primaryText}>{type}</MenuItemText>
        </MenuLinkContainer>
    );
};

const NewsfeedLightIcon = S3Key + "newsfeed-white.png";
const NewsfeedDarkIcon = S3Key + "newsfeed-dark.png";
const RiskForecastingLightIcon = S3Key + "risk-forecasting-white.png";
const RiskForecastingDarkIcon = S3Key + "risk-forecasting-grey.png";
const MapFilterLightIcon = S3Key + "filter-white.png";
const MapFilterDarkIcon = S3Key + "filter-dark.png";
const GroupsLightIcon = S3Key + "teams-white.png";
const GroupsDarkIcon = S3Key + "teams-grey.png";
const AddUserLightIcon = S3Key + "add-user-white.png";
const AddUserDarkIcon = S3Key + "add-user-grey.png";
const AccountSettingsLightIcon = S3Key + "gear-white.png";
const AccountSettingsDarkIcon = S3Key + "gear-grey.png";
const ReportsLightIcon = S3Key + "reports-white.png";
const ReportsDarkIcon = S3Key + "reports-dark.png";
const AnalyticsLightIcon = S3Key + "analytics-white-new.png";
const AnalyticsDarkIcon = S3Key + "analytics-dark-new.png";
const InsightsLightIcon = S3Key + "insights-white.png";
const InsightsDarkIcon = S3Key + "insights-grey.png";