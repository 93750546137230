import { Operation, PromiseOperation } from "..";
import { ConnectionError, ErrorResponse, StatusCode } from "../../types/api";
import { cuserActions } from ".";
import { ContainerResponseItem, GeoAttribution } from "../../types/geo";
import { toastActions } from "../toast";
import * as authtypes from "../../types/api/auth";
import * as client from "../../types/api/client";
import { getSuggestedReportTypes } from "../../types/api/client";
import { messaging } from "../../api/push-notifications";
import { isChrome, isChromium, isFirefox, isIOS, isMobile, isOpera, isSafari } from "react-device-detect";
import * as fingerPrint from "fingerprintjs2";
import { Point } from "geojson";
import store from "../store";
import { filterActions } from "../filter";
import { Device, DeviceSubscription, Login } from "../../types/auth";
import { ClientProfile, UserProfile, UserSignUp } from "../../types/client";
import { AllReportTypes, ReportCategories, reportType } from "../../types/reports";
import { auth, signInFirebase } from "../../types/firebase";
import { Unsubscribe } from "firebase";

export const Global: { unsubscribeAuthStateListener: Unsubscribe | null; unsubscribeIdTokenListener: Unsubscribe | null } = {
    unsubscribeAuthStateListener: null,
    unsubscribeIdTokenListener: null
};

export const signIn: Operation = (email: string, password: string) => async (dispatch) => {
    try {
        const response: Login = await authtypes.signIn(email, password);
        console.log(response);
        // window.analytics.identify("97980cfea0067", {
        //     name: "Peter Gibbons",
        //     email: "peter@example.com",
        //     plan: "premium",
        //     logins: 5
        //   });

        dispatch(handleFirebaseSignIn(response.token, email));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
            switch (error.statusCode) {
                // email doesn't exist
                case 300:
                    dispatch(toastActions.openError("There is no account with this email. Please try again."));
                    break;
                // wrong password
                case 416:
                    dispatch(toastActions.openError("Incorrect password. Please try again."));
                    break;
                // anything else
                default:
                    dispatch(toastActions.openError("Error signing in. Please try again."));
                    break;
            }
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
            dispatch(toastActions.openError("Connection error. Please try again."));
        } else {
            console.error("Unknown error occurred in signIn: ", error);
            dispatch(toastActions.openError("Error signing in. Please try again."));
        }
        // TODO: Create better status codes SB-157
        dispatch(cuserActions.signOut());
    }
};

export const createAccountAndSignIn: Operation = (pendingUserUuid: string, signUp: UserSignUp) => async (dispatch) => {
    try {
        const response: Login = await authtypes.signUpPendingUser(pendingUserUuid, signUp);
        dispatch(handleFirebaseSignIn(response.token, signUp.email ?? signUp.phone_primary ?? null));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
            if (error.statusCode === StatusCode.ClientLogicError) {
                dispatch(toastActions.openError(error.message ?? "Something went wrong. Please try again."));
            } else {
                dispatch(toastActions.openError("Server error. Please try again or contact support."));
            }
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
            dispatch(toastActions.openError("Connection error. Please check your internet connection and try again."));
        } else {
            console.error("Unknown error occurred in signIn: ", error);
            dispatch(toastActions.openError("Something went wrong, please try again."));
        }
        // TODO: Create better status codes SB-157
        dispatch(cuserActions.signOut());
    }
};

export const signOut: Operation = () => async (dispatch) => {
    console.log("Initiating sign out...");
    try {
        if (Global.unsubscribeAuthStateListener) {
            try {
                Global.unsubscribeAuthStateListener();
            } catch (err) {
                console.error("Failed to unsubscribe AuthStateListener on sign out: " + err);
            }
        } else {
            console.warn("Failed to unsubscribe AuthStateListener, no listener exists.");
        }

        if (Global.unsubscribeIdTokenListener) {
            try {
                Global.unsubscribeIdTokenListener();
            } catch (err) {
                console.error("Failed to unsubscribe IdTokenListener on sign out: " + err);
            }
        } else {
            console.warn("Failed to unsubscribe IdTokenListener, no listener exists.");
        }

        dispatch(setToken(null));
        store.dispatch({ type: "RESET_APP" });
        auth.signOut()
            .then(() => console.log("Signed out of firebase."))
            .catch((err) => console.log("[Error] Sign out of firebase failed: " + err));
    } catch (err) {
        console.error("Error signing out: " + err);
        dispatch(setToken(null));
        store.dispatch({ type: "RESET_APP" });
    }
};

const handleFirebaseSignIn: Operation = (token: string, email?: string) => (dispatch) => {
    signInFirebase(auth, token)
        .then((userCredential) => {
            userCredential.user
                .getIdToken()
                .then((idToken) => {
                    dispatch(handleLogin(idToken));
                    // if (auth.currentUser && email) {
                    //     updateUserEmail(auth.currentUser, email).catch((err) => {
                    //         console.warn("Failed to set firebase user email: " + err);
                    //     });
                    // }
                })
                .catch((err: Error) => {
                    dispatch(toastActions.openError("Failed to sign in (Code: 67). Please try again or contact support."));
                    console.error("Failed to retrieve firebase user token: ", err);
                });
        })
        .catch((err) => {
            if (err.code === "auth/user-disabled") {
                dispatch(toastActions.openError("This account is disabled. Please contact support or your group administrator."));
            } else {
                dispatch(toastActions.openError("Failed to sign in (Code: 66). Please try again or contact support."));
                console.error("Failed to sign in via firebase: ", err);
            }
        });
};

export const setToken: Operation = (idToken: string | null) => async (dispatch) => {
    if (idToken) {
        console.log("Cached token to local storage: " + idToken);
        localStorage.setItem("idToken", idToken);
        dispatch(cuserActions.successfulSignIn(idToken));
    } else {
        dispatch(cuserActions.signOut());
        localStorage.removeItem("idToken");
    }
};

const handleLogin: Operation = (token: string) => async (dispatch) => {
    dispatch(setToken(token));
    const fingerPrint = localStorage.getItem("fingerprint") ? localStorage.getItem("fingerprint") : await GetDeviceFingerprint();
    const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS && isIOS && !isSafari && !isChrome && !isFirefox && !isChromium && !isOpera) {
        // pass token and fingerprint to iOS
        (window as any).webkit.messageHandlers.userLoggedIn.postMessage(token + " " + fingerPrint);
    }
};

export const changePassword: PromiseOperation<boolean> = (oldPassword: string, newPassword: string) => async (dispatch) => {
    try {
        await authtypes.changePassword(oldPassword, newPassword);
        // this shouldn't be an error, but as of now this is the only good way to do this.
        return true;
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in getCuserProfile: ", error);
        }
        dispatch(toastActions.openError("Error changing passwords. Make sure you entered your old password correctly."));
        return false;
    }
};

const getTop12ReportTypes = async (): Promise<reportType[]> => {
    const fromDB = await getSuggestedReportTypes();
    const ignored = store.getState().cuser.clientProfile?.ignored_report_types;
    // @ts-ignore
    const withoutOther = fromDB.filter(
        (type) =>
            type !== "Other" &&
            // && type !== "Roadblock/checkpoint"
            !ignored?.includes(type)
    );
    const result: reportType[] = [];
    let i: number;
    for (i = 0; i < 12; i++) {
        if (i < withoutOther.length) {
            result.push(withoutOther[i]);
        } else {
            let j: number = 0;
            while (result.includes(AllReportTypes[j])) {
                j++;
            }
            result.push(AllReportTypes[j]);
        }
    }
    return result;
};

const getRemainingReportTypes = async (): Promise<reportType[]> => {
    const top10 = await getTop12ReportTypes();
    const ignored = store.getState().cuser.clientProfile?.ignored_report_types;
    const result: reportType[] = [];
    AllReportTypes.forEach((type: reportType) => {
        if (!top10.includes(type) && !ignored?.includes(type)) {
            result.push(type);
        }
    });
    return result;
};

const getSosTop12ReportTypes = async (): Promise<reportType[]> => {
    const fromDB = await getSuggestedReportTypes();
    const ignored = store.getState().cuser.clientProfile?.ignored_report_types;
    // @ts-ignore
    const withoutOther = fromDB.filter(
        (type: reportType) =>
            type !== "Other" &&
            !ReportCategories.Hazard.includes(type) &&
            // type !== "Roadblock/checkpoint" &&
            !ignored?.includes(type)
    );
    const result: reportType[] = [];
    let i: number;
    for (i = 0; i < 12; i++) {
        if (i < withoutOther.length) {
            result.push(withoutOther[i]);
        } else {
            let j: number = 0;
            while (result.includes(AllReportTypes[j]) || ReportCategories.Hazard.includes(AllReportTypes[j])) {
                j++;
            }
            result.push(AllReportTypes[j]);
        }
    }
    return result;
};

const getSosRemainingReportTypes = async (): Promise<reportType[]> => {
    const top10 = await getSosTop12ReportTypes();
    const ignored = store.getState().cuser.clientProfile?.ignored_report_types;
    const result: reportType[] = [];
    AllReportTypes.forEach((type: reportType) => {
        if (!top10.includes(type) && !ReportCategories.Hazard.includes(type) && !ignored?.includes(type)) {
            result.push(type);
        }
    });
    return result;
};

export const getCuserProfile: Operation = () => async (dispatch) => {
    try {
        const profile: UserProfile = await client.user.getProfile();
        dispatch(cuserActions.successfulGetProfile(profile));

        // allows segment to tie a user to their actions
        window.analytics.identify(profile.id, {
            email: profile.email,
            title: profile.role === 1 ? "Admin" : "Staff",
            company: { id: profile.client_id },
            description: "Team ID: " + profile.team_id
        });

        if (profile.features?.filterTimeRangeDefault) {
            dispatch(filterActions.updateTimeFilter(profile.features?.filterTimeRangeDefault));
        }
        dispatch(cuserActions.updateTop12ReportTypes(await getTop12ReportTypes()));
        dispatch(cuserActions.updateRemainingReportTypes(await getRemainingReportTypes()));
        dispatch(cuserActions.updateSosTop12ReportTypes(await getSosTop12ReportTypes()));
        dispatch(cuserActions.updateSosRemainingReportTypes(await getSosRemainingReportTypes()));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in getCuserProfile: ", error);
        }
    }
};

export const getCuserClient: Operation = () => async (dispatch) => {
    try {
        const profile: ClientProfile = await client.getClient();
        dispatch(cuserActions.successfulGetClient(profile));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in getCuserClient: ", error);
        }
    }
};

export const getCuserContainers: Operation = () => async (dispatch) => {
    try {
        const response: ContainerResponseItem = await client.getContainers();
        dispatch(cuserActions.successfulGetContainers(response));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in getCuserClient: ", error);
        }
    }
};

export const getDevices: Operation = () => async (dispatch) => {
    try {
        const response: Device[] = await client.getDevices();
        dispatch(cuserActions.loadDevices(response));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
        } else {
            console.error("Unknown error occurred in getCuserClient: ", error);
        }
    }
};

export const subscribeDeviceToNotifications: PromiseOperation<boolean> = () => async (dispatch, getState) => {
    const user_id = getState().cuser?.userProfile?.id;
    const client_id = getState().cuser?.userProfile?.client_id;
    if (!user_id || !client_id) {
        console.error("Couldn't get user/client information for device subscription, something went wrong.");
        return false;
    }

    // Get Device fingerprint
    const deviceFingerprint: string | null = localStorage.getItem("fingerprint");
    if (!deviceFingerprint) {
        console.error("Could not get device fingerprint.");
        dispatch(toastActions.openError("There's an issue recognizing this device. Please try again."));
        return false;
    }

    const userDevices = getState().cuser?.devices;
    let deviceAlreadyRegistered = false;
    userDevices.forEach((device) => {
        if (device.device_fingerprint === deviceFingerprint) {
            deviceAlreadyRegistered = true;
        }
    });

    const point: Point | null = getState().cuser.latestDBPoint;
    if (!point) {
        //if (isMobile) {
            dispatch(toastActions.openError("Failed to subscribe to notifications: couldn't determine user location. Please restart the app and try again."));
            return false;
        //}
    } else {
        console.log("Position: ");
        console.log(point);
    }

    // FCM messaging setup starts here
    if (messaging) {
        try {
            await messaging.requestPermission();
        } catch (err) {
            console.log("No notification permissions! " + err);
            dispatch(toastActions.openError("Enable notification permissions for real-time updates."));
            return false;
        }
        try {
            await messaging.getToken().then(async (token) => {
                // create DeviceSubscription
                const subscription: DeviceSubscription = {
                    token,
                    device_type: isMobile ? "Mobile" : "Web",
                    device_fingerprint: deviceFingerprint
                };

                if (point) {
                    subscription.point = point;
                    subscription.modified_at = new Date();
                }

                // Calling subscribeDevice
                try {
                    if (deviceAlreadyRegistered) {
                        await client.updateSubscription(token, deviceFingerprint, point ?? undefined);
                        dispatch(cuserActions.updateDeviceSubscription(subscription));
                    } else {
                        await client.subscribeDevice(subscription);
                        dispatch(cuserActions.subscribeThisDevice({ user_id, client_id, ...subscription }));
                    }
                    dispatch(toastActions.openSuccess("Push notifications enabled"));
                    return true;
                } catch (error) {
                    if (error instanceof ErrorResponse) {
                        console.log("Error response: " + error.message);
                        dispatch(toastActions.openError("Error subscribing to notifications. Please contact support."));
                        if (error.message === "We have already registered this device for notifications, please try again") {
                            console.log("Error response: " + error.message);
                            dispatch(toastActions.openError(error.message));
                        } else {
                            console.log("Error response: " + error.message);
                            dispatch(toastActions.openError("Error subscribing to notifications. Please contact support."));
                        }
                    } else if (error instanceof ConnectionError) {
                        console.log("Connection error: " + error.message);
                        dispatch(toastActions.openError("No network connection available. Are you connected to the internet?"));
                    } else {
                        console.error("Unknown error occurred in subscribeDeviceToNotifications: ", error);
                        dispatch(toastActions.openError("Something went wrong. Please try again."));
                    }
                    return false;
                }
            });
        } catch (err) {
            if (err?.message && typeof err.message === "string" && err.message.includes("messaging/permission-blocked")) {
                console.log("Device error subscribing to notifications: " + err?.message);
                dispatch(
                    toastActions.openError(
                        "Failed to enable push notifications. Please ensure notification permissions are enabled for this site in your web browser."
                    )
                );
            } else {
                console.log("Device error subscribing to notifications: " + err?.message ?? "No message");
                dispatch(
                    toastActions.openError("There was an issue subscribing to notifications on this device. Please refresh and try again or contact support.")
                );
            }
            return false;
        }
    } else {
        console.log("FCM not supported on this device.");
        dispatch(toastActions.openError("Notifications are not supported on this device."));
    }
    return false;
};

export const removeDeviceSubscription: Operation = () => async (dispatch) => {
    // Get Device fingerprint
    const deviceFingerprint: string | null = localStorage.getItem("fingerprint");
    if (!deviceFingerprint) {
        console.error("Could not get device fingerprint.");
        dispatch(toastActions.openError("There's an issue recognizing this device. Please try again."));
        return;
    }

    try {
        await client.removeSubscription(deviceFingerprint);
        dispatch(cuserActions.removeDeviceSubscription(deviceFingerprint));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error response: " + error.message);
            dispatch(toastActions.openError("Error unsubscribing from notifications. Please contact support."));
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
            dispatch(toastActions.openError("No network connection available. Are you connected to the internet?"));
        } else {
            console.error("Unknown error occurred in removeDeviceSubscription: ", error);
            dispatch(toastActions.openError("Something went wrong. Please try again."));
        }
    }
};

export const sendLocationUpdate: PromiseOperation<GeoAttribution | null> = (position: Position) => async (dispatch) => {
    // Get Device fingerprint
    const deviceFingerprint: string | null = localStorage.getItem("fingerprint");
    if (!deviceFingerprint) {
        console.log("Could not send location update without device fingerprint.");
        return null;
    }

    const point: Point = {
        type: "Point",
        coordinates: [position.coords.longitude, position.coords.latitude]
    };

    try {
        const geoAttribution: GeoAttribution | null = await client.updateLocation(deviceFingerprint, point);
        dispatch(getDevices());
        return geoAttribution;
    } catch (error) {
        if (error instanceof ErrorResponse) {
            console.log("Error updating location: " + error.message);
            // dispatch(errorActions.openError("Error unsubscribing from notifications. Please contact support."));
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
            dispatch(toastActions.openError("No network connection available. Are you connected to the internet?"));
        } else {
            console.error("Unknown error occurred in sendLocationUpdate: ", error);
            // dispatch(errorActions.openError("Something went wrong. Please try again."));
        }
    }
    return null;
};

export function GetDeviceFingerprint(): Promise<string> {
    return new Promise((resolve: any, reject: any) => {
        fingerPrint
            .getPromise({})
            .then((value: fingerPrint.Component[]) => {
                const values: any[] = value.map((x) => x.value);
                const murmur: string = fingerPrint.x64hash128(values.join(""), 31);
                resolve(murmur);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
}

/**
 * Sending reset password code to client via email or SMS
 */
export const forgotPassword: PromiseOperation<boolean> = (email: string, isPhone: boolean) => async (dispatch) => {
    try {
        await authtypes.forgotPassword(email, isPhone);
        dispatch(toastActions.openSuccess("Verification code sent via " + (isPhone ? "SMS." : "email.")));
        return true;
    } catch (error) {
        if (error instanceof ErrorResponse) {
            if (error.statusCode === StatusCode.ClientLogicError) {
                dispatch(toastActions.openError(error.message ?? "Something went wrong, please try again."));
            } else {
                dispatch(toastActions.openError("Error sending verification code, please try again or contact support."));
            }
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
            dispatch(toastActions.openError("Error connecting to server. Please check your network connection and try again."));
        } else {
            console.error("Unknown error occurred in forgot password: ", error);
            dispatch(toastActions.openError("Something went wrong, please try again."));
        }
    }
    return false;
};

/**
 * Reset client password
 * @param email
 * @param code
 * @param newPassword
 */
export const resetPassword: PromiseOperation<void> = (emailOrPhone: string, code: string, newPassword: string) => async (dispatch) => {
    try {
        await authtypes.resetPassword(emailOrPhone, code, newPassword);
        dispatch(toastActions.openSuccess("Password reset successfully."));
    } catch (error) {
        if (error instanceof ErrorResponse) {
            if (error.statusCode === StatusCode.ClientLogicError) {
                dispatch(toastActions.openError(error.message ?? "Something went wrong, please try again."));
            } else {
                dispatch(toastActions.openError("Error resetting password, please try again or contact support."));
            }
            console.log("Error response: " + error.message);
        } else if (error instanceof ConnectionError) {
            console.log("Connection error: " + error.message);
            dispatch(toastActions.openError("Error connecting to server. Please check your network connection and try again."));
        } else {
            console.error("Unknown error occurred in reset password: ", error);
            dispatch(toastActions.openError("Something went wrong, please try again."));
        }
    }
};
