import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion, simpleAction } from "reductser";

const actionMap = {
    openError: payloadAction<string>(),
    openSuccess: payloadAction<string>(),
    closeToast: simpleAction()
};

export const toastActions = actionFactory(actionMap, "ERROR");

export type ToastAction = ActionUnion<typeof toastActions>;

// space to describe specific types of actions

export interface ToastState {
    isOpen: boolean;
    currentText: string;
    isError: boolean;
}

export const getInitialState = (): ToastState => ({
    isOpen: false,
    currentText: "",
    isError: true
});

const toastReducer = (state = getInitialState(), action: ToastAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "ERROR") {
            switch (action.type) {
                case "openError":
                    draftState.isOpen = true;
                    draftState.isError = true;
                    draftState.currentText = action.payload;
                    break;
                case "openSuccess":
                    draftState.isOpen = true;
                    draftState.isError = false;
                    draftState.currentText = action.payload;
                    break;
                case "closeToast":
                    draftState.isOpen = false;
                    draftState.currentText = "";
                    break;
                default:
                    break;
                // unreachableCode(action);
            }
        }
    });

export default toastReducer;
