import * as cuserOperations from "./operations";
import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion, simpleAction } from "reductser";
import { Container, DefaultRegion, ContainerResponseItem } from "../../types/geo";
import { ClientProfile, UserProfile } from "../../types/client";
import { Device, DeviceSubscription } from "../../types/auth";
import { reportType } from "../../types/reports";

import { Point } from "geojson";

const actionMap = {
    successfulSignIn: payloadAction<string>(),
    signOut: simpleAction(),
    successfulGetProfile: payloadAction<UserProfile>(),
    successfulGetClient: payloadAction<ClientProfile>(),
    successfulGetContainers: payloadAction<ContainerResponseItem>(),
    updateCurrentContainer: payloadAction<number>(),
    updateLocationServices: payloadAction<boolean>(),
    updatePoint: payloadAction<Point>(),
    loadDevices: payloadAction<Device[]>(),
    subscribeThisDevice: payloadAction<Device>(),
    updateDeviceSubscription: payloadAction<DeviceSubscription>(),
    removeDeviceSubscription: payloadAction<string>(),
    updateTop12ReportTypes: payloadAction<reportType[]>(),
    updateRemainingReportTypes: payloadAction<reportType[]>(),
    updateSosTop12ReportTypes: payloadAction<reportType[]>(),
    updateSosRemainingReportTypes: payloadAction<reportType[]>(),
    updateMilitaryMode: payloadAction<boolean>(),
    updateEditMode: payloadAction<boolean>()
};

export const cuserActions = actionFactory(actionMap, "CUSER");

export type CuserAction = ActionUnion<typeof cuserActions>;

export interface CuserState {
    signedIn: string | null;
    userProfile: UserProfile | null;
    clientProfile: ClientProfile | null;
    containers: Container[];
    defaultRegions: DefaultRegion[];
    locationServices: boolean;
    latestDBPoint: Point | null;
    devices: Device[];
    top12ReportTypes: reportType[];
    remainingReportTypes: reportType[];
    sosTop12ReportTypes: reportType[];
    sosRemainingReportTypes: reportType[];
    militaryMode: boolean;
    editMode: boolean;
}

export const getInitialState = (): CuserState => ({
    signedIn: localStorage.getItem("idToken") || null,
    userProfile: null,
    clientProfile: null,
    containers: [],
    defaultRegions: [],
    locationServices: false,
    latestDBPoint: null,
    devices: [],
    top12ReportTypes: [],
    remainingReportTypes: [],
    sosTop12ReportTypes: [],
    sosRemainingReportTypes: [],
    militaryMode: false,
    editMode: false
});

const CuserReducer = (state = getInitialState(), action: CuserAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "CUSER") {
            switch (action.type) {
                case "successfulSignIn":
                    draftState.signedIn = action.payload;
                    break;
                case "signOut":
                    draftState.signedIn = null;
                    break;
                case "successfulGetProfile":
                    draftState.userProfile = action.payload;
                    break;
                case "successfulGetClient":
                    draftState.clientProfile = action.payload;
                    break;
                case "successfulGetContainers":
                    draftState.containers = action.payload.containers;
                    draftState.defaultRegions = action.payload.default_regions;
                    break;
                case "updateCurrentContainer":
                    if (draftState.userProfile) {
                        draftState.userProfile = { ...draftState.userProfile, current_container: action.payload };
                    }
                    break;
                case "updateLocationServices":
                    draftState.locationServices = action.payload;
                    break;
                case "updatePoint":
                    draftState.latestDBPoint = action.payload;
                    break;
                case "loadDevices":
                    draftState.devices = action.payload;
                    break;
                case "subscribeThisDevice":
                    draftState.devices = addThisDeviceSubscription(draftState.devices, action.payload);
                    break;
                case "updateDeviceSubscription":
                    draftState.devices = updateThisDeviceSubscription(draftState.devices, action.payload);
                    break;
                case "removeDeviceSubscription":
                    draftState.devices = removeThisDeviceSubscription(draftState.devices, action.payload);
                    break;
                case "updateTop12ReportTypes":
                    draftState.top12ReportTypes = action.payload;
                    break;
                case "updateRemainingReportTypes":
                    draftState.remainingReportTypes = action.payload;
                    break;
                case "updateSosTop12ReportTypes":
                    draftState.sosTop12ReportTypes = action.payload;
                    break;
                case "updateSosRemainingReportTypes":
                    draftState.sosRemainingReportTypes = action.payload;
                    break;
                case "updateMilitaryMode":
                    draftState.militaryMode = action.payload;
                    localStorage.setItem("militaryMode", "" + action.payload);
                    break;
                case "updateEditMode":
                    draftState.editMode = action.payload;
                    break;
                default:
                    return;
            }
        }
    });

const addThisDeviceSubscription = (devices: Device[], thisDevice: Device): Device[] => {
    devices.push(thisDevice);
    return devices;
};

const updateThisDeviceSubscription = (devices: Device[], newSubscription: DeviceSubscription): Device[] => {
    const currentDeviceIndex = devices.findIndex((device) => device.device_fingerprint === newSubscription.device_fingerprint);
    if (currentDeviceIndex === -1) {
        console.error("Something went wrong while updating device subscription in redux.");
        return devices;
    }
    const currentDevice = devices[currentDeviceIndex];

    currentDevice.token = newSubscription.token;
    return devices;
};

const removeThisDeviceSubscription = (devices: Device[], fingerprint: string): Device[] => {
    const currentDeviceIndex = devices.findIndex((device) => device.device_fingerprint === fingerprint);
    if (currentDeviceIndex === -1) {
        console.error("Something went wrong while updating device subscription in redux.");
        return devices;
    }
    const currentDevice = devices[currentDeviceIndex];

    currentDevice.token = null;
    return devices;
};

export { cuserOperations };
export default CuserReducer;
