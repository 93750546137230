import { produce } from "immer";
import { simpleAction, actionFactory, ActionUnion, payloadAction } from "reductser";
import { clientInfoActions } from "../clientInfo";
import { ReportNotificationType, ClientReport, ClientAlertReport, PublicReport } from "../../types/reports";

export interface ReportToast {
    report_type: ReportNotificationType | "New-Quick";
    report: ClientReport | ClientAlertReport | PublicReport;
}

export interface BulletinToast {
    title: string;
    text: string;
}

const NotificationActionsMap = {
    toggleAlertNotificationDetails: simpleAction(),
    confirmAlertNotification: payloadAction<number>(),
    openReportNotification: payloadAction<ReportToast>(),
    openBulletinNotification: payloadAction<BulletinToast>(),
    openConfirmationNotification: simpleAction(),
    openCancelNotification: simpleAction(),
    openAlertConfirmBoard: simpleAction(),
    closeAlertConfirmBoard: simpleAction(),
    openQuickReportConfirmationNotification: payloadAction<number>(),
    openPasswordChangeConfirmation: simpleAction(),
    toggleEditUserProfile: payloadAction<boolean>(),
    toggleAddPendingUserConfirmation: payloadAction<boolean>()
};

export const notificationActions = actionFactory(NotificationActionsMap, "NOTIFICATIONS_INFO");

export type NotificationActions = ActionUnion<typeof notificationActions>;

export interface NotificationState {
    showAlertNotificationDetails: boolean;
    toast: ReportToast | null;
    bulletinToast: BulletinToast | null;
    confirmationToast: number;
    cancelToast: number;
    alertConfirmBoard: boolean;
    quickReportConfirmationNotification: number;
    changePasswordConfirmation: number;
    editUserProfile: boolean;
    addPendingUserConfirmation: boolean;
}

export const getInitialState = (): NotificationState => ({
    showAlertNotificationDetails: false,
    toast: null,
    bulletinToast: null,
    confirmationToast: 0,
    cancelToast: 0,
    alertConfirmBoard: false,
    quickReportConfirmationNotification: -1,
    changePasswordConfirmation: 0,
    editUserProfile: false,
    addPendingUserConfirmation: false
});

const notificationsReducer = (state = getInitialState(), action: NotificationActions) =>
    produce(state, (draftState) => {
        if (action.reducer === "NOTIFICATIONS_INFO") {
            switch (action.type) {
                case "toggleAlertNotificationDetails":
                    draftState.showAlertNotificationDetails = !draftState.showAlertNotificationDetails;
                    return;
                case "confirmAlertNotification":
                    draftState.showAlertNotificationDetails = false;
                    return clientInfoActions.confirmAlertNotification;
                case "openReportNotification":
                    draftState.toast = action.payload;
                    break;
                case "openBulletinNotification":
                    draftState.bulletinToast = action.payload;
                    break
                case "openConfirmationNotification":
                    draftState.confirmationToast = ++draftState.confirmationToast;
                    break;
                case "openCancelNotification":
                    draftState.cancelToast = ++draftState.cancelToast;
                    break;
                case "openAlertConfirmBoard":
                    draftState.alertConfirmBoard = true;
                    break;
                case "closeAlertConfirmBoard":
                    draftState.alertConfirmBoard = false;
                    break;
                case "openQuickReportConfirmationNotification":
                    draftState.quickReportConfirmationNotification = action.payload;
                    break;
                case "openPasswordChangeConfirmation":
                    draftState.changePasswordConfirmation = ++draftState.changePasswordConfirmation;
                    break;
                case "toggleEditUserProfile":
                    draftState.editUserProfile = action.payload;
                    break;
                case "toggleAddPendingUserConfirmation":
                    draftState.addPendingUserConfirmation = action.payload;
                    break;
                default:
                    return;
            }
        }
    });
export default notificationsReducer;