import styled from "styled-components";
import { VerticallyCenteredRow } from "../_shared";

export const MenuLinkContainer = styled(VerticallyCenteredRow)`
    margin-bottom: 30px;

    :active {
        font-weight: 600;
    }

    :hover {
        cursor: pointer;
    }
`;

interface MenuItemTextProps {
    color: string;
}

export const MenuItemText = styled.div`
    font-size: 16px;
    color: ${(props: MenuItemTextProps) => props.color};
    margin-left: 30px;
    transition: 0.4s;

    :hover {
        padding-left: 13px;
    }
`;

export const LargeIconContainer = styled.div`
    width: 22px;
    display: flex;
    justify-content: center;
    transition: 0.4s;

    :hover {
        margin-right: 13px;
    }
`;
