import React, { useMemo, useEffect, useState } from "react";
import { S3Key } from "../../utils";
import moment from "moment";

// redux
import { useDispatchPromise, useSelectFromRedux } from "../../utils/_hooks";
import { teamsActions, teamsOperations } from "../../state/teams";
import { useDispatch } from "react-redux";
import { leftModalActions } from "../../state/left-modal";
import { cuserActions } from "../../state/cuser";

// components
import {
    ReportItemContainer,
    ReportItemHeader,
    ReportContentItem,
    IconContainer,
    TextWithIconContainer,
    AdditionalDetailsContainer
} from "../_shared/report-summary/styled";
import { FilterContainer, EditButton, CloseButton } from "../_shared";
import { Dot, Button, Dropdown, FieldTitle, FieldContent, LoadingButton } from "./styled";
import Select from "antd/lib/select";
import { SelectValue } from "antd/lib/select";
import { Expand, ExpandItem } from "../update-report/styled";

const { Option } = Select;

const RoleGreyIcon = S3Key + "role-grey.png";
const RoleWhiteIcon = S3Key + "role-white.png";
const EditGreyIcon = S3Key + "edit-grey.png";
const EditWhiteIcon = S3Key + "edit-white.png";
const CloseGreyIcon = S3Key + "close-dark.png";
const CloseWhiteIcon = S3Key + "close-white.png";
const TeamsGreyIcon = S3Key + "teams-grey.png";
const TeamsWhiteIcon = S3Key + "teams-white.png";
const StarGreyIcon = S3Key + "star-grey.png";
const StarWhiteIcon = S3Key + "star-white.png";
const ErrorGreyIcon = S3Key + "error-grey.png";
const ErrorWhiteIcon = S3Key + "error-white.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const dispatch = useDispatch();
    const promiseDispatch = useDispatchPromise();

    const clientUsers = useSelectFromRedux((state) => state.clientInfo.users);
    const fullViewUserId = useSelectFromRedux((state) => state.teams.fullViewUserId);
    const userProfile = useSelectFromRedux((state) => state.cuser.userProfile);
    const modifyUserMode = useSelectFromRedux((state) => state.teams.modifyUserMode);
    const modifyUserRole = useSelectFromRedux((state) => state.teams.modifyUserRole);
    const modifyUserTeam = useSelectFromRedux((state) => state.teams.modifyUserTeam);
    const colors = useSelectFromRedux((state) => state.color);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const primaryEmail = useSelectFromRedux((state) => state.cuser.clientProfile?.primary_email);
    const clientDevices = useSelectFromRedux((state) => state.clientInfo.clientDevices);
    const [webDevice, updateWebDevice] = useState<boolean>();
    const [isDisablingUser, updateIsDisablingUser] = useState<boolean>(false);
    // const modifyUserDesignation = useSelectFromRedux((state) => state.teams.modifyUserDesignation);

    const fullUserProfile = useMemo(() => {
        const fullUserProfile = clientUsers.find((clientUser) => clientUser.id === fullViewUserId);
        return fullUserProfile;
    }, [clientUsers, fullViewUserId]);

    const clearAndCloseModifyUser = () => {
        dispatch(teamsActions.toggleModifyUserMode(false));
        dispatch(teamsActions.updateModifyUserRole(undefined));
        dispatch(teamsActions.updateModifyUserTeam(undefined));
        dispatch(teamsActions.updateModifyUserDesignation(undefined));
    };

    const lastOnline = useMemo(() => {
        const device = clientDevices.find((device) => device.user_id === fullViewUserId);
        if (device?.device_type === "Web") {
            updateWebDevice(true);
        } else updateWebDevice(false);
        return moment(device?.modified_at).format("YYYY-MM-DD HH:mm zz");
    }, [clientDevices, fullViewUserId]);

    useEffect(() => {
        if (fullUserProfile) {
            dispatch(teamsActions.updateModifyUserRole(fullUserProfile.role === 1 ? 1 : 0));
            dispatch(teamsActions.updateModifyUserDesignation(fullUserProfile.designation === "Medic" ? "Medic" : "None"));
            dispatch(teamsActions.updateModifyUserTeam(!fullUserProfile.team_id ? "Default Group" : fullUserProfile.team_id));
        }
    }, [dispatch, fullUserProfile]);

    const submitModifyUser = () => {
        dispatch(teamsOperations.modifyUser());
        dispatch(teamsActions.toggleModifyUserMode(false));
    };

    const getPrivilegeString = (role: number) => {
        if (role === 1) {
            return "Admin";
        } else {
            return "Standard User";
        }
    };

    const getTeamNameFromTeamId = (teamId: number | null | undefined) => {
        if (teamId === null || teamId === undefined) {
            return "Default Group";
        }
        const team = teams.filter((team) => team.id === teamId);
        if (team[0].name) {
            return team[0].name;
        } else {
            return "Group Not Found";
        }
    };

    const closeAndClear = () => {
        dispatch(teamsActions.toggleDisplayFullUserView(false));
        dispatch(teamsActions.updateFullViewUserId(null));
        dispatch(teamsActions.toggleModifyUserMode(false));
        dispatch(teamsActions.updateModifyUserRole(undefined));
        dispatch(teamsActions.updateModifyUserTeam(undefined));
    };

    const disableUser = () => {
        updateIsDisablingUser(true);

        const user_id = fullUserProfile?.id;
        const enable = fullUserProfile?.disabled;
        console.log("user is currently " + fullUserProfile?.disabled);

        promiseDispatch(teamsOperations.disableUser(user_id, enable)).then(() => {
            updateIsDisablingUser(false);
        });
    };

    return (
        <FilterContainer background={colors.scheme.modalBackground} boxshadow={colors.scheme.selectorBackground} style={{ top: "0px", maxHeight: "600px" }}>
            {(userProfile?.role === 1 || fullViewUserId === userProfile?.id) && (
                <EditButton
                    src={colors.mode === "light" ? EditGreyIcon : EditWhiteIcon}
                    alt="Edit"
                    onClick={() => {
                        if (userProfile?.id === fullUserProfile?.id) {
                            dispatch(cuserActions.updateEditMode(true));
                            dispatch(leftModalActions.setModalContent({ modalContent: "user" }));
                        } else {
                            dispatch(teamsActions.toggleModifyUserMode(!modifyUserMode));
                        }
                    }}
                />
            )}
            <CloseButton src={colors.mode === "light" ? CloseGreyIcon : CloseWhiteIcon} alt="Exit" onClick={() => closeAndClear()} />
            <ReportItemContainer
                style={{
                    marginBottom: "0px",
                    overflowY: "auto",
                    maxHeight: "400px",
                    marginTop: "25px",
                    paddingBottom: "0px",
                    paddingTop: "0px"
                }}
                background={colors.scheme.modalBackground}
            >
                <ReportItemHeader style={{ justifyContent: "flex-start", alignItems: "center" }}>
                    {fullUserProfile?.first_name && fullUserProfile?.last_name && (
                        <div style={{ fontSize: "18px" }}>{fullUserProfile?.first_name + " " + fullUserProfile?.last_name}</div>
                    )}
                    {fullUserProfile?.current_container !== null && fullUserProfile?.disabled !== true && (
                        <Dot background={colors.general.standardGreen} style={{ height: "12px", width: "12px", marginLeft: "7px" }} />
                    )}
                </ReportItemHeader>
                <ReportContentItem style={{ flexDirection: "column" }}>
                    <TextWithIconContainer>Last Online: {lastOnline}</TextWithIconContainer>
                    {webDevice && <TextWithIconContainer style={{ fontSize: 11 }}>(Approximate Location ~10km)</TextWithIconContainer>}
                </ReportContentItem>
                {!modifyUserMode && (
                    <ReportContentItem style={{ cursor: "pointer" }}>
                        <IconContainer>
                            <img src={colors.mode === "light" ? TeamsGreyIcon : TeamsWhiteIcon} alt="Team" style={{ height: "12px" }} />
                        </IconContainer>
                        <TextWithIconContainer>{getTeamNameFromTeamId(fullUserProfile?.team_id)}</TextWithIconContainer>
                    </ReportContentItem>
                )}
                {/*{fullUserProfile?.email && (*/}
                {/*    <ReportContentItem>*/}
                {/*        <IconContainer>*/}
                {/*            <img src={colors.mode === "light" ? MailGreyIcon : MailWhiteIcon} alt="Mail" style={{ height: "12px" }} />*/}
                {/*        </IconContainer>*/}
                {/*        <TextWithIconContainer>{fullUserProfile?.email}</TextWithIconContainer>*/}
                {/*    </ReportContentItem>*/}
                {/*)}*/}
                {fullUserProfile?.email === primaryEmail && (
                    <ReportContentItem>
                        <IconContainer>
                            <img src={colors.mode === "light" ? StarGreyIcon : StarWhiteIcon} alt="Star" style={{ height: "12px" }} />
                        </IconContainer>
                        <TextWithIconContainer>Primary Admin</TextWithIconContainer>
                    </ReportContentItem>
                )}
                {fullUserProfile && fullUserProfile?.email !== primaryEmail && !isNaN(fullUserProfile.role) && (
                    <ReportContentItem style={{ cursor: "pointer" }}>
                        <IconContainer>
                            <img src={colors.mode === "light" ? RoleGreyIcon : RoleWhiteIcon} alt="Team" style={{ height: "12px" }} />
                        </IconContainer>
                        <TextWithIconContainer>{getPrivilegeString(fullUserProfile.role)}</TextWithIconContainer>
                    </ReportContentItem>
                )}
                {/*{fullUserProfile && fullUserProfile.designation === "Medic" && (*/}
                {/*    <ReportContentItem style={{ cursor: "pointer" }}>*/}
                {/*        <IconContainer>*/}
                {/*            <img src={colors.mode === "light" ? MedicGreyIcon : MedicWhiteIcon} alt="Team" style={{ height: "12px" }} />*/}
                {/*        </IconContainer>*/}
                {/*        <TextWithIconContainer>{fullUserProfile.designation}</TextWithIconContainer>*/}
                {/*    </ReportContentItem>*/}
                {/*)}*/}
                {fullUserProfile && fullUserProfile.current_container === null && (
                    <ReportContentItem style={{ cursor: "pointer" }}>
                        <IconContainer>
                            <Dot background={"rgba(84,84,84,0.4)"} />
                        </IconContainer>
                        <TextWithIconContainer>Hasn't logged in yet</TextWithIconContainer>
                    </ReportContentItem>
                )}
                {fullUserProfile && fullUserProfile.disabled === true && (
                    <ReportContentItem style={{ cursor: "pointer" }}>
                        <IconContainer>
                            <img src={colors.mode === "light" ? ErrorGreyIcon : ErrorWhiteIcon} alt="not logged in" style={{ height: "12px" }} />{" "}
                        </IconContainer>
                        <TextWithIconContainer>Account disabled</TextWithIconContainer>
                    </ReportContentItem>
                )}
                {modifyUserMode && (
                    <div style={{ marginTop: "10px" }}>
                        {fullUserProfile && !isNaN(fullUserProfile.role) && fullUserProfile?.id !== userProfile?.id && (
                            <div style={{ margin: "10px 0" }}>
                                <div
                                    style={{
                                        margin: "5px 0",
                                        textTransform: "uppercase",
                                        color: colors.scheme.primaryText
                                    }}
                                >
                                    Role
                                </div>
                                <Dropdown
                                    value={modifyUserRole}
                                    dropdownMatchSelectWidth={false}
                                    showArrow={true}
                                    onChange={(e: SelectValue) => dispatch(teamsActions.updateModifyUserRole(e as number))}
                                >
                                    <Option value={1}>Admin</Option>
                                    <Option value={0}>Standard User</Option>
                                </Dropdown>
                            </div>
                        )}
                        {/*{fullUserProfile && (*/}
                        {/*    <div style={{ margin: "10px 0" }}>*/}
                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                margin: "5px 0",*/}
                        {/*                textTransform: "uppercase",*/}
                        {/*                color: colors.scheme.primaryText*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            Designation*/}
                        {/*        </div>*/}
                        {/*        <Dropdown*/}
                        {/*            value={modifyUserDesignation ? modifyUserDesignation : "None"}*/}
                        {/*            color={colors.scheme.primaryText}*/}
                        {/*            background={colors.scheme.selectorBackground}*/}
                        {/*            dropdownMatchSelectWidth={false}*/}
                        {/*            arrowcolor={colors.scheme.primaryText}*/}
                        {/*            showArrow={true}*/}
                        {/*            onChange={(e: SelectValue) => dispatch(teamsActions.updateModifyUserDesignation(e as string))}*/}
                        {/*        >*/}
                        {/*            <Option value="Medic">Medic</Option>*/}
                        {/*            <Option value="None">None</Option>*/}
                        {/*        </Dropdown>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {fullUserProfile?.email !== primaryEmail && (
                            <div style={{ margin: "10px 0" }}>
                                <div
                                    style={{
                                        margin: "5px 0",
                                        textTransform: "uppercase",
                                        color: colors.scheme.primaryText
                                    }}
                                >
                                    Group
                                </div>
                                <Dropdown
                                    value={modifyUserTeam}
                                    dropdownMatchSelectWidth={false}
                                    showArrow={true}
                                    onChange={(e: SelectValue) => dispatch(teamsActions.updateModifyUserTeam(e as number | string))}
                                >
                                    <Option value={0} key="default">
                                        Default Group
                                    </Option>
                                    {teams.map((team) => (
                                        <Option value={team.id} key={team.id}>
                                            {team.name}
                                        </Option>
                                    ))}
                                </Dropdown>
                            </div>
                        )}
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "5px" }}>
                            <Button color={"#4C4C4C"} background={colors.scheme.filterUnselectedTag} onClick={() => clearAndCloseModifyUser()}>
                                Cancel
                            </Button>
                            <Button color={"white"} background={colors.scheme.submitButtonBackground} onClick={() => submitModifyUser()}>
                                Submit
                            </Button>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <LoadingButton
                                style={{ marginTop: "20px", width: "70%", alignItems: "center", justifyContent: "center" }}
                                color={"white"}
                                loading={isDisablingUser}
                                background={fullUserProfile?.disabled ? colors.general.standardGreen : colors.general.sosCountdown}
                                onClick={() => disableUser()}
                            >
                                {fullUserProfile?.disabled ? "ENABLE" : "DISABLE"}
                            </LoadingButton>
                        </div>
                    </div>
                )}
                <AdditionalDetailsContainer>
                    <Expand expandIconPosition="right" headerColor={colors.scheme.primaryText} color={colors.scheme.primaryText}>
                        {(fullUserProfile?.profile && fullUserProfile?.profile?.phone_work) ||
                            fullUserProfile?.profile?.phone_home ||
                            fullUserProfile?.profile?.phone_cell ||
                            (fullUserProfile?.profile?.address && (
                                <ExpandItem header={"Profile"} key={1} style={{ margin: "-5px -15px" }}>
                                    {Boolean(fullUserProfile?.profile?.nickname) && (
                                        <>
                                            <FieldTitle>Nickname</FieldTitle>
                                            <FieldContent>{fullUserProfile?.profile?.nickname}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile?.phone_work && (
                                        <>
                                            <FieldTitle>phone (work)</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.phone_work}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile?.phone_home && (
                                        <>
                                            <FieldTitle>phone (home)</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.phone_home}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile?.phone_cell && (
                                        <>
                                            <FieldTitle>phone (cell)</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.phone_cell}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile?.address && (
                                        <>
                                            <FieldTitle>home address</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.address}</FieldContent>
                                        </>
                                    )}
                                </ExpandItem>
                            ))}
                        {fullUserProfile?.profile?.emergency_contact_1 && (
                            <ExpandItem header={"Emergency Contact 1"} key={2} style={{ margin: "-5px -15px" }}>
                                <FieldTitle>Name</FieldTitle>
                                <FieldContent>{fullUserProfile.profile?.emergency_contact_1.name}</FieldContent>
                                <div>
                                    {fullUserProfile?.profile?.emergency_contact_1.relationship && (
                                        <>
                                            <FieldTitle>emergency contact relationship</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.emergency_contact_1.relationship}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile.emergency_contact_1.phone_primary && (
                                        <>
                                            <FieldTitle>emergency phone (primary)</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.emergency_contact_1.phone_primary}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile.emergency_contact_1.phone_secondary && (
                                        <>
                                            <FieldTitle>emergency phone (secondary)</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.emergency_contact_1.phone_secondary}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile.emergency_contact_1.address && (
                                        <>
                                            <FieldTitle>Address</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.emergency_contact_1.address}</FieldContent>
                                        </>
                                    )}
                                </div>
                            </ExpandItem>
                        )}
                        {fullUserProfile?.profile?.emergency_contact_2 && (
                            <ExpandItem header={"Emergency Contact 2"} key={3} style={{ margin: "-5px -15px" }}>
                                <FieldTitle>Name</FieldTitle>
                                <FieldContent>{fullUserProfile.profile?.emergency_contact_2.name}</FieldContent>
                                <div>
                                    {fullUserProfile?.profile?.emergency_contact_2.relationship && (
                                        <>
                                            <FieldTitle>emergency contact relationship</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.emergency_contact_2.relationship}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile.emergency_contact_2.phone_primary && (
                                        <>
                                            <FieldTitle>emergency phone (primary)</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.emergency_contact_2.phone_primary}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile.emergency_contact_2.phone_secondary && (
                                        <>
                                            <FieldTitle>emergency phone (secondary)</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.emergency_contact_2.phone_secondary}</FieldContent>
                                        </>
                                    )}
                                    {fullUserProfile?.profile.emergency_contact_2.address && (
                                        <>
                                            <FieldTitle>Address</FieldTitle>
                                            <FieldContent>{fullUserProfile.profile.emergency_contact_2.address}</FieldContent>
                                        </>
                                    )}
                                </div>
                            </ExpandItem>
                        )}
                        {fullUserProfile?.profile?.medical && (
                            <ExpandItem header={"Medical"} key={4} style={{ margin: "-5px -15px" }}>
                                {fullUserProfile?.profile?.medical.dob && (
                                    <>
                                        <FieldTitle>Date of Birth</FieldTitle>
                                        <FieldContent>{fullUserProfile.profile?.medical.dob}</FieldContent>
                                    </>
                                )}
                                {fullUserProfile?.profile?.medical.blood_type && (
                                    <>
                                        <FieldTitle>Blood Type</FieldTitle>
                                        <FieldContent>{fullUserProfile.profile?.medical.blood_type}</FieldContent>
                                    </>
                                )}
                                {fullUserProfile?.profile?.medical.medical_conditions && (
                                    <>
                                        <FieldTitle>Medical Conditions</FieldTitle>
                                        <FieldContent>{fullUserProfile.profile?.medical.medical_conditions}</FieldContent>
                                    </>
                                )}
                                {fullUserProfile?.profile?.medical.preferred_hospital && (
                                    <>
                                        <FieldTitle>Preferred Hospital</FieldTitle>
                                        <FieldContent>{fullUserProfile.profile?.medical.preferred_hospital}</FieldContent>
                                    </>
                                )}
                                {fullUserProfile?.profile?.medical.doctor_1 && (
                                    <>
                                        <FieldTitle>Doctor 1</FieldTitle>
                                        <div style={{ marginLeft: "10px" }}>
                                            {fullUserProfile?.profile?.medical.doctor_1.name && (
                                                <>
                                                    <FieldTitle>Name</FieldTitle>
                                                    <FieldContent>{fullUserProfile.profile?.medical.doctor_1.name}</FieldContent>
                                                </>
                                            )}
                                            {fullUserProfile?.profile?.medical.doctor_1.office && (
                                                <>
                                                    <FieldTitle>Office</FieldTitle>
                                                    <FieldContent>{fullUserProfile.profile?.medical.doctor_1.office}</FieldContent>
                                                </>
                                            )}
                                            {fullUserProfile?.profile?.medical.doctor_1.phone && (
                                                <>
                                                    <FieldTitle>Phone</FieldTitle>
                                                    <FieldContent>{fullUserProfile.profile?.medical.doctor_1.phone}</FieldContent>
                                                </>
                                            )}
                                            {fullUserProfile?.profile?.medical.doctor_1.address && (
                                                <>
                                                    <FieldTitle>Address</FieldTitle>
                                                    <FieldContent>{fullUserProfile.profile?.medical.doctor_1.address}</FieldContent>
                                                </>
                                            )}
                                        </div>
                                    </>
                                )}
                                {fullUserProfile?.profile?.medical.doctor_2 && (
                                    <>
                                        <FieldTitle>Doctor 2</FieldTitle>
                                        <div style={{ marginLeft: "10px" }}>
                                            {fullUserProfile?.profile?.medical.doctor_2.name && (
                                                <>
                                                    <FieldTitle>Name</FieldTitle>
                                                    <FieldContent>{fullUserProfile.profile?.medical.doctor_2.name}</FieldContent>
                                                </>
                                            )}
                                            {fullUserProfile?.profile?.medical.doctor_2.office && (
                                                <>
                                                    <FieldTitle>Office</FieldTitle>
                                                    <FieldContent>{fullUserProfile.profile?.medical.doctor_2.office}</FieldContent>
                                                </>
                                            )}
                                            {fullUserProfile?.profile?.medical.doctor_2.phone && (
                                                <>
                                                    <FieldTitle>Phone</FieldTitle>
                                                    <FieldContent>{fullUserProfile.profile?.medical.doctor_2.phone}</FieldContent>
                                                </>
                                            )}
                                            {fullUserProfile?.profile?.medical.doctor_2.address && (
                                                <>
                                                    <FieldTitle>Address</FieldTitle>
                                                    <FieldContent>{fullUserProfile.profile?.medical.doctor_2.address}</FieldContent>
                                                </>
                                            )}
                                        </div>
                                    </>
                                )}
                            </ExpandItem>
                        )}
                    </Expand>
                </AdditionalDetailsContainer>
            </ReportItemContainer>
        </FilterContainer>
    );
};
