import { MapController } from "deck.gl";
import { WebMercatorViewport } from "react-map-gl";

export default class MyController extends MapController {
    constructor(
        options = {
            scrollZoom: true,
            dragPan: true,
            dragRotate: true,
            doubleClickZoom: true,
            touchZoom: true,
            touchRotate: true,
            keyboard: true
        }
    ) {
        super(options);
        this.events = ["mousedown"];
    }

    _unproject(pos) {
        const viewport = new WebMercatorViewport(this.controllerState.getViewportProps());
        return pos && viewport.unproject(pos);
    }

    // handleEvent(event) {
    //     super.handleEvent(event);
    //     if (event.type === "mousedown") {
    //         event.preventDefault();
    //         return true;
    //     }
    // }
}
