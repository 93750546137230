import ClusterLayer from "../layers/cluster-layer";
import { ScatterplotLayer } from "@deck.gl/layers";
import { getMarkerColor, dataToGeojson, timeFilterMarkers } from "../utils";

export default function renderLayers({ data = [], onClick, filter: { showTimeFilter = false }, colors, timeFilter }) {
    var layers = [
        new ClusterLayer({
            id: "clusterLayer",
            data,
            pickable: true,
            autoHighlight: false,
            getPosition: (d) => {
                return [d.longitude, d.latitude];
            },
            onClick,
            colors,
            clusterRadius: 40,
            maxZoom: 13,
            minClusterSize: 3,
            transitionRadius: 1300,
            visible: !showTimeFilter
        }),
        new ScatterplotLayer({
            id: "timeFilter-scatterplot",
            data: dataToGeojson(data),
            pickable: true,
            getPosition: (d) => d.geometry.coordinates,
            opacity: 0.5,
            filled: true,
            radiusScale: 10,
            getRadius: 1,
            onClick,
            updateTriggers: {
                getFillColor: [timeFilter]
            },
            visible: showTimeFilter,
            getLineColor: [0, 0, 0],
            getLineWidth: 2,
            radiusMinPixels: 5,
            radiusMaxPixels: 5,
            getFillColor: (d) => (timeFilterMarkers(d, timeFilter) ? getMarkerColor(d.properties.category, colors) : [0, 0, 0, 0])
        })
    ];

    return layers;
}
