import React from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { PublicInsight } from "../../types/client";
import InsightSummary from "./insight-summary";
import { InsightsRegionHeader, InsightsContainer } from "./styled";
import { S3Key } from "../../utils";

const NoDataWhiteIcon = S3Key + "no-data-white.png";
const NoDataGreyIcon = S3Key + "no-data-grey.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [colors, insights, current_container, containers] = useSelectFromRedux((state) => [
        state.color,
        state.publicInfo.insights,
        state.cuser.userProfile?.current_container,
        state.cuser.containers
    ]);

    localStorage.setItem("lastInsightAccess", JSON.stringify(new Date()));

    return (
        <>
            {/*<InsightsRegionHeader>Region: {containers.find((container) => container.id === current_container)?.name}</InsightsRegionHeader>*/}
            <InsightsContainer>
                {insights.filter((insight: PublicInsight) => insight.container_ids.includes(current_container || -1)).length > 0 &&
                    insights
                        .filter((insight: PublicInsight) => insight.container_ids.includes(current_container || -1))
                        .map((insight: PublicInsight) => <InsightSummary insight={insight} key={insight.id} />)}
                {insights.filter((insight: PublicInsight) => insight.container_ids.includes(current_container || -1)).length === 0 && (
                    <div style={{ textAlign: "center", width: "100%", padding: "50px 0" }}>
                        <img src={colors.mode === "light" ? NoDataGreyIcon : NoDataWhiteIcon} style={{ height: "22px", marginRight: "10px" }} alt="no data" />
                        No Insights Available For This Region
                    </div>
                )}
            </InsightsContainer>
        </>
    );
};