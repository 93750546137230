import React, { useState, useEffect } from "react";
import { FilterContainer, Row } from "../_shared";
import { useSelectFromRedux } from "../../utils/_hooks";
import { S3Key } from "../../utils";
import { ShortTextInput, ShortTextInputAutoComplete } from "../update-report/styled";
import { isMobile, isIOS } from "react-device-detect";
import { DefaultRegion } from "../../types/geo";
import { AllReportTypes } from "../../types/reports";
import Select, { SelectValue } from "antd/lib/select";
import { Button, Dropdown, StyledTag, ShowScrollbarDiv, FilterSectionHeader } from "./styled";
import { DefaultRegionNoViewport } from "../analytics";
import { Team, UserProfile } from "../../types/client";
const { Option } = Select;
const CloseWhiteIcon = S3Key + "close-white.png";
const CloseGreyIcon = S3Key + "close-dark.png";
const SearchGreyIcon = S3Key + "search-grey.png";
const SearchWhiteIcon = S3Key + "search-white.png";

export const ComponentFilter = ({
    setFilterOpen,
    currentContainer,
    updateCurrentContainerAndRegions,
    regionSelections,
    selectedRegion,
    updateSelectedRegion,
    clearPostedBy,
    individualUserChecked,
    removeFromFilteredUsers,
    updateIndividualUserChecked,
    userAutofillValue,
    individualTeamChecked,
    updateIndividualTeamChecked,
    setTeamFilter,
    teamAutofillValue,
    myReportsChecked,
    myTeamChecked,
    violentChecked,
    nonviolentChecked,
    hazardChecked,
    otherChecked,
    updateMyReportsChecked,
    updateMyTeamChecked,
    updateUserAutofillValue,
    updateTeamAutofillValue,
    setIndividualUserChecked,
    setIndividualTeamChecked,
    updateViolentChecked,
    updateNonviolentChecked,
    updateHazardChecked,
    updateOtherChecked,
    eventTypeSearchOpen,
    setSelectedEventTypes,
    updateEventTypeSearchOpen,
    selectedEventTypes,
    eventChecked,
    updateEventChecked,
    selectedTimePeriod,
    updateTimePeriod,
    sort,
    updateSort,
    filterChanged,
    filterChangedFromDefault,
    updateFilter,
    resetFilter,
    verifiedChecked,
    updateVerifiedChecked,
    unverifiedChecked,
    updateUnverifiedChecked
}: ComponentFilterProps) => {
    const colors = useSelectFromRedux((state) => state.color);
    const availableContainers = useSelectFromRedux((state) => state.cuser.containers);
    const cuser = useSelectFromRedux((state) => state.cuser);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const cuserTeam = teams.filter((team) => team.id === cuser?.userProfile?.team_id)[0] ?? null;
    const users = useSelectFromRedux((state) => state.clientInfo.users);
    const [defaultTeams, updateDefaultTeams] = useState<Team[]>(teams);
    const timePeriodOptions = ["All Time", "28 Day", "7 Day", "24 Hour"];
    const { modalContent } = useSelectFromRedux((state) => state.leftModal);

    const userNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    users
                        .filter((user) => user.id !== cuser.userProfile?.id)
                        .map((user: UserProfile) => ({
                            value: user.first_name + " " + user.last_name + " (" + user.email + ")",
                            key: user.id
                        }))
                )
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [users]
    );

    let teamNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    defaultTeams.map((team: Team) => ({
                        value: team.name,
                        key: team.id
                    }))
                )
            ),
        [defaultTeams]
    );

    let eventTypeNames = React.useMemo(
        () =>
            Array.from(
                new Set(
                    AllReportTypes.map((reportType: string) => ({
                        value: reportType,
                        key: reportType
                    }))
                )
            ),
        []
    );

    useEffect(() => {
        if (!(cuser.userProfile?.team_id === null && cuser.userProfile.role === 1)) {
            let filteredTeams;

            if ((cuserTeam !== null && cuserTeam.permissions === 0) || cuser.userProfile?.team_id === null) {
                filteredTeams = defaultTeams.filter((team) => team.permissions === 0 || team.id === cuser.userProfile?.team_id);
            }

            if (cuserTeam !== null && cuserTeam.permissions === 1) {
                filteredTeams = defaultTeams.filter((team) => team.id === cuserTeam.id || team.id === cuser.userProfile?.team_id);
            }

            if (filteredTeams) {
                updateDefaultTeams(filteredTeams);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedEventTypes.length > 0) {
            updateViolentChecked(false);
            updateNonviolentChecked(false);
            updateHazardChecked(false);
            updateOtherChecked(false);
        }
    }, [selectedEventTypes, updateViolentChecked, updateNonviolentChecked, updateHazardChecked, updateOtherChecked]);

    useEffect(() => {
        if (violentChecked === true || nonviolentChecked === true || hazardChecked === true || otherChecked === true) {
            setSelectedEventTypes("all", "clear");
            updateEventTypeSearchOpen(false);
        }
        // don't want it to close searchbar because category is checked
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [violentChecked, nonviolentChecked, hazardChecked, otherChecked]);

    return (
        <FilterContainer style={{ marginTop: "70px" }} background={colors.scheme.filterModal} boxshadow={colors.scheme.selectorBackground}>
            <img
                src={colors.mode === "light" ? CloseGreyIcon : CloseWhiteIcon}
                alt="close"
                style={{
                    position: "absolute",
                    right: "10px",
                    marginTop: "-10px",
                    height: "20px",
                    cursor: "pointer"
                }}
                onClick={() => setFilterOpen(false)}
            />
            <>
                <FilterSectionHeader color={colors.scheme.primaryText} space={false}>
                    Region
                </FilterSectionHeader>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: "10px"
                    }}
                >
                    {updateCurrentContainerAndRegions && (
                        <div
                            style={{
                                display: "flex",
                                textAlign: "left",
                                borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                            }}
                        >
                            <Dropdown
                                defaultValue={-2}
                                value={currentContainer}
                                onChange={(value) => updateCurrentContainerAndRegions(value as number)}
                                showArrow={true}
                                color={colors.scheme.cardLightCaptionText}
                                background={"transparent"}
                                bordered={false}
                                arrowcolor={colors.scheme.primaryText}
                                style={{ textAlign: "left" }}
                            >
                                <Option key={-2} value={-2}>
                                    All Regions
                                </Option>
                                {availableContainers.map((container) => {
                                    return (
                                        <Option key={container.id} value={container.id}>
                                            {container.name}{" "}
                                        </Option>
                                    );
                                })}
                                <Option key={-1} value={-1}>
                                    No Region
                                </Option>
                            </Dropdown>
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                        }}
                    >
                        <Dropdown
                            defaultValue={selectedRegion || undefined}
                            value={selectedRegion}
                            onChange={(value) => updateSelectedRegion(value as number)}
                            showArrow={true}
                            color={colors.scheme.cardLightCaptionText}
                            arrowcolor={colors.scheme.primaryText}
                            background={"transparent"}
                            bordered={false}
                            style={{ textAlign: "left" }}
                        >
                            {regionSelections.map((region: DefaultRegion | DefaultRegionNoViewport) => {
                                return (
                                    <Option key={region.id} value={region.id}>
                                        {region.name}{" "}
                                    </Option>
                                );
                            })}
                        </Dropdown>
                    </div>
                </div>
            </>
            {modalContent === "reports" &&
                updateMyReportsChecked &&
                updateMyTeamChecked &&
                updateIndividualUserChecked &&
                updateUserAutofillValue &&
                updateIndividualTeamChecked &&
                updateTeamAutofillValue && (
                    <>
                        <FilterSectionHeader color={colors.scheme.primaryText} space={true}>
                            Posted by
                        </FilterSectionHeader>
                        <div
                            onClick={clearPostedBy}
                            style={{
                                position: "absolute",
                                color: colors.scheme.secondaryHeaderText,
                                fontWeight: "bold",
                                right: "35px",
                                top: "89px",
                                cursor: "pointer",
                                textTransform: "uppercase",
                                fontSize: "12px"
                            }}
                        >
                            Clear
                        </div>
                        {individualUserChecked && removeFromFilteredUsers && updateIndividualUserChecked && (
                            <ShortTextInputAutoComplete
                                options={userNames}
                                filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                color={colors.scheme.primaryText}
                                background={colors.scheme.filterModalBottomBar}
                                onSelect={(value: string, option) => {
                                    removeFromFilteredUsers(option.key as number);
                                    updateIndividualUserChecked(false);
                                }}
                                style={{ margin: "0 0 10px", width: "calc(100% - 8px)" }}
                                allowClear={isMobile && isIOS ? false : true}
                                placeholdercolor={colors.scheme.primaryText}
                            >
                                <ShortTextInput maxLength={30} placeholder="Find A User" value={userAutofillValue} />
                            </ShortTextInputAutoComplete>
                        )}
                        {individualTeamChecked && updateIndividualTeamChecked && setTeamFilter && (
                            <ShortTextInputAutoComplete
                                options={teamNames}
                                filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                color={colors.scheme.primaryText}
                                background={colors.scheme.filterModalBottomBar}
                                onSelect={(value: string, option) => {
                                    updateIndividualTeamChecked(false);
                                    setTeamFilter(option.key as number);
                                }}
                                style={{ margin: "0 0 10px", width: "calc(100% - 8px)" }}
                                allowClear={isMobile && isIOS ? false : true}
                                placeholdercolor={colors.scheme.primaryText}
                            >
                                <ShortTextInput maxLength={30} placeholder="Find A Team" value={teamAutofillValue} />
                            </ShortTextInputAutoComplete>
                        )}
                        <ShowScrollbarDiv
                            color={colors.scheme.primaryText}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: "5px",
                                width: "100%",
                                maxHeight: "153px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                scrollSnapType: "y mandatory",
                                scrollSnapStop: "always"
                            }}
                        >
                            <StyledTag
                                fontcolor={myReportsChecked ? "#FFFFFF" : "#4C4C4C"}
                                background={myReportsChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                                checked={myReportsChecked ?? false}
                                onChange={(checked) => {
                                    updateMyReportsChecked(!myReportsChecked);
                                    updateMyTeamChecked(false);
                                    updateIndividualUserChecked(false);
                                    updateUserAutofillValue(undefined);
                                    updateIndividualTeamChecked(false);
                                    updateTeamAutofillValue(undefined);
                                }}
                            >
                                <div style={{ width: "90%" }}>My Reports</div>
                                {myReportsChecked && (
                                    <img
                                        src={CloseWhiteIcon}
                                        alt={"close"}
                                        style={{
                                            position: "relative",
                                            marginTop: "3px",
                                            height: "12px",
                                            zIndex: 100,
                                            justifySelf: "right"
                                        }}
                                    />
                                )}
                            </StyledTag>
                            {(!!(cuserTeam === null) || (!!(cuserTeam !== null) && !!(cuserTeam.permissions !== 1))) && (
                                <StyledTag
                                    fontcolor={myTeamChecked ? "#FFFFFF" : "#4C4C4C"}
                                    background={myTeamChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                                    checked={myTeamChecked ?? false}
                                    onChange={(checked) => {
                                        updateMyReportsChecked(false);
                                        updateMyTeamChecked(!myTeamChecked);
                                        updateIndividualUserChecked(false);
                                        updateUserAutofillValue(undefined);
                                        updateIndividualTeamChecked(false);
                                        updateTeamAutofillValue(undefined);
                                    }}
                                >
                                    <div style={{ width: "90%" }}>My Group</div>
                                    {myTeamChecked && (
                                        <img
                                            src={CloseWhiteIcon}
                                            alt={"close"}
                                            style={{
                                                position: "relative",
                                                marginTop: "3px",
                                                height: "12px",
                                                zIndex: 100,
                                                justifySelf: "right"
                                            }}
                                        />
                                    )}
                                </StyledTag>
                            )}
                            {setIndividualUserChecked && (
                                <StyledTag
                                    fontcolor={userAutofillValue ? "#FFFFFF" : "#4C4C4C"}
                                    background={userAutofillValue ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                                    checked={individualUserChecked ?? false}
                                    onChange={(checked) => setIndividualUserChecked()}
                                >
                                    <div style={{ overflow: "hidden", display: "flex", alignItems: "center" }}>
                                        {userAutofillValue ? userAutofillValue : "Search Members"}
                                        <img
                                            src={colors.mode === "light" ? SearchGreyIcon : SearchWhiteIcon}
                                            alt={"search"}
                                            style={{
                                                height: "12px",
                                                marginLeft: "5px"
                                            }}
                                        />
                                    </div>
                                </StyledTag>
                            )}
                            {(!!(cuserTeam === null) || (!!(cuserTeam !== null) && !!(cuserTeam.permissions !== 1))) && setIndividualTeamChecked && (
                                <StyledTag
                                    fontcolor={teamAutofillValue ? "#FFFFFF" : "#4C4C4C"}
                                    background={teamAutofillValue ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                                    checked={individualTeamChecked ?? false}
                                    onChange={(checked) => setIndividualTeamChecked()}
                                >
                                    <div style={{ overflow: "hidden", display: "flex", alignItems: "center" }}>
                                        {teamAutofillValue ? teamAutofillValue : "Search Groups"}
                                        <img
                                            src={colors.mode === "light" ? SearchGreyIcon : SearchWhiteIcon}
                                            alt={"search"}
                                            style={{
                                                height: "12px",
                                                marginLeft: "5px"
                                            }}
                                        />
                                    </div>
                                </StyledTag>
                            )}
                        </ShowScrollbarDiv>
                    </>
                )}
            <FilterSectionHeader color={colors.scheme.primaryText} space={true}>
                Category of Incident
            </FilterSectionHeader>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                    width: "100%"
                }}
            >
                <StyledTag
                    fontcolor={violentChecked ? "#FFFFFF" : "#4C4C4C"}
                    background={violentChecked ? colors.scheme.filterViolentTag : colors.scheme.filterUnselectedTag}
                    checked={violentChecked}
                    onChange={(checked) => updateViolentChecked(!violentChecked)}
                >
                    <div>Violent</div>
                    {violentChecked && (
                        <img
                            src={CloseWhiteIcon}
                            alt={"close"}
                            style={{
                                position: "relative",
                                marginTop: "3px",
                                height: "12px",
                                zIndex: 100,
                                justifySelf: "right"
                            }}
                        />
                    )}
                </StyledTag>
                <StyledTag
                    fontcolor={nonviolentChecked ? "#FFFFFF" : "#4C4C4C"}
                    background={nonviolentChecked ? colors.scheme.filterNonViolentTag : colors.scheme.filterUnselectedTag}
                    checked={nonviolentChecked}
                    onChange={(checked) => updateNonviolentChecked(!nonviolentChecked)}
                >
                    <div>Nonviolent</div>
                    {nonviolentChecked && (
                        <img
                            src={CloseWhiteIcon}
                            alt={"close"}
                            style={{
                                position: "relative",
                                marginTop: "3px",
                                height: "12px",
                                zIndex: 100,
                                justifySelf: "right"
                            }}
                        />
                    )}
                </StyledTag>
                <StyledTag
                    fontcolor={hazardChecked ? "#FFFFFF" : "#4C4C4C"}
                    background={hazardChecked ? colors.scheme.filterHazardTag : colors.scheme.filterUnselectedTag}
                    checked={hazardChecked}
                    onChange={(checked) => updateHazardChecked(!hazardChecked)}
                >
                    <div>Hazard</div>
                    {hazardChecked && (
                        <img
                            src={CloseWhiteIcon}
                            alt={"close"}
                            style={{
                                position: "relative",
                                marginTop: "3px",
                                height: "12px",
                                zIndex: 100,
                                justifySelf: "right"
                            }}
                        />
                    )}
                </StyledTag>
                <StyledTag
                    fontcolor={otherChecked ? "#FFFFFF" : "#4C4C4C"}
                    background={otherChecked ? colors.scheme.filterSelectedTag : colors.scheme.filterUnselectedTag}
                    checked={otherChecked}
                    onChange={(checked) => updateOtherChecked(!otherChecked)}
                >
                    <div>Other</div>
                    {otherChecked && (
                        <img
                            src={CloseWhiteIcon}
                            alt={"close"}
                            style={{
                                position: "relative",
                                marginTop: "3px",
                                height: "12px",
                                zIndex: 100,
                                justifySelf: "right"
                            }}
                        />
                    )}
                </StyledTag>
            </div>
            <FilterSectionHeader color={colors.scheme.primaryText} space={true}>
                Type of Event
            </FilterSectionHeader>
            {eventTypeSearchOpen && (
                <ShortTextInputAutoComplete
                    options={eventTypeNames}
                    filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    color={colors.scheme.primaryText}
                    background={colors.scheme.selectorBackground}
                    onSelect={(value: string, option) => setSelectedEventTypes(value, "add")}
                    style={{ margin: "0 0 10px", width: "calc(100% - 8px)" }}
                    allowClear={isMobile && isIOS ? false : true}
                    placeholdercolor={colors.scheme.secondaryHeaderText}
                >
                    <ShortTextInput maxLength={30} placeholder="Find An Event Type" />
                </ShortTextInputAutoComplete>
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                    width: "100%"
                }}
            >
                {selectedEventTypes.length === 0 && (
                    <StyledTag
                        fontcolor={"#4C4C4C"}
                        background={colors.scheme.filterUnselectedTag}
                        checked={eventTypeSearchOpen}
                        onChange={(checked) => updateEventTypeSearchOpen(!eventTypeSearchOpen)}
                    >
                        <div>Search Event Types</div>
                    </StyledTag>
                )}
                {selectedEventTypes.map((eventType) => (
                    <StyledTag
                        fontcolor={"#FFFFFF"}
                        background={colors.scheme.filterSelectedTag}
                        checked={eventChecked}
                        key={eventType}
                        onChange={() => {
                            updateEventChecked(!eventChecked);
                            setSelectedEventTypes(eventType, "delete");
                        }}
                    >
                        <div>{eventType}</div>
                        <img
                            src={CloseWhiteIcon}
                            alt={"close"}
                            style={{
                                position: "relative",
                                marginTop: "3px",
                                height: "12px",
                                zIndex: 100,
                                justifySelf: "right"
                            }}
                        />
                    </StyledTag>
                ))}
            </div>
            {modalContent === "newsfeed" && updateVerifiedChecked && updateUnverifiedChecked && (
                <>
                    <FilterSectionHeader color={colors.scheme.primaryText} space={true}>
                        Verification
                    </FilterSectionHeader>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "5px"
                        }}
                    >
                        <StyledTag
                            fontcolor={verifiedChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={verifiedChecked ? colors.general.standardGreen : colors.scheme.filterUnselectedTag}
                            checked={verifiedChecked ?? false}
                            onChange={(checked) => updateVerifiedChecked(!verifiedChecked)}
                        >
                            <div>Verified</div>
                            {verifiedChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                        <StyledTag
                            fontcolor={unverifiedChecked ? "#FFFFFF" : "#4C4C4C"}
                            background={unverifiedChecked ? colors.general.amber : colors.scheme.filterUnselectedTag}
                            checked={unverifiedChecked ?? false}
                            onChange={(checked) => updateUnverifiedChecked(!unverifiedChecked)}
                        >
                            <div>Unverified</div>
                            {unverifiedChecked && (
                                <img
                                    src={CloseWhiteIcon}
                                    alt={"close"}
                                    style={{
                                        position: "relative",
                                        marginTop: "3px",
                                        height: "12px",
                                        zIndex: 100,
                                        justifySelf: "right"
                                    }}
                                />
                            )}
                        </StyledTag>
                    </div>
                </>
            )}
            <Row style={{ justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <FilterSectionHeader color={colors.scheme.primaryText} space={false}>
                        Time Period
                    </FilterSectionHeader>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "10px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                textAlign: "left",
                                borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                            }}
                        >
                            <Dropdown
                                onChange={(value: any) => {
                                    value = parseInt(value);
                                    updateTimePeriod(timePeriodOptions[value]);
                                }}
                                value={timePeriodOptions.indexOf(selectedTimePeriod)}
                                defaultValue={timePeriodOptions.indexOf(selectedTimePeriod)}
                                showArrow={true}
                                color={colors.scheme.cardLightCaptionText}
                                background={"transparent"}
                                bordered={false}
                                arrowcolor={colors.scheme.primaryText}
                                style={{ textAlign: "left" }}
                            >
                                {timePeriodOptions.map((el, index) => {
                                    return (
                                        <Select.Option value={index} key={index}>
                                            {el}
                                        </Select.Option>
                                    );
                                })}
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {modalContent === "reports" && updateSort && (
                    <>
                        <div style={{ display: "flex", flexDirection: "column", paddingRight: "20px" }}>
                            <FilterSectionHeader color={colors.scheme.primaryText} space={false}>
                                Sort by
                            </FilterSectionHeader>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingBottom: "10px"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        textAlign: "left",
                                        borderBottom: "1px solid ".concat(colors.scheme.secondaryHeaderText)
                                    }}
                                >
                                    <Dropdown
                                        onChange={(value: SelectValue) => {
                                            updateSort((value as "Time Occurred") || "Time Submitted");
                                        }}
                                        value={sort}
                                        defaultValue={"Time Occurred"}
                                        showArrow={true}
                                        color={colors.scheme.cardLightCaptionText}
                                        background={"transparent"}
                                        bordered={false}
                                        arrowcolor={colors.scheme.primaryText}
                                        style={{ textAlign: "left" }}
                                    >
                                        {["Time Occurred", "Time Submitted"].map((value) => {
                                            return (
                                                <Select.Option value={value} key={value}>
                                                    {value}
                                                </Select.Option>
                                            );
                                        })}
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Row>
            <div
                style={{
                    width: "calc(100% + 40px)",
                    display: "flex",
                    alignSelf: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                    background: colors.scheme.filterModalBottomBar,
                    margin: "10px -20px -20px -20px",
                    borderRadius: "0 0 5px 5px"
                }}
            >
                <Button
                    onClick={() => {
                        updateFilter();
                    }}
                    active={filterChanged}
                    color={filterChanged ? colors.scheme.submitButtonText : "#4C4C4C"}
                    background={filterChanged ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag}
                >
                    {isMobile ? "Apply" : "Update"}
                </Button>
                <Button
                    onClick={() => {
                        resetFilter();
                    }}
                    active={filterChangedFromDefault}
                    color={filterChangedFromDefault ? colors.scheme.submitButtonText : "#4C4C4C"}
                    background={filterChangedFromDefault ? colors.scheme.submitButtonBackground : colors.scheme.filterUnselectedTag}
                >
                    Reset
                </Button>
            </div>
        </FilterContainer>
    );
};

interface ComponentFilterProps {
    setFilterOpen: (newValue: boolean) => void;
    currentContainer?: number;
    updateCurrentContainerAndRegions?: (newValue: number) => void;
    regionSelections: (DefaultRegionNoViewport | DefaultRegion)[];
    selectedRegion: number;
    updateSelectedRegion: (newValue: number) => void;
    clearPostedBy?: () => void;
    individualUserChecked?: boolean;
    removeFromFilteredUsers?: (newValue: number) => void;
    updateIndividualUserChecked?: (newValue: boolean) => void;
    userAutofillValue?: string | undefined;
    individualTeamChecked?: boolean;
    updateIndividualTeamChecked?: (newValue: boolean) => void;
    setTeamFilter?: (newValue: number) => void;
    teamAutofillValue?: string | undefined;
    myReportsChecked?: boolean;
    myTeamChecked?: boolean;
    violentChecked: boolean;
    nonviolentChecked: boolean;
    hazardChecked: boolean;
    otherChecked: boolean;
    updateMyReportsChecked?: (newValue: boolean) => void;
    updateMyTeamChecked?: (newValue: boolean) => void;
    updateUserAutofillValue?: (newValue: string | undefined) => void;
    updateTeamAutofillValue?: (newValue: string | undefined) => void;
    setIndividualUserChecked?: () => void;
    setIndividualTeamChecked?: () => void;
    updateViolentChecked: (newValue: boolean) => void;
    updateNonviolentChecked: (newValue: boolean) => void;
    updateHazardChecked: (newValue: boolean) => void;
    updateOtherChecked: (newValue: boolean) => void;
    eventTypeSearchOpen: boolean;
    setSelectedEventTypes: (newValue: string, operation: string) => void;
    updateEventTypeSearchOpen: (newValue: boolean) => void;
    selectedEventTypes: string[];
    eventChecked: boolean;
    updateEventChecked: (newValue: boolean) => void;
    selectedTimePeriod: string;
    updateTimePeriod: (newValue: string) => void;
    sort?: "Time Occurred" | "Time Submitted";
    updateSort?: (newValue: "Time Occurred" | "Time Submitted") => void;
    filterChanged: boolean;
    filterChangedFromDefault: boolean;
    updateFilter: () => void;
    resetFilter: () => void;
    verifiedChecked?: boolean;
    updateVerifiedChecked?: (newValue: boolean) => void;
    unverifiedChecked?: boolean;
    updateUnverifiedChecked?: (newValue: boolean) => void;
}
