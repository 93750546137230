import * as analytics from "../../types/api/analytics";
import { Operation } from "..";
import { analyticsActions, analyticsOperations } from "./index";
import { filterOutOtherCategory, filterPerCategoryStats } from "../../app/analytics/operations";
import { CategoryStats } from "../../types/analytics";
import { leftModalActions } from "../left-modal";

export const fetchAnalyticsData: Operation = (containerString: "container" | "default_region", containerChange: number) => async (dispatch) => {
    try {
        const response = await analytics.summary(containerString, containerChange);
        dispatch(analyticsActions.setError(null));
        dispatch(analyticsActions.setData(response));
    } catch (error) {
        console.error(error, "Error on Analytics Route Container Summary Stats: ");
        dispatch(analyticsActions.setError("Error Fetching Analytics"));
    }

    dispatch(analyticsOperations.loadCategoricalDataArray());
};

export const loadCategoricalDataArray: Operation = () => async (dispatch, getState) => {
    const timeRange = getState().analytics.timeRange;
    const data = getState().analytics.data;
    const appliedEventTypes = getState().analytics.appliedEventTypes;

    switch (timeRange) {
        case "24 Hour":
            dispatch(analyticsActions.setCategoricalDataArray(filterOutOtherCategory(data?.pastDayCategoryStats ?? [], appliedEventTypes)));
            break;
        case "7 Day":
            dispatch(analyticsActions.setCategoricalDataArray(filterOutOtherCategory(data?.pastWeekCategoryStats ?? [], appliedEventTypes)));
            break;
        case "28 Day":
            dispatch(analyticsActions.setCategoricalDataArray(filterOutOtherCategory(data?.pastMonthCategoryStats ?? [], appliedEventTypes)));
            break;
        default:
            dispatch(analyticsActions.setCategoricalDataArray(filterOutOtherCategory(data?.allTimeCategoryStats ?? [], appliedEventTypes)));
            break;
    }

    dispatch(analyticsOperations.getTotalReportsFromCategoricalDataArray());
};

export const getTotalReportsFromCategoricalDataArray: Operation = () => async (dispatch, getState) => {
    const categoricalDataArray = getState().analytics.categoricalDataArray;
    const violentCheckedApplied = getState().analytics.violentCheckedApplied;
    const nonviolentCheckedApplied = getState().analytics.nonviolentCheckedApplied;
    const hazardCheckedApplied = getState().analytics.hazardCheckedApplied;
    const appliedEventTypes = getState().analytics.appliedEventTypes;

    let count = 0;
    categoricalDataArray
        ?.filter((entry: CategoryStats) =>
            filterPerCategoryStats(entry, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, appliedEventTypes)
        )
        .forEach((entry: CategoryStats) => {
            count += entry.count ?? 0;
        });
    dispatch(analyticsActions.updateTotalReports(count));
    dispatch(analyticsActions.toggleTotalReportsLoading(false));
};

export const initAnalytics: Operation = () => async (dispatch, getState) => {
    const currentFilter = getState().filter;
    const userProfile = getState().cuser.userProfile;

    dispatch(leftModalActions.setModalContent({ modalContent: "analytics" }));
    dispatch(analyticsActions.setSelectedRegion(currentFilter.selectedRegion));
    dispatch(analyticsActions.setRegionChange(currentFilter.selectedRegion));
    dispatch(analyticsActions.toggleTimeRange(currentFilter.timePeriod));
    dispatch(analyticsActions.toggleViolentChecked(currentFilter.violentIncluded));
    dispatch(analyticsActions.toggleNonviolentChecked(currentFilter.nonviolentIncluded));
    dispatch(analyticsActions.toggleHazardChecked(currentFilter.hazardIncluded));
    dispatch(analyticsActions.toggleViolentCheckedApplied(currentFilter.violentIncluded));
    dispatch(analyticsActions.toggleNonviolentCheckedApplied(currentFilter.nonviolentIncluded));
    dispatch(analyticsActions.toggleHazardCheckedApplied(currentFilter.hazardIncluded));
    dispatch(analyticsActions.toggleViolentCheckedEffect(currentFilter.violentIncluded));
    dispatch(analyticsActions.toggleNonviolentCheckedEffect(currentFilter.nonviolentIncluded));
    dispatch(analyticsActions.toggleHazardCheckedEffect(currentFilter.hazardIncluded));
    dispatch(analyticsActions.setCurrentContainer(userProfile?.current_container ?? 0));
    dispatch(analyticsActions.setContainerChange(userProfile?.current_container ?? 0));
};
