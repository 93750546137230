import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";

const actionMap = {
    setCurrentReportId: payloadAction<number>(),
    setCurrentReportType: payloadAction<string>(),
    flyTo: payloadAction<boolean>(),
    toggleFullReportExists: payloadAction<boolean>()
};

export const individualReportActions = actionFactory(actionMap, "INDIVIDUAL_REPORT");

export type IndividualReportAction = ActionUnion<typeof individualReportActions>;

export interface IndividualReportState {
    currentReportId: number | null;
    currentReportType: string | null;
    flyToReport: boolean;
    fullReportExists: boolean;
}

export const getInitialState = (): IndividualReportState => ({
    currentReportId: null,
    currentReportType: null,
    flyToReport: false,
    fullReportExists: false
});

const individualReportReducer = (state = getInitialState(), action: IndividualReportAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "INDIVIDUAL_REPORT") {
            switch (action.type) {
                case "setCurrentReportId":
                    draftState.currentReportId = action.payload;
                    return;
                case "setCurrentReportType":
                    draftState.currentReportType = action.payload;
                    return;
                case "flyTo":
                    draftState.flyToReport = action.payload;
                    return;
                case "toggleFullReportExists":
                    draftState.fullReportExists = action.payload;
                    return;
                default:
                    // unreachableCode(action);
                    break;
            }
        }
    });

export default individualReportReducer;
