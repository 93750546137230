import React, { useState } from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import { PerCategoryStats, CategoryStats, ThirtyDayCount } from "../../types/analytics";
import { analyticsActions } from "../../state/analytics";
import { SpaceBetweenFlexContainer, TimeRangeToggle } from "./styled";
import { filterOutOtherCategory } from "./operations";

export const TimeRangePicker = () => {
    const dispatch = useDispatch();
    const colors = useSelectFromRedux((state) => state.color);
    const { data, timeRange, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, timeRangeApplied, appliedEventTypes } = useSelectFromRedux(
        (state) => state.analytics
    );

    const filterPerCategoryStats = (stats: PerCategoryStats | CategoryStats): boolean => {
        let result = true;

        if (stats.category === "Other") {
            result = false;
        }

        if (!(!violentCheckedApplied && !nonviolentCheckedApplied && !hazardCheckedApplied)) {
            if (!violentCheckedApplied) {
                if (stats.category === "Violent") {
                    result = false;
                }
            }

            if (!nonviolentCheckedApplied) {
                if (stats.category === "Non-Violent") {
                    result = false;
                }
            }

            if (!hazardCheckedApplied) {
                if (stats.category === "Hazard") {
                    result = false;
                }
            }
        }

        return result;
    };

    const filterTimeRangeArray = (newTimeRange: "24 Hour" | "7 Day" | "28 Day" | null): ThirtyDayCount[] => {
        var result: ThirtyDayCount[] = [];

        let dataUsed = data?.perCategoryStats;

        if (newTimeRange === "24 Hour") {
            dataUsed = data?.pastDayHourlyCategoryStats;
        } else if (newTimeRange === "7 Day") {
            dataUsed = data?.perCategoryStats?.slice(data?.perCategoryStats.length - 28, data?.perCategoryStats.length);
            if (Array.isArray(dataUsed) && dataUsed!.length > 1) {
                let dateString = data?.perCategoryStats![data?.perCategoryStats!.length - 1].dateTime!.replace(/\s/g, "T")!; // Get today's dateDtring from array
                let theDate: Date = new Date(dateString); // create new Date
                theDate.setDate(theDate.getDate() - 7); // get date from week ago
                dataUsed = data?.perCategoryStats?.filter((it) => new Date(it.dateTime!.replace(/\s/g, "T")!).getTime() > theDate.getTime()); // get list of stats for past week
            }
        } else if (newTimeRange === "28 Day") {
            dataUsed = data?.perCategoryStats;
        } else {
            dataUsed = data?.allTimeCategoryStatsPerWeek;
        }

        dataUsed
            ?.filter((entry) => filterPerCategoryStats(entry))
            .forEach((entry: PerCategoryStats) => {
                if (result.some((data: ThirtyDayCount) => entry.dateTime === data.dateTime)) {
                    const existingEntry = result.filter((data: ThirtyDayCount) => entry.dateTime === data.dateTime)[0];
                    if (!existingEntry.count) {
                        existingEntry.count = entry.count ?? 0;
                    } else {
                        existingEntry.count += entry.count ?? 0;
                    }
                } else {
                    result.push({
                        count: entry.count,
                        dateTime: entry.dateTime,
                        dayName: entry.day,
                        checkInfo: () => true
                    });
                }
            });
        return result;
    };

    // eslint-disable-next-line
    const [filteredTimeRangeArray, updateFilteredTimeRangeArray] = useState<ThirtyDayCount[]>(filterTimeRangeArray(timeRangeApplied));

    const updateTimeRangeFetch = (newTimeRange: "7 Day" | "28 Day" | "24 Hour" | null) => {
        dispatch(analyticsActions.updateTotalReports(0));
        dispatch(analyticsActions.toggleTimeRange(newTimeRange));
        // dispatch(analyticsOperations.getTotalReportsFromCategoricalDataArray());
        if (newTimeRange !== timeRange) {
            dispatch(analyticsActions.toggleFilterLocked(true));
        }
        if (newTimeRange === "7 Day") {
            dispatch(analyticsActions.setCategoricalDataArray(filterOutOtherCategory(data?.pastWeekCategoryStats ?? [], appliedEventTypes)));
        } else if (newTimeRange === "28 Day") {
            dispatch(analyticsActions.setCategoricalDataArray(filterOutOtherCategory(data?.pastMonthCategoryStats ?? [], appliedEventTypes)));
        } else if (newTimeRange === "24 Hour") {
            dispatch(analyticsActions.setCategoricalDataArray(filterOutOtherCategory(data?.pastDayCategoryStats ?? [], appliedEventTypes)));
        } else {
            dispatch(analyticsActions.setCategoricalDataArray(filterOutOtherCategory(data?.allTimeCategoryStats ?? [], appliedEventTypes)));
        }
        updateFilteredTimeRangeArray(filterTimeRangeArray(newTimeRange));
    };

    return (
        <SpaceBetweenFlexContainer width={"65px"} style={{ flexDirection: "column", margin: "0 20px 0 10px" }}>
            <TimeRangeToggle
                selected={timeRange === null}
                onClick={() => updateTimeRangeFetch(null)}
                color={colors.scheme.secondaryHeaderText}
                selectedColor={colors.scheme.cardLightCaptionText}
                mode={colors.mode}
            >
                ALL TIME
            </TimeRangeToggle>
            <TimeRangeToggle
                selected={timeRange === "28 Day"}
                onClick={() => updateTimeRangeFetch("28 Day")}
                color={colors.scheme.secondaryHeaderText}
                selectedColor={colors.scheme.cardLightCaptionText}
                mode={colors.mode}
            >
                28 DAY
            </TimeRangeToggle>
            <TimeRangeToggle
                selected={timeRange === "7 Day"}
                onClick={() => updateTimeRangeFetch("7 Day")}
                color={colors.scheme.secondaryHeaderText}
                selectedColor={colors.scheme.cardLightCaptionText}
                mode={colors.mode}
            >
                7 DAY
            </TimeRangeToggle>
            <TimeRangeToggle
                selected={timeRange === "24 Hour"}
                onClick={() => updateTimeRangeFetch("24 Hour")}
                color={colors.scheme.secondaryHeaderText}
                selectedColor={colors.scheme.cardLightCaptionText}
                mode={colors.mode}
            >
                24 HOUR
            </TimeRangeToggle>
        </SpaceBetweenFlexContainer>
    );
};
