import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelectFromRedux } from "../utils/_hooks";
import { initLogRocket } from "../utils/logrocket";
import Home from "./home";
import Login from "./login";
import { SignUp } from "./signup";
import AuthenticationLayer from "./authentication-layer";
import { Verify } from "./signup/Verify";
import { ResetPassword } from "./login/resetPassword";
import { ForgotPassword } from "./login/forgotPassword";
import { lightTheme, darkTheme } from "../utils/theme";
import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export const App = () => {
    const colors = useSelectFromRedux((state) => state.color);
    initLogRocket();
    return (
        <Router>
            <ThemeProvider theme={colors.mode === "dark" ? darkTheme : lightTheme}>
                <Switch>
                    <Route path="/resetpassword" exact component={ResetPassword} />
                    <Route path="/forgotpassword" exact component={ForgotPassword} />
                    <Route path="/signup" exact component={SignUp} />
                    <Route path="/verify" exact component={Verify} />
                    <Route path="/signup/:token" exact component={SignUp} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/:coBranding" exact component={Login} />
                    <AuthenticationLayer>
                        <QueryClientProvider client={queryClient}>
                            <PrivateRoute path="/" component={Home} />
                            <PrivateRoute path="/:type/:id" component={Home} />
                        </QueryClientProvider>
                    </AuthenticationLayer>
                </Switch>
            </ThemeProvider>
        </Router>
    );
};

const PrivateRoute = ({ component: Component, ...rest }: any) => {
    const signedIn = useSelectFromRedux((state) => state.cuser.signedIn);
    return (
        <Route
            {...rest}
            render={(props) =>
                signedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};
export default App;
