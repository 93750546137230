/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { fadedRed, getContainerViewportFromId } from "../../utils";
import { isMobile, isIOS } from "react-device-detect";
import { useParams } from "react-router-dom";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import { toastActions } from "../../state/toast";
import { leftModalActions } from "../../state/left-modal";
import { cuserActions } from "../../state/cuser";
import { clientInfoOperations } from "../../state/clientInfo";
import { publicInfoOperations, publicInfoActions } from "../../state/publicInfo";

// components
import {
    Fullscreen,
    Error,
    CurrentContainerButton,
    DropdownContent,
    LogoContainer,
    CurrentDisplayContainer,
    StyledMenuItem,
    OrionMenuButton,
    ContentText,
    BoldContentText,
    PNModal
} from "./styled";
import Map from "../map";
import Notification from "../notification";
import LeftModal from "../left-modal";
import Dropdown from "antd/lib/dropdown";
import { Badge } from "@material-ui/core";
import { filterActions } from "../../state/filter";
import store from "../../state/store";
import { Container } from "../../types/geo";
import moment from "moment";
import SwiftNotificationWrapper from "./swiftNotificationWrapper";
import LogRocket from "logrocket";
import { logRocketInitialized } from "../../utils/logrocket";
import { S3Key } from "../../utils";
import { subscribeDeviceToNotifications } from "../../state/cuser/operations";
import { mapActions } from "../../state/map";
import { Alert } from "../_shared";
import { SmallIcon } from "../_shared/typography";
import { SmallSpacer } from "../_shared/layout";

const OrionDarkIcon = S3Key + "orion-blue.svg";
const OrionLightIcon = S3Key + "orion-white.svg";
const CloseGreyIcon = S3Key + "close-dark.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default (_props: any) => {
    const dispatch = useDispatch();
    const dispatchPromise = useDispatchPromise();
    const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);
    const errorContent = useSelectFromRedux((state) => state.error.currentText);
    const toastIsError = useSelectFromRedux((state) => state.error.isError);
    const leftModalIsOpen = useSelectFromRedux((state) => state.leftModal.isOpen);
    const currentContainer = useSelectFromRedux((state) => state.cuser.userProfile?.current_container);
    const availableContainers = useSelectFromRedux((state) => state.cuser.containers);
    const colors = useSelectFromRedux((state) => state.color);
    const unconfirmedAlerts = useSelectFromRedux((state) => state.clientInfo.unconfirmedAlerts);
    const unaddressedAlerts = useSelectFromRedux((state) => state.clientInfo.unaddressedAlerts);
    const userProfile = useSelectFromRedux((state) => state.cuser.userProfile);
    const currentFilter = useSelectFromRedux((state) => state.filter);
    const newInsight = useSelectFromRedux((state) => state.publicInfo.newInsight);
    const insights = useSelectFromRedux((state) => state.publicInfo.insights);
    const [showLocationModal, updateShowLocationModal] = useState<boolean>(false);
    const [showPNModal, updateShowPNModal] = useState<boolean>(false);
    const { locationServices, latestDBPoint } = useSelectFromRedux((state) => state.cuser);
    const containers = useSelectFromRedux((state) => state.cuser.containers);

    // eslint-disable-next-line
    let { id } = useParams();
    // eslint-disable-next-line
    let { type } = useParams();

    const email = useSelectFromRedux((state) => state.cuser.userProfile?.email);
    const modalOpen = useSelectFromRedux((state) => state.leftModal.isOpen);

    if (!availableContainers) {
        console.error("Could not get containers in drawer.");
        return null;
    }

    useEffect(() => {
        if (window.analytics) {
            window.analytics.page();
            window.analytics.track("App Loaded");

            if (type && id) {
                window.analytics.track("User Clicked A Notification");
            }
        }
        // start segment page tracking on render only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFocus = () => {
        if (store.getState().cuser.userProfile) {
            if (!type && !id) {
                const lastFetchString = localStorage.getItem("lastFetch");
                const lastFetch = new Date(lastFetchString ?? "");
                if (lastFetch.getTime() < new Date().getTime() - 30000 || !localStorage.getItem("lastFetch")) {
                    console.log("Back in foreground. Refetching data");
                    localStorage.setItem("lastFetch", new Date().toString());
                    dispatch(clientInfoOperations.fetchClientInfo());
                    dispatch(publicInfoOperations.getPublicInsights());
                }
            }
        }
        const latestInsight = insights.filter((insight) => insight.container_ids.includes(currentContainer || -1))[0];
        if (latestInsight) {
            if (localStorage.getItem("lastInsightAccess")) {
                if (
                    moment(new Date()).diff(latestInsight.created_at, "hours") < 24 &&
                    moment(JSON.parse(localStorage.getItem("lastInsightAccess") ?? "")).diff(latestInsight.created_at, "milliseconds") < 0
                ) {
                    if (userProfile?.features?.publicInsightsMenu === true) dispatch(publicInfoActions.setNewInsight(true));
                }
            } else {
                if (moment(new Date()).diff(latestInsight.created_at, "hours") < 24) {
                    if (userProfile?.features?.publicInsightsMenu === true) dispatch(publicInfoActions.setNewInsight(true));
                }
            }
        }
    };

    const handleVisibility = () => {
        if (document.visibilityState === "visible") {
            window.analytics.track("Session Started");
        } else if (document.visibilityState === "hidden") {
            window.analytics.track("Session Ended");
        }
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("visibilitychange", handleVisibility);

    try {
        const broadcast = new BroadcastChannel("bgSync");
        broadcast.onmessage = (event) => {
            dispatch(clientInfoOperations.fetchClientInfo());
        };
    } catch (error) {
        console.error("Broadcast channel not supported? : ", error);
    }

    const handleErrorClose = () => {
        dispatch(toastActions.closeToast());
    };

    useEffect(() => {
        if (type && id) {
            _props.history.push("");
            switch (type) {
                case "public":
                    // @ts-ignore incorrect typing for operations that return promises TODO SB-187
                    // dispatch(publicInfoOperations.fetchPublicReportsFromContainer()).then(() => {
                    //     if (type && id) {
                    //         dispatch(individualReportActions.flyTo(true));
                    //         dispatch(individualReportActions.setCurrentReportId(Number(id)));
                    //         dispatch(individualReportActions.setCurrentReportType(type));
                    //         dispatch(leftModalActions.toggleModal());
                    //         dispatch(leftModalActions.setModalContent({ modalContent: "individual-report" }));
                    //     }
                    // });
                    break;
                case "insight":
                    if (type && id) {
                        dispatch(publicInfoOperations.getPublicInsights());
                        if (modalOpen === false) {
                            dispatch(
                                leftModalActions.toggleModal()
                            );
                        }
                        dispatch(
                            leftModalActions.setModalContent({
                                modalContent: "insights"
                            })
                        );
                    }
                    break;

                default:
                    console.log("Invalid route.");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, id]);

    useEffect(() => {
        if (userProfile && userProfile.id) {
            if (logRocketInitialized) {
                console.log("Identifying logrocket user: " + userProfile.id);
                LogRocket.identify(userProfile.id.toString(), {
                    name: userProfile.first_name + " " + userProfile.last_name,
                    email: userProfile.email
                });
            }
        } else {
            console.warn("No valid user profile or UID: " + userProfile + ", " + userProfile!.id ?? null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    /**
     * Push Notifications Setup
     **/

    const userDevices = useSelectFromRedux((state) => state.cuser.devices);

    const determinePNSettings = useCallback(() => {
        const current_fingerprint = localStorage.getItem("fingerprint");
        if (!current_fingerprint) {
            console.error("Could not get device fingerprint, unable to determine push notification settings.");
            return false;
        }

        let pnEnabled = false;
        userDevices.forEach((device) => {
            if (device.device_fingerprint === current_fingerprint) {
                if (device.token) {
                    pnEnabled = true;
                }
            }
        });

        return pnEnabled;
    }, [userDevices]);

    useEffect(() => {
        if (!isIOS && isMobile) {
            // Attempt to auto-enable notifications on mobile Android only
            const autoApplyPN = localStorage.getItem("autoApplyPN");
            if (autoApplyPN !== "false") {
                if (userDevices) {
                    const PNSettings = determinePNSettings();
                    if (!PNSettings) {
                        if (latestDBPoint) {
                            // Only attempt to subscribe once a location is determined
                            console.log("[NOTIFICATIONS] Attempting to subscribe to push notifications...");
                            dispatchPromise(subscribeDeviceToNotifications())
                                .then((success) => {
                                    if (success) {
                                        localStorage.setItem("autoApplyPN", "false");
                                    }
                                })
                                .catch((err) => {
                                    console.log("Error updating push notification settings: " + err);
                                    dispatch(
                                        toastActions.openError(
                                            "Failed to subscribe to push notifications. Please be sure that notification permissions are enabled."
                                        )
                                    );
                                });
                        }
                    } else {
                        console.log("[NOTIFICATIONS] Push Notifications Enabled.");
                    }
                }
            }
        }
    }, [dispatch, dispatchPromise, determinePNSettings, userDevices, latestDBPoint]);

    /**
     * Set & display current container & container selector
     */

    const setContainer = (container_id: number) => {
        dispatch(cuserActions.updateCurrentContainer(container_id));
        const containerViewportInfo = getContainerViewportFromId(container_id, containers);
        dispatch(mapActions.flyToPoint([containerViewportInfo.longitude, containerViewportInfo.latitude, containerViewportInfo.zoom]));
        dispatch(
            filterActions.updateFilter({
                ...currentFilter,
                selectedRegion: 0
            })
        );
    };

    const getContainerAbbreviation = () => {
        const container = availableContainers.filter((c: Container) => c.id === currentContainer)[0];
        if (container?.abbreviation) {
            return container.abbreviation;
        } else {
            return "";
        }
    };

    useEffect(() => {
        const success = () => {
            const pushNotificationsOn = determinePNSettings();
            if (locationServices && !pushNotificationsOn && (Number(localStorage.getItem("PNCounter")) < 4 || !localStorage.getItem("PNCounter"))) {
                updateShowPNModal(true);
            }
        };

        const error = () => {
            if (!locationServices && (Number(localStorage.getItem("PNCounter")) < 4 || !localStorage.getItem("PNCounter"))) {
                updateShowLocationModal(true);
            }
        };

        if (!isIOS && isMobile) {
            navigator.geolocation.getCurrentPosition(success, error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const menu = (
        <DropdownContent background={colors.scheme.modalBackground} color={colors.scheme.secondaryHeaderText}>
            Select your region. This will reload the map and reset your data.
            {availableContainers
                .slice(0)
                .reverse()
                .map((availableContainer) => (
                    <StyledMenuItem
                        key={availableContainer.id}
                        background={colors.scheme.modalBackground}
                        hoverbackground={colors.scheme.generalCard}
                        selected={availableContainer.id === currentContainer}
                    >
                        <div style={{ color: colors.scheme.primaryText }} onClick={() => setContainer(availableContainer.id)}>
                            {availableContainer.name}
                        </div>
                    </StyledMenuItem>
                ))}
        </DropdownContent>
    );

    const source = useRef<any>(null);

    const handleLocationModalAccepted = () => {
        const success = () => {
            dispatch(cuserActions.updateLocationServices(true));
            updateShowLocationModal(false);
            updateShowPNModal(true);
        };

        const error = () => {
            updateShowLocationModal(false);
            dispatch(toastActions.openError("Failed to enable location. Please ensure location permissions are enabled for this site in your web browser."));
        };

        navigator.geolocation.getCurrentPosition(success, error);
    };

    const handlePNModalAccepted = () => {
        dispatchPromise(subscribeDeviceToNotifications())
            .then(() => {
                updateShowPNModal(false);
                localStorage.setItem("PNCounter", "4");
            })
            .catch((err) => {
                console.log("Error updating push notification settings: " + err);
            });
    };

    const handleLocationModalDenied = () => {
        const counter = Number(localStorage.getItem("PNCounter"));
        if (counter) {
            localStorage.setItem("PNCounter", (counter + 1).toString());
        } else {
            localStorage.setItem("PNCounter", "1");
        }
        updateShowLocationModal(false);
    };

    return (
        <Fullscreen>
            <SwiftNotificationWrapper />
            <Notification />
            <PNModal
                visible={showLocationModal}
                onOk={handleLocationModalAccepted}
                onCancel={handleLocationModalDenied}
                cancelText={"Skip"}
                okText={"Enable Location"}
                closeIcon={<img src={CloseGreyIcon} alt="close icon" width={18} />}
            >
                <BoldContentText>Enable Location Permissions</BoldContentText>
                <br />
                <ContentText>
                    Atlas needs access to your location to display incidents near you. We don’t share this information, and we’ll only have access to your
                    location while you’re using the app. Tap below to enable location permissions.
                </ContentText>
                <br />
                <img
                    src="https://orion-pwa.s3.us-east-2.amazonaws.com/enable-location-example.png"
                    alt="enable location"
                    width="100%"
                    style={{ borderRadius: "6px" }}
                />
            </PNModal>
            <PNModal
                visible={showPNModal}
                onOk={handlePNModalAccepted}
                cancelText={"Skip"}
                okText={"Enable Push Notifications"}
                closeIcon={<img src={CloseGreyIcon} alt="close icon" width={18} />}
            >
                <BoldContentText>Enable Notifications</BoldContentText>
                <br />
                <ContentText>
                    Enable push notifications permissions for Atlas to receive alerts of incidents happening nearby in real-time. You can always turn off
                    notifications from the{" "}
                    <b>
                        <i>settings</i>
                    </b>{" "}
                    page. Tap below to enable notification permissions.
                </ContentText>
            </PNModal>
            <LogoContainer>
                <Badge
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    color={"error"}
                    badgeContent={unconfirmedAlerts + unaddressedAlerts + (newInsight ? 1 : 0)}
                >
                    <OrionMenuButton
                        src={colors.mode === "dark" ? OrionLightIcon : OrionDarkIcon}
                        alt="Atlas Logo"
                        onClick={() => dispatch(leftModalActions.toggleModal())}
                    />
                </Badge>
            </LogoContainer>
            {!isMobile && (
                <CurrentDisplayContainer className="noselect">
                    <Dropdown overlay={menu}>
                        {/* please keep the next line here, it changes behavior even though it doesn't seem like it does anything */}
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <div
                                style={{
                                    height: "60px",
                                    width: "90px",
                                    position: "fixed",
                                    top: "0",
                                    right: "0"
                                }}
                            >
                                <CurrentContainerButton>{getContainerAbbreviation()}</CurrentContainerButton>
                            </div>
                        </a>
                    </Dropdown>
                </CurrentDisplayContainer>
            )}
            <Map source={source} />
            {leftModalIsOpen && <LeftModal source={source} />}
            {errorIsOpen && errorContent && (
                <Error
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    onClose={handleErrorClose}
                    autoHideDuration={10000}
                    background={toastIsError ? colors.scheme.filterViolentTag : colors.general.standardGreen}
                >
                    <Alert style={{ background: toastIsError ? fadedRed : colors.general.standardGreen }}>
                        {
                            toastIsError ?
                                <SmallIcon src={S3Key + "alert-white.svg"} alt="alert" />
                                : <SmallIcon src={S3Key + "check-white.svg"} alt="checkmark" />
                        }
                        <SmallSpacer />
                        {errorContent}
                    </Alert>
                </Error>
            )}
        </Fullscreen>
    );
};
