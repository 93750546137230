import { produce } from "immer";
import { simpleAction, payloadAction, actionFactory, ActionUnion } from "reductser";
import { FlyToInterpolator } from "react-map-gl";
import * as d3 from "d3-ease";
import WebMercatorViewport from "viewport-mercator-project";
import bbox from "@turf/bbox";
import { FeatureCollection, Feature } from "geojson";

const actionMap = {
    // FIXME any type
    handleViewportChange: payloadAction<Viewport>(),
    toggleTooltip: simpleAction(),
    renderTooltip: payloadAction<any>(),
    flyToPoint: payloadAction<number[]>(),
    flyToPointWithBearing: payloadAction<number[]>(),
    recenterOnPoint: payloadAction<{ point: number[]; zoom: number }>(),
    zoomToFeature: payloadAction<{ width: number; height: number; feature: Feature | FeatureCollection; options: any }>(),
    setTooltipVisible: payloadAction<boolean>()
};

export const mapActions = actionFactory(actionMap, "MAP");

export type MapAction = ActionUnion<typeof mapActions>;

// space to describe specific types of actions
export interface Viewport {
    width: string;
    height: string;
    latitude: number;
    longitude: number;
    zoom: number;
    bearing: number;
    pitch?: number;
}

export interface Marker {
    id: string;
    longitude: number;
    latitude: number;
    offsetLeft: number;
    offsetTop: number;
    title: string;
    location: string;
    postedBy: string;
    time: string;
    markerTitle: string;
    containerId: number | undefined;
    defaultRegionId: number | undefined;
    category: string;
    verified?: number;
    public?: boolean;
    confirmed?: number;
    active?: boolean;
    dateTimeMilli?: number;
}

/**
 * Map State
 */
export interface MapState {
    viewport: Viewport;
    showTooltip: boolean;
    selectedMarker: any;
}

export const getInitialState = (): MapState => ({
    viewport: {
        width: "100vw",
        height: "100vh",
        latitude: 42.3601,
        longitude: -71.0589,
        zoom: 13,
        bearing: 0,
        pitch: 0
    },
    showTooltip: false,
    selectedMarker: null
});

const mapReducer = (state: MapState = getInitialState(), action: MapAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "MAP") {
            switch (action.type) {
                case "toggleTooltip":
                    draftState.showTooltip = !draftState.showTooltip;
                    return;
                case "handleViewportChange":
                    draftState.viewport = action.payload;
                    return;
                case "renderTooltip":
                    draftState.selectedMarker = action.payload;
                    return;
                case "flyToPoint":
                    const newViewport = {
                        ...draftState.viewport,
                        longitude: action.payload[0],
                        latitude: action.payload[1],
                        zoom: action.payload[2],
                        transitionDuration: 5000,
                        transitionInterpolator: new FlyToInterpolator(),
                        transitionEasing: d3.easeCubic,
                        bearing: 0,
                        pitch: 0
                    };
                    draftState.viewport = newViewport;
                    return;
                case "flyToPointWithBearing":
                    const newViewport3 = {
                        ...draftState.viewport,
                        longitude: action.payload[0],
                        latitude: action.payload[1],
                        zoom: action.payload[2],
                        transitionDuration: 5000,
                        transitionInterpolator: new FlyToInterpolator(),
                        transitionEasing: d3.easeCubic,
                        bearing: action.payload[3],
                        pitch: 0
                    };
                    draftState.viewport = newViewport3;
                    return;
                case "recenterOnPoint":
                    console.log("Current zoom: " + draftState.viewport.zoom);
                    const newViewport2 = {
                        ...draftState.viewport,
                        longitude: action.payload.point[0],
                        latitude: action.payload.point[1],
                        zoom: action.payload.zoom,
                        transitionDuration: 1000,
                        transitionInterpolator: new FlyToInterpolator(),
                        transitionEasing: d3.easeCubic,
                        bearing: 0,
                        pitch: 0
                    };
                    draftState.viewport = newViewport2;
                    return;
                case "zoomToFeature":
                    const payload = action.payload;
                    const viewport = fitViewportToFeature(payload.width, payload.height, payload.feature, payload.options);
                    const updatedViewport = {
                        ...draftState.viewport,
                        longitude: viewport.longitude,
                        latitude: viewport.latitude,
                        zoom: draftState.viewport.zoom,
                        transitionDuration: 5000,
                        transitionInterpolator: new FlyToInterpolator(),
                        transitionEasing: d3.easeCubic,
                        bearing: 0,
                        pitch: 0
                    };
                    draftState.viewport = updatedViewport;
                    return;
                case "setTooltipVisible":
                    draftState.showTooltip = action.payload;
                    return;
                default:
                    return;
            }
        }
    });

/**
 * A helper function that animates the viewport to fit a given feature on-screen.
 * @param clientWidth number dimensions of map view
 * @param clientHeight number
 * @param feature Feature<any> feature to fit the map to
 * @param options padding/offset options
 * @returns Bounds
 */
const fitViewportToFeature = (clientWidth: number, clientHeight: number, feature: Feature | FeatureCollection, options: any = {}): WebMercatorViewport => {
    /** Invariants */
    if (!feature) throw Error("You must pass a feature to fitMapToFeature");
    // if (!map) throw Error("Map hasn't loaded yet, be patient.");

    /** Get bounding box of feature/collection */
    const bounds = bbox(feature);

    /** Setup WebMercatorViewport instances to fit bounds */
    // const { clientWidth, clientHeight } = map.getContainer();
    const viewport = new WebMercatorViewport({ width: clientWidth, height: clientHeight });

    /** Edge case: if width is less than horizontal padding, remove padding */
    if (typeof options.padding === "object" && clientWidth < (options.padding.left || 0) + (options.padding.right || 0)) {
        options.padding = 0;
        console.warn("map width is less than padding width, resetting to 0px");
    }

    /** Edge case: if width is less than vertical padding, remove padding */
    if (typeof options.padding === "object" && clientHeight < (options.padding.top || 0) + (options.padding.bottom || 0)) {
        options.padding = 0;
        console.warn("map height is less than padding height, resetting to 0px");
    }

    /** Fit the bounds we found to the new viewport and return it */
    return viewport.fitBounds(
        [
            [bounds[0], bounds[1]],
            [bounds[2], bounds[3]]
        ],
        options
    );
};

export default mapReducer;
