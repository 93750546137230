import React from "react";
import { useSelectFromRedux } from "../../utils/_hooks";
import { isSafari, isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { PerCategoryStats, CategoryStats } from "../../types/analytics";
import { analyticsActions } from "../../state/analytics";
import { PieChart as RechartsPie, Pie, Cell, Label } from "recharts";
import { lightenDarkenColor } from "../../utils";
import { ReportCategories, reportType, CategoryTypes } from "../../types/reports";

interface PieChartProps {
    drawerRef: any;
}

export const PieChart = ({ drawerRef }: PieChartProps) => {
    const dispatch = useDispatch();

    const colors = useSelectFromRedux((state) => state.color);
    const centralSubtitle = <text style={{ fontSize: "12px" }}>reports</text>;

    const { timeRange, categoricalDataArray, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, totalReports, appliedEventTypes } =
        useSelectFromRedux((state) => state.analytics);

    let violentCount = 0;
    let nonviolentCount = 0;
    let hazardCount = 0;
    let otherCount = 0;

    const filterPerCategoryStats = (stats: PerCategoryStats | CategoryStats): boolean => {
        let result = true;

        if (stats.category === "Other") {
            result = false;
        }

        if (!(!violentCheckedApplied && !nonviolentCheckedApplied && !hazardCheckedApplied)) {
            if (!violentCheckedApplied) {
                if (stats.category === "Violent") {
                    result = false;
                }
            }

            if (!nonviolentCheckedApplied) {
                if (stats.category === "Non-Violent") {
                    result = false;
                }
            }

            if (!hazardCheckedApplied) {
                if (stats.category === "Hazard") {
                    result = false;
                }
            }
        }

        if (appliedEventTypes.length > 0 && stats.category) {
            if (!appliedEventTypes.includes(stats.category)) {
                result = false;
            }
        }
        return result;
    };

    const renderLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, outerRadius, percent } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 6) * cos;
        const sy = cy + (outerRadius + 6) * sin;
        const mx = cx + (outerRadius + 10) * cos;
        const my = cy + (outerRadius + 20) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 6;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        return (
            <g>
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={colors.scheme.primaryText} fill="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 7} y={ey + 5} textAnchor={textAnchor} fill={colors.scheme.primaryText}>{`${Math.round(
                    percent * 100
                )}%`}</text>
            </g>
        );
    };

    const getPieChartWidth = () => {
        if (drawerRef.current !== null) {
            if (isMobile) {
                return drawerRef.current.offsetWidth - 50;
            } else {
                return drawerRef.current.offsetWidth - 70;
            }
        } else {
            return 0;
        }
    };

    const getColor = (name: reportType | CategoryTypes | undefined) => {
        if (name) {
            if (name === "Violent") {
                return colors.scheme.analyticsPieViolent;
            } else if (name === "Non-Violent") {
                return colors.scheme.analyticsPieNonviolent;
            } else if (name === "Hazard") {
                return colors.scheme.analyticsPieHazard;
            } else if (name === "Other") {
                return colors.scheme.analyticsPieOther;
            } else if (ReportCategories.Violent.includes(name)) {
                let startingColor = lightenDarkenColor(colors.scheme.analyticsPieViolent, -20);
                let color = lightenDarkenColor(startingColor, 20 * violentCount);
                violentCount += 1;
                return color;
            } else if (ReportCategories["Non-Violent"].includes(name)) {
                let color = lightenDarkenColor(colors.scheme.analyticsPieNonviolent, 20 * nonviolentCount);
                nonviolentCount += 1;
                return color;
            } else if (ReportCategories.Hazard.includes(name)) {
                let color = lightenDarkenColor(colors.scheme.analyticsPieHazard, 20 * hazardCount);
                hazardCount += 1;
                return color;
            } else if (ReportCategories.Other.includes(name)) {
                let color = lightenDarkenColor(colors.scheme.analyticsPieOther, 20 * otherCount);
                otherCount += 1;
                return color;
            } else {
                return colors.scheme.analyticsPieOther;
            }
        } else {
            return colors.scheme.analyticsPieOther;
        }
    };

    return (
        <RechartsPie
            width={getPieChartWidth()}
            height={250}
            style={{
                marginBottom: isSafari ? "0px" : isMobile ? "-60px" : "0",
                marginTop: "-40px",
                marginLeft: "-90px"
            }}
        >
            <Pie
                data={categoricalDataArray?.filter((entry: CategoryStats) => filterPerCategoryStats(entry)) ?? []}
                dataKey={"count"}
                cx={175}
                cy={130}
                innerRadius={45}
                outerRadius={65}
                isAnimationActive={true}
                onAnimationEnd={() => {
                    dispatch(analyticsActions.toggleTimeRangeApplied(timeRange));
                    dispatch(analyticsActions.toggleViolentCheckedEffect(violentCheckedApplied));
                    dispatch(analyticsActions.toggleNonviolentCheckedEffect(nonviolentCheckedApplied));
                    dispatch(analyticsActions.toggleHazardCheckedEffect(hazardCheckedApplied));
                    dispatch(analyticsActions.toggleBlockUpdate(false));
                    dispatch(analyticsActions.toggleFilterLocked(false));
                }}
                label={renderLabel}
            >
                {categoricalDataArray
                    ?.filter((entry: CategoryStats) => filterPerCategoryStats(entry))
                    .map((entry: CategoryStats, index: number) => (
                        <Cell key={`cell-${index}`} fill={getColor(entry.category)} strokeWidth={0} />
                    ))}
                <Label
                    x={
                        totalReports.toString().length === 3
                            ? 160
                            : totalReports.toString().length === 2
                            ? 167
                            : totalReports.toString().length === 4
                            ? 153
                            : totalReports.toString().length === 5
                            ? 151
                            : 173
                    }
                    y={135}
                    fill={colors.scheme.primaryText}
                    content={
                        <text style={{ fontSize: "22px", fontWeight: 600 }}>
                            {totalReports.toString().length > 4
                                ? totalReports.toString().split("").slice(0, 2).join("") + "." + totalReports.toString().split("")[3] + "k"
                                : totalReports}
                        </text>
                    }
                />
                <Label x={161} y={150} fill={colors.scheme.primaryText} content={centralSubtitle} />
            </Pie>
        </RechartsPie>
    );
};
