import React, { useState, useEffect } from "react";
import { isMobile, isIOS } from "react-device-detect";
import { Team, UserProfile } from "../../types/client";
import { S3Key } from "../../utils";

// redux
import { useSelectFromRedux } from "../../utils/_hooks";
import { teamsActions } from "../../state/teams";
import { useDispatch } from "react-redux";

// components
import { Expand, ExpandItem, ContentContainer, Subheader, InputContainer } from "./styled";
import TitleRow from "./TitleRow";
import FullUserView from "./FullUserView";
import { ShortTextInputWithPlaceholderColor, ShortTextInputAutoComplete } from "../update-report/styled";

// icons
const SearchGreyIcon = S3Key + "search-grey.png";
const SearchWhiteIcon = S3Key + "search-white.png";
const LockWhiteIcon = S3Key + "lock-white.png";
const LockGreyIcon = S3Key + "lock-grey.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const dispatch = useDispatch();
    const colors = useSelectFromRedux((state) => state.color);
    const clientUsers = useSelectFromRedux((state) => state.clientInfo.users);
    const createTeamMode = useSelectFromRedux((state) => state.teams.createTeamMode);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const cuser = useSelectFromRedux((state) => state.cuser.userProfile);
    const users = useSelectFromRedux((state) => state.clientInfo.users);
    const accordianCurrentTeam = useSelectFromRedux((state) => state.teams.accordianCurrentTeam);
    const currentTeam = accordianCurrentTeam ? accordianCurrentTeam : "";
    const displayFullUserView = useSelectFromRedux((state) => state.teams.displayFullUserView);

    const [selectedUserId, updateSelectedUserId] = useState<number>(0);
    const [updatedTeams, updateUpdatedTeams] = useState<Team[]>(teams);
    const cuserTeam = teams.filter((team) => team.id === cuser?.team_id)[0];

    useEffect(() => {
        if (teams) {
            updateUpdatedTeams(teams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    useEffect(() => {
        if (accordianCurrentTeam) {
        } else if (cuser?.team_id) {
            dispatch(teamsActions.updateAccordianCurrentTeam(cuser?.team_id.toString()));
        } else {
            dispatch(teamsActions.updateAccordianCurrentTeam("default"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuser?.team_id, dispatch]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [userNames, updateUserNames] = useState<{ value: string; key: number }[]>(
        Array.from(
            new Set(
                users.map((user: UserProfile) => ({
                    value: user.first_name + " " + user.last_name + " (" + user.email + ")",
                    key: user.id
                }))
            )
        )
    );

    const getValueFromId = (id: number) => {
        if (id > 0) {
            const username = userNames.filter((userName: { value: string; key: number }) => userName.key === selectedUserId);
            if (username) {
                return username[0].value;
            } else {
                return "";
            }
        } else {
            return "";
        }
    };

    // const clearAndCloseCreateTeamOrUser = () => {
    //     dispatch(teamsActions.toggleCreateTeamMode(false));
    //     dispatch(teamsActions.updateCreateTeamName(null));
    //     dispatch(teamsActions.updateAddNewUserDesignation(null));
    //     dispatch(teamsActions.updateAddNewUserFirstName(""));
    //     dispatch(teamsActions.updateAddNewUserLastName(""));
    //     dispatch(teamsActions.updateAddNewUserRole(null));
    //     dispatch(teamsActions.updateAddNewUserTeam(null));
    //     dispatch(teamsActions.updateAddNewUserUnique(""));
    // };

    const setAutofillUser = (id: number) => {
        updateSelectedUserId(id);
        dispatch(teamsActions.updateFullViewUserId(id));
        dispatch(teamsActions.toggleDisplayFullUserView(true));
        const autofillUser = users.filter((user: UserProfile) => user.id === id);
        if (autofillUser[0]) {
            if (autofillUser[0].team_id) {
                dispatch(teamsActions.updateAccordianCurrentTeam(autofillUser[0].team_id.toString()));
                const anchor = document.getElementById(autofillUser[0].team_id.toString());
                if (anchor) {
                    anchor.scrollIntoView();
                }
            } else {
                dispatch(teamsActions.updateAccordianCurrentTeam("default"));
                const anchor = document.getElementById("default");
                if (anchor) {
                    anchor.scrollIntoView();
                }
            }
        }
    };

    const getNumberOfMembers = (teamId: string) => {
        if (teamId === "default") {
            const filteredUsers = users.filter((user) => user.team_id === null);
            return filteredUsers.length;
        } else {
            const filteredUsers = users.filter((user) => user.team_id === Number(teamId));
            return filteredUsers.length;
        }
    };

    const sortTeamUsers = (users: UserProfile[]) => {
        return users.sort((user1, user2): number => {
            if (
                user1 &&
                typeof user1.first_name === "string" &&
                user2 &&
                typeof user2.first_name === "string" &&
                typeof user1.last_name === "string" &&
                typeof user2.last_name === "string"
            ) {
                if (user1.first_name.toLowerCase() > user2.first_name.toLowerCase()) {
                    return 1;
                } else if (user1.first_name.toLowerCase() < user2.first_name.toLowerCase()) {
                    return -1;
                } else {
                    if (user1.last_name.toLowerCase() > user2.last_name.toLowerCase()) {
                        return 1;
                    } else if (user1.last_name.toLowerCase() < user2.last_name.toLowerCase()) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            } else {
                return 0;
            }
        });
    };

    const getHeader = (name: string, id: string, permissions: number) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ marginRight: "10px" }}>{name}</div>
                <div
                    style={{
                        background: colors.scheme.selectorBackground,
                        height: "20px",
                        minWidth: "20px",
                        borderRadius: "8px",
                        marginRight: "10px",
                        fontWeight: "bold",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0 5px"
                    }}
                >
                    {getNumberOfMembers(id)}
                </div>
                {permissions === 1 && (
                    <img src={colors.mode === "light" ? LockGreyIcon : LockWhiteIcon} alt="lock" style={{ height: "16px", marginRight: "10px" }} />
                )}
            </div>
        );
    };

    return (
        <>
            {!createTeamMode && (
                <Subheader
                    style={{
                        filter: displayFullUserView && isMobile ? "blur(4px)" : "none"
                    }}
                >
                    <img src={colors.mode === "light" ? SearchGreyIcon : SearchWhiteIcon} alt="search" style={{ width: "18px", marginRight: "10px" }} />
                    <InputContainer>
                        <ShortTextInputAutoComplete
                            options={userNames}
                            filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            color={colors.scheme.primaryText}
                            background={colors.scheme.selectorBackground}
                            onSelect={(value: string, option) => setAutofillUser(option.key as number)}
                            allowClear={isMobile && isIOS ? false : true}
                        >
                            <ShortTextInputWithPlaceholderColor
                                maxLength={30}
                                onChange={(e) => {
                                    if (e.target.value.length === 0) {
                                        updateSelectedUserId(0);
                                    }
                                }}
                                placeholder="Search All Users"
                                placeholdercolor={"#000000"}
                                value={getValueFromId(selectedUserId)}
                            />
                        </ShortTextInputAutoComplete>
                    </InputContainer>
                </Subheader>
            )}
            <ContentContainer style={{ filter: displayFullUserView && isMobile ? "blur(4px)" : "none" }}>
                <Expand
                    expandIconPosition="right"
                    ghost={true}
                    headerColor={colors.scheme.primaryText}
                    color={colors.scheme.selectorDarkText}
                    activeKey={[currentTeam]}
                    onChange={(e) => {
                        dispatch(teamsActions.updateAccordianCurrentTeam(e as string));
                        updateSelectedUserId(0);
                        dispatch(teamsActions.toggleModifyTeamMode(false));
                    }}
                    accordion={true}
                >
                    {
                        // if cuser is in default team or in public team
                        (cuser?.team_id === null || (cuserTeam && cuserTeam.permissions !== 1)) && (
                            <ExpandItem header={getHeader("Principal Group", "default", 0)} key="default" id="default">
                                <TitleRow
                                    key="default"
                                    teamName="Principal Group"
                                    users={sortTeamUsers(clientUsers.filter((clientUser) => clientUser.team_id === null))}
                                />
                            </ExpandItem>
                        )
                    }
                    {updatedTeams
                        .slice()
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map(
                            (team) =>
                                // if it's the cuser's team
                                // if cuser's team is public or null and team.permissions is public
                                // if cuser's team === null and cuser.role === 1 (default admin)
                                (cuser?.team_id === team.id ||
                                    (cuserTeam?.permissions === Number(0) && team.permissions === Number(0)) ||
                                    (cuser?.team_id === null && team.permissions === Number(0)) ||
                                    (cuser?.team_id === null && cuser.role === 1)) && (
                                    <ExpandItem header={getHeader(team.name, team.id.toString(), team.permissions)} key={team.id} id={team.id.toString()}>
                                        <TitleRow
                                            key={team.id}
                                            teamName={team.name}
                                            team={team}
                                            users={sortTeamUsers(clientUsers.filter((clientUser) => clientUser.team_id === team.id))}
                                        />
                                    </ExpandItem>
                                )
                        )}
                </Expand>
            </ContentContainer>
            {displayFullUserView && <FullUserView />}
        </>
    );
};
