import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import { PublicReport } from "../../types/reports";
import { S3Key } from "../../utils";
import { isMobile } from "react-device-detect";

// redux
import { useSelectFromRedux } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import { mapActions } from "../../state/map";

// components
import { LoadingSpinner } from "../_shared";
import { AdditionalDetailsContainer, FieldTitle, FieldContent, PhotoDisplay } from "./styled";
import {
    ReportItemContainer,
    ReportItemHeader,
    EventType,
    EventDate,
    ReportContentItem,
    IconContainer,
    TextWithIconContainer
} from "../_shared/report-summary/styled";

// icons
import { individualReportActions } from "../../state/individual-report";
import { useQuery } from "react-query";
import * as publicApi from "../../types/api/public";

const LatLongGreyIcon = S3Key + "lat-long-grey.png";
const LatLongWhiteIcon = S3Key + "lat-long-white.png";
const CalendarGreyIcon = S3Key + "calendar-grey.png";
const CalendarWhiteIcon = S3Key + "calendar-white.png";
const LocationGreyIcon = S3Key + "location-grey.png";
const LocationWhiteIcon = S3Key + "location-white.png";
const TimeGreyIcon = S3Key + "time-grey.png";
const TimeWhiteIcon = S3Key + "time-white.png";
const UserGreyIcon = S3Key + "user-dark.png";
const UserWhiteIcon = S3Key + "user-white.png";
const VerifiedIcon = S3Key + "check_mark.png";
const UnverifiedIcon = S3Key + "open-circle-amber.png";
const TeamsGreyIcon = S3Key + "teams-grey.png";
const TeamsWhiteIcon = S3Key + "teams-white.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const dispatch = useDispatch();

    const [imageDisplayError, updateImageDisplayError] = useState<boolean>(false);
    const userProfile = useSelectFromRedux((state) => state.cuser.userProfile);
    const clientProfile = useSelectFromRedux((state) => state.cuser.clientProfile);
    const colors = useSelectFromRedux((state) => state.color);
    const clientLoading = useSelectFromRedux((state) => state.clientInfo.loading.combinedReports);
    const publicLoading = useSelectFromRedux((state) => state.publicInfo.loading.publicReports);
    const reportId = useSelectFromRedux((state) => state.individualReport.currentReportId);
    const reportType = useSelectFromRedux((state) => state.individualReport.currentReportType);
    const flyToReport = useSelectFromRedux((state) => state.individualReport.flyToReport);
    const teams = useSelectFromRedux((state) => state.clientInfo.teams);
    const current_container = useSelectFromRedux((state) => state.cuser.userProfile?.current_container);
    const { filter } = useSelectFromRedux((state) => state);
    const timePeriodNumber = filter && filter.timePeriod ? Number(filter.timePeriod.split(" ")[0]) : 250;

    const getNumberFromTimePeriod = (timePeriod: string | null) => {
        if (timePeriod === "24 Hour") {
            return 1;
        } else if (timePeriod === "7 Day") {
            return 7;
        } else if (timePeriod === "28 Day") {
            return 28;
        } else {
            return 250;
        }
    };

    const publicReportsQuery = useQuery(["public-reports", current_container, timePeriodNumber], () =>
        publicApi.getReportsBounded(current_container ?? 2, getNumberFromTimePeriod(filter.timePeriod))
    );

    const [individualReport] = useMemo(() => {
        if (reportType === "public" && publicReportsQuery.data) {
            const individualReport = publicReportsQuery.data.find((report) => report.id === reportId);
            return [individualReport];
        } else {
            return [null];
        }
    }, [reportType, reportId, publicReportsQuery]);

    const getSource = () => {
        const source = (individualReport as PublicReport)?.source_type ?? "";
        if (individualReport?.hasOwnProperty("source_type")) {
            if (source === "VAMP") {
                return "Atlas Source";
                // } else if (source === "AAMP") {
                //     return "Atlas Asset";
            } else if (source === "Client") {
                if (clientProfile && clientProfile.id === individualReport.client_id) {
                    return clientProfile.name + " User";
                } else {
                    return "Atlas Source";
                }
            } else if (source === "AAMP") {
                return "Atlas Source";
            } else {
                return "Atlas Source";
            }
        }
    };

    const flyTo = () => {
        dispatch(
            mapActions.flyToPoint([individualReport?.point.coordinates[0] ?? 0, (individualReport?.point.coordinates[1] ?? 0) + (isMobile ? 0.003 : 0), 15])
        );
        dispatch(
            mapActions.renderTooltip({
                geometry: {
                    coordinates: individualReport?.point.coordinates
                },
                properties: {
                    id: individualReport?.id,
                    cluster: false,
                    title: individualReport?.report_type
                        ? (individualReport?.hasOwnProperty("confirmed") ? "SOS: " : "") + individualReport?.report_type
                        : individualReport?.hasOwnProperty("confirmed")
                        ? "SOS"
                        : "Event",
                    postedBy: getSource(),
                    verified: individualReport?.hasOwnProperty("verified") ? (individualReport as PublicReport)?.verified : -2
                }
            })
        );
        dispatch(mapActions.setTooltipVisible(true));
    };

    const getTeamNameFromTeamId = (teamId: number | null | undefined) => {
        if (teamId === null || teamId === undefined) {
            return "Principal Group";
        }
        const team = teams.filter((team) => team.id === teamId);
        if (team[0]) {
            if (team[0].permissions === 1 && userProfile?.team_id === null && userProfile.role === 1) {
                return team[0].name + " (Private)";
            } else {
                return team[0].name;
            }
        } else {
            return "Team Not Found";
        }
    };

    useEffect(() => {
        if (flyToReport) {
            flyTo();
            dispatch(individualReportActions.flyTo(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, flyToReport, individualReport]);

    if (publicLoading || clientLoading) {
        return (
            <div
                style={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <LoadingSpinner background={colors.scheme.primaryText} />
            </div>
        );
    }

    if (!individualReport) {
        return <div>Failed to find report.</div>;
    }

    return (
        <>
            {individualReport !== undefined &&
                individualReport && ( // possibly unnecessary now
                    <ReportItemContainer style={{ marginBottom: "0" }} background={colors.scheme.modalBackground}>
                        <ReportItemHeader>
                            <EventType>
                                {individualReport.report_type
                                    ? (individualReport.hasOwnProperty("confirmed") ? "SOS: " : "") + individualReport.report_type
                                    : individualReport.hasOwnProperty("confirmed")
                                    ? "SOS"
                                    : "Event"}
                            </EventType>
                            <EventDate>
                                <img
                                    src={colors.mode === "light" ? CalendarGreyIcon : CalendarWhiteIcon}
                                    alt="Calendar"
                                    style={{ height: "12px", marginRight: "7px" }}
                                />
                                {moment(individualReport.date_time).format("MM.DD.YYYY")}
                            </EventDate>
                        </ReportItemHeader>
                        {individualReport.address && (
                            <ReportContentItem onClick={flyTo} style={{ cursor: "pointer" }}>
                                <IconContainer>
                                    <img src={colors.mode === "light" ? LocationGreyIcon : LocationWhiteIcon} alt="Location" style={{ height: "12px" }} />
                                </IconContainer>
                                <TextWithIconContainer style={{ textDecoration: "underline" }}>{individualReport.address}</TextWithIconContainer>
                            </ReportContentItem>
                        )}
                        <ReportContentItem onClick={flyTo} style={{ cursor: "pointer" }}>
                            <IconContainer>
                                <img src={colors.mode === "light" ? LatLongGreyIcon : LatLongWhiteIcon} alt="Lat Long" style={{ height: "12px" }} />
                            </IconContainer>
                            <TextWithIconContainer style={{ textDecoration: "underline" }}>
                                {individualReport.point.coordinates[0].toFixed(3) + ", " + individualReport.point.coordinates[1].toFixed(3)}
                            </TextWithIconContainer>
                        </ReportContentItem>
                        <ReportContentItem>
                            <IconContainer>
                                <img src={colors.mode === "light" ? TimeGreyIcon : TimeWhiteIcon} alt="Time" style={{ height: "12px" }} />
                            </IconContainer>
                            <TextWithIconContainer>{moment(individualReport.date_time).format("HH:mm")}</TextWithIconContainer>
                        </ReportContentItem>
                        <ReportContentItem>
                            <IconContainer>
                                <img src={colors.mode === "light" ? UserGreyIcon : UserWhiteIcon} alt="User" style={{ height: "12px" }} />
                            </IconContainer>
                            <TextWithIconContainer>{getSource()}</TextWithIconContainer>
                        </ReportContentItem>
                        {individualReport.team_id && (
                            <ReportContentItem>
                                <IconContainer>
                                    <img src={colors.mode === "light" ? TeamsGreyIcon : TeamsWhiteIcon} alt="User" style={{ height: "12px" }} />
                                </IconContainer>
                                <TextWithIconContainer>{getTeamNameFromTeamId(individualReport.team_id as number)}</TextWithIconContainer>
                            </ReportContentItem>
                        )}
                        {individualReport?.hasOwnProperty("verified") && (individualReport as PublicReport).verified === 0 && (
                            <ReportContentItem>
                                <IconContainer>
                                    <img
                                        src={UnverifiedIcon}
                                        alt="Unverified"
                                        style={{
                                            height: "15px",
                                            marginTop: "-2px"
                                        }}
                                    />
                                </IconContainer>
                                <TextWithIconContainer>Unverified</TextWithIconContainer>
                            </ReportContentItem>
                        )}
                        {individualReport?.hasOwnProperty("verified") && (individualReport as PublicReport).verified === 1 && (
                            <ReportContentItem>
                                <IconContainer>
                                    <img
                                        src={VerifiedIcon}
                                        alt="Verified"
                                        style={{
                                            height: "15px",
                                            marginTop: "-2px"
                                        }}
                                    />
                                </IconContainer>
                                <TextWithIconContainer>Verified</TextWithIconContainer>
                            </ReportContentItem>
                        )}
                    </ReportItemContainer>
                )}
            <AdditionalDetailsContainer>
                <>
                    {individualReport?.hasOwnProperty("description") &&
                        (individualReport as PublicReport).verified > 0 &&
                        (individualReport as PublicReport).description && (
                            <>
                                <FieldTitle>Description</FieldTitle>
                                <FieldContent>{(individualReport as PublicReport).description}</FieldContent>
                            </>
                        )}
                    {individualReport?.hasOwnProperty("actor") && (individualReport as PublicReport).actor && (
                        <>
                            <FieldTitle>Actor</FieldTitle>
                            <FieldContent>{(individualReport as PublicReport).actor}</FieldContent>
                        </>
                    )}
                    {individualReport?.hasOwnProperty("target") && (individualReport as PublicReport).target && (
                        <>
                            <FieldTitle>Target</FieldTitle>
                            <FieldContent>{(individualReport as PublicReport).target}</FieldContent>
                        </>
                    )}
                </>
                {individualReport.photo_url && !individualReport.hasOwnProperty("verified") && (
                    <>
                        <FieldTitle>Media</FieldTitle>
                        {imageDisplayError ? (
                            <FieldContent>Could not load image, please try again.</FieldContent>
                        ) : (
                            <PhotoDisplay onError={() => updateImageDisplayError(true)} src={individualReport.photo_url} alt={individualReport.photo_url} />
                        )}
                    </>
                )}
            </AdditionalDetailsContainer>
            {!individualReport.hasOwnProperty("verified") && (
                <ReportContentItem
                    style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "15px",
                        color: colors.scheme.secondaryHeaderText,
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}
                >
                    {"Posted on " + moment(individualReport.created_at).format("MM.DD.YYYY") + " at " + moment(individualReport.created_at).format("HH:mm")}
                </ReportContentItem>
            )}
        </>
    );
};
