import { ClientAlertReport, ClientReport, PublicReport, ReportCategories, EventType, CategoryTypes } from "../../types/reports";
import { UserProfile } from "../../types/client";
import { Marker } from "../../state/map";
import { FilterState } from "../../state/filter";
import moment from "moment";
import { getDateTimeString } from "../../utils";

// tslint:disable-next-line: no-var-requires
const GeoJSON = require("geojson");

export const createMarkersFromReports = (
    publicReports: PublicReport[],
    userProfile: UserProfile,
    clientUsers: UserProfile[],
    filter: FilterState
): Marker[] => {
    // Ignore Public/Private reports by filter type

    if (!(filter.incidentsIncluded === filter.sosIncluded)) {
        if (!filter.incidentsIncluded) {
            publicReports = [];
        }

        if (!filter.sosIncluded) {
        }
    }

    if (!(filter.filteredUsers.length === 0)) {
        publicReports = [];
    }

    if (filter.teamFilter !== null || filter?.myTeamIncluded === true) {
        publicReports = [];
    }

    const publicReportsFiltered = publicReports.filter((report) => filterReport(report, filter));

    const publicMarkers = createMarkersFromPublicReports(publicReportsFiltered, userProfile.client_id);

    return publicMarkers;
};

const createMarkersFromPublicReports = (reports: PublicReport[], cuser_client_id: number): Marker[] => {
    const markers = Array<Marker>();

    reports.forEach((report) => {
        // Public reports generated from the current client's reports can be ignored
        if (report.client_id !== cuser_client_id) {
            const getPostedBy = (): string => {
                switch (report.source_type) {
                    case "Client":
                        return "Atlas Source";
                    case "VAMP":
                        return "Atlas Source";
                    case "AAMP":
                        return "Atlas Source";
                    case "Open Source":
                        // return report.source ?? "Open Source";
                        return "Atlas Source";
                    default:
                        return "Unknown Source";
                }
            };

            const marker: Marker = {
                id: report.id + "-public",
                longitude: report.point.coordinates[0],
                latitude: report.point.coordinates[1],
                offsetLeft: -20,
                offsetTop: -40,
                title: report.report_type,
                markerTitle: report.report_type + "-public",
                location: report.address ?? "No Address Available",
                postedBy: getPostedBy(),
                time: getDateTimeString(report.date_time),
                dateTimeMilli: moment(report.date_time).valueOf(),
                containerId: report.container_id,
                defaultRegionId: report.default_region_id,
                public: true,
                category: eventToCategory(report.report_type),
                verified: report.verified
            };

            markers.push(marker);
        }
    });

    return markers;
};

export const createGeojsonFromMarkers = (markers: Marker[]) => GeoJSON.parse(markers, { Point: ["latitude", "longitude"] });

const filterReport = (report: PublicReport | ClientReport | ClientAlertReport, filter: FilterState, profile?: UserProfile): boolean => {
    let result = true;

    if (filter.selectedRegion > 0) {
        if (filter.selectedRegion !== report.default_region_id) {
            result = false;
        }
    }

    if (filter.teamFilter !== null) {
        if (filter.teamFilter === Number(0)) {
            if (report.team_id !== null) {
                result = false;
            }
        } else {
            if (report.team_id !== filter.teamFilter) {
                result = false;
            }
        }
    }

    if (filter.myTeamIncluded) {
        if (report.team_id !== profile?.team_id) {
            result = false;
        }
    }

    if (filter.eventTypeApplied && filter.eventTypeApplied.length > 0) {
        if (!filter.eventTypeApplied.includes(report.report_type)) {
            result = false;
        }
    }

    if (
        !(!filter.orionSourceIncluded && !filter.openSourceIncluded && !filter.orionUserIncluded && !filter.myOrganizationIncluded && !filter.myReportsIncluded)
    ) {
        if (!filter.orionSourceIncluded && report.hasOwnProperty("source_type")) {
            if ((report as PublicReport).source_type === "AAMP" || (report as PublicReport).source_type === "VAMP") {
                result = false;
            }
        }

        if (!filter.orionUserIncluded && report.hasOwnProperty("source_type")) {
            if ((report as PublicReport).source_type === "Client") {
                result = false;
            }
        }

        if (!filter.openSourceIncluded && report.hasOwnProperty("source_type")) {
            if ((report as PublicReport).source_type === "Open Source") {
                result = false;
            }
        }

        if (!filter.myReportsIncluded && !report.hasOwnProperty("verified")) {
            if ((report as ClientReport | ClientAlertReport).user_id === profile?.id) {
                result = false;
            }
        }

        if (!filter.myOrganizationIncluded && !report.hasOwnProperty("verified")) {
            if (
                (report as ClientReport | ClientAlertReport).client_id === profile?.client_id &&
                !((report as ClientReport | ClientAlertReport).user_id === profile?.id)
            ) {
                result = false;
            }
        }
    }

    if (filter.filteredUsers.length > 0 && report.hasOwnProperty("user_id")) {
        filter.filteredUsers.forEach((user: number) => {
            if (user === (report as ClientReport | ClientAlertReport).user_id) {
                result = false;
            }
        });
    }

    if (!(!filter.violentIncluded && !filter.nonviolentIncluded && !filter.hazardIncluded && !filter.otherIncluded)) {
        if (!filter.violentIncluded) {
            if (ReportCategories["Violent"].includes(report.report_type)) {
                result = false;
            }
        }

        if (!filter.nonviolentIncluded) {
            if (ReportCategories["Non-Violent"].includes(report.report_type)) {
                result = false;
            }
        }

        if (!filter.hazardIncluded) {
            if (ReportCategories["Hazard"].includes(report.report_type)) {
                result = false;
            }
        }

        if (!filter.otherIncluded) {
            // sos reports initally do not have a report_type field
            if (ReportCategories["Other"].includes(report.report_type) || !report.report_type) {
                result = false;
            }
        }
    }

    if (!(!filter.verifiedIncluded && !filter.unverifiedIncluded) && report.hasOwnProperty("verified")) {
        if (!filter.verifiedIncluded) {
            if ((report as PublicReport).verified > 0) {
                result = false;
            }
        }

        if (!filter.unverifiedIncluded) {
            if ((report as PublicReport).verified < 1) {
                result = false;
            }
        }
    }

    if (filter.timePeriod) {
        const daysSinceReport = moment(new Date()).diff(report.date_time, "hours");
        switch (filter.timePeriod) {
            case "28 Day":
                if (!(daysSinceReport <= 28 * 24)) {
                    result = false;
                }
                break;
            case "7 Day":
                if (!(daysSinceReport <= 7 * 24)) {
                    result = false;
                }
                break;
            case "24 Hour":
                if (!(daysSinceReport <= 24)) {
                    result = false;
                }
                break;
        }
    }

    return result;
};

// TODO
export const getMarkerImage = (eventType: string) => {
    switch (eventType) {
        case "theft":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-theft-marker.png";
        case "assault":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-assault-marker.png";
        case "shooting":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-shooting-marker.png";
        case "protest":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-protest-marker.png";
        case "auto theft":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-auto-theft-marker.png";
        case "explosion (accidental)":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-explosion-marker.png";
        case "explosive weapon":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-explosive-weapon-marker.png";
        case "harassment":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-harassment-marker.png";
        case "abduction":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-kidnap-marker.png";
        case "dangerous terrain":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-dangerous-terrain-marker.png";
        case "emergency response":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-emergency_response-marker.png";
        case "gang activity":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-gang-activity-marker.png";
        case "roadblock":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-roadblock-marker.png";
        case "violent crime":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-violent-crime.png";
        case "congested area":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-congested-area-marker.png";
        case "coordinated attack":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-coordinated-attack-marker.png";
        case "danger":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-danger-marker.png";
        case "fire":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-fire-marker.png";
        case "police":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-police-marker.png";
        case "rocket attack":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-rocket-attack-marker.png";
        case "suspicious activity":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-suspicious-person-marker-marker.png";
        case "suspicious object":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-sus-object-marker.png";
        case "suspicious vehicle":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-sus-vehicle-marker.png";
        case "auto accident":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-traffic-accident-marker.png";
        case "stabbing":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-violent-crime-marker.png";
        case "homicide":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-murder.png";
        case "hijacking":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-hijacking-marker.png";
        case "smash and grab":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-smash-and-grab.png";
        case "home invasion":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-home-invasion.png";
        case "corruption":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-corruption-marker.png";
        case "drugs":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-drug-deal.png";
        case "other":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-other-marker.png";
        case "sos":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-SOS-inactive.png";
        case "active sos":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-SOS.png";
        case "checkpoint":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-checkpoint-marker.png";
        case "robbery":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-robbery-marker.png";
        case "arms & ammunition":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-arms-ammunition-marker.png";
        case "terrorism":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-terrorism-marker.png";
        case "rioting":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-rioting-marker.png";
        case "police response":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-police-response-marker.png";
        case "fraud":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-fraud-marker.png";
        case "vandalism":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-police-response-marker.png";
        case "disorderly conduct":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-disorderly_conduct-marker.png";
        case "property damage":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-property_damage-marker.png";
        default:
            console.log("unhandled marker image", eventType);
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/markers/event-other-marker.png";
    }
};

export const getPublicMarkerImage = (eventType: string) => {
    switch (eventType) {
        case "arms & ammunition":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-arms-ammunition-marker.png";
        case "theft":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-theft-marker.png";
        case "assault":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-assault-marker.png";
        case "shooting":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-shooting-marker.png";
        case "protest":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-protest-marker.png";
        case "auto theft":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-auto-theft-marker.png";
        case "explosion (accidental)":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-explosion-marker.png";
        case "explosive weapon":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-explosive-weapon-marker.png";
        case "harassment":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-harassment-marker.png";
        case "abduction":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-kidnap-marker.png";
        case "dangerous terrain":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-dangerous-terrain-marker.png";
        case "emergency response":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-emergency-response-marker.png";
        case "gang activity":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-gang-activity-marker.png";
        case "roadblock":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-roadblock-marker.png";
        case "violent crime":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-violent-crime.png";
        case "coordinated attack":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-coordinated-attack-marker.png";
        case "fire":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-fire-marker.png";
        case "police":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-police-marker.png";
        case "police response":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-police-response-marker.png";
        case "rocket attack":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-rocket-attack-marker.png";
        case "suspicious activity":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-suspicious-person-marker.png";
        case "suspicious object":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-sus-object-marker.png";
        case "suspicious vehicle":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-sus-vehicle-marker.png";
        case "auto accident":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-traffic-accident-marker.png";
        case "stabbing":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-violent-crime-marker.png";
        case "homicide":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-murder.png";
        case "hijacking":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-hijacking-marker.png";
        case "smash and grab":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-smash-and-grab.png";
        case "home invasion":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-home-invasion.png";
        case "corruption":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-corruption-marker.png";
        case "drugs":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-drug-deal.png";
        case "other":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-other.png";
        case "sos":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-SOS-inactive.png";
        case "active sos":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-SOS.png";
        case "checkpoint":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-checkpoint-marker.png";
        case "robbery":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-robbery-marker.png";
        case "terrorism":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-terrorism-marker.png";
        case "rioting":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-rioting-marker.png";
        case "fraud":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-fraud-marker.png";
        case "vandalism":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-vandalism-marker.png";
        case "disorderly conduct":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-disorderly-conduct-marker.png";
        case "property damage":
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-property-damage-marker.png";
        default:
            console.log("unhandled marker image", eventType);
            return "https://orion-assets-s3.s3.us-east-2.amazonaws.com/PublicMarkers/event-other.png";
    }
};

export function isHexColor(hex: any) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result;
}

export function hexToRgb(hex: any) {
    const result = isHexColor(hex);

    if (!result) {
        return [0, 0, 0];
    }

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return [r, g, b];
}

export const eventToCategory = (eventType: EventType | null): CategoryTypes => {
    let category: CategoryTypes = "Violent";
    if (!eventType) {
        category = "Other";
    } else {
        for (const cat of Object.keys(ReportCategories) as (keyof typeof ReportCategories)[]) {
            if (ReportCategories[cat].includes(eventType)) {
                category = cat;
            }
        }
    }
    return category;
};

export const getFrequency = (data: any) =>
    data.reduce(
        (uniques: any, val: any) => ({
            ...uniques,
            [val]: (uniques[val] || 0) + 1
        }),
        {}
    );

export const getMode = (data: any) => {
    const occur = getFrequency(data);
    return Object.keys(occur).reduce((prev, key) => (occur[prev] >= occur[key] ? prev : key), Object.keys(occur)[0]);
};

export const getMarkerColor = (category: string, colors: any) => {
    switch (category) {
        case "Violent":
            return hexToRgb(colors.scheme.analyticsPieViolent);
        case "Non-Violent":
            return hexToRgb(colors.scheme.analyticsPieNonviolent);
        case "Hazard":
            return hexToRgb(colors.scheme.analyticsPieHazard);
        case "Other":
            return hexToRgb(colors.scheme.analyticsPieOther);
        default:
            return hexToRgb(colors.scheme.analyticsPieOther);
    }
};

export const dataToGeojson = (data: any) =>
    data.map((d: any) => ({
        geometry: {
            coordinates: [d.longitude, d.latitude],
            type: "Point"
        },
        id: d.id,
        properties: {
            ...d
        }
    }));

export const timeFilterMarkers = (marker: any, timeFilter: number[]) => {
    return marker.properties.dateTimeMilli! >= timeFilter[0] && marker.properties.dateTimeMilli! <= timeFilter[1];
};
