import React, { useState, useEffect } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid } from "recharts";
import { AreaChartHeading /* Arrow */ } from "./styled";
import moment from "moment";
import { useSelectFromRedux } from "../../utils/_hooks";
import { ThirtyDayCount } from "../../types/analytics";
import { filterTimeRangeArray } from "./operations";
import { ReportCategories, reportType } from "../../types/reports";

interface Props {
    timeRangeArray: ThirtyDayCount[];
    width: number;
    height: number;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ timeRangeArray, width, height }: Props) => {
    const { data, timeRange, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, appliedEventTypes } = useSelectFromRedux(
        (state) => state.analytics
    );
    const [dataApplied, updateDataApplied] = useState<ThirtyDayCount[]>(timeRangeArray);

    const colors = useSelectFromRedux((state) => state.color);
    const endMoment = moment(dataApplied[dataApplied.length - 1].dateTime, "YYYY-MM-DD HH:mm");
    const endYearString = endMoment.format("YYYY");
    const endMonthString = endMoment.format("MMM");
    const endDayString = endMoment.format("DD");
    const endTimeString = endMoment.format("HH:mm");
    const endDay = Number(endDayString);

    const beginMoment = moment(dataApplied[0].dateTime, "YYYY-MM-DD HH:mm");
    const beginYearString = beginMoment.format("YYYY");
    const beginMonthString = beginMoment.format("MMM");
    const beginDayString = beginMoment.format("DD");
    const beginTimeString = beginMoment.format("HH:mm");
    const beginDay = Number(beginDayString);

    const filteredViolentTypes = appliedEventTypes.filter((event: string) => ReportCategories.Violent.includes(event as reportType));
    const filteredNonviolentTypes = appliedEventTypes.filter((event: string) => ReportCategories["Non-Violent"].includes(event as reportType));
    const filteredHazardTypes = appliedEventTypes.filter((event: string) => ReportCategories.Hazard.includes(event as reportType));
    const filteredOtherTypes = appliedEventTypes.filter((event: string) => ReportCategories.Other.includes(event as reportType));

    useEffect(() => {
        const newData = filterTimeRangeArray(timeRange, data, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, appliedEventTypes);

        updateDataApplied(newData);
    }, [data, violentCheckedApplied, nonviolentCheckedApplied, hazardCheckedApplied, timeRange, appliedEventTypes]);

    const XAxisTick = (props: any) => {
        const { x, y, payload, timeRange } = props;

        return (
            <g
                transform={
                    timeRange === "28 Day"
                        ? `translate(${x + 20},${y})`
                        : timeRange === "24 Hour"
                        ? `translate(${x + 30},${y})`
                        : timeRange === null
                        ? `translate(${x + 10},${y})`
                        : `translate(${x + 5},${y})`
                }
            >
                <text x={0} y={0} dy={16} textAnchor="end" fill={colors.scheme.primaryText}>
                    {timeRange === "28 Day"
                        ? moment(payload.value).format("MM/DD")
                        : timeRange === "7 Day"
                        ? payload.value.charAt(0)
                        : timeRange === "24 Hour"
                        ? moment(payload.value).format("HH:mm")
                        : moment(payload.value).format("MMM")}
                </text>
            </g>
        );
    };

    const YAxisTick = (props: any) => {
        const { x, y, payload } = props;

        return (
            <g transform={`translate(${x},${y - 10})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill={colors.scheme.primaryText}>
                    {payload.value}
                </text>
            </g>
        );
    };

    const headingString =
        timeRange === "24 Hour"
            ? `${beginTimeString} ${beginMonthString} ${beginDay} - ${endTimeString} ${endMonthString} ${endDay}`
            : `${beginMonthString} ${beginDay} ${timeRange ? "" : beginYearString} - ${endMonthString} ${endDay} ${timeRange ? "" : endYearString}`;

    const getGraphColor = (): string => {
        if (violentCheckedApplied && !nonviolentCheckedApplied && !hazardCheckedApplied) {
            return colors.scheme.analyticsPieViolent;
        } else if (!violentCheckedApplied && nonviolentCheckedApplied && !hazardCheckedApplied) {
            return colors.scheme.analyticsPieNonviolent;
        } else if (!violentCheckedApplied && !nonviolentCheckedApplied && hazardCheckedApplied) {
            return colors.scheme.analyticsPieHazard;
        } else if (appliedEventTypes.length > 0 && filteredViolentTypes.length === appliedEventTypes.length) {
            return colors.scheme.analyticsPieViolent;
        } else if (appliedEventTypes.length > 0 && filteredNonviolentTypes.length === appliedEventTypes.length) {
            return colors.scheme.analyticsPieNonviolent;
        } else if (appliedEventTypes.length > 0 && filteredHazardTypes.length === appliedEventTypes.length) {
            return colors.scheme.analyticsPieHazard;
        } else if (appliedEventTypes.length > 0 && filteredOtherTypes.length === appliedEventTypes.length) {
            return colors.scheme.analyticsPieOther;
        } else {
            return colors.scheme.analyticsGraphBase;
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "left",
                width: "100%",
                height: "200px",
                marginTop: "20px"
            }}
        >
            <AreaChartHeading color={colors.scheme.primaryText}>{headingString}</AreaChartHeading>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    paddingLeft: 0,
                    paddingRight: 30
                }}
            >
                <AreaChart
                    width={width}
                    height={height}
                    data={dataApplied}
                    margin={{
                        top: 5,
                        right: 10,
                        left: 0,
                        bottom: 0
                    }}
                    style={{ color: colors.scheme.primaryText }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor={getGraphColor()} stopOpacity={0.95} />
                            <stop offset="95%" stopColor={getGraphColor()} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} stroke={colors.scheme.analyticsPieOther} />
                    <XAxis
                        padding={{ left: 0, right: 0 }}
                        dataKey={timeRange === "7 Day" ? "dayName" : "dateTime"}
                        height={90}
                        tick={<XAxisTick timeRange={timeRange} />}
                        tickLine={false}
                        interval={timeRange === "7 Day" ? 0 : timeRange === "28 Day" ? 5 : timeRange === null ? 9 : 6}
                        stroke={colors.scheme.analyticsGraphBase}
                    />
                    <YAxis
                        padding={{ top: 0, bottom: 0 }}
                        dataKey="count"
                        tick={<YAxisTick />}
                        tickLine={false}
                        interval={1}
                        stroke={colors.scheme.analyticsGraphBase}
                    />
                    <Area type="linear" fill="url(#colorUv)" dataKey="count" stroke={getGraphColor()} strokeWidth={2} dot={false} />
                </AreaChart>
                {/* < Arrow src={RightArrow} /> */}
            </div>
        </div>
    );
};
