import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelectFromRedux } from "../../utils/_hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import Geocode from "react-geocode";

// redux
import { toastActions } from "../../state/toast";
import { cuserOperations } from "../../state/cuser";

// components
import { FullScreenImage, LogoAndFormContainer, VerstaanLogo, OtherCompanyLogo, FormField, SubmitButton } from "../_shared/auth";
import { Error } from "../home/styled";
import { MessageContainer, PhoneNumberInput, MessageContent, PhoneLoadingContainer } from "../signup/styled";
import { Alert, LoadingSpinner } from "../_shared";
import { fadedRed, GoogleGeocoderAPIKey, S3Key } from "../../utils";
import { SmallIcon } from "../_shared/typography";
import { SmallSpacer } from "../_shared/layout";

const COBRANDING_RESTYLES: { [key: string]: string } = {
    kenya: "https://idylworld-static.sgp1.digitaloceanspaces.com/kngForAuth.png"
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { signedIn } = useSelectFromRedux((state) => state.cuser);
    const dispatch = useDispatch();
    const { coBranding } = useParams();

    if (coBranding && Object.keys(COBRANDING_RESTYLES).includes(coBranding) && !localStorage.getItem("coBranding")) {
        console.log("setting local storage: " + coBranding);
        localStorage.setItem("coBranding", coBranding);
    }

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loginOptionValue, updateLoginOptionValue] = useState<string>("email");
    const [phone, updatePhone] = useState<string>("");
    const [defaultPhoneNumberCountryCode, updateDefaultPhoneNumberCountryCode] = useState<string>("us");
    const [phoneNumberCountryCodeLoading, updatePhoneNumberCountryCodeLoading] = useState<boolean>(true);
    const colors = useSelectFromRedux((state) => state.color);
    const toastIsError = useSelectFromRedux((state) => state.error.isError);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    Geocode.fromLatLng("" + lat, "" + lng, GoogleGeocoderAPIKey)
                        .then((response) => {
                            updateDefaultPhoneNumberCountryCode(response.results[0].address_components[6].short_name.toLowerCase());
                            updatePhoneNumberCountryCodeLoading(false);
                        })
                        .catch((error) => {
                            updatePhoneNumberCountryCodeLoading(false);
                            console.log(error);
                        });
                },
                (error) => {
                    updatePhoneNumberCountryCodeLoading(false);
                    console.log(error);
                }
            );
        } else {
            updatePhoneNumberCountryCodeLoading(false);
        }

        setTimeout(() => {
            updatePhoneNumberCountryCodeLoading(false);
        }, 5000);

        // eslint-disable-next-line
    }, []);

    const submitLogin = () => {
        if (phone.length > 0) {
            dispatch(cuserOperations.signIn(phone, password));
        }

        if (email.length > 0) {
            // if (email.includes("@")) {
            dispatch(cuserOperations.signIn(email, password));
            // } else {
            //     dispatch(errorActions.openError("Please enter a valid email."));
            // }
        }
    };

    const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);
    const errorContent = useSelectFromRedux((state) => state.error.currentText);

    const handleErrorClose = () => {
        dispatch(toastActions.closeToast());
    };

    if (signedIn) {
        return <Redirect to="/" />;
    }

    if (window.location.hostname === "kenyanationalguard.arcturus.us.com" || window.location.hostname === "kenyanationalguard.arcturus.co")
        localStorage.setItem("coBranding", "kenya");

    const coBrandingApplied = localStorage.getItem("coBranding");

    return (
        <FullScreenImage>
            <LogoAndFormContainer>
                {window.location.hostname === "kenyanationalguard.arcturus.us.com" || window.location.hostname === "kenyanationalguard.arcturus.co" ? (
                    <OtherCompanyLogo src="https://idylworld-static.sgp1.digitaloceanspaces.com/kngForAuth.png" alt="Company Logo" />
                ) : (
                    <VerstaanLogo
                        src={coBrandingApplied ? COBRANDING_RESTYLES[coBrandingApplied] : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/orion-logo.png"}
                        alt="Verstaan Logo"
                    />
                )}
                {loginOptionValue === "email" && (
                    <FormField
                        id="email"
                        type="text"
                        name="email"
                        placeholder="EMAIL"
                        className="inputs"
                        value={email}
                        onChange={(event) => setEmail(event.target.value.replace(/\s/g, ""))}
                    />
                )}
                {loginOptionValue === "phone" && phoneNumberCountryCodeLoading && (
                    <PhoneLoadingContainer style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                        <LoadingSpinner background={"white"} />
                    </PhoneLoadingContainer>
                )}
                {loginOptionValue === "phone" && !phoneNumberCountryCodeLoading && (
                    <div style={{ margin: "10px", width: "254px", height: "37px", display: "flex" }}>
                        <PhoneLoadingContainer style={{ margin: "0" }}>
                            <PhoneNumberInput
                                country={defaultPhoneNumberCountryCode}
                                value={phone}
                                onChange={(phone) => updatePhone(phone)}
                                preferredCountries={["us", "za", "ng", "ke"]}
                            />
                        </PhoneLoadingContainer>
                    </div>
                )}
                {loginOptionValue === "email" ? (
                    <MessageContainer style={{ margin: "0 0 10px" }}>
                        <MessageContent
                            onClick={() => {
                                updateLoginOptionValue("phone");
                                setEmail("");
                            }}
                        >
                            <u style={{ cursor: "pointer" }}>Log in with phone</u>?
                        </MessageContent>
                    </MessageContainer>
                ) : (
                    <MessageContainer style={{ margin: "0 0 10px" }}>
                        <MessageContent
                            onClick={() => {
                                updateLoginOptionValue("email");
                                updatePhone("");
                            }}
                        >
                            <u style={{ cursor: "pointer" }}>Log in with email</u>?
                        </MessageContent>
                    </MessageContainer>
                )}
                <FormField
                    id="password"
                    type="password"
                    name="password"
                    placeholder="PASSWORD"
                    className="inputs"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <SubmitButton type="button" onClick={submitLogin}>
                    Log In
                </SubmitButton>
                <Link to="/signup">
                    <MessageContainer>
                        <MessageContent>
                            Don't have an account yet? <u style={{ cursor: "pointer" }}>Sign up</u>.
                        </MessageContent>
                    </MessageContainer>
                </Link>
                <Link to="/forgotpassword">
                    <MessageContainer>
                        <MessageContent>
                            Forgot your password? <u style={{ cursor: "pointer" }}>Reset</u>.
                        </MessageContent>
                    </MessageContainer>
                </Link>
            </LogoAndFormContainer>
            {errorIsOpen && errorContent && (
                <Error
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    onClose={handleErrorClose}
                    autoHideDuration={10000}
                    background={toastIsError ? colors.scheme.filterViolentTag : colors.general.standardGreen}
                >
                    <Alert style={{ background: toastIsError ? fadedRed : colors.general.standardGreen }}>
                        {
                            toastIsError ?
                                <SmallIcon src={S3Key + "alert-white.svg"} alt="alert" />
                                : <SmallIcon src={S3Key + "check-white.svg"} alt="checkmark" />
                        }
                        <SmallSpacer />
                        {errorContent}
                    </Alert>
                </Error>
            )}
        </FullScreenImage>
    );
};
