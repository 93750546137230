import React from "react";
import { PublicInsight } from "../../types/client";
import { useSelectFromRedux } from "../../utils/_hooks";
import { ReportItemContainer, ReportItemHeader, EventType, EventDate } from "../_shared/report-summary/styled";
import moment from "moment";
import { InsightContentItem, InsightContentTitle, InsightContentContent, InsightRegion } from "./styled";

interface InsightSummaryProps {
    insight: PublicInsight;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ insight }: InsightSummaryProps) => {
    const [colors, defaultRegions] = useSelectFromRedux((state) => [state.color, state.cuser.defaultRegions]);

    // Conversion from Map to usable Object
    let content: { title: string; content: string | string[] }[] = [];
    let keys: string[] = Object.keys(insight.content);
    let values: string[] = Object.values(insight.content);
    keys.forEach((key: string) => {
        content.push({ title: key, content: values[keys.indexOf(key)] });
    });

    return (
        <ReportItemContainer key={insight.id} background={colors.scheme.generalCard} style={{ padding: "20px" }}>
            <ReportItemHeader style={{ marginBottom: "-5px" }}>
                <EventType>{insight.title}</EventType>
                <EventDate>
                    <img
                        src={
                            colors.mode === "light"
                                ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/calendar-grey.png"
                                : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/calendar-white.png"
                        }
                        alt="Calendar"
                        style={{ height: "12px", marginRight: "7px" }}
                    />
                    {moment(insight.created_at).format("MM.DD.YYYY")}
                </EventDate>
            </ReportItemHeader>
            {content.map((entry: { title: string; content: string | string[] }) => (
                <InsightContentItem key={entry.title} style={{ flexDirection: "column" }}>
                    <InsightContentTitle color={colors.scheme.primaryText}>{entry.title}</InsightContentTitle>
                    {entry.content instanceof Array ? (
                        <InsightContentContent color={colors.scheme.primaryText}>
                            <ul style={{ paddingLeft: "20px" }}>
                                {entry.content.map((bullet: string) => (
                                    <li>{bullet}</li>
                                ))}
                            </ul>
                        </InsightContentContent>
                    ) : (
                        <InsightContentContent color={colors.scheme.primaryText}>{entry.content}</InsightContentContent>
                    )}
                </InsightContentItem>
            ))}
            {insight.default_region_ids && (
                <InsightRegion color={colors.scheme.secondaryHeaderText}>
                    <img
                        src={
                            colors.mode === "light"
                                ? "https://orion-assets-s3.s3.us-east-2.amazonaws.com/location-grey.png"
                                : "https://orion-assets-s3.s3.us-east-2.amazonaws.com/location-white.png"
                        }
                        alt="Location"
                        style={{ height: "12px", marginRight: "5px", marginBottom: "3px" }}
                    />
                    {defaultRegions.find((region) => insight.default_region_ids?.includes(region.id))?.name}
                </InsightRegion>
            )}
        </ReportItemContainer>
    );
};