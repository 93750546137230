import React, { useState, useEffect, useRef } from "react";
import BottomWidget from "./bottom-widget";
import { useSelectFromRedux } from "../../../../utils/_hooks";
import { useDispatch } from "react-redux";
import { timeFilterActions } from "../../../../state/time-filter";
import { ThemeProvider } from "styled-components";
import { theme } from "../styles/base";
import { getFilterProps } from "../utils/filter-utils";
import { validateInputData } from "../processors/data-processor";
import { createGeojsonFromMarkers } from "../../utils";
import { processGeojson } from "kepler.gl/processors";
import moment from "moment";

export default function TimeComponent(props) {
    const colors = useSelectFromRedux((state) => state.color);
    const { data } = props;
    const dispatch = useDispatch();

    const [{ showTimeFilter }, { timeFilter: value }, { modalContent }, { timePeriod }] = useSelectFromRedux((state) => [
        state.filter,
        state.timeFilter,
        state.leftModal,
        state.filter
    ]);
    const timePeriodToDays = (timePeriod) => {
        switch (timePeriod) {
            case "28 Day":
                return 30;
            case "7 Day":
                return 7;
            case "24 Hour":
                return 1;
            case null:
                // sets start date at 5/24/2020 at the current hour
                return moment().diff(moment("2020-05-24"), "days");
            default:
                return 30;
        }
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevData = usePrevious(data);

    const [speed, setSpeed] = useState(1);
    const [domain, setDomain] = useState([moment().subtract(timePeriodToDays(timePeriod), "d").valueOf(), moment().valueOf()]);

    const [filterProps, setFilterProps] = useState();
    useEffect(() => {
        const domainTemp = [moment().subtract(timePeriodToDays(timePeriod), "d").valueOf(), moment().valueOf()];
        setDomain(domainTemp);
        dispatch(timeFilterActions.updateTimeFilter(domainTemp));
    }, [timePeriod, dispatch]);

    useEffect(() => {
        if (data && prevData) {
            data.forEach((x, i) => {
                data[i] = { ...data[i], defaultRegionId: x.defaultRegionId ?? -1 };
            });
            const geojson = createGeojsonFromMarkers(data);
            const keplerData = processGeojson(geojson);
            const validatedData = validateInputData(keplerData) || null;
            if (!validatedData) return;
            const timeField = validatedData.fields.find((e) => e.name === "dateTimeMilli");
            setFilterProps(getFilterProps(validatedData.rows, timeField, domain));
        }
    }, [data, prevData, domain]);

    const [containerW, setContainerW] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setContainerW(window.innerWidth);

        window.addEventListener("resize", handleResize);
    });

    return (
        <>
            {modalContent === "analytics" && showTimeFilter && (
                <ThemeProvider theme={{ orion: colors }}>
                    <ThemeProvider theme={theme}>
                        {filterProps && (
                            <BottomWidget
                                filter={{
                                    ...filterProps,
                                    dataId: ["historical"],
                                    id: "61rnneqvh",
                                    name: ["Historical"],
                                    value,
                                    domain,
                                    speed
                                }}
                                visStateActions={{
                                    setFilter: (timeFilter) => dispatch(timeFilterActions.updateTimeFilter(timeFilter)),
                                    updateFilterAnimationSpeed: setSpeed
                                }}
                                containerW={containerW}
                            />
                        )}
                    </ThemeProvider>
                </ThemeProvider>
            )}
        </>
    );
}
