import styled from "styled-components";
import Collapse from "antd/lib/collapse";
import Input from "antd/lib/input";
import InputNumber from "antd/lib/input-number";
import Select from "antd/lib/select";
import { Button as AntdButton } from "antd";
const { TextArea } = Input;
const { Panel } = Collapse;

interface ExpandProps {
    headerColor: string;
    color: string;
}

export const Expand = styled(Collapse)`
    width: 100%;
    background: transparent !important;
    border: none;

    .ant-collapse {
        background: transparent !important;
    }

    .ant-collapse-item {
        border-bottom: none;
    }

    .ant-collapse-header {
        color: ${(props: ExpandProps) => props.headerColor} !important;
        font-size: 16px;
    }

    .ant-collapse-content {
        background: transparent !important;
        border-top: none;
        color: ${(props: ExpandProps) => props.color};
    }
`;

export const ExpandItem = styled(Panel)`
    .ant-collapse-content-box {
        padding: 0px 25px;
    }
`;

export const ContentContainer = styled.div`
    width: 100%;
    overflow-y: scroll;
`;

export const UserRowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    font-size: 15px;
`;

interface NameContainerProps {
    textColor: string;
    cuser: boolean;
    highlight: boolean;
}

export const NameContainer = styled.div`
    width: 80%;
    color: ${(props: NameContainerProps) => props.textColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-weight: ${(props: NameContainerProps) => (props.cuser ? 600 : 400)};
    letter-spacing: ${(props: NameContainerProps) => (props.highlight === true ? 0.8 : 0)};
`;

export const RoleContainer = styled.div`
    vertical-align: super;
    font-size: 10px;
    margin-left: 7px;
`;

interface StatusContainerProps {
    textColor: string;
}

export const StatusContainer = styled.div`
    width: 20%;
    color: ${(props: StatusContainerProps) => props.textColor};
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface DotProps {
    background: string;
}

export const Dot = styled.div`
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background: ${(props: DotProps) => props.background};
`;

export const FieldTitle = styled.div`
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 400;
`;

export const FieldContent = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`;

interface TextInputProps {
    background: string;
    color: string;
    placeholdercolor?: string;
}

export const SingleLineTextInput = styled(Input)`
    border-radius: 8px !important;
    background: ${(props: TextInputProps) => props.background} !important;
    border: none;
    color: ${(props: TextInputProps) => props.color};

    ::placeholder {
        color: ${(props: TextInputProps) => props.placeholdercolor} !important;
    }
`;

export const TextInput = styled(TextArea)`
    border-radius: 8px !important;
    background: ${(props: TextInputProps) => props.background} !important;
    border: none;
    color: ${(props: TextInputProps) => props.color};
`;

interface ButtonProps {
    background: string;
    color: string;
}

export const Button = styled.button`
    width: 30%;
    margin: 10px 10px 10px;

    background-color: ${(props: ButtonProps) => props.background};
    height: 37px;
    text-transform: uppercase;
    border-radius: 18px;
    border: none;
    color: ${(props: ButtonProps) => props.color};
    outline: none;

    :hover {
        cursor: pointer;
    }
`;

export const LoadingButton = styled(AntdButton)`
    width: 30%;
    margin: 10px 10px 10px;

    background-color: ${(props: ButtonProps) => props.background};
    height: 37px;
    text-transform: uppercase;
    border-radius: 18px;
    border: none;
    color: ${(props: ButtonProps) => props.color};
    outline: none;

    :hover {
        cursor: pointer;
        background-color: ${(props: ButtonProps) => props.background};
        color: ${(props: ButtonProps) => props.color};
        opacity: 0.8;
    }
    :active {
        cursor: pointer;
        background-color: ${(props: ButtonProps) => props.background};
        color: ${(props: ButtonProps) => props.color};
        opacity: 0.8;
    }
    :focus {
        cursor: pointer;
        background-color: ${(props: ButtonProps) => props.background};
        color: ${(props: ButtonProps) => props.color};
        opacity: 0.8;
    }
`;

interface NumberInputProps {
    background: string;
    color: string;
}

export const NumberInput = styled(InputNumber)`
    border-radius: 8px !important;
    background: ${(props: NumberInputProps) => props.background} !important;
    border: none;
    color: ${(props: NumberInputProps) => props.color};
    width: 100%;

    .ant-input-number-handler-wrap {
        display: none !important;
    }
`;

interface DropdownProps {
    placeholdercolor?: string;
}

export const Dropdown = styled(Select)<DropdownProps>`
    width: 100%;
    color: ${({ theme }) => theme.primaryText};
    font-size: 14px;

    .ant-select-selector {
        background: ${({ theme }) => theme.selectorBackground} !important;
        border: none !important;
        border-radius: 8px !important;
    }

    .ant-select-arrow {
        color: ${({ theme }) => theme.primaryText};
        pointer-events: none;
    }

    ::placeholder {
        color: ${({ placeholdercolor }) => placeholdercolor} !important;
    }
`;

export const Subheader = styled.div`
    width: 100%;
    height: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
`;

export const InputContainer = styled.div`
    width: calc(100% - 22px);
`;
