import * as firebase from "firebase/app";
import "firebase/messaging";
import { toastActions } from "../state/toast";
import { DeviceSubscription } from "../types/auth";
import store from "../state/store";
import { publicInfoOperations } from "../state/publicInfo";
import { GetDeviceFingerprint } from "../state/cuser/operations";
import * as client from "../types/api/client";
import { cuserActions } from "../state/cuser";
import { isMobile } from "react-device-detect";
import { notificationActions, ReportToast, BulletinToast } from "../state/notification";
import { getFirebaseConfig } from "../utils/firebaseConfig";
import { BulletinNotificationData, ReportNotificationData } from "../types/reports";

const initializeFCM = firebase.initializeApp(getFirebaseConfig(process.env.REACT_APP_ENV!));

let messaging: firebase.messaging.Messaging;

if (firebase.messaging.isSupported()) {
    messaging = initializeFCM.messaging();

    messaging.onTokenRefresh(() => {
        messaging
            .getToken()
            .then((refreshedToken) => {
                console.log("[Messaging] Refreshing FCM Token.");
                // Indicate that the new Instance ID token has not yet been sent to the
                // app server.

                GetDeviceFingerprint().then((fingerprint) => {
                    if (!fingerprint) {
                        console.error("[Messaging] Could not get device fingerprint.");
                        store.dispatch(toastActions.openError("Issue recognizing this device while refreshing subscription. Please try again."));
                        return;
                    }

                    const subscription: DeviceSubscription = {
                        token: refreshedToken,
                        device_type: isMobile ? "Mobile" : "Web",
                        device_fingerprint: fingerprint
                    };

                    client
                        .updateSubscription(refreshedToken, fingerprint)
                        .then(() => {
                            store.dispatch(cuserActions.updateDeviceSubscription(subscription));
                        })
                        .catch((err) => {
                            console.error("[Messaging] Could not update FCM subscription: ", err);
                            store.dispatch(toastActions.openError("Error refreshing real-time listener subscription. Please restart the application."));
                        });
                });
            })
            .catch((err) => {
                console.log("Unable to retrieve refreshed token ", err);
                store.dispatch(toastActions.openError("Notifications subscription interrupted. Please restart the application."));
            });
    });

    /**
     * Handle messages when app is in the foreground
     */
    messaging.onMessage((payload) => {
        console.log("on Message: ", payload);

        if (payload.data) {

            if (payload.data.type == "Public") {
                const notificationData: ReportNotificationData = payload.data;
                handleForegroundNotification(notificationData);
            } else if (payload.data.type == "Bulletin") {

                let notificationData: BulletinNotificationData = payload.notification;
                notificationData.type = "Bulletin"

                handleForegroundBulletinNotif(notificationData);
            }
            
        } else {
            console.error("Empty Notification, no data field: " + payload);
        }

    });
}

export const handleForegroundNotification = (notificationData: ReportNotificationData) => {
    if (notificationData) {
        switch (notificationData.type) {
            case "Public":
                console.log("Public report added!");
                // @ts-ignore incorrect typing for operations that return promises TODO SB-187
                store.dispatch(publicInfoOperations.fetchPublicReportsFromContainer()).then(() => {
                    console.log("we fetched those public reports this time");
                    const reportData = store.getState().publicInfo.reports.find((report) => "" + report.id === notificationData.id);
                    if (reportData) {
                        const toast: ReportToast = {
                            report: reportData,
                            report_type: "Public"
                        };
                        store.dispatch(notificationActions.openReportNotification(toast));
                    }
                });
                break;
        }
    }
};

export const handleForegroundBulletinNotif = (notificationData: BulletinNotificationData) => {
    if (notificationData) {
        switch (notificationData.type) {
            case "Bulletin":
                const toast: BulletinToast = {
                    title: notificationData.title,
                    //@ts-ignore TS IGNORING error after schema changes .text to .body but works fine
                    text: notificationData.body
                };
                store.dispatch(notificationActions.openBulletinNotification(toast))
                break;
        }
    }
};

export { messaging };